import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const VerticalChart = (props) => {
	return <Bar options={
		{
			responsive: true,
			plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				text: props.title,
			},
			},
		}
	} data={props.data} />;
}

export default VerticalChart