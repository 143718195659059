import React,{Component, Fragment} from 'react';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChessBishop, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import ReputationData from './ReputationData';

class ProfilePreview extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  
  render() {
	const avatar = this.props.avatar ? this.props.avatar : null
	const user = this.props.user ? this.props.user : null
	const style = this.props.style ? this.props.style : {}
	let user_print = user
	const is_system = this.props.system ? this.props.system : false
	const time = this.props.time ? this.props.time : ''
	if (this.props.to) {
		user_print = <Link className='post-recipient' to={this.props.to}>{user}</Link>
	}

	const avatar_print = this.props.to ? 
	<Link to={this.props.to}>
		<figure className="avatar me-3"><img src={`${avatar}`} alt="avatar" className="shadow-sm rounded-circle w45" /></figure>
	</Link> :
	<figure className="avatar me-3"><img src={`${avatar}`} alt="avatar" className="shadow-sm rounded-circle w45" /></figure>

    return (
      <div className="profile-preview" style={style}>
		{
			(!is_system && this.props.member) &&
			<div className='info-box'>
				<ReputationData className="reputation-data-mini" member={this.props.member ? this.props.member : null} />
			</div>
		}
		
		{
			(avatar && avatar !== null) &&
			avatar_print
		}
			<h4 className="fw-600 text-grey-900 font-xsss mt-1">{this.props.recipient && <FontAwesomeIcon icon={faCaretRight} />} {user_print} 
				<span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {!is_system ? time : this.props.system_type ? this.props.system_type : i18n.t('σύστημα')}</span>
			</h4>
		
          
      </div>
    );
  }
}

export default ProfilePreview;