import React, { Component , Fragment } from "react";
import ProfilePreview from "../components/ProfilePreview";
import storage from "../storage";
import PropertyCard from "../components/PropertyCard";
import RequestCard from "../components/RequestCard";
import i18n from "../i18n";
import request from "../request";
import history from '../helpers/history';
import Swal from "sweetalert2";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import ModalGeneric from "../components/ModalGeneric";
import Rightchat from "../components/Rightchat";
import Popupchat from "../components/Popupchat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faPlus } from "@fortawesome/free-solid-svg-icons";
import Milestone from "../components/Milestone";
import SortableList from "../components/SortableList";
import CooperationChatWrapper from "../components/CooperationChat/CooperationChatWrapper";
import CooperationHistory from "../components/CooperationHistory";
import notificationsHelper from "../notifications-helper";
import socket from "../socket";

class CooperationDetails extends Component {
	constructor(props) {
		super(props)

		this.state = {
			cooperation: {},
			rating_text: '',
			stars: 0,
			hover_stars: 0,
			showRateModal: false,
			milestones: [],
			active_tab: 'information',
			new_milestone: null
		}

		this.fetchCooperation = this.fetchCooperation.bind(this)
	}

	componentDidMount() {
		this.unmountSocket = socket.onCooperationChat((data) => {
			if (data.cooperation == this.state.cooperation.cooperations_id) {
				let unread_messages = 0
				if (data.sender != storage.me.realtors_id) {
					unread_messages = parseInt(this.state.cooperation.unread_messages) + 1 
				}
				this.setState({
					cooperation: {
						...this.state.cooperation,
						unread_messages: unread_messages
					}
				})
			}
		})
		this.unmountLocale = i18n.onChangeLocale(() => {
			this.forceUpdate();
		});
		this.fetchCooperation()
	}

	componentWillUnmount() {
		this.unmountLocale()
	}

	fetchCooperation() {
		request.get(`collab/id/${this.props.match.params.id}`).then(response => {
			if (response.status === 200) {
				notificationsHelper.fetchCooperationsCount()
				this.setState({
					cooperation: response.data
				}, () => {
					this.getMilestones()
				})
			}
			else {
				history.push('/collabs/active')
			}
		})
	}

	getMilestones() {
		request.get(`milestones/${this.props.match.params.id}`).then(response => {
			this.setState({
				milestones: response.data.map(ms => {
					return {
						...ms,
						checked: ms.milestone_status === "1" ? true : false
					}
				}),
				new_milestone: null
			})
		})
	}

	closeCollaboration() {
		request.patch(`collabs/close/${this.state.cooperation.cooperations_id}`).then((response) => {
			this.fetchCooperation()
		});
	  }

	cooperationRequest(data){
		this.setState({
		  cooperation: {
			...this.state.cooperation,
			collab: data.collab,
			spam_collab: data.spam_collab
		  }
		})
		  
	}
	
	cancelCooperationRequest(data){
		this.setState({
			cooperation: {
			  ...this.state.cooperation,
			  collab: data.collab,
			  spam_collab: data.spam_collab
			}
		})
	}

	rateCooperation() {
		request.put(`collabs/rate/${this.state.cooperation.cooperations_id}`, {
			rating: this.state.stars,
			message: this.state.rating_text
		}).then(response => {
			Swal.fire({
				title: i18n.t('cooperation_rate_sendeed'),
				text: i18n.t('thank_you'),
				icon: 'success'
			})
			this.setState({
				showRateModal: false
			})
		})
	}
	
	respond(id, answer) {
		request.patch(`respond_collab/${id}`, {
			status: answer
		}).then((response) => {
			if (response.data.attempt_status === 200) {
				if (answer === 1) {
					// socket.send({
					// 	type: 'event',
					// 	data: {
					// 		class: 'friend-accepted',
					// 		realtor: senderId,
					// 		me: `${storage.me.realtors_fname} ${storage.me.realtors_lname}`,
					// 	},
	
					// })
					Swal.fire({
						title: i18n.t('cooperation_accepted'),
						text: i18n.t('cooperation_moved_to_active'),
						icon: 'success'
					})
				} else if (answer === 2) {
					Swal.fire({
						title: i18n.t('cooperation_rejected'),
						text: i18n.t('cooperation_moved_to_rejected'),
						icon: 'success'
					})
				}
				this.fetchCooperation()
				// this.removeCollab(id)
			}
		})
	}
	
	cancelRequest(id) {
		request.delete(`collab/id/${id}`).then((response) => {
			if (response.data.attempt_status === 200) {
				// this.removeCollab(id)
			}
		});
	}

	openRatingCooperation() {
		this.setState({
			rating_text: '',
			stars: 0,
			hover_stars: 0,
			showRateModal: true
		})
	}
	
	setStars(stars) {
		this.setState({
			stars: stars
		})
	}
	
	setHoverStars(stars) {
		this.setState({
			hover_stars: stars
		})
	}

	setNewMilestone(key, value, submit = false) {
		this.setState({
			new_milestone: {
				...this.state.new_milestone,
				[key]: value
			}
		}, () => {
			if (submit) {
				this.submitMilestone()
			}
		})
	}

	submitMilestone() {
		const ms = this.state.new_milestone
		if (ms && ms.milestone_description && typeof ms.milestone_description !== 'undefined') {
			request.post('milestones', {
				cooperation_id: this.state.cooperation.cooperations_id,
				description: ms.milestone_description
			}).then(response => {
				this.getMilestones()
			})
		}
		else {
			this.setState({
				new_milestone: null
			})
		}
	}

	onMilestoneChange(key, value, id) {
		let data = {}
		if (key === 'checked') {
			data = {
				milestone_status: value ? 1 : 0
			}
		}
		else {
			data = {
				milestone_description: value
			}
		}
		request.patch(`milestones/${id}`, data).then(response => {
			socket.send({
				type: 'event',
				data: {
				  class: 'cooperation-updated',
				  realtor: this.state.cooperation.requester === "1" ? this.state.cooperation.holder_id : this.state.cooperation.requester_id,
				  cooperationId: this.state.cooperation.cooperations_id,
				  me: `${storage.me.realtors_fname} ${storage.me.realtors_lname}`,
				},
			});
			this.getMilestones()
		})
	}

	handleMilestoneOrder(ids) {
		request.post(`milestones/order/${this.state.cooperation.cooperations_id}`, {
			ids
		})
	}

	deleteMilestone(id) {
		request.delete(`milestones/${id}`).then(response => {
			this.getMilestones()
		})
	}

	render() {
		let title = i18n.t("Αίτηση συνεργασίας")
		if (this.state.cooperation.cooperations_status === "1") {
			title = i18n.t("Ενεργή συνεργασία")
			if (this.state.cooperation.closed === "1") {
				title = i18n.t("Κλειστή συνεργασία")
			}
			
		} else if (this.state.cooperation.cooperations_status === "2") {
			title = i18n.t("Απορριφθήσα συνεργασία")
		}

		let partner;
		let actions;
		if (this.state.cooperation.requester === "0") {
			partner = <ProfilePreview 
				user={`${this.state.cooperation.requester_fname} ${this.state.cooperation.requester_lname}`}
				to={`/members/${this.state.cooperation.requester_id}`}
				member={{
					reputation: this.state.cooperation.requester_rating
				}}
			/>
			if (this.state.cooperation.closed === "0") {
				if (this.state.cooperation.cooperations_status === "2") {
					actions = <>
						<button onClick={() => this.cancelRequest(this.state.cooperation.cooperations_id)} className="bg-danger btn-action btn-leave p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Διαγραφή αιτήματος')}</button>
					</>
				} else if (this.state.cooperation.cooperations_status === "0") {
					actions = <>
						<button onClick={() => this.respond(this.state.cooperation.cooperations_id, 1)} className="bg-success btn-action btn-accept p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Αποδοχή')}</button>
						<button onClick={() => this.respond(this.state.cooperation.cooperations_id, 2)} className="bg-danger btn-action btn-leave ms-2 p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Απόρριψη')}</button>
					</>
				} else if (this.state.cooperation.cooperations_status === "1") {
					actions = <>
						{/* <button onClick={() => this.openRatingCooperation(this.state.cooperation.cooperations_id)} className="bg-primary btn-action btn-accept p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Αξιολόγηση συνεργασίας')}</button> */}
						<button onClick={() => this.closeCollaboration(this.state.cooperation.cooperations_id)} className="bg-success btn-action btn-accept p-2 ms-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Κλείσιμο συνεργασίας')}</button>
					</>
				} 
			}
			else {
				actions = <>
					<button onClick={() => this.openRatingCooperation(this.state.cooperation.cooperations_id)} className="bg-primary btn-action btn-accept p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Αξιολόγηση συνεργασίας')}</button>
					<button onClick={() => this.cancelRequest(this.state.cooperation.cooperations_id)} className="bg-danger btn-action btn-leave p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Απόκρυψη συνεργασίας')}</button>
				</>
			}
			
		} else {
			if (this.state.cooperation.closed === "0") {
				if (this.state.cooperation.cooperations_status === "0") {
					
					actions = <>
						<button onClick={() => this.cancelRequest(this.state.cooperation.cooperations_id)} className="bg-danger btn-action btn-leave p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Ακύρωση αιτήματος')}</button>
					</>	
				}
				else if (this.state.cooperation.cooperations_status === "1") {
					actions = <>
						{/* <button onClick={() => this.openRatingCooperation(this.state.cooperation.cooperations_id)} className="bg-primary btn-action btn-accept p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Αξιολόγηση συνεργασίας')}</button> */}
						<button onClick={() => this.closeCollaboration(this.state.cooperation.cooperations_id)} className="bg-success btn-action btn-accept p-2 ms-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Κλείσιμο συνεργασίας')}</button>
					</>
				} 
				else if (this.state.cooperation.cooperations_status === "2") {
					actions = <>
						<button onClick={() => this.cancelRequest(this.state.cooperation.cooperations_id)} className="bg-danger btn-action btn-leave p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Διαγραφή αιτήματος')}</button>
					</>
				}
			}
			else {
				actions = <>
					<button onClick={() => this.openRatingCooperation(this.state.cooperation.cooperations_id)} className="bg-primary btn-action btn-accept p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Αξιολόγηση συνεργασίας')}</button>
					<button onClick={() => this.cancelRequest(this.state.cooperation.cooperations_id)} className="bg-danger btn-action btn-leave p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Απόκρυψη συνεργασίας')}</button>
				</>
			}
			partner = <ProfilePreview 
				user={`${this.state.cooperation.holder_fname} ${this.state.cooperation.holder_lname}`}
				to={`/members/${this.state.cooperation.holder_id}`}
				member={{
					reputation: this.state.cooperation.holder_rating
				}}
			/>
			
			
		}
		let item_view;
		if (this.state.cooperation.cooperations_type === '1' && this.state.cooperation.property) {
			item_view = <PropertyCard 
							key={this.state.cooperation.cooperations_id} 
							data={this.state.cooperation.property}
							horizontal={true}
						/>
		} else if (this.state.cooperation.cooperations_type === '2' && this.state.cooperation.request) {
			item_view = <RequestCard key={this.state.cooperation.cooperations_id} 
							request={this.state.cooperation.request}
							cooperationRequest={(e) => this.cooperationRequest(e)}
							cancelCooperationRequest={(e) => this.cancelCooperationRequest(e)}
						/>
		}

		const type = this.state.cooperation.cooperations_type == 1 ? i18n.t("για το ακίνητο") :  i18n.t("για την ζήτηση")
		const code = `${i18n.t("με κωδικό")} #${this.state.cooperation.cooperations_related_id}`
		return (
			<Fragment>
			<Header />
			<Leftnav />

			<div className="main-content PropertyDetails right-chat-active cooperations-details-wrapper">
				<div className="tabs tabs-size-xl theme-dark-bg">
					<span onClick={() => {this.setState({active_tab: 'information'})}} className={`tab ${this.state.active_tab === 'information' ? 'active-tab' : ''}`}>{i18n.t('information')}</span>
					<span onClick={() => {this.setState({active_tab: 'milestones'})}} className={`tab ${this.state.active_tab === 'milestones' ? 'active-tab' : ''}`}>Milestones</span>
					<span onClick={() => {this.setState({active_tab: 'chat', cooperation: {...this.state.cooperation, unread_messages: 0}})}} className={`tab ${this.state.active_tab === 'chat' ? 'active-tab' : ''}`}>
						{i18n.t('cooperation_chat')}
						{
							this.state.cooperation && parseInt(this.state.cooperation.unread_messages) > 0 &&
							<b className="messages-counter">{this.state.cooperation.unread_messages}</b>
						}
					</span>
				</div>
				{
					this.state.active_tab === 'information' &&
					<div className={`col-12 co-op-wrapper coop-dark-bg ${this.state.cooperation.cooperations_status === "2" ? 'rejected-coop' : ''}`}>
						<div className='co-op-header'>
							<h1 className='co-op-title'>
								{`${title} ${type} ${code}`}
							</h1>
							<div className="co-op-actions">
								{actions}
							</div>
						</div>
						<div className="co-op-member">
							<h4 className="fw-600 text-grey-900 font-xsss mt-1">{i18n.t("Συνεργάτης")}:</h4>&nbsp;&nbsp; {partner}
							
							
						</div>
						
						<div className="co-op-item-view">
							{item_view}
						</div>
						<hr/>
						<h1>{i18n.t('cooperation_history')}</h1>
						<CooperationHistory history={this.state.cooperation.history} />
					</div>
				}
				{
					this.state.active_tab === 'milestones' &&
					<div className="col-12 milestones-wrapper">
						<div className="row">
							<div className="col-lg-4 col-6 pe-2 ps-2 font-xsss ms-sm-1">
							<button 
								onClick={e => {
									this.setState({
										new_milestone: {
											checked: false,
											milestone_description: ''
										}
									})
								}}
								className={`border-none fs-17 cursor-pointer d-inline-block ps-3 pe-3 lh-32 my-4 rounded-xl ls-2 text-white me-1 bg-primary`}
							>
								{i18n.t('create_new_milestone')}
							</button>
							</div>
						</div>
						{
							this.state.milestones && this.state.milestones.length ?
							<div className="milestone-progress-wrapper">
								<div className="milestone-progress-elem">
									<span className="milestone-progress-active" style={{width: `${(this.state.milestones.filter(ms => ms.checked).length / this.state.milestones.length) * 100}%`}}>
										<b className="milestone-progress-text">{parseInt((this.state.milestones.filter(ms => ms.checked).length / this.state.milestones.length) * 100)}%</b>
									</span>
								</div>
								{/* <progress className="milestone-progress" value={this.state.milestones.filter(ms => ms.checked).length / this.state.milestones.length}/> */}
								</div> : ''
						}
						{
							this.state.new_milestone &&
							<Milestone 
								className='new-milestone'
								onCheck={e => {this.setNewMilestone('checked', e)}}
								onDescriptionChange={e => {this.setNewMilestone('milestone_description', e, true)}}
								onDelete={e => {this.setState({new_milestone: null})}}
							/>
						}
						<div>
						<SortableList 
							items={
								this.state.milestones.map((ms, index) => {
									return {
										id: ms.id,
										item: <Milestone 
											key={index}
											{
												...ms
											}
											onCheck={e => {this.onMilestoneChange('checked', e, ms.id)}}
											onDescriptionChange={e => {this.onMilestoneChange('milestone_description', e, ms.id)}}
											onDelete={e => {this.deleteMilestone(ms.id)}}
										/>
									}
								})
							}
							onChange={(ids) => this.handleMilestoneOrder(ids)}
							strategy='vertical'
							sortByProps={true}
						/>
						</div>
					</div>
				}
				{
					this.state.active_tab === 'chat' &&
					<div className="col-12 chat-wrapper">
						<CooperationChatWrapper 
							realtor={
								this.state.cooperation.requester === "1" ? {
									id: this.state.cooperation.holder_id,
									name: `${this.state.cooperation.holder_fname} ${this.state.cooperation.holder_lname}`,
									profile_image: this.state.cooperation.holder_profile_picture,
								} : {
									id: this.state.cooperation.requester_id,
									name: `${this.state.cooperation.requester_fname} ${this.state.cooperation.requester_lname}`,
									profile_image: this.state.cooperation.requester_profile_picture,
								}
							}
							cooperation={this.state.cooperation}
							isFull={true}

						/>
					</div>
				}
			</div>
			<ModalGeneric
				show={this.state.showRateModal}
				handleClose={() => this.setState({
					...this.state,
					showRateModal: false
				})}
				header={<h4>{i18n.t('cooperation_rating')}</h4>}
				footer={<div className="card-body p-0 d-flex space-between open-house-footer align-center">
							<div className="d-flex">
								<button onClick={() => this.rateCooperation()} className="bg-success btn-action btn-accept p-2 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-600">{i18n.t('send')}</button>
								<button onClick={() => this.setState({
											showRateModal: false
										})}
									className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">
										{i18n.t('button_cancel_request')}
								</button>
							</div>
						</div>}
			>
				<div className="card w-100 border-0 p-4 mb-3">
					<div className="card-body mt-2 p-0">
						
						<form>
						<div className="row mt-4">
								<div className="col-md-12">
									<div className="form-group p-relative">
										<label className="mb-2">{i18n.t('rate_the_cooperation_with_realtor')}</label>
										<div className="stars">
											<span className={`star star-1 ${this.state.hover_stars >= 1 || this.state.stars >= 1  ? 'active' : ''}`} onMouseLeave={() => this.setHoverStars(0)} onMouseEnter={() => this.setHoverStars(1)} onClick={() => this.setStars(1)}><FontAwesomeIcon icon={faStar} /></span>
											<span className={`star star-2 ${this.state.hover_stars >= 2 || this.state.stars >= 2  ? 'active' : ''}`} onMouseLeave={() => this.setHoverStars(0)} onMouseEnter={() => this.setHoverStars(2)} onClick={() => this.setStars(2)}><FontAwesomeIcon icon={faStar} /></span>
											<span className={`star star-3 ${this.state.hover_stars >= 3 || this.state.stars >= 3  ? 'active' : ''}`} onMouseLeave={() => this.setHoverStars(0)} onMouseEnter={() => this.setHoverStars(3)} onClick={() => this.setStars(3)}><FontAwesomeIcon icon={faStar} /></span>
											<span className={`star star-4 ${this.state.hover_stars >= 4 || this.state.stars >= 4  ? 'active' : ''}`} onMouseLeave={() => this.setHoverStars(0)} onMouseEnter={() => this.setHoverStars(4)} onClick={() => this.setStars(4)}><FontAwesomeIcon icon={faStar} /></span>
											<span className={`star star-5 ${this.state.hover_stars >= 5 || this.state.stars >= 5  ? 'active' : ''}`} onMouseLeave={() => this.setHoverStars(0)} onMouseEnter={() => this.setHoverStars(5)} onClick={() => this.setStars(5)}><FontAwesomeIcon icon={faStar} /></span>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-md-12">
									<div className="form-group">
										<label className="mb-2">{i18n.t('rating_message')}</label>
										<textarea rows="6" value={this.state.rating_text} onChange={(e) => this.setState({...this.state, rating_text: e.target.value})} className="form-control mh-125" placeholder={i18n.t('describe_your_reason_of_request_in_a_few_words')}/>
										<p className="error_message d-none">*Υποχρεωτικό πεδίο</p>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</ModalGeneric>
			<Rightchat />
			<Popupchat />
			<Appfooter /> 
		</Fragment>
		)
	}
}

export default CooperationDetails