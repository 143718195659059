import React, { PureComponent } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import i18n from "../i18n";
import request from "../request";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ImageUploadDialog extends PureComponent {
  fileInput = React.createRef();
  state = {
    src: null,
    crop: {
      unit: "%",
      width: 30,
      aspect: this.props.aspect ? null : 1/1,
    }
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        
        this.fileInput.current.value = ''
        this.setState({ src: reader.result })

      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onClose(){
    this.props.handleClickImageDialogClose();
    this.setState({
        src: null,
        croppedImageUrl: null,
        crop: {
					unit: "%",
					width: this.getWidth(this.props.imageType),
					aspect: this.getAspect(this.props.imageType), 
        }
    });
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    console.log("crop is", crop)
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ratio = (scaleX /scaleY);
    // const crop_scaleX = crop.width / image.width;
    // const crop_scaleY = crop.height / image.height;
    canvas.width = Math.ceil(crop.width*scaleX);
    canvas.height = Math.ceil(crop.height*scaleY);
    console.log({
      naturalWidth: image.naturalWidth,
      naturalHeight: image.naturalHeight,
      scaleX: scaleX * ratio,
      scaleY: scaleY * ratio,
      crop_width: crop.width,
      crop_height: crop.height,
      image_width: image.width,
      image_height: image.height,
      sx: crop.x * scaleX,
      sy: crop.y * scaleY,
      swidth: crop.width * scaleX,
      sheight: crop.height * scaleY,
    })
    const ctx = canvas.getContext("2d");
    

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return new Promise((resolve, reject) => {
			resolve(canvas.toDataURL());
    });
  }

  getCroppedImgNew(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width*scaleX);
    canvas.height = Math.ceil(crop.height*scaleY);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width*scaleX,
      crop.height*scaleY,
    );
    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');
    // As a blob
    return new Promise((resolve, reject) => {
			resolve(canvas.toDataURL());
    });
    // return new Promise((resolve, reject) => {
    //   canvas.toBlob(blob => {
    //     // blob.name = fileName;
    //     resolve(blob);
    //   }, 'image/jpeg',1);
    // });
  }

  uploadImage = (e) => {
    if (!this.props.async) {
      request.put(`image`,{
        image: this.state.croppedImageUrl,
        type: e,
        network: (this.props.selectedNetwork ? this.props.selectedNetwork : null),
      }).then((response) => {
        if(this.props.imageType === 'profile_cover' || this.props.imageType === 'network_cover'){
          this.props.changeCoverImage(response.data.image)
        }
        if(this.props.imageType === 'profile_image'){
          this.props.changeProfileImage(response.data.image)
        }
        if(this.props.imageType === 'network_image'){
          this.props.changeNetworkImage(response.data.image)
        }
        this.onClose();
      });
    }
    else {
      this.props.changeProfileImage(this.state.croppedImageUrl, this.props.stateIndex).then(response => {
        this.onClose()
      })
      
    }
    
  };

  componentDidUpdate(prevProps, prevState){
    
    if(prevProps.imageType !== this.props.imageType){
			this.setAspect(this.props.imageType);
    }
  }

  setAspect(type){
    this.setState({ 
      ...this.state,
      crop: {
        ...this.state.crop,
        aspect: this.getAspect(type),
        width: this.getWidth(type),
      },
    });
  }

  getAspect(type) {
    const aspect = this.props.aspect ? undefined : ( (type !== 'profile_cover' && type !== 'network_cover') ? (1/1) : (16/6) )
    return aspect;
  }

  getWidth(type) {
    const aspect = ( type !== 'profile_cover' && type !== 'network_cover' ? 30 : 1200 )
    return aspect;
  }

  render() {
    
    const { crop, croppedImageUrl, src } = this.state;
		var dialogTitle = '';
		if(this.props.imageType === 'profile_image') {
		 	dialogTitle = i18n.t('update_profile_image');
		} 
		else if (this.props.imageType === 'profile_cover' || this.props.imageType === 'network_cover') {
		 	dialogTitle = i18n.t('update_cover_image');
		}
		else if(this.props.imageType === 'network_image'){
		 	dialogTitle = i18n.t('update_network_image');
		}

    return (
      <div>
        <Dialog
          open={this.props.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.TransitionComponenthandleClose}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <input
              type="file"
              ref={this.fileInput}
              style={{ display: "none" }}
              onChange={this.onSelectFile}
              multiple
            />
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
            {croppedImageUrl && (
              <img
                alt="Crop"
                style={{ maxWidth: "100%" }}
                src={croppedImageUrl}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onClose()} color="primary">
              {i18n.t('close')}
            </Button>
            <Button onClick={() => this.fileInput.current.click()}>
              {src === null ? i18n.t('upload_image') : i18n.t('change_image')}
            </Button>
            {src !== null ? (
              <Button onClick={() => this.uploadImage(this.props.imageType)}>
                {i18n.t('save')}
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ImageUploadDialog;