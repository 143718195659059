import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import i18n from "../i18n";
import request from '../request';
import Pagetitle from "../components/Pagetitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faLock, faEye } from "@fortawesome/free-solid-svg-icons";
import ImageUploadDialog from "../components/ImageUploadDialog";
import Swal from "sweetalert2";
import history from '../helpers/history';
import LoadingEvent from "../components/LoadingEvent";
import config from "../config";

class NetworkForm extends Component {
  constructor(props) {
    super(props);

	this.state = {
		title: '',
		description: '',
		status: 0,
		cover_image: null,
		type: 'public',
		logo: null,
		step: 1,
		imageOpen: false,
		imageType: '',
		imageIndex: null,
		form_submited: false,
		is_edit: false
	}
  }

  componentDidMount() {
	const id = this.props.match.params.id

	if (id) {
		request.get(`network_for_edit/${id}`).then(response => {
		  this.setState({
			...response.data,
			cover_image: response.data.cover_image,
			logo: response.data.logo,
			is_edit: true
		  })
		})
	  }
    this.localeTrigger = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
	this.localeTrigger()
  }

  handleClickImageDialogClose() {
	this.setState({
		...this.state,
		imageOpen: false
	})
  }

  getErrors(errors) {
	let str = ''
	errors.map(e => {
		str += `<br> ${i18n.t(e)}`
	})

	return str
  }

  storeNetwork() {
	this.setState({
		...this.state,
		form_submited: true
	}, () => {
		request.post('networks/store', {
			title: this.state.title,
			description: this.state.description,
			type: this.state.type === 'public' ? 1 : 2,
			cover_image: this.state.cover_image,
			logo: this.state.logo
		}).then(response => {
			if (response.data.status == '200') {
				Swal.fire({
					title: i18n.t('success'),
					text: i18n.t('network_created'),
					confirmButtonText: i18n.t('alright'),
					icon: 'success'
				}).then(() => {
					history.push(`/network/${response.data.network_id}`)
				})
			}
			else {
				Swal.fire({
					title: i18n.t('fail'),
					// html: this.getErrors(Object.values(response.data)),
					confirmButtonText: i18n.t('i_understand'),
				})
			}

			this.setState({
				...this.state,
				form_submited: false
			})
		})
	})
  }

  updateNetwork() {
	this.setState({
		...this.state,
		form_submited: true
	}, () => {
		request.post(`networks/update/${this.state.id}`, {
			title: this.state.title,
			description: this.state.description,
			type: this.state.type === 'public' ? 1 : 2,
			cover_image: this.state.cover_image,
			logo: this.state.logo,
			id: this.state.id
		}).then(response => {
			if (response.data.status == '200') {
				Swal.fire({
					title: i18n.t('success'),
					text: i18n.t('network_updated'),
					confirmButtonText: i18n.t('alright'),
					icon: 'success'
				})
			}
			else {
				Swal.fire({
					title: i18n.t('fail'),
					// html: this.getErrors(Object.values(response.data)),
					confirmButtonText: i18n.t('i_understand'),
				})
			}

			this.setState({
				...this.state,
				form_submited: false
			})
		})
	})
  }

  handleOptionChange(changeEvent) {
	this.setState({
		...this.state,
	  type: changeEvent.target.value
	});
  }

  async changeImage(e, index) {
	return new Promise((resolve, reject) => {
		this.setState({
			...this.state,
			[index]: e
		}, () => resolve(true))
	})
  }

  render() {
	
    return (
      <Fragment> 
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content">
			<LoadingEvent show={this.state.form_submited}/>
			<div className="middle-sidebar-bottom">
				<div className="middle-sidebar-left">
					<div className="row">
						<div className="col-xl-11 col-xxl-11 col-lg-11 col-md-12">
							<Pagetitle
								title={i18n.t("create_new_network")}
								noSearch={true}
							/>
						</div>
						<ImageUploadDialog 
							handleClickImageDialogClose={()=>this.handleClickImageDialogClose()}
							open={this.state.imageOpen} 
							imageType={this.state.imageType}
							changeProfileImage={(e, index) => this.changeImage(e, index)}
							async={true}
							stateIndex={this.state.imageIndex}
						/>
						
						<div className="col-xl-11 col-xxl-11 col-lg-11 col-md-12">
							<div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 p-3">
								<div className="slide-track">
									<h3 className="form-header">{i18n.t('basic_info')}</h3>
									<form>
										<div className="row">
											<div className="col-md-4">
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2">{i18n.t('network_title')}</label>
													<input type="text" value={this.state.title} onChange={(e) => this.setState({...this.state, title: e.target.value})} className="form-control" placeholder={i18n.t('what_is_your_network_title')}/>
													<p className="error_message d-none">*Υποχρεωτικό πεδίο</p>
												</div>
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2 py-2">{i18n.t('network_type')}</label>
													<div className="radio-group">
														<div className="radio">
															<label className="check-label d-block">
																<input type="radio" value="public" 
																			checked={this.state.type === 'public'} 
																			onChange={(e) => this.handleOptionChange(e)} />
																<span className="radio-choice">{i18n.t('public_network')} <FontAwesomeIcon icon={faEye} /></span>
															</label>
														</div>
																
														
														<div className="radio">
															<label className="check-label d-block">
																<input type="radio" value="private" 
																			checked={this.state.type === 'private'} 
																			onChange={(e) => this.handleOptionChange(e)} />
																<span className="radio-choice">{i18n.t('private_network')} <FontAwesomeIcon icon={faLock} /></span>
															</label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-8">
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2">{i18n.t('network_description')}</label>
													<textarea rows="6" value={this.state.description} onChange={(e) => this.setState({...this.state, description: e.target.value})} className="form-control mh-125" placeholder={i18n.t('describe_your_network_in_a_few_words')}/>
													<p className="error_message d-none">*Υποχρεωτικό πεδίο</p>
												</div>
											</div>
										</div>
									</form>
									<h3 className="form-header">{i18n.t('media_info')}</h3>
									<form>
										<div className="row">
											<div className="col-md-6">
												<div className="image-wrapper text-center shadow-xs">
												{
												this.state.logo ?
													<img src={this.state.logo} /> :
													<div className="skeleton-image"></div>
												}
												</div> 
												<button 
													type="button"
													className="btn btn-secondary bg-secondary text-white"
													onClick={() => this.setState({
														...this.state,
														imageOpen: true,
														imageType: 'network_logo',
														imageIndex: 'logo'
													})}
												>
													Upload logo
												</button>
											</div>
											<div className="col-md-6">
												<div className="image-wrapper text-center shadow-xs">
												{
												this.state.cover_image ?
													<img src={this.state.cover_image} /> :
													<div className="skeleton-cover-image"></div>
												}
												</div> 
												<button 
													type="button"
													className="btn btn-secondary bg-secondary text-white"
													onClick={() => this.setState({
														...this.state,
														imageOpen: true,
														imageType: 'profile_cover',
														imageIndex: 'cover_image'
													})}
												>
													Upload cover
												</button>
											</div>
										</div>
										<div className="row mt-4">
											<div className="col-md-12">
												<button type="button" onClick={() => {
													if (this.state.is_edit) {
														this.updateNetwork()
													}
													else {
														this.storeNetwork()
													}
												}} className="btn btn-primary bg-primary text-white float-right">{i18n.t('continue')} <FontAwesomeIcon icon={faAngleRight} /></button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>

        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default NetworkForm;