import React, { Component , Fragment, } from "react";

import Header from '../components/Header';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

import Createpost from '../components/Createpost';
import validator from 'validator'
// import Memberslider from '../components/Memberslider';
// import Friendsilder from '../components/Friendsilder';
// import Storyslider from '../components/Storyslider';
import Postview from '../components/Postview';
import Load from '../components/Load';
import Utils from "../utils"
import i18n from "../i18n";
import request from "../request";
import storage from '../storage';
import FriendRequestsList from "../components/FriendRequestsList";
import SpecialPostView from "../components/SpecialPostView";
import Pagetitle from "../components/Pagetitle";
import Swal from "sweetalert2";
import history from '../helpers/history';
// import Friends from "./Friends";

class Home extends Component {
    constructor(props) {
      super(props);

      this.state = {
        form: {
			title: '',
			description: ''
		}
      };
    }

    componentDidMount() {
     
      this.unmountLocale = i18n.onChangeLocale(() => {
        this.forceUpdate();
      });

    }

    send = () => {
		const form = {...this.state.form}

		if (form.title == '' || form.description == '') {
			Swal.fire({
				title: i18n.t('fill_all_fields'),
				icon: 'error'
			})
			return;
		}

		request.post('report_a_bug', {
			title: form.title, 
			description: form.description
		}).then(rsp => {
			if (rsp.status === 200) {
				Swal.fire({
					title: i18n.t('success'),
					text: i18n.t('thank_you_for_the_report'),
					icon: 'success'
				}).then(() => {
					history.push('/')
				})
			}
		})
	}

    componentWillUnmount() {
      i18n.clearOnChangeLocale();
    }

    render() {
        return (
            <Fragment> 
                <Header />
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row feed-body">
								<div className="col-xl-12 col-xxl-12 col-lg-12">
									<Pagetitle 
										title={i18n.t('report_a_bug')}
									/>
								</div>
								<div className="col-12">
									<div className="row">
										<div className="col-lg-12 mb-3">
											<div className="form-group with-label mb-1">
												<label className="mont-font fw-600 font-xssss">{i18n.t('bug_title')}</label>
												<input 
													type="text" 
													name="title"
													id="title"
													value={this.state.form.title}
													className="style2-input form-control text-grey-900 font-xss ls-3"
													onChange={(e) => 
														this.setState({form: {...this.state.form, title: e.target.value}})
													} 
												/>
											</div>        
										</div>
									</div>
									<div className="row">
										<div className="col-lg-12 mb-3">
											<div className="form-group with-label mb-1">
												<label className="mont-font fw-600 font-xssss">{i18n.t('bug_description')}</label>
												<textarea 
													name="description"
													id="description"
													value={this.state.form.description}
													className="style2-input min-h-200 form-control text-grey-900 font-xss ls-3"
													rows="10"
													onChange={(e) => 
														this.setState({form: {...this.state.form, description: e.target.value}})
													} 
												/>
											</div>        
										</div>
									</div>
									<div className="row">
										{/* SUBMIT BUTTON */}
										<div className="col-lg-12 mb-0">
											<button onClick={this.send} className="border-none bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">{i18n.t('send')}</button>
										</div>
									</div>      
								</div>
                            </div>
                        </div>
                    </div>
                  
                </div>
                
                <Rightchat />
                <Popupchat />
                <Appfooter /> 
            </Fragment>
        );
    }
}

export default Home;