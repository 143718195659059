import React, { useEffect, useState, Fragment } from "react";
import storage from "../storage";
import request from "../request";
import i18n from "../i18n";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const NotificationsSettings = props => {
	const [currentNotifications, setCurrentNotifications] = useState([])
	const [, updateState] = React.useState();
 	const forceUpdate = React.useCallback(() => updateState({}), []);

	useEffect(() => {
		const localeUnmount = i18n.onChangeLocale(() => {
			forceUpdate()
		})
		request.get('whoami').then(response => {
			if (response.status === 200) {
				if (response.data.notifactions_prefs) {
					setCurrentNotifications(response.data.notifactions_prefs)
				}
			}
		})

		return localeUnmount
	}, [])

	const handleOptionChange = (id, e) => {
		setCurrentNotifications(currentNotifications.map(n => {
			if (id === n.id) {
				return {
					...n,
					accepted_notification: parseInt(e.target.value)
				}
			}
			return n
		}))
		console.log(e)
	}

	const savePreferences = () => {
		request.post(`profile/save_notification_preferences`, {
			prefs: currentNotifications.map(n => {
				return {
					id: n.id,
					accepted_notification: n.accepted_notification
				}
			})
		}).then(response => {
			if (response.status === 200) {
				Swal.fire({
					title: i18n.t('success'),
					text: i18n.t('changes_saved'),
					icon: 'success'
				})
				storage.me.notifactions_prefs = currentNotifications
			}
		})
	}

	return (
	  <Fragment> 
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="middle-wrap">
                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
				  <Pagetitle
				  	title={i18n.t('email_notifications_settings')}
				  />
                  <div className="card-body p-lg-5 p-4 w-100 border-0">
                    <div className="row">
						{
							currentNotifications.map((n, i) => {
								return (
									<div className="col-lg-12 notification-check-wrapper" key={`notification-check-${i}`}>
										<div className="notification-check">
											<div className="form-group">
													<div className="radio-group">
														<div className="radio">
															<label className="check-label d-block">
																<input type="radio" value="1" 
																			checked={parseInt(n.accepted_notification) === 1} 
																			onChange={(e) => handleOptionChange(n.id, e)} />
																<span className="radio-choice">{i18n.t('email_notifications_settings_accept')} <FontAwesomeIcon icon={faCheck} /></span>
															</label>
														</div>
																
														
														<div className="radio">
															<label className="check-label d-block">
																<input type="radio" value="0" 
																			checked={parseInt(n.accepted_notification) === 0} 
																			onChange={(e) => handleOptionChange(n.id, e)} />
																<span className="radio-choice">{i18n.t('email_notifications_settings_reject')} <FontAwesomeIcon icon={faX} /></span>
															</label>
														</div>
													</div>
												</div>
										</div>
										<div className="notification-descriptions">
											<h3>{i18n.get_data('email_type', n)}</h3>
											<span>{i18n.get_data('email_type_description', n)}</span>
										</div>
									</div>
								)
							})
						}
					  <hr/>
                      <div className="col-lg-12 mt-6">
						<div className="save-prefs-wrapper">
							<button onClick={savePreferences} className="btn btn-primary text-white mr-5 rounded-xl">{i18n.t('save_notifications_prefferences')}</button>
						</div>
					  </div>
				    </div>
				  </div>
				</div>
	    	  </div>
			</div>
		  </div>
		</div>
	  </Fragment>
	)
}

export default NotificationsSettings