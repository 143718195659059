import React, {createRef} from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import config from '../config';
class PropertyCard extends React.Component {
  constructor(props) {
    super(props);

    
    this.elementRef = createRef(null)
    this.state = {
      isMenuOpen: false,
    };
    
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  componentDidMount() {
    this.localeUnmount = i18n.onChangeLocale(() => {
      this.setState({
        ...this.state,
      });
    });

    window.addEventListener('click', this.onDocumentClick)
  }

  componentWillUnmount() {
    this.localeUnmount()
    window.removeEventListener('click', this.onDocumentClick)
  }

  onDocumentClick(e) {
    if (this.elementRef.current && !this.elementRef.current.contains(e.target) && this.state.isMenuOpen === true) {
      this.setState({
        isMenuOpen: false
      })
    }
  }

  toggleMenuOpen = () => this.setState({ isMenuOpen: !this.state.isMenuOpen });

  render() {
    const menuClass = `${this.state.isMenuOpen ? " show" : ""}`;
    return (
      <div className={`${this.props.horizontal ? 'col-lg-12 col-md-12 col-sm-12 mb-3 pe-2 ps-2 flex-lg-row' : 'col-lg-6 col-md-6 col-sm-6 mb-3 pe-2 ps-2'}`}>
        <div className={`card property-card w-100 p-0 hover-card border-0 rounded-3 me-1 ${this.props.horizontal ? 'flex-lg-row' : ''} ${this.props.system ? 'system-card' : ''}`}>
          {/* {this.props.data.featured ? <span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-primary-gradiant d-inline-block text-white position-absolute mt-3 ms-3 z-index-1">Featured</span> : ''} */}
          {
            this.props.match_icon &&
            <FontAwesomeIcon className='match-icon' icon={faPuzzlePiece} />
          }
          {this.props.network_owner ? 
          <div className="ms-auto" style={{position: 'relative'}} ref={this.elementRef}>
            <div onClick={this.toggleMenuOpen} className={`ms-auto pointer ${menuClass}`} style={{textAlign: 'right'}} id={`dropdownMenu${this.props.data.id}`} data-bs-toggle="dropdown" aria-expanded="false">
              <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
            </div>
            <div className={`dropdown-menu p-3 right-0 rounded-xxl border-0 shadow-lg ${menuClass}`} aria-labelledby={`dropdownMenu${this.props.data.id}`}>
              
              <div onClick={() => {
                if (this.props.onRemoveFromNetwork) this.props.onRemoveFromNetwork(this.props.data)
              }} className="card-body p-0 d-flex">
                <i className="feather-trash-2 text-grey-500 me-2 font-lg"></i>
                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-3 pointer">{i18n.t('button_delete')} <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500 mw-190">{i18n.t('button_delete_property_from_network')}</span></h4>
              </div>
            </div>
          </div> 
          : '' }
          <div className="w-100 mb-3">
            <Link
              to={`/properties/${this.props.data.id}`}
              className="position-relative d-block property-image-link">
              <img src={`${config.imageURL}/${this.props.data.imageUrl}`} alt="" className="w-100" />
            </Link>
          </div>
          <div className="card-body mh-180 pt-0 d-flex flex-column mw-50 p-relative">
            
            {/* <i className="feather-bookmark font-md text-grey-500 position-absolute right-0 me-3"></i> */}
            <h4 className="fw-700 font-xs mt-0 lh-18 mb-0 property-card-title">
              <Link to={`/properties/${this.props.data.id}`} className="text-dark text-grey-900">
                {i18n.get_data('title', this.props.data)}
              </Link>
            </h4>
            <h6 className="font-xsss text-grey-600 fw-600 my-2"> {`${i18n.get_data('area', this.props.data)}`}</h6>
            <h5 className="mt-3 d-inline-block font-xsss text-grey-600 me-2">
              <span className="fw-600 me-2">{this.props.data.sq} {i18n.t('sqm')}</span> 
              {this.props.data.rooms > 0 && <span className="fw-600 me-2">{this.props.data.rooms} {i18n.t('bedrooms')}</span> }
              {this.props.data.bathroom > 0 && <span className="fw-600 me-2">{this.props.data.bathroom} {i18n.t('bathrooms')}</span> }
              {this.props.data.wc > 0 && <span className="fw-600 me-2">{this.props.data.wc} wc</span> }
            </h5> 
            
            <div className="clearfix"></div>
            <span className="font-lg fw-700 mt-0 pe-3 ls-2 lh-32 d-inline-block text-black-50 float-left d-block">
              <span className="font-xs">€</span>
              {this.props.data.price}
              {
                this.props.data.action === 'rent' && <span className="font-xsssss text-grey-600">/ {i18n.t('per_month_shortcut')}</span>
              }
            </span>
            <Link to={`/properties/${this.props.data.id}`} className="position-absolute bottom-15 mb-2 right-15">
              <i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-right"></i>
            </Link>
              {
                typeof this.props.data.viewed_reason !== 'undefined' && this.props.data.viewed_reason.networks && this.props.data.viewed_reason.networks.length > 0 &&
                <div className='networks-wrapper'>
                  <span className='text-grey-600'>{i18n.t('Παρέχεται από τα δίκτυα')}:</span>
                  {  
                    this.props.data.viewed_reason.networks.map((n, index) => {
                      return (
                        <Link key={index} to={`/network/${n.networks_id}`} className='text-grey-600 network-element'>
                          {/* {
                            n.networks_logo.length > 0 &&
                            <img className='network-logo' src={n.networks_logo} />
                          } */}
                          <span>{n.networks_name}</span>
                        </Link>
                      )
                    })
                  }
                </div>
              }
            
            {
              typeof this.props.data.viewed_reason !== 'undefined' && this.props.data.viewed_reason.realtor && 
              <div className='agent-wrapper'>
                <Link to={`/members/${this.props.data.viewed_reason.realtor.id}`}>
                  <img className='agent-logo mr-5' src={this.props.data.viewed_reason.realtor.photo} /> 
                  <span className='fs-5 text-grey-600'>{this.props.data.viewed_reason.realtor.fname} {this.props.data.viewed_reason.realtor.lname}</span>
                </Link>
              </div>
            }
            </div>
        </div>
      </div>
    );
  }
}

export default PropertyCard;