import i18n from './i18n';
import request from './request';
import socket from './socket';
import wavFile from './assets/mp3/notify.mp3'
import storage from './storage';

class NotificationsHelper {
  notifications = [];
  cooperation_counter = {
    active: 0,
    incoming: 0,
  };
  onChangeFns = [];
  onChangeCoopFns = [];

  constructor() {
    this.fetchNotifications();
    this.fetchCooperationsCount();
    this.audio = new Audio(wavFile);
    

    socket.onEvent((data) => {
      if (data.type === 'event') {
        this.refresh()
        this.fetchCooperationsCount()
        
        // this.audio.play()
      }
    })
  }

  set(notifications) {
    this.notifications = notifications;
    
    this._triggerOnChangeHandlers();
  }

  push(notification) {
    this.notifications.push(notification);

    this._triggerOnChangeHandlers();
  }

  _triggerOnChangeHandlers() {
    this.onChangeFns.forEach((fn) => {
      if (typeof fn.once === 'undefined' || fn.once === 0) {
        fn();
        
        if (fn.once === 0) {
          fn.once++;
        }
      }
    });
  }
  
  onChange(fn, once = undefined) {
    fn.once = once;
    this.onChangeFns.push(fn);

    return () => { // unwatch function
      this.onChangeFns.splice(this.onChangeFns.indexOf(fn), 1);
    };
  }

  onceChange(fn) {
    this.onChange(fn, 0);
  }

  getMessage(notificationType) {
    let message;
    switch (notificationType) {
      case '1':
        message = i18n.t('friend_request_event_message');
        break;
      case '2':
        message = i18n.t('network_request_event_message');
        break;
      case '3':
        message = i18n.t('network_accepted_event_message');
        break;
      case '4':
        message = i18n.t('cooperation_request_event_message');
        break;
      case '5':
        message = i18n.t('cooperation_accepted_event_message');
        break;
      case '6':
        message = i18n.t('friend_accepted_event_message');
        break;
      case '7':
        message = i18n.t('liked_post_event_message');
        break;
      case '8':
        message = i18n.t('commented_post_event_message');
        break;
      case '9':
        message = i18n.t('cooperation_rejected_event_message');
        break;
      case '10':
        message = i18n.t('cooperation_completed_event_message');
        break;
      case '10':
        message = i18n.t('cooperation_message_event_message');
        break;
    }

    return message;
  }

  fetchNotifications() {
    request.get(`notifications`).then((response) => {
      if (response.data && response.data.data)
        this.set(response.data.data);
    });
  }

  set_cooperation(coop) {
    this.cooperation_counter = coop;
    this.onChangeCoopFns.forEach((fn) => {
      fn();
    });
  }

  onChangeCoop(fn) {
    this.onChangeCoopFns.push(fn);

    return () => { // unwatch function
      this.onChangeCoopFns.splice(this.onChangeCoopFns.indexOf(fn), 1);
    };
  }

  fetchCooperationsCount() {
    request.get('cooperations_notifications').then(response => {
      this.set_cooperation(response.data)
    })
  }

  getUniqueNotifications() {
    const uniqueNotifications = [];
    this.notifications.forEach((notification) => {
      const alreadyProcessed = uniqueNotifications.some((n) => n.type === notification.type && n.from === notification.from && n.to === notification.to);
      if (!alreadyProcessed) {
        uniqueNotifications.push(notification);
      }
    });
    return uniqueNotifications;
  }

  refresh() {
    this.fetchNotifications();
  }

  clear() {
    this.set([]);
  }
}

const notificationsHelper = new NotificationsHelper();
window.notificationsHelper = notificationsHelper;

export default notificationsHelper;