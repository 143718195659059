import history from './helpers/history';

const valid_variables = [
				'for',
				'N',
				'R',
				'L',
				'category',
				'subcategories',
				'roomsFrom',
				'roomsTo',
				'heat',
				'baths',
				'floorsFrom',
				'floorsTo',
				'plan',
				'constructionDateFrom',
				'constructionDateTo',
				'priceFrom',
				'priceTo',
				'areaFrom',
				'areaTo',
				'page',
				'areas',
                'members',
                'cross',
                'id'
]

class UriHelper {
    constructor() {
        
        this.init()
        this.reinit()
    }

    /**
     * Initializes the object
     */
    init() {
        this.raw_uri = window.location.href
        this.pieces = this.raw_uri.split('/')
        this.url = this.pieces[2]

        //This is only a guess. If you want to be sure then call fn set_component_view(String c)
        this.current_component = this.pieces[3]

        this.mapper = {}
        this.pieces.forEach((piece, index) => {
            if (valid_variables.includes(piece) && this.pieces[index + 1]) {
                const p = decodeURIComponent(this.pieces[index + 1])
                this.mapper[piece] = p.includes('-') ? p.split('-') : p
            } 
        })

    }

    init_uri(uri) {
        this.pieces = uri.split('/')
        this.url = this.pieces[2]

        this.mapper = {}
        this.pieces.forEach((piece, index) => {
            if (valid_variables.includes(piece) && this.pieces[index + 1]) {
                const p = decodeURIComponent(this.pieces[index + 1])
                this.mapper[piece] = p.includes('-') ? p.split('-') : p
            } 
        })
        
        delete this.mapper['page'];

        this.onChangeExec()
    }

    reinit() {
        // onChangeFns will be populated from any component need to run a function when this.mapper changes
        this.onChangeFns = []
        // onRemove will be populated from any component need to run a function when this.mapper changes
        this.onRemoveFns = []
    }

    /**
     * Sets the current component view in order to use it on history.push
     * @param {String} c 
     */
    set_component_view(c) {
        this.current_component = c
    }

    /**
     * Set an onChange callback function
     * @param {function} fn 
     */
    onChange(fn) {
        this.onChangeFns.push(fn)

        // return an unmount function to unset this function when needed
        return () => { 
            this.onChangeFns.splice(this.onChangeFns.indexOf(fn), 1);
        };
    }

    /**
     * execute onchange functions set by components
     */
    onChangeExec() {
        this.onChangeFns.forEach(fn => fn())
    }

    /**
     * Set an onRemove callback function
     * @param {function} fn 
     */
    onRemove(fn) {
        this.onRemoveFns.push(fn)

        // return an unmount function to unset this function when needed
        return () => { 
            this.onRemoveFns.splice(this.onRemoveFns.indexOf(fn), 1);
        };
    }

    /**
     * execute onRemove functions set by components
     */
    onRemoveExec(key) {
        this.onRemoveFns.forEach(fn => fn(key))
    }
    /**
     * retrieves the value of the {key} parameter
     * If value is not set then return default_value (null)
     * @param {String} key 
     * @param {mixed} default_value 
     */
    get(key, default_value = null) {
        if (typeof this.mapper[key] !== 'undefined') {
            return this.mapper[key]
        } else {
            return default_value
        }
    }

    /**
     * retrieves all values of uri as object|array
     */
    getAll() {
        return this.mapper
    }

    /**
     * Sets the parameter value
     * Sets the value in the URI if {silent} is false
     * @param {String} key 
     * @param {String} value 
     * @param {boolean} silent default false
     */
    set(key, value, silent = false) {
        if (!value || (Array.isArray(value) && value.length === 0))
            delete this.mapper[key]
        else
            this.mapper[key] = value

        if (!silent) {
            this.onChangeExec()
            this.set_uri()
        }
    }

    /**
     * Unsets the parameter if exists
     * Unset the value in the URI
     * @param {String} key 
     * @param {boolean} silent default false
     */
    unset(key, silent = false, value = null) {
        if (value && Array.isArray(this.mapper[key])) {
            this.mapper[key].splice(this.mapper[key].indexOf(value), 1);

            if (!this.mapper[key].length ) {
                delete this.mapper[key];    
            }
        } 
        else {
            delete this.mapper[key];
        }
        if (!silent) {
            this.set_uri()
            this.onRemoveExec(key)
        }
            
    }

    clear() {
        this.mapper = []
    }

    /**
     * Pushes uri to history
     */
    set_uri() {
        const uri = this.uri_to_string(true)
        history.push(`/${uri}`)
    }

    /**
     * Transforms the mapper object into an actual uri string
     */
    uri_to_string(exclude_page = false) {
        let str = this.current_component
        for(let m in this.mapper) {
            if (typeof this.mapper[m] !== 'undefined' && (m !== 'page' || !exclude_page)) {
                if (Array.isArray(this.mapper[m])) {
                    str += `/${m}/${encodeURIComponent(this.mapper[m].join('-'))}`
                } else {
                    str += `/${m}/${encodeURIComponent(this.mapper[m])}`
                }
            } 
        }
        return str
    }

    /**
     * Transforms the mapper object into a query string
     */
    uri_to_query_string() {
        let str = '?'
        for(let m in this.mapper) {
            if (typeof this.mapper[m] !== 'undefined') {
                if (Array.isArray(this.mapper[m])) {
                    this.mapper[m].forEach(e => {
                        str += `${m}[]=${encodeURIComponent(e)}&`
                    })
                } else {
                    str += `${m}=${encodeURIComponent(this.mapper[m])}&`
                }
                
            }
        }

        return str
    }
}

const uriHelper = new UriHelper()

export default uriHelper