import './Chat.css';
import React from 'react';
import Bubble from '../Bubble/Bubble';

class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.closeBubble = this.closeBubble.bind(this);
  }

  openBubble(contactId) {
    this.props.onOpenBubble(contactId)
  }

  closeBubble(contactId) {
    this.props.onCloseBubble(contactId)
  }

  prevMessages(contactId) {
    this.props.onPrevMessages(contactId)
  }

  render() {
    return (
      <div className="Chat">
        {
          this.props.bubbles.map((data, i) =>
            <Bubble
              key={i}
              data={data}
              onOpen={() => this.openBubble(data.id)}
              onClose={() => this.closeBubble(data.id)}
              onPrev={() => this.prevMessages(data.id)}
            />
          )
        }
      </div>
    );
  }
}

export default Chat;