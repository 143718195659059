import React, { Component , Fragment } from "react";
import Load from '../Load';
import i18n from "../../i18n";
import request from "../../request";
import RequestCard from "../RequestCard";
// import FriendsOnProfile from '../components/FriendsOnProfile';

class RequestsProfile extends Component {

  constructor(props) {
    super(props);
	
    this.state = {
	  requests: [],
      requests_page: 0,
      loaded: false,
      end_requests: false
    };

    this.onDocumentScroll = this.onDocumentScroll.bind(this);
    this.cooperationRequest = this.cooperationRequest.bind(this);
    this.cancelCooperationRequest = this.cancelCooperationRequest.bind(this);
  }

  componentDidMount() {
    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    this.getRequests(1);
    document.addEventListener('scroll', this.onDocumentScroll);
  }

  onDocumentScroll = (e) => {
    const totalPageHeight = document.body.scrollHeight - 1; 
    const scrollPoint = window.scrollY + window.innerHeight;

    if(scrollPoint >= totalPageHeight) {
      this.showMoreRequests()
      // this.showMorePosts(profile);
    }
  };

  getRequests(page){
    request.get(`requests/members/${this.props.member.id}/page/${page || this.state.requests_page}`).then((response) => {
      if (response.data) {
        this.setState({
          
          requests: this.state.requests.concat(response.data.listings),
          end_requests: response.data.listings.length < 10
        });
      }
    });
  }

  showMoreRequests() {
    if(!this.state.end_requests){
      const newPage = this.state.requests_page + 1;
      this.getRequests(newPage);
      this.setState({
        
        requests_page: newPage,
      });
    } 
  }

  cooperationRequest(data){
    this.setState({
      ...this.state,
      requests: this.state.requests.map(r => {
        if (r.id !== data.id) return r

        return {
          ...r,
          collab: data.collab,
          spam_collab: data.spam_collab
        }
      })
    })
      
  }

  cancelCooperationRequest(data){
    this.setState({
      ...this.state,
      requests: this.state.requests.map(r => {
        if (r.id !== data.id) return r

        return {
          ...r,
          collab: data.collab,
          spam_collab: data.spam_collab
        }
      })
    })
  }

  removePreview(){
    this.setState({
      
      meta: [],
    });
  }

  componentWillUnmount() {
    i18n.clearOnChangeLocale();
    document.removeEventListener('scroll', this.onDocumentScroll);
  }
  
  render() {
    const member = this.props.member ? this.props.member : null
    return (
      <Fragment> 
			 {
					this.state.requests && this.state.requests.map((request , index) => {
					return <RequestCard key={index} 
						request={request}
						cooperationRequest={(e) => {this.cooperationRequest(e)}}
						cancelCooperationRequest={(e) => {this.cancelCooperationRequest(e)}}
					/>
					})
			}
			<Load end={this.state.end_requests}/>
      </Fragment>
    );
  }
}

export default RequestsProfile;