import React, { Component , Fragment, createRef } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import i18n from "../i18n";
import request from '../request';
import Cooperation from "../components/Cooperation";
import Pagetitle from "../components/Pagetitle";
import socket from '../socket';
import Load from "../components/Load";
import ModalGeneric from "../components/ModalGeneric";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2'
import notificationsHelper from "../notifications-helper";

class Cooperations extends Component {
  constructor(props) {
    super(props);

	const active = this.props.match.params.status

	let title = i18n.t('Ενεργές συνεργασίες')
	if (active == 'incoming-requests') {
		title = i18n.t('Εισερχόμενα αιτήματα συνεργασίας')
	} else if (active == 'outgoing-requests') {
		title = i18n.t('Εξερχόμενα αιτήματα συνεργασίας')
	} else if (active == 'rejects') {
		title = i18n.t('Απορριφθείσες συνεργασίες')
	} else if (active == 'closed') {
		title = i18n.t('Κλειστές συνεργασίες')
	}
    this.state = {
	  request_uri: this.getIndexCollabs(active, false),
      active_collabs: [],
	  incoming_requests: [],
	  outgoing_requests: [],
	  rejects: [],
	  closed: [],
	  active_collabs_page: 1,
	  incoming_requests_page: 1,
	  outgoing_requests_page: 1,
	  rejects_page: 1,
	  closed_page: 1,
	  component: active,
	  title: title,
	  infinite_scroll: true,
	  perPage: 10,
	  scroll_blocked: true,
	  showModal: false,
	  rating_cooperation: null,
	  rating_text: '',
	  stars: 0,
	  hover_stars: 0
    };
  }

  componentDidMount() {
    this.unmountLocale = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

	
	// this.scrollRef.current.addEventListener('scroll', this.onCollabsScroll);

	this.getCollabs()
  }

  componentWillUnmount() {
	this.unmountLocale()
  }

  componentDidUpdate(prevProps, prevState) {
	const active = this.props.match.params.status
	
	if (active && active !== prevProps.match.params.status) {
		this.changeActive(active)
	}
  }

  onCollabsScroll = (e) => {
	if (!this.state.scroll_blocked)
		this.getCollabs();
  };

  changeActive(active) {
	let title = i18n.t('Ενεργές συνεργασίες')
	if (active == 'incoming-requests') {
		title = i18n.t('Εισερχόμενα αιτήματα συνεργασίας')
	} else if (active == 'outgoing-requests') {
		title = i18n.t('Εξερχόμενα αιτήματα συνεργασίας')
	} else if (active == 'rejects') {
		title = i18n.t('Απορριφθείσες συνεργασίες')
	} else if (active == 'closed') {
		title = i18n.t('Κλειστές συνεργασίες')
	}
	this.setState({
		...this.state,
		request_uri: this.getIndexCollabs(active, false),
		component: active,
		title: title,
		infinite_scroll: true,
		active_collabs: [],
		incoming_requests: [],
		outgoing_requests: [],
		closed: [],
		rejects: [],
		scroll_blocked: true
	}, () => {
		this.getCollabs()
	})
  }

  resendSign(id) {

  }

  openRatingCooperation(coop_id) {
	this.setState({
		...this.state,
		rating_cooperation: coop_id,
		rating_text: '',
		stars: 0,
		hover_stars: 0,
		showModal: true
	})
  }

  setStars(stars) {
	this.setState({
		...this.state,
		stars: stars
	})
  }

  setHoverStars(stars) {
	this.setState({
		...this.state,
		hover_stars: stars
	})
  }

  removeCollab(id) {
	this.setState({
		...this.state,
		[this.state.request_uri]: this.state[this.state.request_uri].filter(c => c.cooperations_id !== id),
	})
  }

  rateCooperation() {
	request.put(`collabs/rate/${this.state.rating_cooperation}`, {
		rating: this.state.stars,
		message: this.state.rating_text
	}).then(response => {
		Swal.fire({
			title: i18n.t('cooperation_rate_sendeed'),
			text: i18n.t('thank_you'),
			icon: 'success'
		})
		this.setState({
			...this.state,
			showModal: false
		})
	})
  }

  respond(id, answer) {
	request.patch(`respond_collab/${id}`, {
		status: answer
	}).then((response) => {
		if (response.data.attempt_status === 200) {
			if (answer === 1) {
				// socket.send({
				// 	type: 'event',
				// 	data: {
				// 		class: 'friend-accepted',
				// 		realtor: senderId,
				// 		me: `${storage.me.realtors_fname} ${storage.me.realtors_lname}`,
				// 	},

				// })
				
				Swal.fire({
					title: i18n.t('cooperation_accepted'),
					text: i18n.t('cooperation_moved_to_active'),
					icon: 'success'
				})
			} else if (answer === 2) {
				Swal.fire({
					title: i18n.t('cooperation_rejected'),
					text: i18n.t('cooperation_moved_to_rejected'),
					icon: 'success'
				})
			}
			
			notificationsHelper.fetchCooperationsCount()
			this.removeCollab(id)
		}
	})
  }

  cancelRequest(id) {
	request.delete(`collab/id/${id}`).then((response) => {
		if (response.data.attempt_status === 200) {
			this.removeCollab(id)
		}
	});
  }

  getCollabs() {
	if (this.state.infinite_scroll) {
		request.get(`collabs/${this.state.request_uri}/${this.state[`${this.state.request_uri}_page`]}`).then(response => {
			let page = this.state[`${this.state.request_uri}_page`] + 1
			let infinite_scroll = this.state.infinite_scroll
			if (!response.data || this.state.perPage > response.data.length ) {
				infinite_scroll = false
				page = 1
			}
			notificationsHelper.fetchCooperationsCount()
			this.setState({
				...this.state,
				[this.state.request_uri]: this.state[this.state.request_uri].concat(response.data),
				infinite_scroll: infinite_scroll,
				[`${this.state.request_uri}_page`]: page,
				scroll_blocked: false
			})
		})
	}
  }

  getIndexCollabs(active, withData = true) {
	let index_collabs = null
	if (active == 'active') {
		index_collabs = 'active_collabs'
	} else if (active == 'incoming-requests') {
		index_collabs = 'incoming_requests'
	} else if (active == 'outgoing-requests') {
		index_collabs = 'outgoing_requests'
	} else if (active == 'rejects') {
		index_collabs = 'rejects'
	} else if (active == 'closed') {
		index_collabs = 'closed'
	}

	if (withData) {
		if (!this.state[index_collabs]) return null;
	}

	return index_collabs
  }

  render() {
	var index_collabs = this.getIndexCollabs(this.state.component)
    return (
      <Fragment> 
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content right-chat-active">
			<div className="middle-sidebar-bottom">
				<div className="middle-sidebar-left pe-0">
					<div className="row">
						<div className="feed-body">
							<div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12">
								<Pagetitle
									title={this.state.title}
								/>
							</div>
							<div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12">
								{
									index_collabs !== null &&
									this.state[index_collabs].map(c => {
										return <div className="card w-100 rounded-xxl border-0 mb-3">
													<Cooperation 
														key={`coop_${c.cooperations_id}`}
														data={c}
														resendSign={(e) => this.resendSign(e)}
														respond={(e, i) => this.respond(e, i)}
														evaluate={(e) => this.evaluate(e)}
														cancelRequest={(e) => this.cancelRequest(e)}
														removeCollab={(e) => this.removeCollab(e)}
														openRatingCooperation={(coop_id) => this.openRatingCooperation(coop_id)}
														showMore={true}
													/>
												</div>
									})
								}
								<Load end={!this.state.infinite_scroll} onScroll={this.onCollabsScroll} />
							</div>
						</div>
					</div>
				</div>
			</div>
			
        </div>
		<ModalGeneric
			show={this.state.showModal}
			handleClose={() => this.setState({
				...this.state,
				showModal: false
			})}
			header={<h4>{i18n.t('cooperation_rating')}</h4>}
			footer={<div className="card-body p-0 d-flex space-between open-house-footer align-center">
						<div className="d-flex">
							<button onClick={() => this.rateCooperation()} className="bg-success btn-action btn-accept p-2 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-600">{i18n.t('send')}</button>
							<button onClick={() => this.setState({
				...this.state,
				showModal: false
			})}className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">{i18n.t('button_cancel_request')}</button>
						</div>
					</div>}
		>
			<div className="card w-100 border-0 p-4 mb-3">
				<div className="card-body mt-2 p-0">
					
					<form>
					<div className="row mt-4">
							<div className="col-md-12">
								<div className="form-group p-relative">
									<label className="mb-2">{i18n.t('rate_the_cooperation_with_realtor')}</label>
									<div className="stars">
										<span className={`star star-1 ${this.state.hover_stars >= 1 || this.state.stars >= 1  ? 'active' : ''}`} onMouseLeave={() => this.setHoverStars(0)} onMouseEnter={() => this.setHoverStars(1)} onClick={() => this.setStars(1)}><FontAwesomeIcon icon={faStar} /></span>
										<span className={`star star-2 ${this.state.hover_stars >= 2 || this.state.stars >= 2  ? 'active' : ''}`} onMouseLeave={() => this.setHoverStars(0)} onMouseEnter={() => this.setHoverStars(2)} onClick={() => this.setStars(2)}><FontAwesomeIcon icon={faStar} /></span>
										<span className={`star star-3 ${this.state.hover_stars >= 3 || this.state.stars >= 3  ? 'active' : ''}`} onMouseLeave={() => this.setHoverStars(0)} onMouseEnter={() => this.setHoverStars(3)} onClick={() => this.setStars(3)}><FontAwesomeIcon icon={faStar} /></span>
										<span className={`star star-4 ${this.state.hover_stars >= 4 || this.state.stars >= 4  ? 'active' : ''}`} onMouseLeave={() => this.setHoverStars(0)} onMouseEnter={() => this.setHoverStars(4)} onClick={() => this.setStars(4)}><FontAwesomeIcon icon={faStar} /></span>
										<span className={`star star-5 ${this.state.hover_stars >= 5 || this.state.stars >= 5  ? 'active' : ''}`} onMouseLeave={() => this.setHoverStars(0)} onMouseEnter={() => this.setHoverStars(5)} onClick={() => this.setStars(5)}><FontAwesomeIcon icon={faStar} /></span>
									</div>
								</div>
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-md-12">
								<div className="form-group">
									<label className="mb-2">{i18n.t('rating_message')}</label>
									<textarea rows="6" value={this.state.rating_text} onChange={(e) => this.setState({...this.state, rating_text: e.target.value})} className="form-control mh-125" placeholder={i18n.t('describe_your_reason_of_request_in_a_few_words')}/>
									<p className="error_message d-none">*Υποχρεωτικό πεδίο</p>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</ModalGeneric>

        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default Cooperations;