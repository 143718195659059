import React, { Component , createRef, Fragment, useEffect, useState, useMemo } from "react";
import request from "../request";
import i18n from "../i18n";
import logoWhite from '../assets/images/real-status-logo-white.png'
import {useStripe, useElements, PaymentElement, Elements} from '@stripe/react-stripe-js';
import { Link } from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';
import storage from "../storage";

const stripePromise = loadStripe('pk_live_h2avtR7ukCenlOjRibi3bKxI00I9wOZ6Qh');

const Payment = (props) => {
	console.log(props.options)
	return props.options ? <Elements stripe={stripePromise} options={props.options}>
	<Checkout {...props} />
  </Elements> : ''
}

const Checkout = (props) => {
	const stripe = useStripe();
	const elements = useElements();
	// const options = useMemo

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();
	
		if (!stripe || !elements) {
		  // Stripe.js has not yet loaded.
		  // Make sure to disable form submission until Stripe.js has loaded.
		  return;
		}
	
		const result = await stripe.confirmPayment({
		  //`Elements` instance that was used to create the Payment Element
		  elements,
		  confirmParams: {
			return_url: "https://network.realstatus.net/api/stripe/secure_payment",
		  },
		});
	
	
		if (result.error) {
		  // Show error to your customer (for example, payment details incomplete)
		  console.log(result.error.message);
		} else {
		  // Your customer will be redirected to your `return_url`. For some payment
		  // methods like iDEAL, your customer will be redirected to an intermediate
		  // site first to authorize the payment, then redirected to the `return_url`.
		}
	};
	
	// useEffect(() => {
	// 	request.get(`stripe/intent/month`).then(response => {
	// 		console.log(response)
	// 		setOptions({clientSecret: response.data.client_secret})
	// 	})
	// }, [])
	
	return (
		
				<div className="row">
					<div className="col-xl-5 d-none d-xl-flex p-0 vh-100 bg-primary align-items-center justify-content-center bg-cover">
						<div className="col-6 text-center">
							<img src={logoWhite} alt="Real Status Logo Symbol"/>
						</div>
					</div>
					<div className="col-xl-7 login-wrapper vh-100 align-items-center bg-cover2 d-flex bg-white rounded-3 overflow-hidden">
						<div className="card shadow-xxs-2 border-0 ms-auto me-auto login-card">
							<div className="card-body rounded-0 text-left">
								<h1 className="fw-700 mb-3">{i18n.t('renew_subscription')}</h1>
								<div>
									{
											<form onSubmit={handleSubmit}>
												
												<PaymentElement />
												<h4 className='stripe-cost'>{i18n.t('amount')}: {props.price}€</h4>
												<button className="btn bg-success text-white m-4" disabled={!stripe}>{i18n.t('complete_payment')}</button>
												<div>
													<b>{i18n.t('alternative')}</b>
													<p>
													{i18n.t("deposit_to_account_prompt")} {storage.me.realtors_id}{`"`}: <br/>
													<b>{i18n.t("deposit_to_account_bank")} </b> <br/>
													<b>{i18n.t("deposit_to_account_account_num")} </b> <br/>
													<b>{i18n.t("deposit_to_account_iban")} </b> <br/>
													</p>
												</div>
											</form>
									}
									
								</div>
							</div>
						</div> 
					</div>
					<Link to={`/`}>{i18n.t('home')}</Link>
				</div>
			
	);
}
const Plans = (props) => {
	const [price, setPrice] = useState(0)
	const [yearPrice, setYearPrice] = useState(0)

	useEffect(() => {
		request.get('stripe/payment_plan').then(response => {
			if (response.status === 200) {
				setPrice(parseFloat(response.data.monthly_price))
				setYearPrice(parseFloat(response.data.year_price))
			}
		})
	}, [])

	const choosePlan = (intent) => {
		props.choosePlan(intent)
	}

	return (
				<div className="row">
					<div className="col-xl-5 d-none d-xl-flex p-0 vh-100 bg-primary align-items-center justify-content-center bg-cover">
						<div className="col-6 text-center">
							<img src={logoWhite} alt="Real Status Logo Symbol"/>
						</div>
					</div>
					<div className="col-xl-7 login-wrapper vh-100 align-items-center bg-cover2 d-flex bg-white rounded-3 overflow-hidden">
						<div className="card shadow-xxs-2 border-0 ms-auto me-auto login-card max-width-600">
							<div className="card-body rounded-0 text-left">
								<h1 className="fw-700 mb-3 text-center">{i18n.t('renew_subscription')}</h1>
								<div className="plans-container">
									<div className="plan plan-month" onClick={() => choosePlan('month')}>
										<div className="plan-description">
											<img src='/assets/images/map_pr.png'></img>
											<div className='price'>
												{price} € <span className="per-time">/ {i18n.t('per_month')}</span>
											</div>
											<h5 className="plan-title pb-4">─ {i18n.t('monthly_plan')} ─</h5>
											<p className="plan-desc">{i18n.t('monthly_description')}</p>
										</div>
										<div className="price">
											
											<button className="btn bg-success text-white m-auto d-block choose-plan-btn">{i18n.t('choose_plan')}</button>
										</div>
									
									</div>
									<div className="plan plan-year" onClick={() => choosePlan('year')}>
										<div className="plan-description">
											<div className="discount">-10%</div>
											{/* <i className="feather-globe"></i> */}
											<img src='/assets/images/map_pr2.png'></img>
											<div className='price'>
											{yearPrice} € <span className="per-time">/ {i18n.t('per_year')}</span>
											</div>
											<h5 className="plan-title pb-4">─ {i18n.t('year_plan')} ─</h5>
											<p className="plan-desc">{i18n.t('year_description')}</p>
										</div>
										<div className="price">
											<button className="btn bg-success text-white m-auto d-block choose-plan-btn">{i18n.t('choose_plan')}</button>
										</div>
									</div>
								</div>
								<Link to={`/`}>{i18n.t('home')}</Link>
							</div>
						</div> 
					</div>
					
				</div>
			
	);
}
const RenewPlan = (props) => {
	// const options = useMemo

	const [options, setOptions] = useState(null)
	const [plan, setPlan] = useState(null)
	const [price, setPrice] = useState(0)

	useEffect(() => {
		document.body.classList.remove('theme-dark')
		document.body.classList.add('theme-light')
		
		
	  }, []);
	
	// useEffect(() => {
	// 	request.get(`stripe/intent/month`).then(response => {
	// 		console.log(response)
	// 		setOptions({clientSecret: response.data.client_secret})
	// 	})
	// }, [])

	const choosePlan = (p) => {
		setPlan(p)
		setPurchaseIntention(p)
	}

	const setPurchaseIntention = (p) => {
		request.get(`stripe/intent/${p}`).then( (response) => {
			setPrice(response.data.amount / 100)
			setOptions({
				clientSecret: response.data.client_secret,
				billingDetails: {
					email: response.data.receipt_email
				}
			})
			
		})
	}
	
	return (
		<Fragment> 
			{
				plan ?
				options ?
				<Payment options={options} price={price}/> : ''
				:
				<Plans choosePlan={(p) => choosePlan(p)}/>
			}
			{
				
			}				
		</Fragment>
	);
}

export default RenewPlan;