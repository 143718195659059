const storage = {
  onChangeFns: [],

  onChange(fn, field = null) {
    this.onChangeFns.push(fn);
  },

  onceChange(fn) {
    fn.once = true;
    this.onChangeFns.push(fn);
  },

  set(key, value) {
    this[key] = value;
    this.onChangeFns.forEach((fn) => {
      fn(key, value);
      if (fn.once) {
        this.onChangeFns.splice(this.indexOf(fn), 1);
      }
    });
  },
  
  // me: null,
};
window.storage = storage;

export default storage;