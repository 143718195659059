import '../css/PropertyDetails.css';
import React, { Component, Fragment, createRef } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import i18n from "../i18n";
import request from "../request"
import Button from '../components/Button';
import ModalGeneric from '../components/ModalGeneric';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity, faClose , faLocationDot, faPlane, faWater, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import Rightchat from "../components/Rightchat";
import Popupchat from "../components/Popupchat";
import ReputationData from "../components/ReputationData";
import { Link } from 'react-router-dom';
import config from '../config';
import Utils from '../utils';
import * as L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup, FeatureGroup, Circle, Polygon } from 'react-leaflet';
import storage from '../storage';
import '../css/leaflet.css'
import '../css/MarkerCluster.Default.css'
import '../css/MarkerCluster.css'
import 'leaflet-draw/dist/leaflet.draw.css'


class RequestDetails extends Component {
  
  constructor(props) {
    super(props);

    this.searchRef = createRef()
    this.fgRef = createRef(null)
    this.mapRef = createRef(null)

    this.state = {
      show_suggestions: true,
      showModal: false,
      search_text: '',
      search_results: [],
      selected_properties: [],
      message: '',
      request: null,
      collab: false,
      too_many_requests: false,
      serverErrorMessage: '',
      similar_requests: [],
    };
    
  }

  
  componentDidMount() {
    this.inputClicked = () => {
			this.setState({
				show_suggestions: true
			})
		}
		this.documentClicked = (e) => {
			
			if (this.searchRef.current && this.searchRef.current && this.searchRef.current !== e.target && !this.searchRef.current.contains(e.target) && this.state.show_suggestions === true) {
				this.setState({
					show_suggestions: false
				})
            }
			
		}
    if (this.searchRef.current)
		  this.searchRef.current.addEventListener('click', this.inputClicked)
		document.addEventListener('click', this.documentClicked)

    
    

    request.get(`request/id/${this.props.match.params.id}`).then((response) => {

      this.setState({
        request: response.data
      }, () => {
        if (this.state.request.map && this.state.request.map.length > 0) {
          this.setState({
            show_map: true,
            map_data: this.state.request.map
          }, () => {
            this.mapRef.invalidateSize()
            this.state.request.map.forEach(p => {
              // L.polygon(<LatLng[]> latlngs, <Polyline options> options?)
              const poly_json = JSON.parse(p.polygon_encoded)
              const center = JSON.parse(p.polygon_center)
              const polygon = poly_json.map(pol => {
                return [parseFloat(pol[0]), parseFloat(pol[1])]
              })
              const temp = new L.Polygon(polygon)
              temp.addTo(this.fgRef.current)
              this.mapRef.fitBounds(polygon)
              // this.mapRef.panTo(new L.LatLng(center.lat, center.lng))
            })
          })
          
          
        }
        request.get(`requests/members/${this.state.request.realtor.id}`).then((response) => {
          this.setState({
            similar_requests: response.data.listings
          })
        });
      });
    });

    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.documentClicked)
  }

  cooperationRequestInit() {
    this.setState({
      showModal: true,
    });
  }

  searchProperties(str) {
		if (str.length > 2) {
			request.get(`my_properties/${str}`).then(response => {
				this.setState({
					// ...this.state,
					search_results: response.data
				})
			})
		}
	}

  cooperationRequest(){
    request.put(`collab`, {
      type: 2,
      related_id: this.state.request.id,
      refs: this.state.selected_properties.map(s => s.id),
			message: this.state.message
    }).then((response) => {
      if (response.data.attempt_status === 200 && response.data.data !== null) {
        this.setState({
          collab: response.data.data,
          showModal: false
        }, () => {
          Swal.fire({
            title: i18n.t('cooperation_request_sended'),
            text: i18n.t('you_will_be_informed_if_coop_accepted'),
            icon: 'success'
          })
        })
      } else if (response.data.attempt_status === 429) {
        this.setState({
          collab: false,
          too_many_requests: true
        })
        Swal.fire({
          title: i18n.t('cooperation_request_not_sended'),
          text: i18n.t('wait_1_day_before_resending_request'),
          icon: 'error'
        })
      } else if (response.data.attempt_status === 201) {
        Swal.fire({
          title: i18n.t('request_already_sended'),
          text: i18n.t('you_will_be_informed_if_coop_accepted'),
          icon: 'info'
        })
      } else if (response.data.attempt_status === 401) {
        Swal.fire({
          title: i18n.t('cooperation_request_not_sended'),
          text: i18n.t('you_are_not_connected_with_user'),
          icon: 'error'
        })
      }
    });
  }

  cancelCooperationRequest(){
    request.delete(`collab/type/2/id/${this.state.property.details.id}`, {
      type: 2,
      related_id: this.state.property.details.id
    }).then((response) => {
      if (response.data.attempt_status === 200) {
        this.setState({
          // ...this.state,
          collab: false
        })
      }
    });
  }

  render() {
    const meID = storage.me && storage.me.realtors_id;
    const my_request = this.state.request;
    
    return (
      <Fragment>
        <Header />
        <Leftnav />

        <div className="main-content PropertyDetails right-chat-active">
          <div className={`middle-sidebar-bottom`}>
            
          
            <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
              <div className="row">
                <div className="col-xl-12 col-xxl-12 col-lg-12 property-slider ">
                  {
                    <MapContainer 
                    preferCanvas={true}
                    id="map" center={[38.227322, 23.211220]} zoom={12} scrollWheelZoom={false}
                    whenCreated={ mapInstance => {
                      this.mapRef = mapInstance
                    } }
                    >
                      <TileLayer
                      attribution='&copy; Real Status Network'
                      url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <FeatureGroup ref={this.fgRef} id="main_map_ref">
                      </FeatureGroup>
                    </MapContainer>
                  }
                </div>
              </div>
              {
              my_request &&
              <div className="row">
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  <div className="card d-block mt-3 border-0 shadow-xss bg-white p-lg-5 p-4">
                  
                   

                    <h2 className="text-default fw-700 font-lg mt-3 mb-2">
                      
                      {i18n.get_data('purpose', my_request)}&nbsp;-&nbsp;{my_request.categories_name !== '' ? i18n.get_data('categories_name', my_request) : i18n.get_data('categories_basic_name', my_request)}<br />
                      
                    </h2>
                    <hr />
                  </div>
                  <div className="card d-block mt-3 border-0 shadow-xss bg-white p-lg-5 p-4">
                      <div className="characteristis">
                        <div className="header">
                          <span className="text-default">{i18n.t('request_values')}</span>
                          &nbsp;
                          <div>
                            <span className="text-italic text-default">#</span>
                            &nbsp;
                            <span className="text-default">{my_request.id}</span>
                            
                          </div>
                        </div>
                        
                        <div className="list">
                          <div className="labels">
                            <div>{i18n.t('property_characteristics.network_code')}</div>
                            {
                              my_request.code && !Utils.stringEmpty(my_request.code) && my_request.code !== '0' &&
                              <div>{i18n.t('property_characteristics.office_code')}</div>
                            }
                            <div>{i18n.t('request_characteristics.for')}</div>
                            <div>{i18n.t('request_characteristics.category')}</div>
                            <div>{i18n.t('request_characteristics.subcategory')}</div>
                            <div>{i18n.t('request_characteristics.price_min')}</div>
                            <div>{i18n.t('request_characteristics.price_max')}</div>
                            <div>{i18n.t('request_characteristics.area_min')}</div>
                            <div>{i18n.t('request_characteristics.area_max')}</div>
                            <div>{i18n.t('request_characteristics.heating_system')}</div>
                            <div>{i18n.t('request_characteristics.energy_class')}</div>
                            <div>{i18n.t('request_characteristics.bedrooms_from')}</div>
                            <div>{i18n.t('request_characteristics.bathrooms_from')}</div>
                            <div>{i18n.t('request_characteristics.wc_from')}</div>
                            <div>{i18n.t('request_characteristics.livingroom_from')}</div>
                            <div>{i18n.t('request_characteristics.floor')}</div>
                            <div>{i18n.t('request_characteristics.parking')}</div>
                            <div>{i18n.t('request_characteristics.year_of_construction_from')}</div>
                            <div>{i18n.t('request_characteristics.year_of_construction_to')}</div>
                            <div>{i18n.t('request_characteristics.last_update')}</div>
                                <div>{i18n.t('request_characteristics.view')}</div>
                            {
                              (my_request.cat_basic_id === '3' || my_request.cat_basic_id === '4') && <React.Fragment>
                                <div>{i18n.t('request_characteristics.access_from')}</div>
                                <div>{i18n.t('request_characteristics.orientation')}</div>
                                <div>{i18n.t('request_characteristics.land_slope')}</div>
                                <div>{i18n.t('request_characteristics.land_use')}</div>
                              </React.Fragment>
                            }
                          </div>

                          <div className="values">
                            <div>#{my_request.id}</div>
                            {
                              my_request.code && !Utils.stringEmpty(my_request.code) && my_request.code !== '0' &&
                              <div>#{my_request.code}</div>
                            }
                            <div>
                              {i18n.get_data('transaction_type_name', my_request)}
                            </div>
                            <div>
                              {i18n.get_data('categories_basic_name', my_request)}
                            </div>
                            <div>
                              {i18n.get_data('categories_name', my_request)}
                            </div>
                            <div>
                              {
                                  i18n.locale === 'en'
                                    ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat(my_request.min_price) * 1.15)
                                    : i18n.locale === 'el'
                                        ? new Intl.NumberFormat('el-GR', { style: 'currency', currency: 'EUR' }).format(my_request.min_price)
                                        : my_request.min_price
                              }
                            </div>
                            <div>
                              {
                                  i18n.locale === 'en'
                                    ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat(my_request.max_price) * 1.15)
                                    : i18n.locale === 'el'
                                        ? new Intl.NumberFormat('el-GR', { style: 'currency', currency: 'EUR' }).format(my_request.max_price)
                                        : my_request.max_price
                              }
                            </div>
                            <div>{my_request.min_sq}</div>
                            <div>{my_request.max_sq}</div>
                            <div>{i18n.get_data('heat_name', my_request)}</div>
                            <div>{i18n.get_data('energy_class_name', my_request)}</div>
                            <div>{my_request.rooms}</div>
                            <div>{my_request.bathroom}</div>
                            <div>{my_request.wc}</div>
                            <div>{my_request.livingroom}</div>
                            <div>{i18n.get_data('floors_name', my_request)}</div>
                            <div>{i18n.get_data('parking_name', my_request)}</div>
                            <div>{my_request.year_from}</div>
                            <div>{my_request.year_to}</div>
                            <div>{my_request.mod_date}</div>
                            
                            <div>{i18n.get_data('views_name', my_request)}</div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            
                            {
                              (my_request.cat_basic_id === '3' || my_request.cat_basic_id === '4') && <React.Fragment>
                                <div>{i18n.get_data('access_type_name', my_request)}</div>
                                <div>{i18n.get_data('orientation', my_request)}</div>
                                <div>{i18n.get_data('land_slope', my_request)}</div>
                                <div>{i18n.get_data('land_use', my_request)}</div>
                              </React.Fragment>
                            }
                          </div>
                        </div>
                      </div>
                     
                      
                  </div>
                </div>
                <div className='col-xl-4 col-xxl-3 col-lg-4 ps-lg-0'>
                  <div className="card w-100 border-0 mt-3 mb-4 p-lg-4 p-3 shadow-xss position-relative rounded-3 bg-white">
                      <div className="section full p-4 rounded-3 realtor-section">
                        <div className="row">
                            <div className="col-12 text-center">
                              <img src={my_request.realtor.photo} alt={my_request.realtor.fname} className="w100 rounded-circle" />
                              <h2 className="lh-1 m-0 text-grey-900 fw-700 pt-2">{my_request.realtor.fname} {my_request.realtor.lname}</h2>
                              
                              <ReputationData
                                member={{
                                  ...my_request.realtor,
                                  reputation: my_request.realtor.rating
                                }}
                                className='reputation-contact'
                              />
                              {
                                my_request.realtor.id !== meID ?
                                <div>
                                  {
                                    (!this.state.request.collab && !this.state.too_many_requests) &&
                                    <Button onClick={() => this.cooperationRequestInit()} className="button bg-success border-0 text-white fw-600 text-uppercase font-xssss ms-2 rounded-xl d-inline-block mt-0 p-3 lh-34 text-center ls-3 ">{i18n.t('Αίτηση συνεργασίας')}</Button>
                                  }
                                  {
                                    (this.state.request.collab) &&
                                    <Button onClick={() => this.cancelCooperationRequest()} className="bg-danger border-0 text-white fw-600 text-uppercase font-xssss ms-2 rounded-3 d-inline-block mt-0 pd-3-6 lh-34 text-center ls-3">{i18n.t('Ακύρωση αιτήματος')}</Button>
                                  }
                                  {
                                    (this.state.too_many_requests) &&
                                    <Button className="button bg-danger border-0 text-white fw-600 text-uppercase font-xssss rounded-3 d-inline-block mt-0 ms-2 pd-3-6 lh-34 text-center ls-3 ">{i18n.t('Ακυρώσατε πρόσφατα αίτημα συνεργασίας')}</Button>
                                  }
                                </div> : ''
                                }
                              {/* <span className="font-xssss mt-1 fw-700 text-grey-800">
                                <a href={`tel:${property.realtor.phone}`}><i className='btn-round-sm bg-greylight text-grey-500 me-1 ti-mobile'></i></a>
                              </span>
                              <span className="font-xssss mt-1 fw-700 text-grey-800">
                                <a href={`mailto:${property.realtor.mail}`}><i className='btn-round-sm bg-greylight text-grey-500 me-1 ti-email'></i></a>
                              </span> */}
                              
                            </div>
                            
                            
                        </div>
                      </div> 
                    </div>
                    
                </div>
              </div>
              }
            </div>
            
          </div>
          <div className={``}>
            {this.state.serverErrorMessage}
          </div>
        </div>
        <ModalGeneric
					show={this.state.showModal}
					handleClose={() => this.setState({
						
						showModal: false
					})}
					header={<h4>{i18n.t('cooperation_request')}</h4>}
					footer={<div className="card-body p-0 d-flex space-between open-house-footer align-center">
								<div className="d-flex">
									<button onClick={() => this.cooperationRequest()} className="bg-success btn-action btn-accept p-2 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-600">{i18n.t('send')}</button>
									<button onClick={() => this.setState({
						
						showModal: false
					})} className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">{i18n.t('button_cancel_request')}</button>
								</div>
							</div>}
				>
					<div className="card w-100 border-0 p-4 mb-3">
						<div className="card-body mt-2 p-0">
							
							<form>
							<div className="row mt-4">
									<div className="col-md-12">
										{/* <div className="form-group p-relative">
											<label className="mb-2">{i18n.t('related_property_for_collaboration')}</label>
											{
												this.state.selected_properties && 
												this.state.selected_properties.map(s => {
													return (
														<div className={`property-suggestion p-1`} onClick={() => this.setState({
															
                                selected_properties: this.state.selected_properties.filter(sp => sp.id !== s.id)
														})}>
															<img src={s.thumbnail} />
															<div className='property-title'>
																<b>#{s.id}</b><br/>
																<span className='text-grey-500 fw-600 font-xssss'>{i18n.get_data('title', s)}</span>
															</div>
															<FontAwesomeIcon icon={faClose} />
														</div>
													)
												})
												
											}
											<input 
												type="text" 
												className='form-control' 
												onChange={(e) => this.searchProperties(e.target.value)}
												placeholder={i18n.t('property_code')}
												ref={this.searchRef}
											/>
											<div className={`search-results scroll-bar ${this.state.show_suggestions ? '' : 'hidden'}`}>
												{
													this.state.search_results && 
													this.state.search_results.filter(s => this.state.selected_properties.filter(sp => sp.id === s.id).length === 0).map(s => {
														return (
															<div className={`property-suggestion p-1`} onClick={() => this.setState({
																
																selected_properties: [...this.state.selected_properties, s]
															})}>
																<div className='property-title'>
																	<b>#{s.id}</b><br/>
																	<span className='text-grey-500 fw-600 font-xssss'>{i18n.get_data('title', s)}</span>
																</div>
															</div>
														)
													})
												}
											</div>
										</div> */}
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-md-12">
										<div className="form-group">
											<label className="mb-2">{i18n.t('request_content')}</label>
											<textarea rows="6" value={this.state.message} onChange={(e) => this.setState({ message: e.target.value})} className="form-control mh-125" placeholder={i18n.t('describe_your_reason_of_request_in_a_few_words')}/>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</ModalGeneric>
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default RequestDetails;