import React from 'react';
import notificationsHelper from '../notifications-helper';
import i18n from '../i18n';
import history from '../helpers/history';
import Button from '../components/Button';

class NotificationSubmitCordes extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    i18n.onChangeLocale(() => {
      this.setState({
        ...this.state,
        message: notificationsHelper.getMessage(this.props.data.type),
      });
    });
  }


  

  render() {
    return (
      <div
        className="notification-item notification--friend-request-item card bg-transparent-card w-100 border-0 ps-5 mb-3"
        ref={this.elementRef}
      >
        <div className="image position-absolute left-0">
          <img src='/assets/images/avatar-real.png' alt="user" className="img-circle w50" />
          <div className="notification-type-icon bg-primary">
            <i className="feather-user text-white"></i>
          </div>
        </div>
        <div className="d-flex flex-direction-row justify-content-between">
          <div className="w-100 ms-3 mt-2">
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
              Network assistant
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4 d-flex justify-content-between align-items-center">
              <span>{i18n.t('update_your_address_for_users')}</span>
            </h6>
             <Button
                className={`px-1 py-0 lh-24 ls-3 font-xsssss fw-700 ls-lg bg-primary text-white white-space-no-wrap`}
                onClick={() => history.push('/accountinformation')}
              >
                {i18n.t('go_to_settings')}
              </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationSubmitCordes;