import React,{Component, createRef, Fragment} from 'react';
import validator from 'validator'
import Preview from './Preview';
import Commentview from '../components/Commentview';
import i18n from '../i18n';
import Utils from "../utils"
import request from "../request";
import storage from '../storage';
import { Link } from 'react-router-dom';
import ProfilePreview from './ProfilePreview';
import ModalGeneric from './ModalGeneric';
import Editpost from './Editpost';

class Postview extends Component {

  constructor(props) {
    super(props);

    this.elementRef = createRef(null)
    this.emojiRef = createRef(null)
    this.state = {
      comments: [],
      comment: '',
      meta: [],
      isMenuOpen: false,
      isShareOpen: false,
      isCommentsOpen: false,
      isCommentsLoaded: false,
      showModal: false,
      editPost: null,
      editMeta: null
    };

    this.getPostComments = this.getPostComments.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.addComment = this.addComment.bind(this);
    this.removePreview = this.removePreview.bind(this);
    this.addPost = this.addPost.bind(this);
    this.handlePostChange = this.handlePostChange.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.showEdit = this.showEdit.bind(this);
  }

  removePreview(){
    this.setState({
      meta: [],
    });
  }

  componentDidMount() {
    window.addEventListener('click', this.onDocumentClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onDocumentClick)
  }

  onDocumentClick(e) {
    if (this.elementRef.current && !this.elementRef.current.contains(e.target) && this.state.isMenuOpen === true) {
      this.setState({
        isMenuOpen: false
      })
    }
  }

  getPostComments(post){
    this.state.isCommentsLoaded ?
    this.setState({ isCommentsOpen: !this.state.isCommentsOpen })
    : 
    request.get(`comments/id/${post}`).then((response) => {
      this.setState({
        comments: this.state.comments.concat(response.data),
        isCommentsOpen: !this.state.isCommentsOpen,
        isCommentsLoaded: true,
      });
    })
  }

  handleCommentChange(e){
    var val = e.target.value;
    this.setState({comment: val});
    if(val && this.state.meta.length==0){
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      var url = val.match(urlRegex);
      if(url && validator.isURL(url[0])){
        request.post(`og`,{
          url: url[0],
        }).then((response) => {
          this.setState({
            meta: response.data,
          });
        });
      } 
    }
  }

  addComment(e){
    if (e.keyCode == 13) {
      request.post(`comments`,{
        comment: this.state.comment,
        meta: this.state.meta,
        post: this.props.postid,
        me: localStorage.loginId,
        member: this.props.member.id,
      }).then((response) => {
        this.setState({
          comments: response.data.concat(this.state.comments),
          meta: [],
          comment: '',
        });
      });
    }
  }

  removeComment(comment){
    request.delete(`comments/id/${comment}`).then((response) => {
      if(response.data.status === '1'){
        const comments = this.state.comments.filter(c => c.id != comment);
        this.setState({
          comments: comments,
        });
      }
    });
  }

  setEmotion(id, emotion){
    request.put(`comments`,{
      id: id,
      emotion: emotion,
    }).then((response) => {
      const comments = JSON.parse(JSON.stringify(this.state.comments));
      comments.filter(p => p.id == id)[0].emotions = response.data[0].emotions;
      this.setState({
        comments: comments,
      });
    });
  }

  handlePostChange(e){
    var val = e.target.value;
    this.setState({editPost: val});
    if(val && (!this.state.editMeta || this.state.editMeta.length === 0)){
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      var url = val.match(urlRegex);
      if(url && validator.isURL(url[0])){
        request.post(`og`,{
          url: url[0],
        }).then((response) => {
          this.setState({
            editMeta: response.data,
          });
        });
      } 
    }
  }

  addPost(){
    request.patch(`posts/${this.props.postid}`,{
      post: this.state.editPost,
      meta: this.state.editMeta
    }).then((response) => {
      if (response.status === 200) {
        if (typeof this.props.updatePost !== 'undefined')
          this.props.updatePost(this.props.postid, this.state.editPost, this.state.editMeta)
          this.setState({
            showModal: false,
            isMenuOpen: false,
            editPost: null,
            editMeta: []
          })
      }
    });
  }

  toggleMenuOpen = () => this.setState({ isMenuOpen: !this.state.isMenuOpen });
  toggleShareOpen = () => this.setState({ isShareOpen: !this.state.isShareOpen });
  toggleCommentsOpen = (e) => this.getPostComments(e);
  toggleActive = () => this.setState({ isActive: !this.state.isActive });

  showEdit = () => {
    this.setState({
      showModal: true,
      editPost: this.props.post ? this.props.post : this.props.full_description,
      editMeta: this.props.meta
    })
  }
    
  render() {
    
    const {user, user_id, time, desc, full_description, avater, postimage, postvideo, postid, meta, removePost, postowner, me, postcomments, postemotions, member, setEmotion, post_to, network_to, network_owner} = this.props;
    const image = me && me.profile_image != '' ? me.profile_image : null
    const emotions = (postemotions && postemotions.emoji ? postemotions.emoji.split(',') : null);
    const action = "postView";
    const menuClass = `${this.state.isMenuOpen ? " show" : ""}`;
    const shareClass = `${this.state.isShareOpen ? " show" : ""}`;
    const commentsClass = `${this.state.isCommentsOpen ? " show" : " hide"}`;
    const emojiClass = `${this.state.isActive ? " active" : ""}`;
    let to = <></>
    if ( (post_to && post_to.post_to_id && post_to.post_to_id !== member) ) {
      // to = <Link className='post-recipient' to={`/members/${post_to.post_to_id}`}>&#10148; {post_to.post_to_fname} {post_to.post_to_lname}</Link>
      to = <ProfilePreview 
              user={`${post_to.post_to_fname} ${post_to.post_to_lname}`}
              to={`/members/${post_to.post_to_id}`}
              recipient={true}
            />
    } else if (network_to && network_to.network_id) {
      to = <ProfilePreview 
              user={network_to.network_name}
              to={`/network/${network_to.network_id}`}
              recipient={true}
            />
    }
    
    return (
      <div className="card w-100 light-shadow-xss rounded-xxl border-0 p-4 mb-3">
        <div className="card-body p-0 d-flex">
          <div className='d-flex'>
          <ProfilePreview 
            user={user}
            to={`/members/${user_id}`}
            avatar={avater}
            time={time}
            member={this.props.rating ? {
              reputation: this.props.rating
            } : null}
          />
          {to}
          </div>
          {/* <h4><span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {time}</span></h4> */}
          {postowner ? 
          <div className="ms-auto" style={{position: 'relative'}} ref={this.elementRef}>
            <div onClick={this.toggleMenuOpen} className={`ms-auto pointer ${menuClass}`} style={{textAlign: 'right'}} id={`dropdownMenu${postid}`} data-bs-toggle="dropdown" aria-expanded="false">
              <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
            </div>
            <div className={`dropdown-menu p-3 right-0 rounded-xxl border-0 shadow-lg ${menuClass}`} aria-labelledby={`dropdownMenu${postid}`}>
              <div onClick={this.showEdit} className="card-body p-0 d-flex">
                <i className="feather-edit-3 text-grey-500 me-2 font-lg"></i>
                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-3 pointer">{i18n.t('button_edit')} <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500 mw-190">{i18n.t('button_edit_post_info')}</span></h4>
              </div>
              {/* <div onClick={() => removePost(postid)} className="card-body p-0 d-flex">
                <i className="feather-eye-off text-grey-500 me-2 font-lg"></i>
                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-3 pointer">{i18n.t('button_hide')} <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500 mw-190">{i18n.t('button_hide_post_info')}</span></h4>
              </div> */}
              <div onClick={() => removePost(postid)} className="card-body p-0 d-flex">
                <i className="feather-trash-2 text-grey-500 me-2 font-lg"></i>
                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-3 pointer">{i18n.t('button_delete')} <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500 mw-190">{i18n.t('button_delete_post_info')}</span></h4>
              </div>
            </div>
          </div> 
          : '' }
          {!postowner && network_owner ? 
          <div className="ms-auto" style={{position: 'relative'}} ref={this.elementRef}>
            <div onClick={this.toggleMenuOpen} className={`ms-auto pointer ${menuClass}`} style={{textAlign: 'right'}} id={`dropdownMenu${postid}`} data-bs-toggle="dropdown" aria-expanded="false">
              <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
            </div>
            <div className={`dropdown-menu p-3 right-0 rounded-xxl border-0 shadow-lg ${menuClass}`} aria-labelledby={`dropdownMenu${postid}`}>
              
              <div onClick={() => removePost(postid)} className="card-body p-0 d-flex">
                <i className="feather-trash-2 text-grey-500 me-2 font-lg"></i>
                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-3 pointer">{i18n.t('button_delete')} <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500 mw-190">{i18n.t('button_delete_post_info')}</span></h4>
              </div>
            </div>
          </div> 
          : '' }
        </div>
        
        {postvideo ?  
        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
          <a href="/defaultvideo" className="video-btn">
            <video autoPlay loop className="float-right w-100">
              <source src={`${postvideo}`} type="video/mp4" />
            </video>
          </a>
        </div>
        : ''}
        <div className="card-body p-0 me-lg-5">
          <p className="fw-500 text-grey-900 font-xsss w-100 mb-2">
            {desc}
            {/* <a href="/defaultvideo" className="fw-600 text-primary ms-2">See more</a> */}
          </p>
        </div>
        {postimage ?
        <div className="card-body d-block p-0 mb-3">
          <div className="row ps-2 pe-2">
            <div className="col-sm-12 p-1"><img src={`${postimage}`} className="rounded-3 w-100" alt="post" /></div>                                        
          </div>
        </div>
        : ''}
        {meta ? <Preview meta={meta} action={action} /> : null}
        <div className="card-body d-flex p-0">
          <div onMouseOver={this.toggleActive} onMouseOut={this.toggleActive} style={{position: 'relative', marginTop: '5px'}}>
            <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
            {postemotions && postemotions.me ? <span>{i18n.t(`${postemotions.me}`)} </span> : <span>{i18n.t('like')} </span> }
              &nbsp;&nbsp;&nbsp;&nbsp; 
            </div>
            <div className={`emoji-wrap pointer ${emojiClass}`} style={{ minWidth: '310px', bottom: '30px'}}>
                <ul className="emojis list-inline mb-0">
                    <li onClick={() => setEmotion(postid, "--1")} className="emoji list-inline-item"><i className="em em---1"></i> </li>
                    <li onClick={() => setEmotion(postid, "heart")} className="emoji list-inline-item"><i className="em em-heart"></i> </li>
                    <li onClick={() => setEmotion(postid, "blush")} className="emoji list-inline-item"><i className="em em-blush"></i></li>
                    <li onClick={() => setEmotion(postid, "smile")} className="emoji list-inline-item"><i className="em em-smile"></i> </li>
                    <li onClick={() => setEmotion(postid, "astonished")} className="emoji list-inline-item"><i className="em em-astonished"></i> </li>
                    <li onClick={() => setEmotion(postid, "cry")} className="emoji list-inline-item"><i className="em em-cry"></i></li>
                    <li onClick={() => setEmotion(postid, "angry")} className="emoji list-inline-item"><i className="em em-angry"></i></li>
                </ul>
            </div>
          </div>
          <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2 position-relative emoji-previewer-wrapper"
            
          >
            {emotions ? 
              <Fragment>
                {
                  emotions && emotions.map( (emotion, i) => {
                    return <i key={i} className={`em em-${emotion} font-xssss`} />
                  })
                }
              </Fragment>
            : null }
            &nbsp;
            {postemotions && postemotions.total > 0 ? 
              <Fragment>
                {postemotions.total}
              </Fragment>
            : null }
            <div className='emoji-realtors-wrapper scroll-bar' ref={this.emojiRef}>
              {
                (this.props.raw_data && this.props.raw_data.emotions_realtors) &&
                this.props.raw_data.emotions_realtors.map(r => {
                  return (
                    <Link to={`/members/${r.user_id}`} className='emoji-realtor font-xssss'>
                      <i className={`em em-${r.emotion}`}></i>
                      <span>{`${r.first_name} ${r.last_name}`}</span>
                    </Link>
                  )
                }) 
              }
            </div>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a onClick={() => this.toggleCommentsOpen(postid)} id={`leaveACommentOnPost${postid}`} className="d-flex pointer align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss">
            <i className="feather-message-square text-dark text-grey-900 btn-round-sm font-sm"></i>
            <span className="d-none-xss">{postcomments == 0 ? i18n.t('comment') : ( postcomments == 1 ? `${postcomments} ${i18n.t('comment')}` : `${postcomments} ${i18n.t('comments')}`) }</span>
          </a>
          {/* <div className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ${shareClass}`} id={`share${postid}`} data-bs-toggle="dropdown" aria-expanded="false" onClick={this.toggleShareOpen}><i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-sm"></i></div>
          <div className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0 ${shareClass}`} aria-labelledby={`share${postid}`}>
            <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">Share <i className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2" onClick={this.toggleShareOpen}></i></h4>
            <div className="card-body p-0 d-flex">
              <ul className="d-flex align-items-center justify-content-between mt-2">
                <li className="me-1"><span className="btn-round-lg pointer bg-facebook"><i className="font-xs ti-facebook text-white"></i></span></li>
                <li className="me-1"><span className="btn-round-lg pointer bg-twiiter"><i className="font-xs ti-twitter-alt text-white"></i></span></li>
                <li className="me-1"><span className="btn-round-lg pointer bg-linkedin"><i className="font-xs ti-linkedin text-white"></i></span></li>
                <li className="me-1"><span className="btn-round-lg pointer bg-instagram"><i className="font-xs ti-instagram text-white"></i></span></li>
                <li><span className="btn-round-lg pointer bg-pinterest"><i className="font-xs ti-pinterest text-white"></i></span></li>
              </ul>
            </div>
            <div className="card-body p-0 d-flex">
              <ul className="d-flex align-items-center justify-content-between mt-2">
                <li className="me-1"><span className="btn-round-lg pointer bg-tumblr"><i className="font-xs ti-tumblr text-white"></i></span></li>
                <li className="me-1"><span className="btn-round-lg pointer bg-youtube"><i className="font-xs ti-youtube text-white"></i></span></li>
                <li className="me-1"><span className="btn-round-lg pointer bg-flicker"><i className="font-xs ti-flickr text-white"></i></span></li>
                <li className="me-1"><span className="btn-round-lg pointer bg-black"><i className="font-xs ti-vimeo-alt text-white"></i></span></li>
                <li><span className="btn-round-lg pointer bg-whatsup"><i className="font-xs feather-phone text-white"></i></span></li>
              </ul>
            </div>
            <h4 className="fw-700 font-xssss mt-4 text-grey-500 d-flex align-items-center mb-3">Copy Link</h4>
            <i className="feather-copy position-absolute right-35 mt-3 font-xs text-grey-500"></i>
            <input type="text" placeholder="https://socia.be/1rGxjoJKVF0" className="bg-grey text-grey-500 font-xssss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg" />
          </div> */}
        </div>
        <div aria-labelledby={`leaveACommentOnPost${postid}`} className={`post-comments ${commentsClass}`}>
          
          {
            this.state.comments && this.state.comments.map( (c, i) => {
              return <Commentview key={i} 
                postid={postid}
                commentid={c.id} 
                commentowner={c.owner}
                avater={c.user_image} 
                user={c.user_name} 
                
                meta={c.meta ? c.meta : null}
                me={storage.me}
                member={member}
                time={ Utils.getMomentText(c.timestamp) } 
                desc={ Utils.getMinifiedDescription(c.description, 200) } 
                commentreplieces={c.replieces}
                commentemotions={c.emotions}
                removeComment={(e) => this.removeComment(e)}
                setEmotion={(a,b) => this.setEmotion(a,b)}
              />
            })
          }
          <div className="card-body p-0 mt-3 position-relative">
            <figure className="avatar position-absolute ms-2 mt-1 top-5">
              <img src={image || "assets/images/user.png"} alt="icon" className="shadow-sm rounded-circle w35" />
            </figure>
            <textarea name="comment" onChange={this.handleCommentChange} value={this.state.comment} onKeyDown={this.addComment} className="h100 sm-h45 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" cols="30" rows="10" placeholder={i18n.t('write_a_comment')}></textarea>
          </div>
          {this.state.meta.length > 0 ? <Preview meta={this.state.meta[0]} removePreview={this.removePreview} action="createComment" /> : null}
        </div>
        <ModalGeneric
          show={this.state.showModal}
          handleClose={() => this.setState({
            showModal: false,
            editPost: this.props.post,
            editMeta: this.props.meta ? this.props.meta : []
          })}
          header={<h4>{i18n.t('edit_post')}</h4>}
          footer={<div className="card-body p-0 d-flex space-between open-house-footer align-center">
                <div className="d-flex">
                  <button onClick={() => this.addPost()} className="bg-success btn-action btn-accept p-2 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-600">{i18n.t('save')}</button>
                  <button onClick={() => this.setState({
                                    showModal: false
                                  })} 
                    className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600"
                  >{i18n.t('cancel')}</button>
                </div>
              </div>}
        >
          <div className="card w-100 border-0 mb-3">
            <div className="card-body mt-2 p-0">
              <Editpost
                me={storage.me}
                meta={this.state.editMeta && this.state.editMeta !== null ? this.state.editMeta : null}
                post={this.state.editPost && this.state.editPost !== null ? this.state.editPost : full_description}
                handlePostChange={this.handlePostChange}
                addPost={this.addPost}
              />
            </div>
          </div>
        </ModalGeneric>
      </div>
    );
  }
}

export default Postview;