import React from 'react';
import notificationsHelper from '../notifications-helper';
import moment from 'moment';
import i18n from '../i18n';
import request from '../request';
import { Button } from 'react-bootstrap';
import ModalGeneric from './ModalGeneric'
import Cooperation from './Cooperation'
import Swal from 'sweetalert2';
import socket from '../socket';
import storage from '../storage';
import Utils from '../utils';

class NotificationCooperationRequestItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: notificationsHelper.getMessage(props.data.type),
      menu: false,
      showModal: false
    };

    this.elementRef = React.createRef();

    this.deleteNotification = this.deleteNotification.bind(this);
    this.setNotificationStatus = this.setNotificationStatus.bind(this);
  }

  componentDidMount() {
    i18n.onChangeLocale(() => {
      this.setState({
        ...this.state,
        message: notificationsHelper.getMessage(this.props.data.type),
      });
    });

    this.onWindowClick = (e) => {
      if (!this.elementRef.current.querySelector('.btn-menu').contains(e.target) && this.state.menu === true) {
        this.setState({
          ...this.state,
          menu: false,
        });
      }
    };
    window.addEventListener('click', this.onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  respondToCooperationRequest(cooperationRequest, requestResponse) {
    // request.post(`networks/id/${networkRequestId}/response/${requestResponse}`).then(async (response) => {
      
    // });
  }

  deleteNotification() {
    request.delete(`notifications/id/${this.props.data.id}`).then(() => {
      this.props.onDelete();
    });
  }

  setNotificationStatus() {
    request.patch(`notifications/id/${this.props.data.id}/status/${this.props.data.status === '1' ? 'unread' : 'read'}`).then((response) => {
      this.props.onStatusChange(response.data.notification_status);
    });
  }

  setManualNotificationStatus(read = 'read') {
    request.patch(`notifications/id/${this.props.data.id}/status/${read}`).then((response) => {
      this.props.onStatusChange(response.data.notification_status);
    });
  }

  respond(id, answer) {
    request.patch(`respond_collab/${id}`, {
      status: answer,
    }).then((response) => {
      if (response.data.attempt_status === 200) {
        if (answer === 1) {
          Swal.fire({
            title: i18n.t('cooperation_accepted'),
            text: i18n.t('cooperation_moved_to_active'),
            icon: 'success'
          })

          socket.send({
						type: 'event',
						data: {
						  class: 'cooperation-accepted',
						  realtor: storage.me.realtors_id,
						  cooperationId:  id
						},
					});
          notificationsHelper.fetchCooperationsCount()
        } else if (answer === 2) {
          Swal.fire({
            title: i18n.t('cooperation_rejected'),
            text: i18n.t('cooperation_moved_to_rejected'),
            icon: 'success'
          })
        }
        this.setManualNotificationStatus('read')
        this.setState({
          ...this.state,
          showModal: false
        })
      }
    })
  }
  

  render() {
    return (
      <div
        className="notification-item notification--network-request-item card bg-transparent-card w-100 border-0 ps-5 mb-3"
        ref={this.elementRef}
      >
        <div className="image position-absolute left-0">
          <img src={this.props.realtor.avatar} alt="user" className="img-circle w50" />
          <div className="notification-type-icon bg-primary">
            <i className="feather-globe text-white"></i>
          </div>
        </div>
        <div className="d-flex flex-direction-row justify-content-between">
          <div className="ms-3 mt-2">
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {this.props.realtor.name}
              <span className="text-grey-400 font-xsssss fw-600 float-right mt-1">
                {Utils.getMomentText(moment(this.props.data.date).format("X"))}  
              </span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4 d-flex justify-content-between align-items-center">
              <span>{i18n.t('has_sended_cooperation_request')}</span>
            </h6>
            <div className="controls">
                <Button
                  className={`px-1 py-0 lh-24 ls-3 font-xsssss fw-700 ls-lg bg-primary text-white white-space-no-wrap`}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      showModal: true
                    })
                  }}
                >
                  {i18n.t('show_cooperation_request')}
                </Button>
                {/* <Button
                  className={`px-1 py-0 lh-24 ls-3 font-xsssss fw-700 ls-lg bg-primary text-white white-space-no-wrap`}
                  onClick={() => this.respondToCooperationRequest(this.props.data.cooperations_id, 1)}
                >
                  {i18n.t('accept')}
                </Button>
                <Button
                  className={`button--reject ms-1 px-1 py-0 lh-24 ls-3 font-xsssss fw-700 ls-lg white-space-no-wrap`}
                  onClick={() => this.respondToCooperationRequest(this.props.data.cooperations_id, 2)}
                >
                  {i18n.t('reject')}
                </Button> */}
            </div>
          </div>
          <div className={`btn-menu ${this.state.menu ? 'active' : ''}`}>
            <i
              className="feather-more-vertical font-size-30"
              onClick={() => {
                this.setState({ ...this.state, menu: !this.state.menu });
              }}
            ></i>
            <div className={`menu ${this.state.menu ? 'open' : 'd-none'}`}>
              <span
                className="text-default"
                onClick={this.setNotificationStatus}
              >
                {
                  this.props.data.status === '0'
                    ? i18n.t('button_notification_read')
                    : i18n.t('button_notification_unread')
                }
              </span>
              <span
                className="text-default"
                onClick={this.deleteNotification}
              >
                {i18n.t('delete_notification')}
              </span>
            </div>
          </div>
        </div>
        <ModalGeneric
					show={this.state.showModal}
					handleClose={() => this.setState({
						...this.state,
						showModal: false
					})}
					header={<h4>{i18n.t('cooperation_request')}</h4>}
					footer={<div className="card-body p-0 d-flex space-between open-house-footer align-center">
								<div className="d-flex">
									<button onClick={() => this.setState({
						...this.state,
						showModal: false
					})} className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">{i18n.t('close')}</button>
								</div>
							</div>}
				>
					<div className="card w-100 border-0 p-4 mb-3">
						<div className="card-body mt-2 p-0">
              <Cooperation 
                data={this.props.data.data.cooperation}
                resendSign={(e) => {}}
                respond={(e, i) => this.respond(e, i)}
                evaluate={(e) => {}}
                cancelRequest={(e) => {}}
                removeCollab={(e) => {}}
                openRatingCooperation={(coop_id) => {}}
              />
						</div>
					</div>
				</ModalGeneric>
      </div>
    );
  }
}

export default NotificationCooperationRequestItem;