import React, { Component } from "react";
import {
  Link,
} from 'react-router-dom';
import { Tooltip } from "@mui/material";
import Button from '../Button';
import i18n from "../../i18n";
import request from '../../request';
import storage from '../../storage';
import socket from '../../socket';

class NetworksProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      networks: [],
      page: 1,
      loaded: false,
      end: false,
      imageDialogOpen: false,
      imageType: '',
      selectedNetwork: null,
    };

    this.getNetworks = this.getNetworks.bind(this);
  }

  componentDidMount() {

    this.getNetworks();

    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    storage.onChange(() => {
      this.forceUpdate();
    });

    this.onDocumentScroll = (e) => {
      const totalPageHeight = document.body.scrollHeight - 1; 
      const scrollPoint = window.scrollY + window.innerHeight;
      if(scrollPoint >= totalPageHeight) {
        this.showMoreNetworks();
      }
    };
    document.addEventListener('scroll', this.onDocumentScroll);
  }

  getNetworks(page) {
    const url =  `networks/members/${this.props.member.id}/?page=${page || this.state.page}`;

    return request.get(url).then((response) => {
      this.setState({
        ...this.state,
        networks: this.state.networks.concat(response.data),
        loaded: true,
        end: response.data.length < 10,
      });
    });
  }

  showMoreNetworks() {
    if(!this.state.end){
      const newPage = this.state.page + 1;
      this.getNetworks(newPage);
      this.setState({
        ...this.state,
        page: newPage,
      });
    }
  }

  sleep(timeout) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, timeout);
    });
  }

  async leaveNetwork(networkId) {
    await this.sleep(2000);
    request.delete(`members/id/${networkId}`).then((response) => {
      const n = this.state.networks.slice(0);
      const i = n.findIndex((n) => n.id === networkId);
      n[i].joined = response.data.joined;
      this.setState({
        ...this.state,
        networks: n,
      });
    });
  }

  async joinNetwork(networkId) {
    await this.sleep(2000);

    request.put(`members/id/${networkId}`).then((response) => {
      const n = this.state.networks.slice(0);
      const i = n.findIndex((n) => n.id === networkId);
      n[i].joined = response.data.joined;
      n[i].pending = response.data.pending;
      this.setState({
        ...this.state,
        networks: n,
      });

      if (response.data.status === '1' && response.data.pending) {
        request.put(`notifications/to/${response.data.owner}/type/network-request/ref/${networkId}`).then(() => {
          socket.send({
            type: 'event',
            data: {
              class: 'network-request',
              networkOwner: response.data.owner,
            },
          });
        });
      }
    });
  }

  changeNetworkImage(e) {
    const network = this.state.networks.findIndex(n => n.id == this.state.selectedNetwork);
    const networks = JSON.parse(JSON.stringify(this.state.networks));
    networks[network].image = e;
    this.setState({
      ...this.state,
      networks: networks
    });
  }

  handleClickImageDialogOpen = (a, b) => {
    this.setState({ 
      ...this.state,
      imageDialogOpen: true,
      imageType: a,
      selectedNetwork: b,
    });
  };

  handleClickImageDialogClose = () => {
    this.setState({ 
      ...this.state,
      imageDialogOpen: false,
      imageType: '',
      selectedNetwork: null, 
    });
  };

  componentWillUnmount() {
    i18n.clearOnChangeLocale();
    document.removeEventListener('scroll', this.onDocumentScroll);
  }

  getNetworkMembers(network) {
    return <>
      {
          network.members_count >= 1
          ? <li><a><img src={network.members[0].profile_image} alt={`User ${network.members[0].id}`} className="w30 d-inline-block img-circle" /></a></li>
          : ''
      }
      {
        network.members_count >= 2
          ? <li><a><img src={network.members[1].profile_image} alt={`User ${network.members[1].id}`} className="w30 d-inline-block img-circle" /></a></li>
          : ''
      }
      {
        network.members_count >= 3
          ? <li><a><img src={network.members[2].profile_image} alt={`User ${network.members[2].id}`} className="w30 d-inline-block img-circle" /></a></li>
          : ''
      }
      {
        network.members_count >= 4
          ? <li><a><img src={network.members[3].profile_image} alt={`User ${network.members[3].id}`} className="w30 d-inline-block img-circle" /></a></li>
          : ''
      }
      {
        network.members_count >= 5
          ? <li className="last-member">
              <a className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center">+{network.members_count - 4}</a>
            </li>
          : ''
      }
    </>
  }

  render() {
    return (
		<div className="row">
		{this.state.networks.map((network , index) => {
		  //const hasActionsOn = network.actions;
		  const hasActionsOn = true;
		  const amOnNetwork  = network.joined;
		  const canJoinDirectly = !amOnNetwork && !network.private;
		  const hasPendingJoinRequest = network.pending;
		  const canRequestToJoin = !amOnNetwork && network.private && !hasPendingJoinRequest;
		  const amOwner = storage.me && (network.owner === storage.me.realtors_id);
		  const amMemberOrAdmin = !amOwner;
		  const networkIsPublic = !network.private;
		  const networkImage    = network.image ? network.image : `https://network.realstatus.net/images/network_image/default.jpg`;
		  const coverImage      = network.cover ? network.cover: `/assets/images/covers/abstract.jpg`;
		  return (
			<div key={index} className="col-lg-6 col-md-6 col-12 pe-2 ps-2">
			  <div className="card p-3 bg-white w-100 network-thumb hover-card border-0 shadow-xss position-relative rounded-xxl border-0 mb-3 overflow-hidden ">
				<div style={{position: 'relative', zIndex: 2}}>
				  {amOwner ?
					<Tooltip title={i18n.t('update_network_image')} placement="top">
					  <i onClick={(a, b)=>this.handleClickImageDialogOpen('network_image', network.id)}
						  className="text-grey-900 btn-round-md bg-greylight font-md feather-camera me-3 text-grey-500"
						  style={{position: 'absolute', top: '2%', right: '-2%', cursor: 'pointer'}}>
					  </i>
					</Tooltip>
					: null }
				  <div className="card-image">
					<div className="network-avatar">
					  <img src={networkImage} alt="networkImage" className="rounded-3" />
					</div>
					  {
					  hasActionsOn && canRequestToJoin && <Button
						className={`cursor-pointer font-xsssss fw-700 ps-3 pe-3 lh-32 float-right mt-4 text-uppercase rounded-xl ls-2 text-white me-1 bg-primary`}
						onClick={() => this.joinNetwork(network.id)}
					  >
						{i18n.t('button_request_join_network')}
					  </Button>
					}
					{
					  hasActionsOn && hasPendingJoinRequest && <Button
						className={`button-disabled font-xsssss fw-700 ps-3 pe-3 lh-32 float-right mt-4 text-uppercase rounded-xl ls-2 text-white me-1 bg-primary`}
					  >
						{i18n.t('button_request_join_network_pending')}
					  </Button>
					}
					{
					  hasActionsOn && canJoinDirectly && <Button
						className={`cursor-pointer font-xsssss fw-700 ps-3 pe-3 lh-32 float-right mt-4 text-uppercase rounded-xl ls-2 text-white btn-action me-1 bg-success`}
						onClick={() => this.joinNetwork(network.id)}
					  >
						{i18n.t('button_join_network')}
					  </Button>
					}
					{
					  hasActionsOn && amOnNetwork && amMemberOrAdmin && <Button
						className={`cursor-pointer font-xsssss fw-700 ps-3 pe-3 lh-32 float-right mt-4 text-uppercase rounded-xl ls-2 text-white me-1 btn-action btn-leave`}
						onClick={() => this.leaveNetwork(network.id)}
					  >
						{i18n.t('button_leave_network')}
					  </Button>
					}
				  </div>
				</div>
				<div className="fw-700 lh-3 font-xss">
				 
				  
				</div>
				<div className="card-body ps-0 pe-0 pb-0 z-index-1">
					<div className="text-break">
					  {
						(amOnNetwork || networkIsPublic)
						  ? <h4><Link to={`/network/${network.id}`}>{network.name}</Link></h4>
						  : <h4>{network.name}</h4>
					  }
					</div>
					<div className="details">
					  {
						network.private
						  ? <div><i className="feather-lock"></i><span>{i18n.t('private')}</span></div>
						  : <div><i className="feather-globe"></i><span>{i18n.t('public')}</span></div>
					  }
					</div>
				  
				</div>
				<div className="card-body d-flex p-0 z-index-1 justify-content-center align-items-center">
					<span className="d-flex font-xsss fw-500 mt-2 lh-3 text-grey-500 bord-r position-relative pad-1512 text-center">
						
						
						{
						  (amOnNetwork || networkIsPublic)
							? <Link to={`/network/${network.id}/properties`}><span>{network.properties}<br></br>{i18n.t('properties')}</span></Link>
							: <span>{network.properties}<br></br>{i18n.t('properties')}</span>
						}
						
					  </span>
					  <span className="d-flex font-xsss fw-500 mt-2 lh-3 text-grey-500 position-relative pad-1512 text-center">
						{
						  (amOnNetwork || networkIsPublic)
							? <Link to={`/network/${network.id}/requests`}><span>{network.requests}<br></br>{i18n.t('requests')}</span></Link>
							: <span>{network.requests}<br></br>{i18n.t('requests')}</span>
						}
						
					  </span>
				  </div>
				<div className="card-body p-0 z-index-1">
				  <ul className={`memberlist mt-4 mb-2 ms-0 d-inline-block`}>
					{
					  network.joined ?
					  this.getNetworkMembers(network)
					  : ''
					}
				  </ul>
				  
				</div>
				<div className="network-cover-img" style={{backgroundImage: `url(${coverImage})`}}></div>
			  </div>
			</div>
		  );
		})}
	  </div>
    );
  }
}

export default NetworksProfile;