import React from 'react';
import notificationsHelper from '../notifications-helper';
import moment from 'moment';
import i18n from '../i18n';
import request from '../request';
import Button from '../components/Button';
import helper from '../helper';
import Utils from '../utils';

class NotificationFriendRequestAcceptedItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: notificationsHelper.getMessage(props.data.type),
      menu: false,
    };

    this.elementRef = React.createRef();

    this.deleteNotification = this.deleteNotification.bind(this);
    this.setNotificationStatus = this.setNotificationStatus.bind(this);
  }

  componentDidMount() {
    i18n.onChangeLocale(() => {
      this.setState({
        ...this.state,
        message: notificationsHelper.getMessage(this.props.data.type),
      });
    });

    this.onWindowClick = (e) => {
      if (!this.elementRef.current.querySelector('.btn-menu').contains(e.target) && this.state.menu === true) {
        this.setState({
          ...this.state,
          menu: false,
        });
      }
    };
    window.addEventListener('click', this.onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }


  deleteNotification() {
    request.delete(`notifications/id/${this.props.data.id}`).then(() => {
      this.props.onDelete();
    });
  }

  setNotificationStatus() {
    request.patch(`notifications/id/${this.props.data.id}/status/${this.props.data.status === '1' ? 'unread' : 'read'}`).then((response) => {
      this.props.onStatusChange(response.data.notification_status);
    });
  }

  render() {
    return (
      <div
        className="notification-item notification--friend-request-item card bg-transparent-card w-100 border-0 ps-5 mb-3"
        ref={this.elementRef}
      >
        <div className="image position-absolute left-0">
          <img src={this.props.data.avatar} alt="user" className="img-circle w50" />
          <div className="notification-type-icon bg-primary">
            <i className="feather-user text-white"></i>
          </div>
        </div>
        <div className="d-flex flex-direction-row justify-content-between">
          <div className="w-100 ms-3 mt-2">
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {this.props.data.name}
              <span className="text-grey-400 font-xsssss fw-600 float-right mt-1">
                {Utils.getMomentText(moment(this.props.data.date).format("X"))}  
              </span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4 d-flex justify-content-between align-items-center">
              <span>{this.state.message}</span>
              {
                this.props.data.status === '0' && <span className="bg-primary btn-round-xss"></span>
              }
            </h6>
            
             <span className="font-xssss text-default">{i18n.t('friend_request_message_accepted')}</span>
          </div>
          <div className={`btn-menu ${this.state.menu ? 'active' : ''}`}>
            <i
              className="feather-more-vertical font-size-30"
              onClick={() => {
                this.setState({ ...this.state, menu: !this.state.menu });
              }}
            ></i>
            <div className={`menu ${this.state.menu ? 'open' : 'd-none'}`}>
              <span
                className="text-default"
                onClick={this.setNotificationStatus}
              >
                {
                  this.props.data.status === '0'
                    ? i18n.t('button_notification_read')
                    : i18n.t('button_notification_unread')
                }
              </span>
              <span
                className="text-default"
                onClick={this.deleteNotification}
              >
                {i18n.t('delete_notification')}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationFriendRequestAcceptedItem;