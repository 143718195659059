import React, { Component , createRef, Fragment, useEffect, useState, useMemo } from "react";
import i18n from "../i18n";
import RenewPlan from "./RenewPlan";


const MandatoryPayment = (props) => {
	
	
	return (
		<Fragment> 
			<div className="main-wrap">
				<div className="nav-header bg-transparent shadow-none border-0">
					<div className="nav-top navigation-login-menu w-100 justify-content-xs-end justify-content-sm-end">
						{/* <img src="assets/images/realstatus-logo.png" className="app-logo ms-5" alt="Real Status Logo Symbol" height="70" /> */}
						{/* <button className="nav-menu me-0 ms-auto"></button> */}

						{/* <Link to="/login" className="header-btn d-none d-lg-block background-light-blue fw-500 text-white font-xsss p-2 ms-auto w100 text-center lh-20 rounded-xl">{i18n.t('login')}</Link>
						<Link to="/register" className="header-btn d-none d-lg-block bg-secondary fw-500 text-white font-xsss p-2 ms-2 w100 text-center lh-20 rounded-xl">{i18n.t('register')}</Link> */}

						<span className="pointer p-2 text-center ms-3 btn-locale"
							onClick={() => {
								if(i18n.getLocale() === 'en')
									i18n.setLocale('el')
								else
									i18n.setLocale('en')
							}}
							>
							<i className="feather-globe"></i>
						</span>
					</div>
				</div>
				<RenewPlan/>
			</div>
		</Fragment>
	);
}


export default MandatoryPayment;