import React,{Component} from 'react';
import i18n from '../i18n';
import Button from './Button';
import Preview from '../components/Preview';

class Createpost extends Component {

  constructor(props) {
    super(props);
    this.state = {
        isOpen: false
    };
  }

  componentDidMount() {
    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const menuClass = `${this.state.isOpen ? " show" : ""}`;
    // const image = this.props.member.profile_image ? this.props.member.profile_image : null
    const image = this.props.me && this.props.me.profile_image != '' ? this.props.me.profile_image : null
    const meID = this.props.me && this.props.me.realtors_id;
    const memberID = this.props.member && this.props.member.id;
    const networkID = this.props.network && this.props.network.id;
    const its_me = meID == memberID;
    const meta = this.props.meta;
    const post = this.props.post;
    const action = "createPost";
    return (
      <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
        <div className="card-body p-0 mt-3 position-relative">
          <figure className="avatar position-absolute ms-2 mt-1 top-5">
            <img src={image || "assets/images/user.png"} alt="user" className="shadow-sm rounded-circle w30" />
          </figure>
          <textarea onChange={this.props.handlePostChange} value={post} name="message" className="h100 placeholder-14 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" cols="30" rows="10" placeholder={its_me ? i18n.t('whats_on_your_mind') : memberID ? i18n.t('write_something_to') : i18n.t('write_something_to_network')}></textarea>
        </div>
        {meta ? <Preview meta={meta} removePreview={this.props.removePreview} action={action} /> : null}
        <div className="card-body d-flex p-0 mt-0">
          {/* <a href="#photo" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-success feather-image me-2"></i><span className="d-none-xs">{i18n.t('photo')}</span></a> */}
          
          <Button onClick={this.props.addPost} className="ms-auto align-items-center bg-success p-2 z-index-1 rounded-xl text-white font-xsssss fw-700 ls-3 text-center">{i18n.t('create_post')}</Button>
        </div>
      </div>
    );
  }
}

export default Createpost;