import '../css/PropertyDetails.css';
import React, { Component, Fragment, createRef } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import i18n from "../i18n";
import request from "../request"
import Button from '../components/Button';
import ModalGeneric from '../components/ModalGeneric';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity, faPlane, faWater, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import Rightchat from "../components/Rightchat";
import Popupchat from "../components/Popupchat";
import ReputationData from "../components/ReputationData";
import { Link } from 'react-router-dom';
import config from '../config';
import Utils from '../utils';
import storage from '../storage';
import socket from '../socket';


class PropertyDetails extends Component {
  
  constructor(props) {
    super(props);

    this.searchRef = createRef()

    this.state = {
      show_suggestions: true,
      showModal: false,
      search_text: '',
      search_results: [],
      selected_requests: [],
      message: '',
      property: {
        details: {
          auto_desc: {},
          description: {},
          d_airport: '',
          d_city: '',
          d_sea: '',
        },
        images: [],
        lookups: {
          categories: {},
          heat: {},
          energy_class: {},
          floors: {},
          parking: {},
          structure_factor: {},
          coverage_factor: {},
          zoning: {},
          height: {},
          depth: {},
          facade: {},
          access_type: {},
          access_from: {},
          orientations: {},
          views: {},
          land_slope: {},
          land_use: {},
          view: {},
        },
        extras: {},
        location: {},
        realtor: {},
      },
      distances: [],
      collab: false,
      too_many_requests: false,
      fetchingPropertyData: false,
      propertyDataFetched: false,
      serverErrorMessage: '',
      tab1: 0,
      isOpen: false,
      showAllExtras: false,
      similarproperties: [],
    };

    this.showAllExtrasToggleRef = React.createRef();

    this.dragDetect = this.dragDetect.bind(this)

    
    
  }

  
  componentDidMount() {
    this.inputClicked = () => {
			this.setState({
				show_suggestions: true
			})
		}
		this.documentClicked = (e) => {
			
			if (this.searchRef.current && this.searchRef.current !== e.target && !this.searchRef.current.contains(e.target) && this.state.show_suggestions === true) {
				this.setState({
					show_suggestions: false
				})
            }
			
		}
    if (this.searchRef.current)
		  this.searchRef.current.addEventListener('click', this.inputClicked)
		document.addEventListener('click', this.documentClicked)

    
    

    request.get(`properties/id/${this.props.match.params.id}`).then((response) => {

      const updatedState = {
        fetchingPropertyData: false,
      };

      if (response.data.status === '1') {
        updatedState.property = {
          ...response.data.data.property[0],
          images: response.data.data.images.map(img => {
            return {
              ...img,
              image_url: `${config.imageURL}/${img.image_url}`
            }
          }),
        };
        updatedState.propertyDataFetched = true;
      } else {
        updatedState.serverErrorMessage = response.data.message;
      }

      this.setState(updatedState, () => {
        request.get(`listings/members/${this.state.property.realtor.id}`).then((response) => {
          this.setState({
            //...this.state,
            similarproperties: response.data.listings
          })
        });
      });
    });

    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    this.setState({
      // ...this.state,
      fetchingPropertyData: true,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.documentClicked)
  }

  cooperationRequestInit() {
    this.setState({
      // ...this.state,
      showModal: true,
    });
  }

  searchRequests(str) {
		if (str.length > 2) {
			request.get(`my_requests/${str}`).then(response => {
				this.setState({
					// ...this.state,
					search_results: response.data
				})
			})
		}
	}

  cooperationRequest(){
    request.put(`collab`, {
      type: 1,
      related_id: this.state.property.details.id,
      refs: this.state.selected_requests.map(s => s.id),
			message: this.state.message
    }).then((response) => {
      if (response.data.attempt_status === 200 && response.data.data !== null) {
        socket.send({
          type: 'event',
          data: {
            class: 'cooperation-request',
            cooperationId: response.data.data.cooperations_id,
            realtor: this.state.property.realtor.id
          }
        })
        this.setState({
          // ...this.state,
          collab: response.data.data,
          showModal: false
        }, () => {
          Swal.fire({
            title: i18n.t('cooperation_request_sended'),
            text: i18n.t('you_will_be_informed_if_coop_accepted'),
            icon: 'success'
          })
        })
      } else if (response.data.attempt_status === 429) {
        this.setState({
          // ...this.state,
          collab: false,
          too_many_requests: true
        })
        Swal.fire({
          title: i18n.t('cooperation_request_not_sended'),
          text: i18n.t('wait_1_day_before_resending_request'),
          icon: 'error'
        })
      } else if (response.data.attempt_status === 201) {
        Swal.fire({
          title: i18n.t('request_already_sended'),
          text: i18n.t('you_will_be_informed_if_coop_accepted'),
          icon: 'info'
        })
      } else if (response.data.attempt_status === 401) {
        Swal.fire({
          title: i18n.t('cooperation_request_not_sended'),
          text: i18n.t('you_are_not_connected_with_user'),
          icon: 'error'
        })
      }
    });
  }

  cancelCooperationRequest(){
    request.delete(`collab/type/1/id/${this.state.property.details.id}`, {
      type: 1,
      related_id: this.state.property.details.id
    }).then((response) => {
      if (response.data.attempt_status === 200) {
        this.setState({
          // ...this.state,
          collab: false
        })
      }
    });
  }

  dragDetect = (event, info) => {

    if (event.type === 'mousemove' || event.type === 'touchmove') {
      this.setState({
        // ...this.state,
        is_dragging: true
      })
    }

    if (event.type === 'mouseup' || event.type === 'touchend') {
      setTimeout(() => {
        this.setState({
          // ...this.state,
          is_dragging: false
        })
      }, 100);

    }
  }

  render() {
    const { tab1, isOpen, } = this.state;
    const property_settings = {
      arrows: true,
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: (this.state.property.images.length < 3) ? this.state.property.images.length : 3,
      swipeToSlide: true,
      centerMode: false,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 426,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      beforeChange: () => this.setState({
        // ...this.state,
        is_dragging: true
      }),
      afterChange: () => this.setState({
        // ...this.state,
        is_dragging: false
      }),
    };

    const property = this.state.property;
    const int_to = property.details.int_to === '1' ? 'Πωλείται' : 'Ενοικιάζεται';
    const category = property.lookups.categories.name;
    const squareMeters = property.details.sq;
    let extras = [];
    if (property.details.cat_basic_id === '3' || property.details.cat_basic_id === '4') {
      extras = extras.concat([
        'artio',
        'oikodom',
        'adeia_oik',
        'goniako',
        'train',
        'perifragmeno',
        'geotrisi',
        'nero_reuma',
        'reuma',
        'gass',
        'tel',
        'optics',
      ]);
    } else {
      extras = extras.concat([
        'plot_sq',
        'orofodiamerisma',
        'retire_last',
        'road',
        'elevator',
        'fireplace',
        'ac',
        'alarm',
        'student',
        'luxury',
        'preserved',
        'neoclassic',
        'amea',
        'satellite',
        'nightpower',
        'furn',
        'devices',
        'open',
        'bright',
        'tents',
        'doubleglass',
        'pestnet',
        'internalstairs',
        'bbq',
        'attic',
        'playroom',
        'panoramiko',
        'paraliako',
        'ependitiko',
        'sun',
        'painted',
        'retire',
        'stair_steps',
        'prof',
        'ygion',
        'yard',
        'pets',
        'koin_val',
      ]);
    }

    const meID = storage.me && storage.me.realtors_id;
    
    return (
      <Fragment>
        <Header />
        <Leftnav />

        <div className="main-content PropertyDetails right-chat-active">
          <div className={`middle-sidebar-bottom ${this.state.propertyDataFetched === false ? 'd-none' : ''}`}>
            <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
              <div className="row">
                <div className="col-xl-12 col-xxl-12 col-lg-12 property-slider ">
                <div className="imgs-counter">
                    <span><i className="feather-image"></i>{this.state.property.images.length}</span>
                  </div>
                  <Slider {...property_settings}>
                    {this.state.property.images.map((data, index) => (
                      <div key={index} className="pe-2" onClick={() => {
                        if (!this.state.is_dragging) {
                          this.setState({  isOpen: true, tab1: index })
                        }
                      }}
                      >
                        
                        <img src={`${data.image_url}`} alt="property_image" className="rounded-3 img-fluid" />
                      </div>
                     
                    ))}
                  </Slider>
                  { this.state.property.images.map((data, index) => (
                    isOpen && (
                    <Lightbox
                      key={`lightbox-item-${index}`}
                      mainSrc={property.images[tab1].image_url}
                      nextSrc={property.images[(tab1 + 1) % property.images.length].image_url}
                      prevSrc={property.images[(tab1 + property.images.length - 1) % property.images.length].image_url}
                      onCloseRequest={() => this.setState({  isOpen: false })}
                      onMovePrevRequest={() =>
                      this.setState({
                          
                          tab1: (tab1 + property.images - 1) % property.images.length,
                      })
                      }
                      onMoveNextRequest={() =>
                          this.setState({
                            
                              tab1: (tab1 + 1) % property.images.length,
                          })
                      }
                    />
                    )
                  
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  <div className="card d-block mt-3 border-0 shadow-xss bg-white p-lg-5 p-4">
                  
                    {
                      parseInt(property.extras.ependitiko) === 1 
                        ? <span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-primary-gradiant d-inline-block text-white ">{i18n.t('property_extras.ependitiko')}</span>
                        : ''
                    }

                    <h2 className="text-default fw-700 font-lg mt-3 mb-2">
                      {int_to} {category} {squareMeters} τ.μ.
                    </h2>
                    <div className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                    <i className="ti-location-pin me-1"></i>{i18n.get_data('area', property.location)}
                    </div>
                    <p className="description font-xsss fw-500 lh-30 pe-5 mt-3 me-5">{i18n.get_data('text', property.details.auto_desc)}<br></br>{i18n.get_data('text', property.details.description)}</p>
                    {/* <div className="clearfix"></div>
                    <h5 className="mt-3 d-inline-block font-xssss text-grey-500 me-2"><span className="fw-600">95</span> {i18n.t('sqm')}</h5>
                    <h5 className="mt-3 d-inline-block font-xssss text-grey-500 me-2"><span className="fw-600">3</span> {i18n.t('bedrooms')}</h5>
                    <h5 className="mt-3 d-inline-block font-xssss text-grey-500"><span className="fw-600">1</span> {i18n.t('bathrooms')}</h5>
                    <div className="clearfix"></div> */}

                    

                    <hr />
                        
                    <div className="d-flex flex-direction-row justify-content-start align-items-center">
                      <label className="switch show-all-extras-switch">
                        <input type="checkbox"
                          ref={this.showAllExtrasToggleRef}
                          value={this.state.showAllExtras}
                          onChange={(e) => {
                            this.setState({
                              // ...this.state,
                              showAllExtras: e.target.checked,
                            });
                          }}
                        />
                        <span className="slider round"></span>
                      </label>

                      <span className="pointer font-xsss ms-1 text-default"
                        onClick={() => {
                          this.showAllExtrasToggleRef.current.click();
                        }}
                      >
                        {i18n.t('show_missing_extras')}
                      </span>
                    </div>
                    
                    <div className="extras">
                      {
                        Object.keys(property.extras).sort((a, b) => {
                          if (i18n.t(`property_extras.${a}`) > i18n.t(`property_extras.${b}`)) {
                            return 1;
                          } else {
                            return -1;
                          }
                        }).map((attributeKey, index) => {
                          if (extras.includes(attributeKey)) {
                            return (
                              <div key={index} 
                                className={`badge badge-pill ${property.extras[attributeKey] === '1' ? 'badge-info' : 'badge-gray'} ${this.state.showAllExtras || property.extras[attributeKey] === '1' ? '' : 'd-none'} ${this.state.showAllExtras && property.extras[attributeKey] === '1' ? 'd-none' : ''}`}
                                style={{opacity: property.extras[attributeKey] === '1' ? '1' : '0.5'}}
                              >
                                  <i className={`mr-5 font-xssss btn-round-xs ${property.extras[attributeKey] === '1' ? 'bg-success ti-check' : 'bg-danger ti-close'} text-white`}></i><span>{i18n.t(`property_extras.${attributeKey}`)}</span>
                                </div>
                            );
                          } else {
                            return '';
                          }
                        })
                      }
                    </div>
                  </div>
                  <div className="card d-block mt-3 border-0 shadow-xss bg-white p-lg-5 p-4">
                      <div className="characteristis">
                        <div className="header">
                          <span className="text-default">{i18n.t('characteristics_of_property')}</span>
                          &nbsp;
                          
                        </div>
                        
                        <div className="list">
                          <div className="labels">
                            <div>{i18n.t('property_characteristics.network_code')}</div>
                            {
                              property.details.code && !Utils.stringEmpty(property.details.code) &&
                              <div>{i18n.t('property_characteristics.office_code')}</div>
                            }
                            <div>{i18n.t('property_characteristics.price')}</div>
                            <div>{i18n.t('property_characteristics.price_per_square_meter')}</div>
                            <div>{i18n.t('property_characteristics.area')}</div>
                            <div>{i18n.t('property_characteristics.type')}</div>
                            <div>{i18n.t('property_characteristics.heating_system')}</div>
                            <div>{i18n.t('property_characteristics.energy_class')}</div>
                            <div>{i18n.t('property_characteristics.bedrooms')}</div>
                            <div>{i18n.t('property_characteristics.bathrooms')}</div>
                            <div>{i18n.t('property_characteristics.floor')}</div>
                            <div>{i18n.t('property_characteristics.parking')}</div>
                            <div>{i18n.t('property_characteristics.year_of_construction')}</div>
                            <div>{i18n.t('property_characteristics.advert_code')}</div>
                            <div>{i18n.t('property_characteristics.available_from')}</div>
                            <div>{i18n.t('property_characteristics.last_update')}</div>
                            {
                              (property.details.cat_basic_id === '3' || property.details.cat_basic_id === '4') && <React.Fragment>
                                {/* <div>{i18n.t('property_characteristics.structure_factor')}</div> */}
                                {/* <div>{i18n.t('property_characteristics.coverage_factor')}</div> */}
                                <div>{i18n.t('property_characteristics.zoning')}</div>
                                {/* <div>{i18n.t('property_characteristics.height')}</div> */}
                                {/* <div>{i18n.t('property_characteristics.depth')}</div> */}
                                {/* <div>{i18n.t('property_characteristics.facade')}</div> */}
                                <div>{i18n.t('property_characteristics.access_from')}</div>
                                <div>{i18n.t('property_characteristics.orientation')}</div>
                                <div>{i18n.t('property_characteristics.land_slope')}</div>
                                <div>{i18n.t('property_characteristics.land_use')}</div>
                                <div>{i18n.t('property_characteristics.view')}</div>
                              </React.Fragment>
                            }
                          </div>

                          <div className="values">
                            <div>#{property.details.id}</div>
                            {
                              property.details.code && !Utils.stringEmpty(property.details.code) &&
                              <div>#{property.details.code}</div>
                            }
                            <div>
                              {
                                i18n.locale === 'en'
                                  ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat(property.details.price) * 1.15)
                                  : i18n.locale === 'el'
                                      ? new Intl.NumberFormat('el-GR', { style: 'currency', currency: 'EUR' }).format(property.details.price)
                                      : property.details.price
                              }
                            </div>
                            <div>
                              {
                                  i18n.locale === 'en'
                                    ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat(property.details.price_per_sq) * 1.15)
                                    : i18n.locale === 'el'
                                        ? new Intl.NumberFormat('el-GR', { style: 'currency', currency: 'EUR' }).format(property.details.price_per_sq)
                                        : property.details.price_per_sq
                              }
                            </div>
                            <div>{property.details.sq}&nbsp;{i18n.t('square_meters_shortcut')}</div>
                            <div>{category}</div>
                            <div>{property.lookups.heat.name          || i18n.t('undefined')}</div>
                            <div>
                              {
                              parseInt(property.lookups.energy_class.id) === 0 || parseInt(property.lookups.energy_class.id) === 10 || property.lookups.energy_class.id == null
                              ? property.lookups.energy_class.name  || i18n.t('undefined') 
                              : <span className={`engrcl energy-class${property.lookups.energy_class.id}`}>{property.lookups.energy_class.name  || i18n.t('undefined')}</span>
                              }</div>
                            <div>{property.details.rooms              || i18n.t('undefined')}</div>
                            <div>{property.details.bathroom           || i18n.t('undefined')}</div>
                            <div>{property.lookups.floors.name        || i18n.t('undefined')}</div>
                            <div>{property.lookups.parking.name       || i18n.t('undefined')}</div>
                            <div>{property.details.year               || i18n.t('undefined')}</div>
                            <div>{property.details.code               || i18n.t('undefined')}</div>
                            <div>{property.details.date}</div>
                            <div>{property.details.mod_date}</div>
                            
                            {
                              (property.details.cat_basic_id === '3' || property.details.cat_basic_id === '4') && <React.Fragment>
                                {/* <div>{property.lookups.structure_factor.name  || i18n.t('undefined')}</div> */}
                                {/* <div>{property.lookups.coverage_factor.name   || i18n.t('undefined')}</div> */}
                                <div>{property.lookups.zoning.name                || i18n.t('undefined')}</div>
                                {/* <div>{property.lookups.height.name            || i18n.t('undefined')}</div> */}
                                {/* <div>{property.lookups.depth.name             || i18n.t('undefined')}</div> */}
                                {/* <div>{property.lookups.facade.name            || i18n.t('undefined')}</div> */}
                                <div>{property.lookups.access_type.name           || i18n.t('undefined')}</div>
                                <div>{property.lookups.orientations.name          || i18n.t('undefined')}</div>
                                <div>{property.lookups.land_slope.name            || i18n.t('undefined')}</div>
                                <div>{property.lookups.land_use.name              || i18n.t('undefined')}</div>
                                <div>{property.lookups.views.name                 || i18n.t('undefined')}</div>
                              </React.Fragment>
                            }
                          </div>
                        </div>
                      </div>
                      {
                        (
                          property.details.d_airport > 0 ||
                          property.details.d_city > 0 ||
                          property.details.d_sea > 0 ||
                          property.details.d_village > 0
                        ) &&
                        <div className='distances'>
                        <h3 className='py-2'>{i18n.t('request_distances')}</h3>
                          <ul className='distances-list'>
                              {property.details.d_airport > 0 &&
                                <li><FontAwesomeIcon icon={faPlane}></FontAwesomeIcon><span>{i18n.t('airport_distance')}: {property.details.d_airport} {i18n.t('metres')}</span></li>
                              }
                              {property.details.d_city > 0 && 
                                <li><FontAwesomeIcon icon={faCity}></FontAwesomeIcon><span>{i18n.t('city_distance')}: {property.details.d_city} {i18n.t('metres')}</span></li>
                              }
                              {property.details.d_sea > 0 && 
                                <li><FontAwesomeIcon icon={faWater}></FontAwesomeIcon><span>{i18n.t('sea_distance')}: {property.details.d_sea} {i18n.t('metres')}</span></li>
                              }
                              {property.details.d_village > 0 && 
                                <li><FontAwesomeIcon icon={faWarehouse}></FontAwesomeIcon><span>{i18n.t('village_distance')}: {property.details.d_village} {i18n.t('metres')}</span></li>
                              }
                          </ul>
                      </div>
                      }
                      
                  </div>
                </div>
                <div className='col-xl-4 col-xxl-3 col-lg-4 ps-lg-0'>
                  <div className="card w-100 border-0 mt-3 mb-4 p-lg-4 p-3 shadow-xss position-relative rounded-3 bg-white">
                      <div className="section full p-4 rounded-3 realtor-section">
                        <div className="row">
                            <div className="col-12 text-center">
                              <img src={property.realtor.photo} alt={property.realtor.fname} className="w100 rounded-circle" />
                              <h2 className="lh-1 m-0 text-grey-900 fw-700 pt-2">{property.realtor.fname} {property.realtor.lname}</h2>
                              
                              <ReputationData
                                member={{
                                  ...property.realtor,
                                  reputation: property.realtor.rating
                                }}
                                className='reputation-contact'
                              />
                              {/* <span className="font-xssss mt-1 fw-700 text-grey-800">
                                <a href={`tel:${property.realtor.phone}`}><i className='btn-round-sm bg-greylight text-grey-500 me-1 ti-mobile'></i></a>
                              </span>
                              <span className="font-xssss mt-1 fw-700 text-grey-800">
                                <a href={`mailto:${property.realtor.mail}`}><i className='btn-round-sm bg-greylight text-grey-500 me-1 ti-email'></i></a>
                              </span> */}
                              {
                                property.realtor.id !== meID ?
                                <>
                                {
                                  (!this.state.collab && !this.state.too_many_requests) &&
                                  <Button onClick={() => this.cooperationRequestInit()} className="button bg-success border-0 text-white fw-600 text-uppercase font-xssss ms-2 rounded-xl d-inline-block mt-0 p-3 lh-34 text-center ls-3 ">{i18n.t('Αίτηση συνεργασίας')}</Button>
                                }
                                {
                                  (this.state.collab) &&
                                  <Button onClick={() => this.cancelCooperationRequest()} className="bg-danger border-0 text-white fw-600 text-uppercase font-xssss ms-2 rounded-3 d-inline-block mt-0 pd-3-6 lh-34 text-center ls-3">{i18n.t('Ακύρωση αιτήματος')}</Button>
                                }
                                {
                                  (this.state.too_many_requests) &&
                                  <Button className="button bg-danger border-0 text-white fw-600 text-uppercase font-xssss rounded-3 d-inline-block mt-0 ms-2 pd-3-6 lh-34 text-center ls-3 ">{i18n.t('Ακυρώσατε πρόσφατα αίτημα συνεργασίας')}</Button>
                                }
                                </> : ''
                              }
                            </div>
                            
                            
                        </div>
                      </div> 
                    </div>
                    {this.state.similarproperties.length > 0 &&
                    <div className="card w-100 border-0 mt-3 mb-4 p-4 shadow-xss position-relative rounded-3 bg-white">
                      <h2 className="fw-700 font-sm mb-4 mt-1 ps-1 mb-3">{i18n.t('realtor_prop')}</h2>
                      {
                        property.realtor.id &&
                        <Link className='more-list' to={`/listings/members/${property.realtor.id}`}>
                          <i className="mr-5 font-xss btn-round-xs bg-success ti-angle-right text-white"></i>
                        </Link>
                      }
                      
                        {
                          this.state.similarproperties.map((data, index) => (
                                <div className="row pb-1" key={index}>
                                  <div className="col-4 text-left">
                                      <Link className='w-100 d-block h-100' to={`/properties/${data.id}`}>
                                        <img src={`${config.imageURL}/${data.imageUrl}`} alt="img" className="shadow-none similar-img me-2"/>
                                      </Link>
                                  </div>
                                  <div className="col-8 ps-1">
                                    <div className="content">
                                      <h6 className="author-name font-xssss fw-600 mb-0 text-grey-800">{i18n.get_data('title', data)}</h6>
                                      <span className="d-block font-xsssss fw-500 text-grey-500 mb-2">{i18n.get_data('area', data)}</span>
                                    </div>
                                  </div>
                                </div>
                          )
                          )}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className={`${this.state.propertyDataFetched ? 'd-none' : ''}`}>
            {this.state.serverErrorMessage}
          </div>
        </div>
        <ModalGeneric
					show={this.state.showModal}
					handleClose={() => this.setState({
						
						showModal: false
					})}
					header={<h4>{i18n.t('cooperation_request')}</h4>}
					footer={<div className="card-body p-0 d-flex space-between open-house-footer align-center">
								<div className="d-flex">
									<button onClick={() => this.cooperationRequest()} className="bg-success btn-action btn-accept p-2 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-600">{i18n.t('send')}</button>
									<button onClick={() => this.setState({
						
						showModal: false
					})} className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">{i18n.t('button_cancel_request')}</button>
								</div>
							</div>}
				>
					<div className="card w-100 border-0 p-4 mb-3">
						<div className="card-body mt-2 p-0">
							
							<form>
							<div className="row mt-4">
									{/* <div className="col-md-12">
										<div className="form-group p-relative">
											<label className="mb-2">{i18n.t('related_requests_for_collaboration')}</label>
											{
												this.state.selected_requests && 
												this.state.selected_requests.map(s => {
													return (
														<div className={`property-suggestion p-1`} onClick={() => this.setState({
															
                                selected_requests: this.state.selected_requests.filter(sp => sp.id !== s.id)
														})}>
															<img src={s.thumbnail} />
															<div className='property-title'>
																<b>#{s.id}</b><br/>
																<span className='text-grey-500 fw-600 font-xssss'>{i18n.get_data('title', s)}</span>
															</div>
															<FontAwesomeIcon icon={faClose} />
														</div>
													)
												})
												
											}
											<input 
												type="text" 
												className='form-control' 
												onChange={(e) => this.searchRequests(e.target.value)}
												placeholder={i18n.t('request_code')}
												ref={this.searchRef}
											/>
											<div className={`search-results scroll-bar ${this.state.show_suggestions ? '' : 'hidden'}`}>
												{
													this.state.search_results && 
													this.state.search_results.filter(s => this.state.selected_requests.filter(sp => sp.id === s.id).length === 0).map(s => {
														return (
															<div className={`property-suggestion p-1`} onClick={() => this.setState({
																
																selected_requests: [...this.state.selected_requests, s]
															})}>
																<div className='property-title'>
																	<b>#{s.id}</b><br/>
																	<span className='text-grey-500 fw-600 font-xssss'>{i18n.get_data('title', s)}</span>
																</div>
															</div>
														)
													})
												}
											</div>
										</div>
									</div> */}
								</div>
								<div className="row mt-4">
									<div className="col-md-12">
										<div className="form-group">
											<label className="mb-2">{i18n.t('request_content')}</label>
											<textarea rows="6" value={this.state.message} onChange={(e) => this.setState({ message: e.target.value})} className="form-control mh-125" placeholder={i18n.t('describe_your_reason_of_request_in_a_few_words')}/>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</ModalGeneric>
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default PropertyDetails;