class AppSettings {
  // chat = {
  //   sideContactsListOpen: true,
  // };
  sideContactsListOpen = false;
  messageNotify = false;
  openChats = [];
  onChangeFns = [];
  onceChangeFns = [];

  // constructor() {
  // }

  onChange(fn) {
    this.onChangeFns.push(fn);
  }

  onceChange(fn) {
    const _fn = () => {
      fn();
      this.onceChangeFns.splice(this.onceChangeFns.indexOf(_fn), 1);
    };
    this.onceChangeFns.push(_fn);
  }

  _changeHandler() {
    this.onChangeFns.concat(this.onceChangeFns).forEach((fn) => {
      fn();
    });
  }

  set(property, value) {
    this[property] = value;
    this._changeHandler();
  }
}

const settings = new AppSettings();
window.settings = settings;

export default settings;