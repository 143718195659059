import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import i18n from "../i18n";
import logoWhite from '../assets/images/real-status-logo-white.png'
import history from '../helpers/history';

class PaymentStatus extends Component {
	constructor(props) {
		super(props);

		const payment_status = localStorage.getItem('payment_status')
		console.log(payment_status)
		if (!payment_status) {
			history.push('/')
		}

		this.state = {
			payment_status: JSON.parse(payment_status)
		}

		localStorage.removeItem('payment_status')
		
		document.body.classList.add('theme-light')
	  }

	componentDidMount() {
		i18n.onChangeLocale(() => {
		  this.forceUpdate();
		});
	}

	render() {
		const payment_status = this.state.payment_status

		if (!payment_status) {
			return ''
		}
		return (
			<Fragment> 
			<div className="main-wrap">
				<div className="nav-header bg-transparent shadow-none border-0">
					<div className="nav-top navigation-login-menu w-100 justify-content-xs-end justify-content-sm-end">
						<span className="pointer p-2 text-center ms-3 btn-locale"
							onClick={() => {
								if(i18n.getLocale() === 'en')
									i18n.setLocale('el')
								else
									i18n.setLocale('en')
							}}
							>
							<i className="feather-globe"></i>
						</span>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-5 d-none d-xl-flex p-0 vh-100 bg-primary align-items-center justify-content-center bg-cover">
						<div className="col-6 text-center">
							<img src={logoWhite} alt="Real Status Logo Symbol"/>
						</div>
					</div>
					<div className="col-xl-7 login-wrapper vh-100 align-items-center bg-cover2 d-flex bg-white rounded-3 overflow-hidden">
						<div className="card shadow-xxs-2 border-0 ms-auto me-auto login-card">
							<div className="card-body rounded-0 text-left">
								<h1 className="fw-700 mb-3">{i18n.t('payment_status')}</h1>
								<div>
									{
										payment_status.type === "success" ?
										<div>
											<h3 className={payment_status.type === "success" ? 'payment-success' : 'payment-failure'}>{i18n.t(payment_status.message)}</h3>
											<h4>{i18n.t('access_renewed_until')} {payment_status.access_until}</h4>

											<a className='btn bg-success text-white d-block m-4' href={payment_status.receipt} target="_blank" rel="noreferrer">{i18n.t('download_receipt')}</a>
											<Link className='btn bg-success text-white d-block m-4' to={'/'}>{i18n.t('home')}</Link>
										</div> :
										<div>
											<h3 className={payment_status.type === "success" ? 'payment-success' : 'payment-failure'}>{i18n.t(payment_status.message)}</h3>
											<Link className='btn bg-success text-white d-block m-4' to={'/'}>{i18n.t('home')}</Link>
										</div>
									}
									
								</div>
							</div>
						</div> 
					</div>
					
				</div>
			</div>
		</Fragment>
		);
	}
}

export default PaymentStatus;