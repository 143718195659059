import React, { Component , createRef, Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import RequestCard from '../components/RequestCard';
import Load from '../components/Load';
import i18n from "../i18n";
import request from '../request';
import Rightchat from "../components/Rightchat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import SearchProperties from "../components/SearchProperties";
import ActiveFilters from "../components/ActiveFilters";
import uriHelper from "../uri_helper";
import ModalGeneric from "../components/ModalGeneric";

import * as L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup, FeatureGroup, Circle, Polygon } from 'react-leaflet';
import '../css/leaflet.css'
import '../css/MarkerCluster.Default.css'
import '../css/MarkerCluster.css'
import 'leaflet-draw/dist/leaflet.draw.css'

class Requests extends Component {

  constructor(props) {
    super(props);

    this.fgRef = createRef(null)
    this.mapRef = createRef(null)
    this.state = {
      requests: [],
      page: 1,
      end: false,
      filters: [],
      show_search: false,
      cross_id: false,
      show_map: false,
      map_data: []
    };

    this.cooperationRequest = this.cooperationRequest.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  componentDidMount() {
    
    uriHelper.init()
    uriHelper.set_component_view('requests')

    if (uriHelper.get('cross', false) !== false) {
      this.setState({
        cross_id: uriHelper.get('cross', false)
      })
    }
    this.unmountLocale = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    this.getRequests(true);

    this.onDocumentScroll = (e) => {
      const totalPageHeight = document.body.scrollHeight - 1; 
      const scrollPoint = window.scrollY + window.innerHeight;
      if(scrollPoint >= totalPageHeight) {
        this.changePage();
      }
    };
    document.addEventListener('scroll', this.onDocumentScroll);
  }

  changePage() {
    const page = uriHelper.get('page', 0)
    uriHelper.set('page', (page + 1), true)
    this.getRequests(false)
  }

  async getRequests(page_reset = true){
    return new Promise((resolve, reject) => {
      if (!this.state.end_of_data || page_reset) {
        if (page_reset)
          uriHelper.set('page', 1)

        const uri = uriHelper.uri_to_string()
        
        if (this.searchTimeout) {
          window.clearTimeout(this.searchTimeout)
        }
        request.get(uri).then((response) => {
          this.setState({
            ...this.state,
            requests: page_reset ? response.data.listings : this.state.requests.concat(response.data.listings),
            filters: response.data.filters,
            end: !response.data.data || !response.data.data.length || response.data.data.length < 10
          }, () => {
            resolve(true)
          });
        });
      }
    })
    
  }

  cooperationRequest(data){
    this.setState({
      ...this.state,
      requests: this.state.requests.map(r => {
        if (r.id !== data.id) return r

        return {
          ...r,
          collab: data.collab,
          spam_collab: data.spam_collab
        }
      })
    })
      
  }

  cancelCooperationRequest(data){
    this.setState({
      ...this.state,
      requests: this.state.requests.map(r => {
        if (r.id !== data.id) return r

        return {
          ...r,
          collab: data.collab,
          spam_collab: data.spam_collab
        }
      })
    })
  }

  sendMessage(request_id){
    // console.log(request_id);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onDocumentScroll);
    this.unmountLocale()
  }

  setShow(show) {
    this.setState({
      show_search: show
    })
  }

  onLocationsClick = (req) => {

    if (req.map && req.map.length > 0) {
      this.setState({
        show_map: true,
        map_data: req.map
      }, () => {
        this.mapRef.invalidateSize()
        req.map.forEach(p => {
          // L.polygon(<LatLng[]> latlngs, <Polyline options> options?)
          const poly_json = JSON.parse(p.polygon_encoded)
          const center = JSON.parse(p.polygon_center)
          const polygon = poly_json.map(pol => {
            return [parseFloat(pol[0]), parseFloat(pol[1])]
          })
          const temp = new L.Polygon(polygon)
          temp.addTo(this.fgRef.current)
          this.mapRef.fitBounds(polygon)
          // this.mapRef.panTo(new L.LatLng(center.lat, center.lng))
        })
      })
      
      
    }
  } 

  handleModalClose = () => {
    const drawnItems = this.fgRef.current._layers;
		if (Object.keys(drawnItems).length > 0) {
			Object.keys(drawnItems).forEach((layerid, index) => {
				const layer = drawnItems[layerid];
				this.fgRef.current.removeLayer(layer);
			});
		}

    this.setState({
      show_map: false
    })
  }

  render() {
    const page_title = this.state.cross_id ? i18n.t('requests_crossings_for_property') + ` #${this.state.cross_id}` : i18n.t('requests_page_title')
    return (
      <Fragment> 
        <Header />
        <Leftnav />
          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="row">
                  <div className="col-xl-12 scroll-bar">
                    <Pagetitle title={page_title} 
                    >
                      <button className="btn btn-primary text-white mr-5" onClick={() => this.setState({show_search: true})}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} /> {i18n.t('filters')}
                      </button>
                      <ActiveFilters filters={this.state.filters} onChange={(key, value) => this.removeFilter(key, value)} />
                    
                    </Pagetitle>
                    <SearchProperties
                      onchange={() => this.getRequests(true)}
                      setShow={(show) => this.setShow(show)}
                      show={this.state.show_search}
                    />
                    {
                      this.state.requests && this.state.requests.map((request , index) => {
                        return <RequestCard key={index} 
                          request={request}
                          cooperationRequest={(e) => this.cooperationRequest(e)}
                          sendMessage={(e) => this.sendMessage(e)}
                          cancelCooperationRequest={(e) => this.cancelCooperationRequest(e)}
                          onLocationsClick={(e) => this.onLocationsClick(e)}
                        />
                      })
                    }
                     <Load end={this.state.end}/>
                </div>
                </div>
              </div>
            </div>
          </div>
          <ModalGeneric
            show={this.state.show_map}
            handleClose={this.handleModalClose}
            header={<h4>{i18n.t('request_locations')}</h4>}
            footer={<div className="card-body p-0 d-flex space-between open-house-footer align-center">
								<div className="d-flex">
									<button onClick={this.handleModalClose}
                  className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">{i18n.t('close')}</button>
								</div>
							</div>}
          >
            <div className="p-relative h-100">
			
            <MapContainer 
            preferCanvas={true}
            id="map" center={[38.227322, 23.211220]} zoom={12} scrollWheelZoom={false}
            whenCreated={ mapInstance => {
              this.mapRef = mapInstance
            } }
            >
              <TileLayer
              attribution='&copy; Real Status Network'
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <FeatureGroup ref={this.fgRef} id="main_map_ref">
              </FeatureGroup>
            </MapContainer>
          </div>  
          </ModalGeneric>
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default Requests;