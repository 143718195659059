import React, { Component, createRef } from "react";
import request from "../request";
import * as L from "leaflet";
import { MapContainer, TileLayer, Marker, FeatureGroup } from 'react-leaflet';
import MarkerClusterGroup from "./MarkerClusterGroup";
import '../css/leaflet.css'
import '../css/MarkerCluster.Default.css'
import '../css/MarkerCluster.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import { EditControl } from "react-leaflet-draw"
import Utils from "../utils";

class InteractiveMap extends Component {

  static defaultProps = {
    center: {
      lat: 39.6642211,
      lng: 20.85371
    },
    zoom: 15
  };

  constructor(props) {
    super(props);

	this.fgRef = createRef(null)
    this.mapRef = createRef(null)
    this.editRef = createRef(null)
    this.customMarkerIcon = L.icon({
      iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=',
      iconSize: [25, 41],
      iconAnchor: [12.5, 41]
    });

    this.state = {
      drawing: false,
	  has_drawn: false,
	  polygons: [],
	  can_render: false
    };

    this.searchTimeout = null;

	this.setCanRender = this.setCanRender.bind(this)
  }

  componentDidMount() {
	if (this.props.initialPolygons && this.props.initialPolygons.length > 0) {
		
		this.setState({
			polygons: this.props.initialPolygons,
			has_drawn: true
			// initial_polygons: this.props.initialPolygons,
		}, () => {
			this.props.initialPolygons.forEach(p => {
				// L.polygon(<LatLng[]> latlngs, <Polyline options> options?)
				const polygon = p.map(pol => {
					return [parseFloat(pol[0]), parseFloat(pol[1])]
				})
				const temp = new L.Polygon(polygon).addTo(this.fgRef.current)
			})
		})
	}

	if (this.props.initialPolygon && this.props.initialPolygon.length > 0) {
		
		this.setState({
			has_drawn: true
			// initial_polygons: this.props.initialPolygons,
		}, () => {
			// L.polygon(<LatLng[]> latlngs, <Polyline options> options?)
			const polygon = this.props.initialPolygon.map(p => {
				const pol = p.split('x')
				return [parseFloat(pol[0]), parseFloat(pol[1])]
			})

			const temp = new L.Polygon(polygon).addTo(this.fgRef.current)
		})
	}

	this.setCanRender()
  }

  componentDidUpdate(prevProps, prevState) {
	const pol = this.props.initialPolygon ? this.props.initialPolygon : []
	const prev_pol = prevProps.initialPolygon ? prevProps.initialPolygon : []

	if (!Utils.arrayEquals(pol, prev_pol)) {
		const drawnItems = this.fgRef.current._layers;
		if (Object.keys(drawnItems).length > 0) {
			Object.keys(drawnItems).forEach((layerid, index) => {
				const layer = drawnItems[layerid];
				this.fgRef.current.removeLayer(layer);
			});
		}

		const polygon = this.props.initialPolygon.map(p => {
			const pol = p.split('x')
			return [parseFloat(pol[0]), parseFloat(pol[1])]
		})

		// main_map_ref
		const temp = new L.Polygon(polygon).addTo(this.fgRef.current)

		this.setState({
			has_drawn: true
			// initial_polygons: this.props.initialPolygons,
		})
	}
  }

  shouldComponentUpdate(nextProps, nextState) {
	// return true;
	if (!this.props.hash) return true;
	if (this.props.hash !== nextProps.hash) return true;
	const pol = this.props.initialPolygon ? this.props.initialPolygon : []
	const next_pol = nextProps.initialPolygon ? nextProps.initialPolygon : []

	if (!Utils.arrayEquals(pol, next_pol)) return true;

	if (this.state.has_drawn)
		return true;

	return false;

  }

  handlePolygonClick = (e) => {
    //Edit this method to perform other actions
    if ((!this.state.drawing && !this.state.has_drawn) || (this.props.multiple && this.props.multiple === true)) {
        this.editRef.current._toolbars.draw._modes.polygon.handler.enable()
    } else {
        // this.editRef.current._toolbars.draw._modes.polygon.handler.completeShape()
        this.editRef.current._toolbars.draw._modes.polygon.handler.disable()
    }
    this.setState({
      drawing: !this.state.drawing
    })
  }

  handleEditClick = (e) => {
        
    //Edit this method to perform other actions
    if (!this.state.drawing && this.state.has_drawn) {
        this.editRef.current._toolbars.edit._modes.edit.handler.enable()
    } else {
        // this.editRef.current._toolbars.draw._modes.polygon.handler.completeShape()
		this.editRef.current._toolbars.edit._modes.edit.handler.revertLayers()
        this.editRef.current._toolbars.edit._modes.edit.handler.disable()
    }
    this.setState({
      drawing: !this.state.drawing
    })
  }

  handleDeleteClick = (e) => {
	// ._modes.remove.handler.enable()

	// // saveDelete 
	// editRef.current.leafletElement._toolbars.edit._modes.remove.handler.save()
	// editRef.current.leafletElement._toolbars.edit._modes.remove.handler.disable()

    //Edit this method to perform other actions
    this.editRef.current._toolbars.edit._modes.remove.handler.enable()
	this.editRef.current._toolbars.edit._modes.remove.handler.save()
    this.editRef.current._toolbars.edit._modes.remove.handler.disable()

	this.editRef.current.options.edit.featureGroup.clearLayers()
  }

  handleSaveClick = (e) => {
        
        // this.editRef.current._toolbars.draw._modes.polygon.handler.completeShape()
    this.editRef.current._toolbars.edit._modes.edit.handler.save()
	this.editRef.current._toolbars.edit._modes.edit.handler.disable()
    this.setState({
      drawing: false
    })
  }

  handleCancelClick = (e) => {
        
        // this.editRef.current._toolbars.draw._modes.polygon.handler.completeShape()
	this.editRef.current._toolbars.edit._modes.edit.handler.revertLayers()
    this.editRef.current._toolbars.edit._modes.edit.handler.disable()
    this.setState({
      drawing: false
    })
  }

  getPopup(e, id) {
	if (this.props.isRealtor) {
		request.get(`member/map_view/${id}`).then(response => {
			L.popup()
			.setLatLng(e.latlng)
			.setContent(response.data)
			.openOn(e.sourceTarget._map)
		  })
	}
	else {
		request.get(`properties/map_property/${id}`).then(response => {
			L.popup()
			.setLatLng(e.latlng)
			.setContent(response.data)
			.openOn(e.sourceTarget._map)
		  })
	}
    
  }

  // map edit methods 
  _onEditPath = (e) => {
	const latlngs = this.editRef.current.options.edit.featureGroup._layers
	const lat_keys = Object.keys(latlngs)

	if (lat_keys.length > 0) {
		if (!this.props.multiple) {
			const lat_to_map = latlngs[lat_keys[0]]._latlngs[0].map(latlng => {
				return `${latlng.lat}x${latlng.lng}`
			})
	
			this.props._onCreate(lat_to_map)
	
			this.setState({
				has_drawn: true,
				drawing: false
			})
		}
		else {
			const lats = []
			lat_keys.forEach(lat_key => {
				const lat_to_map = latlngs[lat_key]._latlngs[0].map(latlng => {
					return [latlng.lat, latlng.lng]
				})
				lats.push(lat_to_map)
			})
			
			this.props._onCreate(lats)

			this.setState({
				has_drawn: true,
				drawing: false,
				polygons: lats
			})
		}
		
		
	}
  }
  _onCreate = (e) => {
	if (!this.props.multiple) {
		this.props._onCreate(e.layer._latlngs[0].map(latlng => {
			return `${latlng.lat}x${latlng.lng}`
		}))
	}
	else {
		this.setState({
			polygons: this.state.polygons.concat([
				e.layer._latlngs[0].map(latlng => {
					return [latlng.lat, latlng.lng]
				})
			])
		}, () => {
			this.props._onCreate(this.state.polygons)
		})
		
	}
    

	this.setState({
		has_drawn: true,
		drawing: false
	})
  }
  _onDeleted = (e) => {
	this.props._onCreate([])

	this.setState({
		has_drawn: false,
		drawing: false,
		polygons: []
	})
	this.editRef.current._toolbars.draw._modes.polygon.handler.disable()
  }

  onEditMounted = (e) => {
    this.editRef.current = e
  }

  setCanRender(can_render = true) {
	this.setState({
		can_render
	})
  }
    
  render() {
    return (
		<div className={`p-relative h-100 ${this.props.className ? this.props.className : ''}`}>
			
			<MapContainer 
			preferCanvas={true}
			id="map" center={[38.227322, 23.211220]} zoom={7} scrollWheelZoom={false}
			whenCreated={ mapInstance => {
				if (this.props.fetchRef) this.props.fetchRef(mapInstance)
				console.log('map init')

				// mapInstance.invalidateSize()
			} }
			ref={this.props.innerRef ? this.props.innerRef : this.mapRef}
			>
				<TileLayer
				attribution='&copy; Real Status Network'
				url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<FeatureGroup ref={this.fgRef} id="main_map_ref">
				<EditControl
					onMounted={this.onEditMounted}
					position='topright'
					onEdited={this._onEditPath}
					onCreated={this._onCreate}
					onDeleted={this._onDeleted}
					draw={{
					rectangle: false,
					polyline: false,
					marker: false,
					circlemarker: false
					}}
				/>
				{
					// (this.state.initial_polygons && this.state.initial_polygons.length > 0) &&
					// this.state.initial_polygons.map(p => {
					// 	const polygon = p.map(pol => {
					// 		return [parseFloat(pol[0]), parseFloat(pol[1])]
					// 	})
					// 	return  <Polygon 
					// 				className='timeline-layer-polygon-editable'
					// 				positions={polygon} 
					// 			/>
					// })
				}
				</FeatureGroup>
				<MarkerClusterGroup>
				{
					this.props.cordes && this.props.cordes.length && 
					this.props.cordes.map( data => 
					<Marker 
						key={`marker-${data.id}`}
						icon={this.customMarkerIcon}
						position={[data.latitude, data.longitude]}
						eventHandlers={{
						click: (e) => {
							this.getPopup(e, data.id)
						},
						}}
					>

					</Marker>
					)
				}
				</MarkerClusterGroup>
				
			</MapContainer>
			<div className="custom-map-controls">
				{
					(!this.state.has_drawn || (this.props.multiple && this.props.multiple === true)) && 
					<button className="create-draw-btn" onClick={this.handlePolygonClick}>Σχεδιάστε την περιοχή αναζήτησης</button>
				}
				{
					( (this.state.has_drawn || (this.props.multiple && this.state.polygons.length) ) && !this.state.drawing) &&
					<>
						<button className="edit-draw-btn" onClick={this.handleEditClick}>Επεξεργασία</button>
						<button className="delete-draw-btn" onClick={this.handleDeleteClick}>Διαγραφή</button>
					</>
				}
				
				{
					this.state.has_drawn && this.state.drawing &&
					<>
						<button className="complete-draw-btn" onClick={this.handleSaveClick}>Αποθήκευση</button>
						<button className="cancel-draw-btn" onClick={this.handleCancelClick}>Ακύρωση</button>
					</>
				}
			</div>
			
		</div>      
    );
  }
}

export default React.forwardRef((props, ref) => <InteractiveMap 
innerRef={ref} {...props}
/>)