import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import NotificationFriendRequestItem from "../components/NotificationFriendRequestItem";
import NotificationNetworkRequestItem from '../components/NotificationNetworkRequestItem';
import NotificationItem from "../components/NotificationItem";
import NotificationCooperationRequestItem from "../components/NotificationCooperationRequestItem";
import NotificationCooperationAcceptedItem from "../components/NotificationCooperationAcceptedItem";
import NotificationFriendRequestAcceptedItem from "../components/NotificationFriendRequestAcceptedItem";
import NotificationCooperationCompletedItem from "../components/NotificationCooperationCompletedItem";
import notificationsHelper from "../notifications-helper";
import i18n from "../i18n";
import request from "../request";

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
    };
  }

  componentDidMount() {
    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    this.setState({
      ...this.state,
      notifications: notificationsHelper.notifications,
    });
    
    notificationsHelper.onChange(() => {
      this.setState({
        ...this.state,
        notifications: notificationsHelper.notifications,
      });
    });
  }

  removeNotification(notification) {
    const n = this.state.notifications.slice(0);
    const notificationToRemoveIndex = n.findIndex((n) => n.id === notification.id);
    if (notificationToRemoveIndex > -1) {
      n.splice(notificationToRemoveIndex, 1);
      this.setState({
        ...this.state,
        notifications: n,
      });
    }
  }

  updateNotificationStatus(notification, status) {
    const n = this.state.notifications.slice(0);
    const notificationChangedIndex = n.findIndex((n) => n.id === notification.id);
    if (notificationChangedIndex > -1) {
      n[notificationChangedIndex].status = status;
      this.setState({
        ...this.state,
        notifications: n,
      });
    }
  }

  render() {
    return (
      <Fragment> 
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content theme-dark-bg right-chat-active">
        
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="row">
                <div className="col-lg-12">
                  <div className="p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                    <h2 className="fw-700 mb-4 mt-2 font-md text-grey-900 d-flex align-items-center">
                      {i18n.t('notifications')}
                      <div
                        className="cursor-pointer ms-auto btn-round-sm bg-greylight rounded-3"
                        onClick={() => {
                          request.delete('notifications').then(() => notificationsHelper.refresh());
                        }}
                      >
                        <i className="feather-trash-2 font-xss text-grey-500"></i>
                      </div>
                    </h2>
                    <ul className="notification-box">
                      {
                        this.state.notifications.map((notification, i) => {
                          let notificationComponent;
                          if (notification.type === '1') {
                            notificationComponent = <NotificationFriendRequestItem
                              key={i} data={notification}
                              onDelete={() => this.removeNotification(notification)}
                              onStatusChange={(status) => this.updateNotificationStatus(notification, status)}
                            />;
                          } else if (notification.type === '2') {
                            notificationComponent = <NotificationNetworkRequestItem
                              key={i}
                              data={notification}
                              onDelete={() => this.removeNotification(notification)}
                              onStatusChange={(status) => this.updateNotificationStatus(notification, status)}
                            />;
                          } else if (notification.type === '3') {
                            notificationComponent = <NotificationItem key={i} data={notification} />;
                          } else if (notification.type === '4') {
                            if (typeof notification.data.cooperation !== 'undefined')
                              notificationComponent = <NotificationCooperationRequestItem
                                key={i}
                                realtor={{
                                  avatar: notification.avatar,
                                  name: notification.name,
                                }}
                                data={notification}
                                onDelete={() => this.removeNotification(notification)}
                                onStatusChange={(status) => this.updateNotificationStatus(notification, status)}
                              />;
                          } else if (notification.type === "5") {
                            if (typeof notification.data.cooperation !== 'undefined')
                              notificationComponent = <NotificationCooperationAcceptedItem
                                key={i}
                                realtor={{
                                  avatar: notification.avatar,
                                  name: notification.name,
                                }}
                                data={notification}
                                onDelete={() => this.removeNotification(notification)}
                                onStatusChange={(status) => this.updateNotificationStatus(notification, status)}
                              />;
                          } else if (notification.type === '6') {
                              notificationComponent = <NotificationFriendRequestAcceptedItem
                                key={i}
                                realtor={{
                                  avatar: notification.avatar,
                                  name: notification.name,
                                }}
                                data={notification}
                                onDelete={() => this.removeNotification(notification)}
                                onStatusChange={(status) => this.updateNotificationStatus(notification, status)}
                              />;
                          } else if (notification.type === "9") {
                            if (typeof notification.data.cooperation !== 'undefined')
                              notificationComponent = <NotificationCooperationCompletedItem
                                key={i}
                                realtor={{
                                  avatar: notification.avatar,
                                  name: notification.name,
                                }}
                                data={notification}
                                onDelete={() => this.removeNotification(notification)}
                                onStatusChange={(status) => this.updateNotificationStatus(notification, status)}
                              />;
                          }

                          return notificationComponent;
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default Notification;