import React, {useState} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons"

const DarkMode = (props) => {
    let clickedClass = "clicked"
    const body = document.body
    const lightTheme = "theme-light"
    const darkTheme = "theme-dark"
    let theme

    const [current, setCurrent] = useState(localStorage.getItem("theme"))
  
    if (localStorage) {
      theme = localStorage.getItem("theme")
    }
  
    if (theme === lightTheme || theme === darkTheme) {
      body.classList.add(theme)
    } else {
      body.classList.add(darkTheme)
    }
  
    const switchTheme = e => {
      if (theme === darkTheme) {
        body.classList.replace(darkTheme, lightTheme)
        e.target.classList.remove(clickedClass)
        localStorage.setItem("theme", "theme-light")
        setCurrent("theme-light")
        theme = lightTheme
      } else {
        body.classList.replace(lightTheme, darkTheme)
        e.target.classList.add(clickedClass)
        localStorage.setItem("theme", "theme-dark")
        setCurrent("theme-dark")
        theme = darkTheme
      }
    }
  
    return (
      <div className={props.className ? props.className : ''}  onClick={e => switchTheme(e) }>
        <FontAwesomeIcon icon={faMoon} /><span>{theme === darkTheme ? props.title_dark : props.title_light}</span>
        <div>
          <input type="checkbox" className="toggle-darkmode" onClick={e => switchTheme(e) } checked={current === "theme-dark"} id='toggle-darkmode'></input>
          <label for="toggle-darkmode" className="toggle-darkmode-label">
          <FontAwesomeIcon className='fs-8' icon={faSun} />
          <FontAwesomeIcon className='fs-8' icon={faMoon} />
          <div className="ball"></div>
          </label>
        </div>
      </div>
    )
  }
  
  export default DarkMode