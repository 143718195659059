import React from "react";
import i18n from "../i18n";
import Utils from "../utils";
import moment from "moment";

const CooperationHistory = (props) => {
	/*
	cooperation_id: "1"
	date: "2023-04-05 14:08:23"
	id: "15"
	is_value_directive: "0"
	new_value: ""
	old_value: ""
	realtor_full_name: "Χρήστος Βαρδάκας"
	realtor_id: "630"
	text_directive_new: ""
	text_directive_old: "closed_cooperation"
	*/
	return (
		<div className="history-wrapper">
			{
				props.history && 
				props.history.map(elem => {
					return <div className={`history-element${elem.log_read == '0' && !props.ignoreUnread ? ' history-elem-unread' : ''}`}>
						<span className="history-date">{Utils.getMomentText(moment(elem.date).format("X"))}</span>
						<span className="history-details">
						{i18n.t('the_user')} <b>{elem.realtor_full_name}</b>&nbsp;
						{
							elem.text_directive_old !== '' ?
							i18n.t(elem.text_directive_old) : ''
						}&nbsp;
						{
							elem.old_value !== '' ?
							elem.is_value_directive == 1 && elem.text_directive_old != 'changed_milestone_status' ?
								i18n.t(elem.old_value) : `"${elem.old_value}"` : ''
						}&nbsp;
						{
							elem.text_directive_new !== '' ?
							i18n.t(elem.text_directive_new) : ''
						}&nbsp;
						{
							elem.new_value !== '' ?
							elem.is_value_directive == 1 ?
								i18n.t(elem.new_value) :`"${elem.new_value}"` : ''
						}&nbsp;
						</span>

					</div>
				})
			}
		</div>
	)
} 

export default CooperationHistory;