import React, { Component , Fragment } from "react";
import ProfilePreview from "./ProfilePreview";
import storage from "../storage";
import PropertyCard from "./PropertyCard";
import RequestCard from "./RequestCard";
import i18n from "../i18n";
import request from "../request";
import { Link } from "react-router-dom";
import CooperationHistory from "./CooperationHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

class Cooperation extends Component {
	constructor(props) {
		super(props)

		this.state = {
			request: this.props.data.request
		}
	}

	closeCollaboration(id) {
		request.patch(`collabs/close/${id}`).then((response) => {
			this.props.removeCollab(id)
		});
	  }

	cooperationRequest(data){
		this.setState({
		  ...this.state,
		  request: {
			...this.state.request,
			collab: data.collab,
			spam_collab: data.spam_collab
		  }
		})
		  
	}
	
	cancelCooperationRequest(data){
		this.setState({
			...this.state,
			request: {
			  ...this.state.request,
			  collab: data.collab,
			  spam_collab: data.spam_collab
			}
		})
	}

	render() {
		let title = i18n.t("Αίτηση συνεργασίας")
		if (this.props.data.cooperations_status === "1") {
			title = i18n.t("Ενεργή συνεργασία")
			if (this.props.data.closed === "1") {
				title = i18n.t("Κλειστή συνεργασία")
			}
			
		} else if (this.props.data.cooperations_status === "2") {
			title = i18n.t("Απορριφθήσα συνεργασία")
		}

		let partner;
		let actions;
		if (this.props.data.requester === "0") {
			partner = <ProfilePreview 
				user={`${this.props.data.requester_fname} ${this.props.data.requester_lname}`}
				to={`/members/${this.props.data.requester_id}`}
				member={{
					reputation: this.props.data.requester_rating
				}}
			/>
			if (this.props.data.closed === "0") {
				if (this.props.data.cooperations_status === "2") {
					actions = <>
						<button onClick={() => this.props.cancelRequest(this.props.data.cooperations_id)} className="bg-danger btn-action btn-leave p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Διαγραφή αιτήματος')}</button>
					</>
				} else if (this.props.data.cooperations_status === "0") {
					actions = <>
						<button onClick={() => this.props.respond(this.props.data.cooperations_id, 1)} className="bg-success btn-action btn-accept p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Αποδοχή')}</button>
						<button onClick={() => this.props.respond(this.props.data.cooperations_id, 2)} className="bg-danger btn-action btn-leave ms-2 p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Απόρριψη')}</button>
					</>
				} else if (this.props.data.cooperations_status === "1") {
					actions = <>
						{/* <button onClick={() => this.props.openRatingCooperation(this.props.data.cooperations_id)} className="bg-primary btn-action btn-accept p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Αξιολόγηση συνεργασίας')}</button> */}
						<button onClick={() => this.closeCollaboration(this.props.data.cooperations_id)} className="bg-success btn-action btn-accept p-2 ms-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Κλείσιμο συνεργασίας')}</button>
					</>
				} 
			}
			else {
				actions = <>
					<button onClick={() => this.props.openRatingCooperation(this.props.data.cooperations_id)} className="bg-primary btn-action btn-accept p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Αξιολόγηση συνεργασίας')}</button>
					<button onClick={() => this.props.cancelRequest(this.props.data.cooperations_id)} className="bg-danger btn-action btn-leave p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Απόκρυψη συνεργασίας')}</button>
				</>
			}
			
		} else {
			if (this.props.data.closed === "0") {
				if (this.props.data.cooperations_status === "0") {
					
					actions = <>
						<button onClick={() => this.props.cancelRequest(this.props.data.cooperations_id)} className="bg-danger btn-action btn-leave p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Ακύρωση αιτήματος')}</button>
					</>	
				}
				else if (this.props.data.cooperations_status === "1") {
					actions = <>
						{/* <button onClick={() => this.props.openRatingCooperation(this.props.data.cooperations_id)} className="bg-primary btn-action btn-accept p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Αξιολόγηση συνεργασίας')}</button> */}
						<button onClick={() => this.closeCollaboration(this.props.data.cooperations_id)} className="bg-success btn-action btn-accept p-2 ms-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Κλείσιμο συνεργασίας')}</button>
					</>
				} 
				else if (this.props.data.cooperations_status === "2") {
					actions = <>
						<button onClick={() => this.props.cancelRequest(this.props.data.cooperations_id)} className="bg-danger btn-action btn-leave p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Διαγραφή αιτήματος')}</button>
					</>
				}
			}
			else {
				actions = <>
					<button onClick={() => this.props.openRatingCooperation(this.props.data.cooperations_id)} className="bg-primary btn-action btn-accept p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Αξιολόγηση συνεργασίας')}</button>
					<button onClick={() => this.props.cancelRequest(this.props.data.cooperations_id)} className="bg-danger btn-action btn-leave p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">{i18n.t('Απόκρυψη συνεργασίας')}</button>
				</>
			}
			partner = <ProfilePreview 
				user={`${this.props.data.holder_fname} ${this.props.data.holder_lname}`}
				to={`/members/${this.props.data.holder_id}`}
				member={{
					reputation: this.props.data.holder_rating
				}}
			/>
			
			
		}
		let item_view;
		if (this.props.data.cooperations_type === '1' && this.props.data.property) {
			item_view = <PropertyCard 
							key={this.props.data.cooperations_id} 
							data={this.props.data.property}
							horizontal={true}
						/>
		} else if (this.props.data.cooperations_type === '2' && this.props.data.request) {
			item_view = <RequestCard key={this.props.data.cooperations_id} 
							request={this.state.request}
							cooperationRequest={(e) => this.cooperationRequest(e)}
							cancelCooperationRequest={(e) => this.cancelCooperationRequest(e)}
						/>
		}

		const type = this.props.data.cooperations_type == 1 ? i18n.t("για το ακίνητο") :  i18n.t("για την ζήτηση")
		const code = `${i18n.t("με κωδικό")} #${this.props.data.cooperations_related_id}`
		return (
			<div className={`col-12 co-op-wrapper ${this.props.data.cooperations_status === "2" ? 'rejected-coop' : ''}`}>
				<div className='co-op-header'>
					<div className='co-op-title'>
						{`${title} ${type} ${code}`}
					</div>
					<div className="co-op-actions">
						{actions}
					</div>
				</div>
				<div className="co-op-member">
					<h4 className="fw-600 text-grey-900 font-xsss mt-1">{i18n.t("Συνεργάτης")}:</h4>&nbsp;&nbsp; {partner}
					
					
				</div>
				{
						this.props.data.cooperations_message && this.props.data.cooperations_message !== '' &&
						<div className="co-op-message"><span>{i18n.t('message')}: {this.props.data.cooperations_message}</span></div>
					}
				<div className="co-op-item-view">
					{item_view}
				</div>
				{
					this.props.data.history && this.props.data.history.length > 0 &&
					<div className="history-preview-wrapper">
						<h3>{i18n.t('new_collab_notifications')}</h3>
						{
							this.props.data.history.length > 2 ?
							<div>
								<CooperationHistory 
									history={this.props.data.history.slice(0, 2)}
									ignoreUnread={true}
								/>
								{
									this.props.showMore &&
									<Link className="history-show-more" to={`/collab/${this.props.data.cooperations_id}`}>+{this.props.data.history.length - 2} {i18n.t('collab_notifications')}</Link>
								}
							</div> :
							<CooperationHistory 
								history={this.props.data.history} 
								ignoreUnread={true}
							/>
						}
					</div>
				}
				{
					this.props.data.unread_messages && this.props.data.unread_messages > 0 &&
					<Link className="unread-messages-counter text-white" to={`/collab/${this.props.data.cooperations_id}`}><FontAwesomeIcon icon={faEnvelope} /> {this.props.data.unread_messages} {this.props.data.unread_messages > 1 ? i18n.t('new_messages_lc') : i18n.t('new_message_lc')}</Link>
				}
				<div>
					{
						this.props.showMore &&
						<Link className="btn btn-secondary text-white" to={`/collab/${this.props.data.cooperations_id}`}>{i18n.t('show_more')}</Link>
					}
					
				</div>
			</div>
		)
	}
}

export default Cooperation