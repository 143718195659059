import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import RequestCard from '../components/RequestCard';
import Load from '../components/Load';
import i18n from "../i18n";
import request from '../request';
import Rightchat from "../components/Rightchat";

class SearchResults extends Component {

  constructor(props) {
    super(props);

    this.state = {
      network_results: [],
	    realtors_results: [],
	    active_page: 'realtors',
      realtors_page: 1,
	    networks_page: 1,
	    perPage: 10,
      end: false,
	    scroll_blocked: false
    };
	
  }

  componentDidMount() {
    
    this.unmountLocale = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    this.getResults()

    this.onDocumentScroll = (e) => {
      const totalPageHeight = document.body.scrollHeight - 1; 
      const scrollPoint = window.scrollY + window.innerHeight;
      if(scrollPoint >= totalPageHeight) {
		this.getResults()
      }
    };
    document.addEventListener('scroll', this.onDocumentScroll);
  }

  componentDidUpdate(prevProps, prevState) {
	const active = this.props.match.params.status
	
	if (active && active !== prevProps.match.params.status) {
		this.changeActive(active)
	}
  }

  changeActive(active) {
	this.setState({
		...this.state,
		active_page: active,
		scroll_blocked: true,
		end: false,
		[`${active}_page`]: 1
	}, () => {
		this.getResults()
	})
  }

  getResults(){
    request.get(`search/${this.state.active_page}/${this.state[`${this.state.active_page}_page`]}`).then(response => {
		let page = this.state[`${this.state.active_page}_page`] + 1
		let end = this.state.end
		
		if (!response.data || this.state.perPage > response.data.length ) {
			end = true
			page = 1
		}

		this.setState({
			...this.state,
			[this.state.request_uri]: this.state[this.state.request_uri].concat(response.data),
			end: end,
			[`${this.state.request_uri}_page`]: page,
			scroll_blocked: false
		})
	})
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onDocumentScroll);
    this.unmountLocale()
  }

  render() {
    return (
      <Fragment> 
        <Header />
        <Leftnav />
          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
                <div className="row">
                  <div className="col-xl-12 scroll-bar">
                    <Pagetitle title={i18n.t('results_page_title')} />
                    {
                      //list results
                    }
                    <Load end={this.state.end}/>
                </div>
                </div>
              </div>
            </div>
          </div>
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default SearchResults;