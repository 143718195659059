import React, {useState, Component , Fragment } from "react";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import axios from "axios";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import MapWithMarker from "../components/MapWithMarker";
import i18n from "../i18n";

import '../css/style.css';


function Contactinfo (){
    
    const {register, handleSubmit, formState: { errors },} = useForm();
    const onSubmit = (data) => {};

    const [user, setUser] = useState({       
        address: '',
        city: '',
        postCode: '',
        email: '',
        phone: '',
    });

    const updateAddress = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const updateCity = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const updatePostCode = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const updateEmail = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const updatePhone = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const [error, setError] = useState({message: "", isError: false});

    const submitHandler = async (user, e) => {
        e.preventDefault();
        try {
            const {address, city, postCode, email, phone} = user;
            setError({message: "", isError: false });           
                const resp = await axios({
                method: "POST",
                url: `http://localhost:3000/api/contactinfo`,
                headers: {
                "Content-Type": "application/json",
                },
                data: {address, city, postCode, email, phone,}, });
        } catch (error) {
            setError({message: error.message, isError: true});
        }        
    };

    return (
        <Fragment> 

            <div className="main-wrapper">
                <Header />
                <Leftnav />
                <Rightchat />                
                <div className="main-content  theme-dark-bg right-chat-active">            
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to="/settings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                        <h1 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">{i18n.t('saved_address')}</h1>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">                                    
                                        <form onSubmit={handleSubmit(submitHandler)}>                                            
                                            <div className="row">
                                                {/* ADDRESS */}
                                                <div className="col-lg-12 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">{i18n.t('address')}</label>
                                                        <input type="text" 
                                                        name='address'
                                                        id='address'
                                                        className="form-control" 
                                                        defaultValue={user.address}
                                                        {...register("address", {required: true, minLength: 2, pattern: /[a-zA-Z0-9\u0370-\u03ff\u1f00-\u1fff&+-]+$/})}
                                                        onChange={updateAddress} />
                                                        {errors.address?.type === "required" && <p className="error_message">{i18n.t('error_field')}</p>}
                                                        {errors.address?.type === "minLength" && <p className="error_message">{i18n.t('address_error')}</p>}
                                                        {errors.address?.type === "pattern" && <p className="error_message">{i18n.t('address_error')}</p>}
                                                    </div>        
                                                </div>
                                            </div>

                                            <div className="row">
                                                {/* CITY */}
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">{i18n.t('city')}</label>
                                                        <input type="text" 
                                                        name='city' 
                                                        id='city'
                                                        className="form-control" 
                                                        defaultValue={user.city}
                                                        {...register("city", {required: true, minLength: 2, pattern: /[a-zA-Z\u0370-\u03ff\u1f00-\u1fff-]+$/})}
                                                        onChange={updateCity} />
                                                        {errors.city?.type === "required" && <p className="error_message">{i18n.t('error_field')}</p>}
                                                        {errors.city?.type === "minLength" && <p className="error_message">{i18n.t('city_error')}</p>}
                                                        {errors.city?.type === "pattern" && <p className="error_message">{i18n.t('city_error')}</p>}
                                                    </div>        
                                                </div>
                                                
                                                {/* POST CODE */}
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">{i18n.t('post_code')}</label>
                                                        <input type="text" 
                                                        name='postCode'
                                                        id='postCode'
                                                        className="form-control" 
                                                        defaultValue={user.postCode}
                                                        {...register("postCode", {required: true, minLength: 5, maxLength: 6, pattern: /[0-9]+$/})}
                                                        onChange={updatePostCode} />
                                                        {errors.postCode?.type === "required" && <p className="error_message">{i18n.t('error_field')}</p>}
                                                        {errors.postCode?.type === "minLength" && <p className="error_message">{i18n.t('post_code_error')}</p>}
                                                        {errors.postCode?.type === "maxLength" && <p className="error_message">{i18n.t('post_code_error')}</p>}
                                                        {errors.postCode?.type === "pattern" && <p className="error_message">{i18n.t('post_code_error')}</p>}
                                                    </div>        
                                                </div>
                                            </div>

                                            <div className="row">
                                                {/* EMAIL */}
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">{i18n.t('email')}</label>
                                                        <input type="email" 
                                                        name="email"
                                                        id="email"
                                                        className="form-control" 
                                                        defaultValue={user.email}
                                                        {...register("email", {required: true, pattern: /[a-z0-9_-]+(?:\.[a-z0-9_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/})}
                                                        onChange={updateEmail} />
                                                        {errors.email?.type === "required" && <p className="error_message">{i18n.t('error_field')}</p>}
                                                        {errors.email?.type === "pattern" && <p className="error_message">{i18n.t('email_error')}</p>}
                                                    </div>        
                                                </div>

                                                {/* PHONE */}
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">{i18n.t('phone')}</label>
                                                        <input type="tel" 
                                                        name="phone"
                                                        id="phone"
                                                        className="form-control" 
                                                        defaultValue={user.phone}
                                                        {...register("phone", {required: true, minLength: 10, maxLength: 14, pattern: /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/})}
                                                        onChange={updatePhone} />
                                                        {errors.phone?.type === "required" && <p className="error_message">{i18n.t('error_field')}</p>}
                                                        {errors.phone?.type === "minLength" && <p className="error_message">{i18n.t('phone_error')}</p>}
                                                        {errors.phone?.type === "maxLength" && <p className="error_message">{i18n.t('phone_error')}</p>}
                                                        {errors.phone?.type === "pattern" && <p className="error_message">{i18n.t('phone_error')}</p>}
                                                    </div>        
                                                </div>
                                                
                                                {/* MAP WITH MARKER */}
                                                {/* <div className="col-lg-12">
                                                    <div className="rounded-3 overflow-hidden mb-2" style={{ height: '250px', width: '100%' }}>
                                                    <MapWithMarker/>
                                                    </div>
                                                </div> */}
                                            </div>
                                            
                                            <div className="row">
                                                {/* SUBMIT BUTTON */}
                                                <div className="col-lg-12 mb-0 mt-2 ps-0">
                                                    <button type='submit' className="border-none bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">{i18n.t('save')}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>            
                </div>

            </div>
            <Popupchat />
            <Appfooter /> 
                
        </Fragment>
    );
    
}

export default Contactinfo;