import React, { Component , Fragment, } from "react";

import Header from '../components/Header';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

import Createpost from '../components/Createpost';
import validator from 'validator'
// import Memberslider from '../components/Memberslider';
// import Friendsilder from '../components/Friendsilder';
// import Storyslider from '../components/Storyslider';
import Postview from '../components/Postview';
import Load from '../components/Load';
import Utils from "../utils"
import i18n from "../i18n";
import request from "../request";
import storage from '../storage';
import FriendRequestsList from "../components/FriendRequestsList";
import SpecialPostView from "../components/SpecialPostView";
// import Friends from "./Friends";

class Home extends Component {
    constructor(props) {
      super(props);

      this.state = {
        posts: [],
        system_notifications: [],
        page: 1,
        loaded: false,
        end: false,
        meta: [],
        post: ''
      };

      this.getMemberPosts = this.getMemberPosts.bind(this);
      this.addPost = this.addPost.bind(this);
      this.handlePostChange = this.handlePostChange.bind(this);
      this.removePreview = this.removePreview.bind(this);
    }

    componentDidMount() {
      const profile = this.props.match.params.id ? this.props.match.params.id : localStorage.loginId;

      i18n.onChangeLocale(() => {
        this.forceUpdate();
      });

      if(profile){
        this.getMemberPosts(profile);
      }
  
      this.onDocumentScroll = (e) => {
        const totalPageHeight = document.body.scrollHeight - 1; 
        const scrollPoint = window.scrollY + window.innerHeight;
        if(scrollPoint >= totalPageHeight) {
          this.showMorePosts(profile);
        }
      };
      document.addEventListener('scroll', this.onDocumentScroll);
    }

    getMemberPosts(profile, page){
      return request.get(`posts/id/${profile}/?view=home&page=${page || this.state.page}`).then((response) => {
        if (response.status === 200) {
          this.setState({
            posts: this.state.posts.concat(response.data.posts),
            system_notifications: this.state.system_notifications.concat(response.data.system_notifications),
            loaded: true,
            end: response.data.posts.length === 0,
          });
        }
        else if (response.status !== 301){
          this.setState({
            end: true,
          });
        }
      });
    }

    removePreview(){
      this.setState({
        meta: [],
      });
    }
  
    showMorePosts(profile) {
      if(!this.state.end){
        const newPage = this.state.page + 1;
        this.getMemberPosts(profile, newPage);
        this.setState({
          ...this.state,
          page: newPage,
        });
      }
    }

    removePost(post){
      request.delete(`posts/id/${post}`).then((response) => {
        if(response.data.status === '1'){
          const posts = this.state.posts.filter(p => p.id != post);
          this.setState({
            ...this.state,
            posts: posts,
          });
        }
      });
    }

    setEmotion(id, emotion){
      request.put(`posts`,{
        id: id,
        emotion: emotion,
      }).then((response) => {
          const posts = JSON.parse(JSON.stringify(this.state.posts));
          posts.filter(p => p.id == id)[0].emotions = response.data[0].emotions;
          this.setState({
            ...this.state,
            posts: posts,
          });
      });
    }

    handlePostChange(e){
      var val = e.target.value;
      this.setState({post: val});
      if(val && this.state.meta.length === 0){
      var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        var url = val.match(urlRegex);
        if(url && validator.isURL(url[0])){
          request.post(`og`,{
            url: url[0],
          }).then((response) => {
            this.setState({
              ...this.state,
              meta: response.data,
            });
          });
        } 
      }
    }

    addPost(){
      if (!Utils.stringEmpty(this.state.post)) {
        request.post(`posts`,{
          post: this.state.post,
          meta: this.state.meta,
          me: localStorage.loginId,
        }).then((response) => {
          if (response.status === 200) {
            this.setState({
              posts: response.data.concat(this.state.posts),
              meta: [],
              post: '',
            });
          }
        });
      }
    }

    updatePost(id, post, meta) {
      
      this.setState({
        ...this.state,
        posts: this.state.posts.map(p => {
          if (p.id === id) {
            return {
              ...p,
              description: post,
              meta: meta
            }
          }

          return p
        })
      })
    }

    componentWillUnmount() {
      i18n.clearOnChangeLocale();
      document.removeEventListener('scroll', this.onDocumentScroll);
    }

    render() {
        return (
            <Fragment> 
                <Header />
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row feed-body">
                                <div className="col-xl-8 col-xxl-9 col-lg-8">
                                    
                                    {/* <Storyslider /> */}
                                    <Createpost 
                                      me={storage.me}
                                      meta={this.state.meta.length>0 ? this.state.meta : null}
                                      post={this.state.post}
                                      handlePostChange={this.handlePostChange}
                                      addPost={this.addPost}
                                      removePreview={this.removePreview}
                                    />

                                    {/*<Memberslider />
                                    <Friendsilder />*/}

                                    {
                                      (this.state.posts && this.state.posts.length > 0) && 
                                      <>
                                      {
                                        this.state.posts.map( (p, i) => {
                                          if (typeof p.type !== 'undefined') {
                                            return <SpecialPostView 
                                              key={`special-post-${p.id}`}
                                              type={p.type}
                                              related_data={p.data}
                                              cross_data={p.cross_data}
                                              total_cross_data={p.total_cross_data}
                                              post_id={p.id}
                                            />
                                          }
                                          return <Postview key={i} 
                                            raw_data={p}
                                            postid={p.id} 
                                            postowner={p.owner}
                                            postvideo="" 
                                            postimage={p.image} 
                                            avater={p.user_image} 
                                            user={p.user_name} 
                                            user_id={p.user_id} 
                                            rating={p.rating}
                                            meta={p.meta ? p.meta : null}
                                            me={storage.me}
                                            member={storage.me}
                                            post_to={p.post_to}
                                            network_to={p.network_to}
                                            time={ Utils.getMomentText(p.timestamp) } 
                                            desc={ Utils.getMinifiedDescription(p.description, 200) } 
                                            full_description={p.description}
                                            postcomments={p.comments}
                                            postemotions={p.emotions}
                                            removePost={(e) => this.removePost(e)}
                                            setEmotion={(a,b) => this.setEmotion(a,b)}
                                            updatePost={(id, post, meta) => this.updatePost(id, post, meta)}
                                          />
                                        })
                                      }
                                      
                                      </>
                                    }

                                    
                                    
                                    <Load end={this.state.end}/>
                                </div>
                                <div className="col-xxl-3 col-xl-4 col-lg-4 ps-lg-0">
                                    <FriendRequestsList />
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
                
                <Rightchat />
                <Popupchat />
                <Appfooter /> 
            </Fragment>
        );
    }
}

export default Home;