import React, { Component , Fragment } from "react";
import i18n from "../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChessBishop, faChessKing, faChessPawn, faChessKnight, faChessRook, faChessQueen } from '@fortawesome/free-solid-svg-icons'

class ReputationData extends Component {
	constructor(props) {
		super(props)

		this.state = {
			pawn_levels: {
				0: faChessPawn,
				1: faChessBishop,
				2: faChessKnight,
				3: faChessRook,
				4: faChessQueen,
				5: faChessKing
			},
			current: 0,
			current_pawn: faChessPawn,
			demo_rep: 100 + Math.ceil(Math.random() * 200)
		}
	}

	// getPawnColor(demo_rep = 200) {
	// 	const rep = (this.props.member && this.props.member.reputation) ? this.props.member.reputation : this.state.demo_rep;
	// 	let pawn = Math.floor(parseInt(rep) / 100) - 1

	// 	if (pawn > 4) pawn = 4
	// 	if (pawn < 0) pawn = 0

	// 	this.setState({
	// 		current: pawn,
	// 		current_pawn: this.state.pawn_levels[pawn],
	// 		// demo_rep: demo_rep + 100
	// 	})
	// }

	componentDidMount() {
		
		// this.getPawnColor()
		// setInterval(() => {	
		// 	this.getPawnColor(this.state.demo_rep)
		// }, 2000)
	}

	getPawn() {
		const rep = (this.props.member && this.props.member.reputation) ? this.props.member.reputation : this.state.demo_rep;
		let pawn = Math.floor(parseInt(rep) / 100) - 1

		return this.state.pawn_levels[pawn] ? this.state.pawn_levels[pawn] : faChessPawn
	}

	getPawnColor() {
		const rep = (this.props.member && this.props.member.reputation) ? this.props.member.reputation : this.state.demo_rep;
		let pawn = Math.floor(parseInt(rep) / 100) - 1

		return pawn
	}

	render() {
		return (
			<div className={`card w-100 rounded-xxl border-0 mb-3 ${this.props.className ? this.props.className : ''}`}>
				<div className="card-body d-flex align-items-center p-4">
					<h4 className="fw-700 mb-0 font-xss text-grey-900">
						Reputation
					</h4>
					<span className={`fw-600 ms-auto font-xss reputation-text reputation-text-${this.getPawnColor()}`}>{<FontAwesomeIcon icon={this.getPawn()}/> } {(this.props.member && this.props.member.reputation) ? parseInt(this.props.member.reputation) : this.state.demo_rep}</span>
				</div>
			</div>
		)
	}
}

export default ReputationData