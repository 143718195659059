import React from 'react';
import notificationsHelper from '../notifications-helper';
import moment from 'moment';
import i18n from '../i18n';

class NotificationItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: notificationsHelper.getMessage(props.data.type),
    };
  }

  componentDidMount() {
    i18n.onChangeLocale(() => {
      this.setState({
        ...this.state,
        message: notificationsHelper.getMessage(this.props.data.type),
      });
    });
  }

  render() {
    return (
      <div className="notification-item card bg-transparent-card w-100 border-0 ps-5 mb-3">
        <div className="image position-absolute left-0">
          <img src={this.props.data.avatar} alt="user" className="img-circle w50" />
        </div>
        <div className="ms-3 mt-2">
          <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
            {this.props.data.name}
            <span className="text-grey-400 font-xsssss fw-600 float-right mt-1">{moment(this.props.data.date, 'YYYY-M-D H:i').fromNow()}</span>
          </h5>
          <h6 className="text-grey-500 fw-500 font-xssss lh-4">{this.state.message}</h6>
        </div>
      </div>
    );
  }
}

export default NotificationItem;