// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, Router  } from 'react-router-dom';

// Create Import File
import './main.css';

// Common Layout
import Home from './pages/Home';
import history from './helpers/history';
import AccountInfo from './pages/Accountinfo';
import Contactinfo from './pages/Contactinfo';
import Socialaccount from './pages/Socialaccount';
import Password from './pages/Password';
import Helpbox from './pages/Helpbox';
import Login from './pages/Login';
import Register from './pages/Register';
import Forgot from './pages/Forgot';
import Notfound from './pages/Notfound';
import Heatmap from './pages/Heatmap';
import Cooperations from './pages/Cooperations';
import { Redirect, Link } from 'react-router-dom';
import Comingsoon from './pages/Comingsoon';

import Properties from './pages/Properties';
import PropertyDetails from './pages/PropertyDetails';
import Profile from './pages/Profile';
import Members from './pages/Members';
import Requests from './pages/Requests';
import Networks from './pages/Networks';
import MySettings from './pages/MySettings';
import Friends from './pages/Friends';
import FriendRequests from './pages/FriendRequests';
import Toast from './components/Toast';
import Notifications from './pages/Notifications';
import notificationsHelper from './notifications-helper';

import './css/style.css';
import request from './request';
import storage from './storage';
import i18n from './i18n';
import socket from './socket';
import chat from './chat';
import AppChat from './components/Chat';

import Network from './pages/Network';
import OpenHouses from './pages/OpenHouses';

import NetworkForm from './pages/NetworkForm';
import SearchResults from './pages/SearchResults';
import PropertyForm from './pages/PropertyForm';
import Messages from './pages/Messages';
import ManageProperties from './pages/ManageProperties';
import PropertyMedia from './pages/PropertyMedia';
import ManageRequests from './pages/ManageRequests';
import RequestForm from './pages/RequestForm';
import Terms from './pages/Terms';
import ManageNetworks from './pages/ManageNetworks';
import ForgotSendEmail from './pages/ForgotSendEmail'
import ReportABug from './pages/ReportABug'
import RealtorsListings from './pages/RealtorsListing';
import ManageSearches from './pages/ManageSearches';
import MandatoryPayment from './pages/MandatoryPayment';
import PaymentStatus from './pages/PaymentStatus';
import RequestDetails from './pages/RequestDetails';
import CooperationDetails from './pages/CooperationDetails';
import NotificationsSettings from './pages/NotificationsSettings';
import OpenForWorkListings from './pages/OpenForWorkListings';
import Statistics from './pages/Statistics';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import settings from './settings';
import { App as CapacitorApp } from '@capacitor/app';
import KeepConnection from './pages/KeepConnection';


class Root extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      events: [],
      contacts: chat.contacts,
      emulate_disconnect: false,
      openChats: [],
      me: null,
      notifications: [],
      fcm_token: '',
      saved_fcm_token: false
      // fcm: new FirebaseApp
    };
  }

  checkDaysLeft = (response) => {
    let date_1 = new Date(response.data.realtors_access_until);
    let date_2 = new Date();

    const days = (date_1, date_2) => {
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }
    
    this.setState({
      me: {
        ...response.data,
        days_left: days(date_1, date_2)
      }
    })
  }

  setFcmToken = (token) => {
    this.setState({
      fcm_token: token
    })
    this.storeFcmToken(token)
  }

  storeFcmToken = (token) => {
    request.post('fcm_token', {
      token: token,
      type: Capacitor.getPlatform()
    }).then((response) => {
      console.log('fcm_token response', JSON.stringify(response))
      if (response.status === 200) {
        this.setState({
          saved_fcm_token: true
        })
      }
    })
  }
  
  componentDidMount() {

    CapacitorApp.addListener('backButton', ({canGoBack}) => {
      if(!canGoBack){
        CapacitorApp.exitApp();
      } else {
        history.goBack()
      }
    });

    const token = localStorage.getItem('token') !== null && localStorage.getItem('token') != 'null' ? localStorage.getItem('token') : null
    if (token) {
      storage.set('token', token)

      request.get(`whoami`).then((response) => {
        storage.set('me', response.data);
        storage.set('is_logged', true)

        this.checkDaysLeft(response)

        if (this.state.fcm_token !== '' && !this.state.saved_fcm_token) {
          this.storeFcmToken(this.state.fcm_token)
        }
      });
    }
    if (Capacitor.getPlatform() !== 'web') {
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
              // showToast('Push Notification permission denied');
            }
            else {
              // showToast('Push Notification permission granted');
              register();
            }
          });
        }
        else {
          register();
        }
      });
    }
    

    const register = (force_inform = false) => {

      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
          (token) => {
            this.setFcmToken(token)
              // showToast('Push registration success');
          }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
          (error) => {
              alert('Error on registration: ' + JSON.stringify(error));
          }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
          (notification) => {
            // console.log('pushNotificationReceived', JSON.stringify(notification))
            // if (notification.data.type === 'message') {
            //     settings.set('openChats', [...settings.openChats, notification.data.message.sender])
            // }
            this.setState({
              notifications: [...this.state.notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }]
            })
              // setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
          }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
          (notification) => {
            console.log('pushNotificationActionPerformed', JSON.stringify(notification))
            if (notification.notification.data.type) {
              if (notification.notification.data.type === 'message') {
                history.push(`/messages/${notification.notification.data.sender}`)
              }
            }
            else if (notification.notification.data.action) {
              if (notification.notification.data.action !== '') {
                history.push(notification.notification.data.action)
              }
            }
            
            this.setState({
              notifications: [...this.state.notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }]
            })
              // setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
          }
      );
    }
    
    

    chat.onChange(() => {
      this.setState({
        ...this.state,
        contacts: chat.contacts
      })
    })

    socket.onEvent((data) => {
      const e = this.state.events.slice(0);
      e.push(data);
      this.setState({
        ...this.state,
        events: e,
      });
      if (storage.is_logged) {
        request.get(`notifications`).then((response) => {
          notificationsHelper.set(response.data.data);
        });
      }
      
    });
    

    i18n.onChangeLocale(() => {
      socket.send({
        type: 'locale-change',
        locale: i18n.getLocale(),
      });
    });
  }

  refreshConnection = () => {
    const refresh_token = localStorage.getItem('refresh_token')

    if (!refresh_token) {
      history.push('/login');
      return;
    }

    request.post('refresh', {
      token: refresh_token
    }).then(response => {
      if (response.status == 200 ) {
        if (response.data.token) {
          localStorage.setItem('token', response.data.token)
          localStorage.setItem('refresh_token', response.data.refresh_token)
          storage.set('token', response.data.token)
          storage.set('refresh_token', response.data.refresh_token)
          request.get(`whoami`).then((response) => {
            storage.set('me', response.data);
            storage.set('is_logged', true);
            chat.init()
            if (this.state.fcm_token !== '' && !this.state.saved_fcm_token) {
              this.register(true)
            }
            setTimeout(() => {
              history.push('/')
              this.checkDaysLeft(response)
            }, 1000)
          });
        } 
        
      }
      else {
        localStorage.removeItem('refresh_token')
        history.push('/login')
      }
    })
  }
  async onLogin(email, password) {
    const rsp = new Promise((res, rej) => {
      request.post('login', {
        email,
        password,
        rspass: 'demo'
      }).then(response => {
        if (response.status == 200 ) {
          if (response.data.token) {
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('refresh_token', response.data.refresh_token)
            localStorage.setItem('loginId', response.data.loginId)
            localStorage.setItem('saw_tutorial', response.data.saw_tutorial)
            storage.set('token', response.data.token)
            storage.set('refresh_token', response.data.refresh_token)
            request.get(`whoami`).then((response) => {
              storage.set('me', response.data);
              storage.set('is_logged', true);
              chat.init()
              if (this.state.fcm_token !== '' && !this.state.saved_fcm_token) {
                this.storeFcmToken(this.state.fcm_token)
              }
              setTimeout(() => {
                history.push('/')
                this.checkDaysLeft(response)
              }, 1000)
            });
  
            res(true);
          } 
          
        }
        res(false);
      })
    })

    return rsp;
  }

  onLogout() {
    const theme = localStorage.getItem('theme')
    localStorage.clear()
    storage.set('me', null)
    notificationsHelper.clear()

    history.push('/login')
  }

  PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...(localStorage.token && localStorage.token !== '') ? rest : {}}
        render={({ location }) => {
          return (localStorage.token && localStorage.token !== '') ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  }

  render(){
    return(
      <Router basename={'/'}
        history={history}
        >
        <Switch>
          <Route exact path={`/terms`}>
            <Terms></Terms>
          </Route>
          <Route exact path={`/login`}>
            <Login onLogin={(e, y) => this.onLogin(e, y)} />
          </Route>
          <Route exact path={`/login/:redirectMessage`}>
            <Login onLogin={(e, y) => this.onLogin(e, y)} />
          </Route>
          <Route exact path={`/register`}>
            <Register/>
          </Route>
          <Route exact path={`/forgot`} component={ForgotSendEmail}/>
          <Route exact path={`/payment_status`} component={PaymentStatus}/>
          <this.PrivateRoute exact path={`/`} component={Home}/>
          <this.PrivateRoute exact path={`/renew_plan`} component={MandatoryPayment}/>
          <this.PrivateRoute exact path={`/home`} component={Home}/>
          <this.PrivateRoute exact path={`/forgot`} component={Forgot}/>
          <this.PrivateRoute exact path={`/profile`} component={Profile}/>
          <this.PrivateRoute exact path={`/friends`} component={Friends} />
          <this.PrivateRoute exact path={`/find-partners`} component={Comingsoon} />
          <this.PrivateRoute exact path={`/open-houses`} component={OpenHouses} />
          <this.PrivateRoute exact path={`/manage_properties`} component={ManageProperties} />
          <this.PrivateRoute exact path={`/manage_requests`} component={ManageRequests} />
          <this.PrivateRoute exact path={`/manage_networks`} component={ManageNetworks} />
          <this.PrivateRoute exact path={`/manage_searches`} component={ManageSearches} />
          <this.PrivateRoute exact path={`/notifications`} component={Notifications} />
          <this.PrivateRoute exact path={`/notification-settings`} component={NotificationsSettings} />
          <this.PrivateRoute exact path={`/settings`} component={MySettings} />
          <this.PrivateRoute exact path={`/search/:query/:active`} component={SearchResults}/>
          <this.PrivateRoute exact path={`/members`} component={Members}/>
          <this.PrivateRoute exact path={`/members/:id`} component={Profile}/>
          <this.PrivateRoute path={`/listings`} component={Properties}/>
          <this.PrivateRoute path={`/statistics`} component={Statistics}/>
          {/* <this.PrivateRoute exact path={`/members/:id/networks`} component={Networks} /> */}
          <this.PrivateRoute exact path={`/members/:id/:component`} component={Profile}/>
          {/* <this.PrivateRoute exact path={`/members/:id/:component`} component={Profile} /> */}
          {/* <this.PrivateRoute exact path={`/members/:id/friends`} component={Friends} /> */}

          <this.PrivateRoute exact path={`/networks`} component={Networks} />
          <this.PrivateRoute exact path={`/network/:id`} component={Network} />
          <this.PrivateRoute exact path={`/network/:id/:component`} component={Network} />
          <this.PrivateRoute exact path={`/edit_network/:id`} component={NetworkForm}/>
          <this.PrivateRoute exact path={`/networks/create_network`} component={NetworkForm}/>
          
          <this.PrivateRoute exact path={`/networks/:id/members`} component={Members} />
          <this.PrivateRoute exact path={`/networks/:id/properties`} component={Properties}/>
          <this.PrivateRoute exact path={`/networks/:id/requests`} component={Requests}/>

          <this.PrivateRoute path={`/social_members`} component={RealtorsListings}/>
          <this.PrivateRoute path={`/potential_partners`} component={OpenForWorkListings}/>
          <this.PrivateRoute exact path={`/properties/:id`} component={PropertyDetails}/>
          <this.PrivateRoute exact path={`/add_property`} component={PropertyForm}/>
          <this.PrivateRoute exact path={`/add_request`} component={RequestForm}/>
          <this.PrivateRoute exact path={`/edit_property/:id`} component={PropertyForm}/>
          <this.PrivateRoute exact path={`/edit_request/:id`} component={RequestForm}/>
          <this.PrivateRoute exact path={`/edit_property_media/:id`} component={PropertyMedia}/>
          {/* <this.PrivateRoute exact path={`/requests/:id`} component={PropertyDetails}/> */}
          <this.PrivateRoute path={`/requests/`} component={Requests}/>
          <this.PrivateRoute path={`/request/:id`} component={RequestDetails}/>
          <this.PrivateRoute exact path={`/collabs/:status`} component={Cooperations}/>
          <this.PrivateRoute exact path={`/collab/:id`} component={CooperationDetails}/>

          <this.PrivateRoute exact path={`/accountinformation`} component={AccountInfo}/>
          <this.PrivateRoute exact path={`/contactinformation`} component={Contactinfo}/>
          <this.PrivateRoute exact path={`/socialaccount`} component={Socialaccount}/>
          <this.PrivateRoute exact path={`/password`} component={Password}/>
          <this.PrivateRoute exact path={`/help`} component={Helpbox}/>

          <this.PrivateRoute exact path={`/heatmap`} component={Heatmap}/>

          <this.PrivateRoute exact path={`/notfound`} component={Notfound}/>
          <this.PrivateRoute exact path={`/comingsoon`} component={Comingsoon}/>

          <this.PrivateRoute exact path={`/friendrequests`} component={FriendRequests}/>
          
          <this.PrivateRoute exact path={`/messages`} component={Messages}/>
          <this.PrivateRoute exact path={`/report_a_bug`} component={ReportABug}/>
          <this.PrivateRoute exact path={`/messages/:id`} component={Messages}/>
          {/* <Route exact path={`${process.env.PUBLIC_URL}/defaultbadge`} component={Badge}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaultgroup`} component={Group}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaultstorie`} component={Storie}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaultemailbox`} component={Email}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaultemailopen`} component={Emailopen}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaultsettings`} component={Settings}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaultvideo`} component={Videos}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaultanalytics`} component={Analytics}/>
          
          <Route exact path={`${process.env.PUBLIC_URL}/accountinformation`} component={Account}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaultmember`} component={_Member}/>
          <Route exact path={`${process.env.PUBLIC_URL}/contactinformation`} component={Contactinfo}/>
          <Route exact path={`${process.env.PUBLIC_URL}/socialaccount`} component={Socialaccount}/>
          <Route exact path={`${process.env.PUBLIC_URL}/password`} component={Password}/>
          <Route exact path={`${process.env.PUBLIC_URL}/payment`} component={Payment}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaultnotification`} component={Notification}/>
          <Route exact path={`${process.env.PUBLIC_URL}/helpbox`} component={Helpbox}/>
          <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
          <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register}/>
          <Route exact path={`${process.env.PUBLIC_URL}/notfound`} component={Notfound}/>

          <Route exact path={`${process.env.PUBLIC_URL}/shop1`} component={ShopOne}/>
          <Route exact path={`${process.env.PUBLIC_URL}/shop2`} component={ShopTwo}/>
          <Route exact path={`${process.env.PUBLIC_URL}/shop3`} component={ShopThree}/>
          <Route exact path={`${process.env.PUBLIC_URL}/singleproduct`} component={Singleproduct}/>
          <Route exact path={`${process.env.PUBLIC_URL}/cart`} component={Cart}/>
          <Route exact path={`${process.env.PUBLIC_URL}/checkout`} component={Checkout}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaultmessage`} component={Chat}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaultlive`} component={Live}/>
          
          <Route exact path={`${process.env.PUBLIC_URL}/defaultjob`} component={Job}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaultevent`} component={Event}/>
          <Route exact path={`${process.env.PUBLIC_URL}/defaulthotel`} component={Hotel}/>
          <Route exact path={`${process.env.PUBLIC_URL}/grouppage`} component={Grouppage}/>
          <Route exact path={`${process.env.PUBLIC_URL}/userpage`} component={Userpage}/>
          <Route exact path={`${process.env.PUBLIC_URL}/authorpage`} component={Authorpage}/>  
          <Route exact path={`${process.env.PUBLIC_URL}/comingsoon`} component={Comingsoon}/>  
          <Route exact path={`${process.env.PUBLIC_URL}/defaulthoteldetails`} component={Hotelsingle}/> */}

          <Route path="/keepconnection">
            <KeepConnection 
              initializeRefresh={() => {
                this.refreshConnection()
              }}
            />
          </Route>
        </Switch>

        <AppChat />
        {
          this.state.me && this.state.me.realtors_payment_month > 0 && this.state.me.days_left <= 30 &&
            <Toast
              message={<Link to="/renew_plan" className="pt-2 pb-2 d-flex align-items-center">
              <i className="btn-round-md bg-blue-gradiant text-white feather-inbox font-md me-3"></i>
              <h3 className="fw-600 font-xsss mb-0 mt-0">{i18n.locale === 'el' ? `Απομένουν ${this.state.me.days_left} μέρες χρήσης` : `${this.state.me.days_left} days left until the end of subscription`}</h3>&nbsp;
              <button className='btn btn-primary text-white'>{i18n.t('renew_subscription')}</button>
            </Link>}
            />
          
        }
        {
          this.state.events.map((event, i) =>
            <Toast
              key={i}
              message={event.message}
              hide_ms={100000}
            />
          )
        }
        
      </Router>
    )
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
// serviceWorker.register();