import moment from 'moment';
require("moment/min/locales.min");

class I18n {
  constructor() {
    moment.locale('el');
  }

  locale = 'el';
  translations = {
    en: {
      map: 'Map',
      search_year_from: 'Con. year from',
      search_year_to: 'Con. year to',
      search_floor_from: 'Floor from',
      choose_floor_from: 'Choose floor',
      search_floor_to: 'Floor to',
      choose_floor_to: 'Choose floor',
      price_per_sq_median: 'Median of Price per s.q.',
      price_median: 'Median of price',
      construction_year_median: 'Median of construction year',
      'Κατοικίες': 'Residential',
      'Επαγγελματικά': 'Professional',
      'Γη': 'Land',
      'Άλλο': 'Other',
      statistics: 'Statistics',
      statistics_page_title: 'Insights',
      sales: 'Properties for sale',
      sum_of_sales_per_price_group: 'Number of properties',
      rents: 'Properties for rent',
      sum_of_rents_per_price_group: 'Number of properties',
      button_reject_friend_request: 'Reject friend request',
      button_delete_friend: 'Remove friend',
      realtors_open_for_cooperation: 'Realtors open for cooperation',
      we_receiver_your_report_thank_you: 'We received your report. Thank you for making network a better place',
      warning: 'Warning',
      it_seems_you_already_reported_this_user: 'It seems you already reported this user',
      report_reason: 'Report reason',
      select_report_reason: 'Coose report reason',
      report_description: 'Description of report',
      required_field: 'Required',
      describe_your_reason_of_report_in_a_few_words: 'Describe what is the reason of this report in a few words',
      report_of_user: 'Report user',
      access_to_API: 'Access to General API',
      plus_vat: '+ VAT (for users of Greece)',
      creating_account_prompt_payment: 'The cost of a monthly subscription is',
      creating_account_prompt: 'After submit of the registration form your information will be validated by our specialized department.', //  After the validation you will get 15 days of free usage.
      show_notifications: 'Show notifications',
      configure_notifications: 'Configure notifications',
      save_notifications_prefferences: 'Save notification preferences',
      email_notifications_settings_accept: 'Accept',
      email_notifications_settings_reject: 'Reject',
      email_notifications_settings: 'Email notifications settings',
      alternative: 'Alternative',
      deposit_to_account_prompt: 'You can deposit the same amount in the account below with written justification as "Renew subscription for account',
      deposit_to_account_bank: 'Peiraios bank (IARTS I.K.E.)',
      deposit_to_account_account_num: 'Account num.: 52 2609 0247 758',
      deposit_to_account_iban: 'IBAN: GR1501722260005226090247758',
      update_your_address_for_users: 'Update your address in order to make it easier for other users to find you',
      go_to_settings: 'Go to settings',
      renew_subscription: 'Renew your subscription',
      new_message_lc: 'new message',
      new_messages_lc: 'new messages',
      new_collab_notifications: 'New updates on cooperation',
      collab_notifications: 'notifications',
      cooperation_accepted_event_message: 'Cooperation request accepted',
      friend_accepted_event_message: 'Friend request accepted',
      liked_post_event_message: 'Liked your post',
      commented_post_event_message: 'Commented on your post',
      cooperation_rejected_event_message: 'Cooperation request rejected',
      cooperation_completed_event_message: 'Cooperation completed',
      cooperation_message_event_message: 'New cooperation message',
      cooperation_history: 'Cooperation history',
      the_user: 'User',
      sended_cooperation_request: 'sended a cooperation request',
      responded_to_cooperation_request: '',
      deleted_milestone: 'removed milestone',
      changed_milestone_status: 'changed milestone status',
      changed_milestone_status_to: 'to',
      changed_milestone_description_from: 'changed milestone description from',
      changed_milestone_description_to: 'changed milestone description to',
      accepted_cooperation: 'accepted the cooperation request',
      rejected_cooperation: 'rejected to cooperation request',
      inserted_new_milestone: 'inserted a new milestone',
      closed_cooperation: 'marked the cooperation as closed',
      rated_cooperation: 'rated your cooperation',
      completed: 'completed',
      pending: 'pending',
      with: 'with',
      cooperation: 'Cooperation',
      chat_cooperation_with: 'Cooperation chat with realtor',
      cooperation_chat: 'Chat',
      created_by: 'Created by',
      closed_by: 'Closed by',
      create_new_milestone: 'New milestone',
      request_locations: 'Request locations',
      amount: 'Amount',
      choose_plan: 'Choose',
      monthly_plan: 'Monthly plan',
      monthly_description: 'Full access to network.realstatus.net for 1 month',
      year_plan: 'Year plan',
      year_description: 'Full access to network.realstatus.net for 1 year with 10% discount',
      per_month: 'per month',
      per_year: 'per year',
      renew_subscription: 'Renew your subscription',
      complete_payment: 'Complete payment',
      payment_status: 'Payment status',
      access_renewed_until: 'Access to network renewed until',
      download_receipt: 'Download payment receipt',
      home: 'Go to home',
      search_has_been_saved: 'Search has been saved',
      saved_searches: 'Manage saved searches',
      manage_saved_searches: 'Saved searches',
      load_saved_search: 'Searches',
      search_title: 'Search title',
      set_a_search_title: 'Search a search title',
      save_search: 'Save search',
      social_members_title: 'Find members',
      report_user: 'Report account',
      user_sended_you_a_friend_request: 'User sended you a friend request',
      admin: 'Admin',
      button_remove_privileges: 'Remove privileges',
      button_remove_realtor_privileges: 'Remove admin privileges from user',
      button_add_privileges: 'Add privileges',
      button_add_realtor_privileges: 'Add admin privileges to user',
      thank_you_for_the_report: 'Thank you for the report',
      fill_all_fields: 'Please fill all fields',
      report_a_bug: 'Report a bug',
      bug_title: 'Bug summary',
      bug_description: 'Bug extended description',
      change_password: 'Change password',
      check_code: 'Check code',
      draw_at_least_one_search_location: 'Please draw at least one search location on map',
      password_field_required: 'Password is required',
      newPassword_field_required: 'New password is required',
      confirmNewPassword_field_required: 'Confirm password is required',
      newPassword_confirmNewPassword_missmatch: 'Passwords don\'t match',
      expired: 'User not found',
      password_changed: 'Password changed!',
      wrong_password: 'Wrong password',
      please_choose_transaction: 'Please choose a translaction type',
      please_choose_basic_category: 'Please choose basic category',
      please_choose_category: 'Please choose property category',
      please_set_description: 'Please set a description',
      please_set_description_en: 'Please set a description in english',
      please_set_price: 'Please set a valid price',
      please_set_sq: 'Please set sq',
      please_set_cordes: 'Please set the pin to the location of the property',
      please_set_zip: 'Please set the property zip code',
      try_again_single: 'Please try again',
      you_can_now_change_your_password: 'You can set a new password',
      you_will_receive_an_email_with_the_recovery_code: 'You will receive an email with the recovery code',
      wrong_code: 'Wrong code',
      login_again: 'Login to continue',
      insert_new_password: 'Insert your new password',
      reset_code: 'Code',
      type_the_code_from_email: 'Insert the code you received in your email',
      account_restore: 'Restore your account',
      send_email: 'Send email',
      button_delete_property_from_network: 'Remove property from network',
      button_delete_realtor_from_network: 'Remove realtor from network',
      button_delete_request_from_network: 'Remove request from network',
      choose_floors: 'Choose floor',
      requests_crossings_for_property: 'Property crossings for request',
      requests_crossings_for_property: 'Request crossings for property',
      crossings: 'Crossings',
      crossing: 'Crossing',
      network_updated: 'Network information updated',
      
      set_metro_distance: 'Set distance from metro',
      metro_distance: 'Distance from metro',
      update_request: 'Update request',
      insert_request: 'Add request',
      request_basic_information: 'Basic information',
      request_location_information: 'Location information',
      request_additional: 'Extra information',
      request_location: 'Location',
      request_distances: 'Distances',
      request_optional: 'Optional information',
      indifferent: 'Indifferent',
      request_updated: 'Request information updated',
      request_inserted: 'Request stored',
      property_updated: 'Property information updated',
      property_inserted: 'Property stored',
      previous_step: 'Previous step',
      update_property: 'Update property',
      insert_property: 'Insert your property',
      property_location_information: 'Property location information',
      property_basic_information: 'Property basic information',
      images_uploaded_succesfully: 'Images uploaded succesfully',
      are_you_sure: 'Are you sure?',
      you_are_going_to_delete_this_image: 'You are going to delete this image. Continue?',
      you_are_going_to_delete_this_network: 'You are going to delete this network. Continue?',
      you_are_going_to_delete_this_user_from_your_friend_list: 'You are going to delete this user from your friends list. By deleting the user you will lose access to the published properties and request of this user. Continue?',
      image_deleted: 'Image deleted succesfully',
      network_deleted: 'Network deleted succesfully',
      try_again: 'Try again after some time',
      the_property_is_not_deleted: 'Property did not deleted',
      something_went_wrong: 'Something went wrong',
      you_deleted_the_request_with_code: 'You succesfully deleted the request with code',
      you_deleted_the_property_with_code: 'You succesfully deleted the property with code',
      ok: 'Ok',
      you_are_about_to_delete_the_request_with_code: 'You are about to delete the request with code',
      you_are_about_to_delete_the_property_with_code: 'You are about to delete the property with code',
      continue_question: 'Do you wish to continue?',
      delete: 'Delete',
      changes_saved: 'Changes have been saved',
      publish_on_profile: 'Publish on profile',
      network_name: 'Network name',
      published_in_networks: 'Publish to networks',
      edit_property_publish: 'Edit property publications',
      publish_to: 'publish_to',
      publish: 'Publish',
      edit: 'Edit',
      manage: 'Manage',
      typeNet: 'Type the network you want',
      manage_requests: 'Your requests',
      manage_properties: 'Your properties',
      manage_networks: 'Your networks',
      manage_your_properties: 'Manage your properties',
      register_rejected: 'Registration rejected',
      pending_register_confirmation: 'Register confirmation pending. You will be able to login to your account after the necessary cross-checks',
      error_sso: 'Login via realstatus failed',
      registered_success: 'You registered successfully. You will be able to login to your account after the necessary cross-checks',
      'land_use': 'Land use',
      'A/C': 'A/C',
      'airport_distance': 'Distance from airport',
      'balcony': 'Balcony (sq.m.)',
      'BBQ': 'BBQ',
      'choose': 'Choose',
      'choose_conditions': 'Choose condition',
      'choose_heat': 'Choose heat',
      'choose_energy_class': 'Choose energy class',
      'choose_directions': 'Choose direction',
      'choose_land_slope': 'Choose land slope',
      'choose_land_use': 'Choose land usage',
      'choose_orientation': 'Choose orientation',
      'choose_public_service': 'Choose public services',
      'choose_retire': 'Retire',
      'choose_student': 'For students',
      'city_distance': 'Distances',
      'conditions': 'Condition',
      'construction_year': 'Construction year',
      'directions': 'Direction',
      'koinoxrista': 'Shared expenses',
      'land_slope': 'Land slope',
      'master_rooms': 'Master rooms',
      'orientation': 'Orientation',
      'Playroom': 'Playrooms',
      'property_descriptions': 'Property description',
      'property_distances': 'Property distances',
      'public_service': 'Public services',
      'sea_distance': 'Distance from sea',
      'set_airport_distance': 'Set a distance from airport',
      'set_balcony': 'Set sq.m. of balcony (if exists)',
      'set_city_distance': 'Set distance from city',
      'set_construction_year': 'Set construction year',
      'set_description': 'Set description in native language',
      'set_description_en': 'Set description in english',
      'set_master': 'Set master rooms',
      'set_plot_sq': 'Set sq.m. of plot',
      'set_sea_distance': 'Set a distance from sea',
      'set_student': 'For students',
      'set_village_distance': 'Set distance from village',
      'sq_plot': 'sq.m. of plot',
      'village_distance': 'Distance from village',
      'Άρτιο': 'Par',
      'Ανελκυστήρας φορτίων': 'Cargo lift',
      'Ανελκυστήρας': 'Elevator',
      'Ανοιχτωσιά': 'Open',
      'Αντιπαροχή': 'Consideration',
      'Αποθήκη': 'Storage',
      'Βαμμένο': 'Painted',
      'Γωνιακό': 'Corner',
      'Διατηρητέο': 'Preserved',
      'Διπλά τζάμια': 'Double glass',
      'Δομημένη καλωδίωση': 'Structured wiring',
      'Δορυφορική κεραία': 'Satellite',
      'Εμβαδόν αποθήκη': 'Storage sq.m.',
      'Εμβαδόν κτίσματος': 'Sq.m. of building',
      'Επαγγελματική Στέγη': 'Commercial property',
      'Επενδυτικό': 'Investment',
      'Επιπλωμένο': 'Furnished',
      'Επιτρέπονται κατοικίδια': 'Pets allowed',
      'Εσωτερική σκάλα': 'Internal stairs',
      'Ηλεκτρικές συσκευές': 'Electric devices',
      'Ηλιακός': 'Sun heater',
      'Κήπος': 'Garden',
      'Κεντρική οδό': 'Central street',
      'Κεντρική οδός': 'Central street',
      'Με άδεια δόμησης': 'With build permit',
      'Με αέριο': 'With gas',
      'Με γεώτρηση': 'With drilling',
      'Με εξοπλισμό': 'With equipment',
      'Με νερό': 'With water',
      'Με οπτικές ίνες': 'With optic fibers',
      'Με ρεύμα': 'With electricity',
      'Με τηλέφωνο': 'With phone',
      'Νεοκλασικό': 'Neoclassic',
      'Νυχτερινό ρεύμα': 'Night power',
      'Οικοδομήσιμο': 'Buildable',
      'Οροφοδιαμέρισμα': 'Floor apartment',
      'ΟΧΙ': 'NO',
      'Πάρκινγκ': 'Parking',
      'Πανοραμικό': 'Panoramic',
      'Παραλιακό': 'Coastal',
      'Περιφραγμένο': 'Fenced',
      'Πισίνα': 'Pool',
      'Πολυτελές': 'Luxurious',
      'Προαύλιο': 'Yard',
      'Πρόσβαση ΑΜΕΑ': 'Access for disabled',
      'Ράμπα εκφόρτωσης': 'Unloading ramp',
      'Ρετιρέ': 'Retire',
      'Ρολά ασφαλείας': 'Safety rolls',
      'Σίτες': 'Sieves',
      'Σαλοκουζίνα': 'Living room kitchen',
      'Σιδηροδρομική γραμμή': 'Railroad',
      'Σκαλιά εισόδου': 'Entrance stairs',
      'Σοφίτα': 'Atiic',
      'Συναγερμός': 'Alarm',
      'Τέντες': 'Tents',
      'Τελευταίος όροφος': 'Last floor',
      'Τζάκι': 'Fireplace',
      'Τιμή ενοικίου': 'Rent price',
      'Υπάρχει κτίσμα': 'With building',
      'Φωτεινό': 'Bright',
      'Χρήση Υγειονομικού': 'Use of Sanitary',
      'Ψευδοροφή': 'Fake roof',
      property_optional: 'Optional',
      view: 'View',
      choose_view: 'Set view',
      frame: 'Frames',
      choose_frame: 'Set frames',
      floors_materials: 'Floor type',
      choose_floors_materials: 'Set floor type',
      door: 'Door',
      choose_door: 'Choose',
      closet: 'Closet',
      choose_closet: 'Choose',
      garden: 'Garden',
      choose_garden: 'Choose',
      pool: 'Pool',
      choose_pool: 'Choose',
      access_type: 'Access from',
      choose_access_type: 'Choose',
      parking: 'Parking',
      choose_parking: 'Choose parking',
      set_retire: 'Retire',
      property_location: 'Property location',
      nomos: 'County',
      choose_nomos: 'Choose county',
      region: 'Location',
      choose_region: 'Choose location',
      location: 'Sublocation',
      choose_location: 'Choose sublocation',
      set_address: 'Address',
      set_zip: 'Zip code',
      property_additional: 'Additional information',
      set_rooms: 'Set number of rooms',
      set_spaces: 'Set number of spaces',
      rooms: 'Rooms',
      set_livingrooms: 'Set number of livingrooms',
      livingrooms: 'Livingrooms',
      set_bathrooms: 'Set number of bathrooms',
      bathrooms: 'Bathrooms',
      set_wc: 'Set number of wc',
      wc: 'wc',
      set_structure_factor: 'Set structure factor',
      structure_factor: 'Structure factor',
      set_coverage_factor: 'Set coverage factor',
      coverage_factor: 'Coverage factor',
      set_ipsos: 'Set permited build height',
      ipsos: 'Build height',
      set_sxedio: 'Set plan',
      choose_sxedio: 'Choose plan',
      in_plan: 'In plan',
      not_in_plan: 'Not in plan',
      property_type: 'Property type',
      property_values: 'Property values',
      purpose: 'For',
      choose_purpose: 'Choose purpose',
      category: 'Category',
      subcategory: 'Subcategory',
      zone: 'Zone',
      choose_zone: 'Choose zone',
      set_price: 'Set a price €',
      set_sq: 'Set s.q.',
      property_required_data: 'Required information',
      property_additional_data: 'Additional information',
      property_media: 'Media',
      active_filters: 'active filters',
      active_filter: 'active filter',
      filters: 'Filters',
      type_at_least: 'Please enter',
      or_more_characters: 'or more characters',
      search_purpose: 'For',
      search_for_sell: 'Sale',
      search_for_rent: 'Rent',
      search_category: 'Category',
      choose_category: 'Choose category',
      search_subcategory: 'Subcategory',
      choose_subcategory: 'Choose subcategory',
      search_locations: 'Locations',
      type_a_location: 'Start typing a location',
      search_price_from: 'Price from',
      search_price_to: 'Price to',
      search_sq_from: 'Sq from',
      search_sq_to: 'Sq to',
      results: 'results',
      no_results: 'No results found',
      people: 'People',
      help_n_support: 'Help and Support',
      button_hide: 'Hide',
      button_hide_post_info: 'Hide from timeline',
      cancel: 'Cancel',
      edit_post: 'Edit post',
      photo: 'Photo',
      i_understand: 'I understand',
      fail: 'Failure',
      title_field_empty: 'Title field is empty',
      title_less_than_3_characters: 'Title length must be more than 3 characters',
      description_field_empty: 'Description field is empty',
      description_less_than_10_characters: 'Description must be at least 10 characters long',
      type_field_empty: 'Type field is empty',
      type_not_numeric: 'Type field must be numeric',
      alright: 'Alright',
      success: 'Success',
      network_created: 'Network created',
      my_properties: 'My properties',
      my_requests: 'My requests',
      has_accepted_cooperation_request: 'Has accepted your cooperation request',
      first_name_field_empty: 'Field First name is empty',
      last_name_field_empty: 'Field Last name is empty',
      gemi_field_empty: 'Field Registration number is empty',
      email_field_empty: 'Field E-mail is empty',
      email_format_invalid: 'The e-mail format seems to be wrong. Please ensure that your e-mail is like x@y.com',
      email_already_exists: 'E-mail already exists',
      password_field_empty: 'Password is empty',
      confirm_password_field_empty: 'Field Confirm password is empty',
      password_must_be_atleast_6_characters_long: 'Password must be at least 6 characters long',
      confirm_password_missmatch: 'Password missmatch',
      you_must_accept_the_terms: 'You must accept the terms of use',
      you_registered_successfully: 'Registration completed. Please login with your new credentials',
      your_last_name: 'Your last name',
      your_gemi: 'Your registration number',
      already_have_account: 'Already have an account?',
      i_accept_the: 'I accept the',
      terms_of_use_register: 'terms of use',
      create_account: 'Create an account',
      your_name: 'Your name',
      your_email: 'Your email',
      confirm_password: 'Confirm password',
      cooperation_request_event_message: 'You got a new cooperation request',
      show_cooperation_request: 'Show request',
      has_sended_cooperation_request: 'Has sended a cooperation request',
      cooperation_accepted: 'Cooperation accepted',
      cooperation_moved_to_active: 'The cooperation request moved to active cooperations',
      cooperation_rejected: 'Cooperation rejected',
      cooperation_moved_to_rejected: 'The cooperation request moved to rejected cooperations',
      'Απόκρυψη συνεργασίας': 'Hide cooperation',
      'Επεξεργασία αξιολόγησης συνεργασίας': 'Edit cooperation rating',
      'Κλειστές συνεργασίες': 'Closed cooperations',
      closed_collaborations: 'Closed',
      cooperation_rate_sendeed: 'Cooperation rating has been sended',
      thank_you: 'Thank you',
      rating_message: 'Rating message',
      cooperation_rating: 'Cooperation rating',
      rate_the_cooperation_with_realtor: 'Rate the cooperation with realtor',
      cooperation_request_not_sended: 'Request has NOT sended',
      request_already_sended: 'You already sended a cooperation request',
      wait_1_day_before_resending_request: 'You already sended a cooperation request. You must wait one day before you resend request',
      cooperation_request_sended: 'Thee cooperation request has been sended succesfully!',
      you_will_be_informed_if_coop_accepted: 'You will be informed about the progression of your request',
      describe_your_reason_of_request_in_a_few_words: 'Describe the reason of the collaboration request in a few words',
      related_property_for_collaboration: 'Suggested properties for cooperation',
      related_requests_for_collaboration: 'Suggested requests for cooperation',
      request_content: 'Request content',
      search_your_properties: 'Search a property for reference',
      public_network: 'Public',
      private_network: 'Private',
      network_type: 'Network visibility',
      media_info: 'Media information',
      basic_info: 'Basic information',
      next_step: 'Next step',
      network_description: 'Network description',
      describe_your_network_in_a_few_words: 'Describe your network in a few words',
      what_is_your_network_title: 'What is your network title?',
      network_title: 'Network title',
      create_new_network: 'Create network',
      connection_down: 'No connection',
      featured: 'Recommended',
      msgsend: 'Message send successfully',
      realtor_prop: 'Realtor Properties',
      about: 'About',
      readall: 'Mark as read all',
      seeallmsg: 'See All in Messages',
      'Ξεχάσατε τον κωδικό σας; Πατήστε': 'Did you forget your password? Click',
      'εδώ': 'here',
      'Λάθος e-mail ή κωδικός πρόσβασης': 'Incorrect e-mail or password',
      'περιοχή': 'area',
      'περιοχές': 'areas',
      'Απορριφθείσες συνεργασίες': 'Rejected cooperations',
      'Απορριφθείσες': 'Rejects',
      'Διαγραφή αιτήματος': 'Delete request',
      'Ακυρώσατε πρόσφατα αίτημα συνεργασίας': 'Ακυρώσατε πρόσφατα αίτημα συνεργασίας. Προσπαθήστε ξανά αύριο',
      'Προστέθηκε ζήτηση η οποία ταιριάζει με το ακίνητό σας': 'A request has been added that matches with a property of yours',
      'Προστέθηκε ακίνητο το οποίο ταιριάζει με την ζήτησή σας': 'A property has been added that matches with a request of yours',
      'διασταύρωση': 'match',
      'σύστημα': 'system',
      'Εξερχόμενα αιτήματα συνεργασίας': 'Incoming co-op requests',
      'Εισερχόμενα αιτήματα συνεργασίας': 'Outgoing co-op requests',
      'Εισερχόμενα': 'Incoming',
      'Εξερχόμενα': 'Outgoing',
      'Κλείσιμο συνεργασίας': 'Close collaboration',
      'Αξιολόγηση συνεργασίας': 'Evaluate collaboration',
      'Επαναποστολή ψηφιακής υπογραφής': 'Resend digital signature',
      'Ακύρωση αιτήματος': 'Cancel request',
      'Απόρριψη': 'Reject',
      'Αποδοχή': 'Accept',
      'Συνεργάτης': 'Partner',
      'Απορριφθήσα συνεργασία': 'Rejected co-op',
      'Ενεργή συνεργασία': 'Active co-op',
      'Κλειστή συνεργασία': 'Closed co-op',
      'με κωδικό': 'with id',
      'για την ζήτηση': 'for the request',
      'για το ακίνητο': 'for the property',
      'Ενεργές συνεργασίες': 'Active co-ops',
      'Αιτήματα συνεργασίας': 'Co-op requests',
      'Βρες συνεργάτες': 'Find partners',
      'Χάρτης τιμών': 'Price map',
      'tools': 'Tools',
      'Γης ανά τμ': 'Land per m²',
      'Οικιστικών ανά τμ': 'Residential per m²',
      'Επαγγελματικών ανά τμ': 'Commercial per m²',
      'Επιλέξτε μέση τιμή': 'Choose category of avg value',
      'Δημοσιεύσεις': 'Posts',
      'Αποστολή μηνύματος': 'Send message',
      'Αίτηση συνεργασίας': 'Cooperation request',
      '--1': "Like",
      'Παρέχεται από τα δίκτυα': 'Provided by',
      'Εγγραφή στο δίκτυο': "Join network",
      'Αποχώρηση από το δίκτυο': 'Leave network',
      'Ακίνητα που ταιριάζουν με την ζήτησή σας': 'Properties that match your request',
      heart: "Love",
      blush: "Care",
      smile: "Haha",
      astonished: "Wow",
      cry: "Sad",
      angry: "Angry",
      show_more: "Show more",
      update_profile_image: "Update profile image",
      update_cover_image: "Update cover image",
      update_network_image: "Update network's image",
      date_added: "Date added",
      date_modified: "Last update",
      mapRequest: "Request with map selected area",
      formRequest: "Request locations",
      private: "Private",
      public: "Public",
      published: "Published",
      published_net: 'Published on the network',
      no_published_net: 'Unpublished on the network',
      no: "No",
      yes: "Yes",
      close: "Close",
      change_image: "Change photo",
      upload_image: "Upload photo",
      save: "Save",
      menu: "Menu",
      nothing_new: "Nothing new...",
      my_profile: "My profile",
      available_networks: "Available networks",
      colaborations: "Colaborations",
      networks: "Networks",
      networks_c: "NETWORKS",
      network_properties: "Network properties",
      network_requests: "Network requests",
      members: "Network members",
      send_to_network: "Send to network",
      delete_from_network: "Delete from network",
      messages: "Messages",
      message: "Message",
      send_message: 'Send message',
      move_to: 'Move to',
      compose: 'Compose',
      inbox: 'Inbox',
      sent: 'Sent',
      starred: 'Starred',
      spam: 'Spam',
      deleted: 'Deleted',
      no_messages_in_the_folder: 'There are no messages in the folder',
      write_your_message: 'Write your message...',
      attach_file: 'Attach file',
      attach_picture: 'Attach picture',
      send: 'Send',
      send_lower: 'Send',
      new_message: 'New Message',
      to: 'To',
      subject: 'Subject',
      terms_of_use: "Terms of use",
      homepage: "Home",
      settings: 'Settings',
      create_post: 'Create Post',
      whats_on_your_mind: "What's on your mind",
      write_something_to: "Write something to",
      write_something_to_network: "Write something to network",
      write_a_comment: "Write a comment...",
      write_a_reply: "Write a reply...",
      comment: "Comment",
      comments: "Comments",
      like: "Like!",
      reply: "Reply",
      replieces: "Replieces",
      request: 'Request',
      photo_video: 'Photo/Video',
      advert: 'Advert',
      start_typing_to_search: 'Start typing to search..',
      start_typing_to_search_C: 'SEARCH',
      properties_page_title: 'Properties',
      page_search_input_placeholder: 'Search...',
      for_sale: 'For Sale',
      for_rent: 'For Rent',
      sqm: 'sqm',
      bedrooms: 'Bedrooms',
      bathrooms: 'Bathrooms',
      spaces: 'Spaces',
      per_month_shortcut: 'mo',
      button_property_interested: 'INTERESTED',
      button_add_connection: 'Add',
      information: 'Information',
      members_page_title: 'Members',
      member_since: 'Member since',
      properties: 'Properties',
      requests: 'Requests',
      offers: 'Offers',
      clients: 'Clients',
      suggestions: 'Suggestions',
      phone: 'Phone',
      email: 'Email',
      address: 'Address',
      city: 'City',
      post_code: 'Zip Code',
      code: 'Network\'s Code',
      office_code: 'Office Code',
      price: 'Price',
      cooperation_request: 'Cooperation request',
      requests_page_title: 'Requests',
      general: 'General',
      account: 'Account',
      other: 'Other',
      account_information: 'Account Information',
      first_name: 'First Name',
      last_name: 'Last Name',
      description: 'Description',
      saved_address: 'Saved Address',
      social_account: 'Social Account',
      password: 'Password',
      help: 'Help',
      logout: 'Logout',
      want_to_logout: 'Do you want to logout?',
      login: 'Login',
      register: 'Register',
      login_into_your_account: 'Login into your account',
      your_email_address: 'Your Email Address',
      remember_me: 'Remember me',
      forgot_your_password: 'Forgot Your Password?',
      dont_have_account: "Don't have an account?",
      sign_in_alternatives: 'Alternative ways to sign in',
      sign_in_with_google: 'Sign in with Google',
      sign_in_with_facebook: 'Sign in with Facebook',
      sign_in_with_realstatus: "Sign in with RealStatus",
      friends: 'Friends',
      friends_c: "FRIENDS",
      friend_invitation: 'Invite friends',
      friend_request: 'Friend Request',
      see_all: 'See all',
      no_friend_requests: 'No friend requests',
      mutual_friends: 'Mutual friends',
      no_mutual_friends: 'No mutual friends',
      button_add: 'Add',
      button_edit: 'Edit',
      button_edit_post_info : 'Edit your post',
      button_delete: 'Delete',
      button_delete_post_info : 'Remove post from timeline',
      button_delete_comment_info : 'Remove comment from this post',
      button_delete_reply_info: 'Remove reply from this comment',
      button_pending: 'Request sent',
      button_cancel_friend_request: 'Cancel friend request',
      button_cancel_request: 'Cancel request',
      button_leave_network: 'Leave',
      button_join_network: 'Join',
      button_request_join_network: 'Request',
      button_request_join_network_pending: 'Pending',
      you_are_on_network: 'You are member',
      join_network: 'Become a member',
      no_friends_message: 'You have no friends yet',
      no_search_results_found: 'No search results found',
      notifications: 'Notifications',
      send_friend_request : 'Friend Request',
      friend_request_event_message: 'Has sent you a friend request',
      network_request_event_message: 'Has sent you a request to join the network',
      network_accepted_event_message: 'accepted your request to join the network',
      request_event_message_accepted: 'Request accepted',
      request_event_message_rejected: 'Request rejected',
      friend_request_message_accepted: 'Accepted your friend request',
      no_notifications_message: 'No notifications yet',
      you_are_no_longer_friends: 'You are no longer friends',
      delete_notification: 'Delete this notification',
      button_notification_read: 'Read notification',
      button_notification_unread: 'Unread notification',
      you_are_friends_on_network: 'You are friends on Network',
      characteristics_of_property: 'Characteristis',
      square_meters_shortcut: 'Sq.m.',
      undefined: 'Undefined',
      error_field: '*Required field',
      first_name_error: '*Invalid name',
      last_name_error: '*Invalid last name',
      email_error: '*Invalid email',
      phone_error: '*Phone should contain 10-14 digits',
      address_error: '*Invalid address',
      city_error: '*Invalid city',
      post_code_error: '*Invalid post code',
      current_password: 'Current Password',
      new_password: 'New Password',
      confirm_new_password: 'Confirm New Password',
      password_error: '*Password must contain...',
      passwords_dont_match: '*Passwords do not match',
      password_changed: 'Password changed!',
      contacts: 'CONTACTS',
      groups: 'GROUPS',
      pages: 'PAGES',
      available: 'Available',
      unavailable: 'Unavailable',
      add_story:'Add Story',
      advert_code: 'Advert Code',
      request_code: 'Request Code',
      request_characteristics: {
        for: 'For',
        category: 'Category',
        subcategory: 'Subcategories',
        price_min: 'Minimum price',
        price_max: 'Maximum price',
        area_min: 'Minimum s.q.',
        area_max: 'Maximum s.q.',
        heating_system: 'Heating',
        energy_class: 'Energy class',
        bedrooms_from: 'Minimum rooms',
        bathrooms_from: 'Minimum bathrooms',
        wc_from: 'Minimum wc',
        livingroom_from: 'Minimum livingrooms',
        floor: 'Preferred floors',
        parking: 'Preferred parking',
        year_of_construction_from: 'Minimum construction year',
        year_of_construction_to: 'Maximum construction year',
        last_update: 'Updated at',
        view: 'Preferred view',
        access_from: 'Access',
        orientation: 'Orientation',
        land_slope: 'Land slope',
        land_use: 'Land use',
      },
      property_characteristics: {
        network_code: 'Network code',
        office_code: 'Office code',
        price: 'Price',
        price_per_square_meter: 'Price per sq.m.',
        area: 'Area',
        type: 'Type',
        heating_system: 'Heating Sytem',
        energy_class: 'Energy class',
        bedrooms: 'Bedrooms',
        bathrooms: 'Bathrooms',
        floor: 'Floor',
        parking: 'Parking',
        year_of_construction: 'Year of construction',
        advert_code: 'Advert Code',
        available_from: 'Available from',
        last_update: 'Last update',
        location: 'Location',
        structure_factor: 'Structure Factor',
        coverage_factor: 'Coverage Factor',
        within_the_project: 'Within the project',
        zoning: 'Zoning',
        height: 'Height',
        depth: 'Depth',
        facade: 'Facade',
        access_from: 'Access from',
        orientation: 'Orientation',
        land_use: 'Land use',
        land_slope: 'Ground Slope',
        view: 'View',
        step1: 'Here you can see the notifications you have received',
        step2: 'Here you can see the Network Properties and Requests',
        step3: 'Here you can see the Networks you belong to as well as your Friends',
        step4: 'Here you can see the Co-ops and their status',
        step5: 'Here you can see the properties, request them and your networks',
        step6: 'Tools which will be continuously enriched',
      },
      show_missing_extras: 'Show missing extras',
      property_extras: {
        artio: 'Par',
        oikodom: 'Buildable',
        adeia_oik: 'With Building Permit',
        goniako: 'Corner',
        train: 'Railway',
        perifragmeno: 'Fenced',
        geotrisi: 'By drilling',
        nero_reuma: 'With water',
        reuma: 'With electricity',
        gass: 'With gas',
        tel: 'By phone',
        optics: 'With optical fibers',
        plot_sq: 'Plot area',
        orofodiamerisma: 'Floor apartment',
        retire_last: 'Final floor',
        road: 'Main street',
        elevator: 'Elevator',
        fireplace: 'Fireplace',
        ac: 'Air Condition',
        alarm: 'With alarm',
        student: 'Student',
        luxury: 'Luxurious',
        preserved: 'Preserved',
        neoclassic: 'Neoclassical',
        amea: 'Disabled access',
        satellite: 'Satellite antenna',
        nightpower: 'Night stream',
        furn: 'Furnished',
        devices: 'Electrical Appliances',
        open: 'Openness',
        bright: 'Bright',
        tents: 'Awnings',
        doubleglass: 'Double glazing',
        pestnet: 'Screens',
        internalstairs: 'Internal stairway',
        bbq: 'BBQ',
        attic: 'Attic',
        playroom: 'Playroom',
        panoramiko: 'Panoramic',
        paraliako: 'Coastal',
        ependitiko: 'Investment',
        sun: 'Solar',
        painted: 'Painted',
        retire: 'Penthouse',
        stair_steps: 'Entrance stairs',
        prof: 'Business Roof',
        ygion: 'Use of Sanitary',
        yard: 'Yard',
        pets: 'Pets are allowed',
        koin_val: 'No shared expenses',
      },
      profile_image_view: 'View profile image',
      profile_image_change: 'Change profile image',
      welcomemsgshelp: 'Welcome to the Real Status Network!',
      detailshelp: 'Learn how to use the network so that you can carry out your work without difficulty',
      question1: 'Who can join the Network? Are members checked for credibility?',
      question2: 'How do I invite partners to my friends list?',
      question3: 'Who sees my posts?',
      question4: 'What are Networks?',
      question5: 'Is my data safe?',
      question6: 'Do I have to accept all requests for cooperation?',
      question7: 'How is the brokerage fee divided?',
      question8: 'What is the cost of membership?',
      answer1: 'ONLY professional brokers registered in the General Commercial Register (GEMI) have the right to register in the Real Status Network. In addition, there is an evaluation system (Reputation) which increases or decreases depending on the behavior and professionalism of the member. Members who fall below a certain threshold will be deleted from the Network.',
      answer2: 'Just search for his name in the search at the top of the page and select him to go to his profile. From there you can tap Add. If he accepts the friend request then from that moment you are now friends and partners!',
      answer3: 'All your posts whether they are still, request, or plain text are shown to your friends and the networks you subscribe to.',
      answer4: 'Apart from your personal profile you can create your own network and invite other brokers to join. At the same time, you can register yourself in another network, so that you are informed about new properties and requests, always with the aim of cooperation. A network could have the name of your brokerage or the name of the franchise you represent.',
      answer5: 'Only the necessary information is published on the Network so that a collaboration can take place. In essence, there is the property information you have on your website and nothing more. On the other hand, the requests have only the requirements of your customer and not his details.',
      answer6: 'You can decide for yourself if you want to work with another broker. If you are not interested, you can ignore or decline the cooperation request.',
      answer7: 'The Real Status Network is the tool that facilitates collaborations between brokers. Collaborating professionals are free to agree and jointly determine how they will be paid. However, we recommend that each party receive their own brokerage fee solely from their client.',
      answer8: 'All Real Status customers are automatically members of the Real Status Network completely free of charge. For partners working in their offices, as long as they are registered in the General Commercial Register (GEMI), the cost is 10 euros + VAT / month. For brokers who do not use Real Status the cost is 25 euros + VAT / month.',
      metres: 'metres',
    },
    el: {
      map: 'Χάρτης',
      search_year_from: 'Έτος κατ. από',
      search_year_to: 'Έτος κατ. έως',
      search_floor_from: 'Όροφος από',
      choose_floor_from: 'Επιλέξτε όροφο',
      search_floor_to: 'Όροφος έως',
      choose_floor_to: 'Επιλέξτε όροφο',
      price_per_sq_median: 'Μέσος όρος Τιμή / τ.μ.',
      price_median: 'Μέσος όρος τιμής',
      construction_year_median: 'Μέσος όρος έτους κατασκευής',
      'Κατοικίες': 'Κατοικίες',
      'Επαγγελματικά': 'Επαγγελματικά',
      'Γη': 'Γη',
      'Άλλο': 'Άλλο',
      statistics: 'Στατιστικά',
      statistics_page_title: 'Insights',
      sales: 'Ακίνητα προς πώληση',
      sum_of_sales_per_price_group: 'Πλήθος ακινήτων',
      rents: 'Ακίνητα προς ενοικίαση',
      sum_of_rents_per_price_group: 'Πλήθος ακινήτων',
      button_reject_friend_request: 'Απόρριψη αιτήματος φιλίας',
      button_delete_friend: 'Αφαίρεση φίλου',
      become_open_for_cooperation_profile_explanation: 'Σηκώνοντας το χέρι θα εμφανιστείτε στην λίστα "Βρείτε συνεργάτες"',
      become_open_for_cooperation_profile_text: 'Επιθυμείτε να ενταχτείτε σε κάποιο γραφείο; Σηκώστε το  χέρι σας',
      realtors_open_for_cooperation_subtitle: 'Εδώ μπορείτε να βρείτε επαγγελματίες μεσίτες, οι οποίοι επιθυμούν να ενταχτούν σε κάποιο γραφείο. Στείλτε τους αίτημα φιλίας και επικοινωνήστε μαζί τους.',
      realtors_open_for_cooperation: 'Βρείτε συνεργάτες',
      we_receiver_your_report_thank_you: 'Λάβαμε την αναφορά σας. Ευχαριστούμε!',
      warning: 'Προσοχή',
      it_seems_you_already_reported_this_user: 'Φαίνεται ότι έχετε ήδη αναφέρει αυτό το χρήστη',
      report_reason: 'Λόγος αναφοράς',
      select_report_reason: 'Επιλογή λόγου αναφοράς',
      report_description: 'Περιγραφή ζητήματος',
      required_field: 'Υποχρεωτικό πεδίο',
      describe_your_reason_of_report_in_a_few_words: 'Περιγράψτε με λίγα λόγια τον λόγο που πρόκειται να αναφέρετε τον χρήστη',
      report_of_user: 'Αναφορά χρήστη',
      access_to_API: 'Πρόσβαση στο General API',
      plus_vat: '+ Φ.Π.Α',
      creating_account_prompt_payment: 'Το κόστος της μηνιαίας συνδρομής είναι',
      creating_account_prompt: 'Αφού δημιουργήσετε τον λογαριασμό σας θα πρέπει να εγκριθεί η εγκυρότητα των στοιχείων από το εξειδικευμένο τμήμα μας.', //  Έπειτα θα σας δωθούν δωρεάν 15 ημέρες χρήσης.
      show_notifications: 'Προβολή ειδοποιήσεων',
      configure_notifications: 'Ρυθμίσεις ειδοποιήσεων',
      save_notifications_prefferences: 'Αποθήκευση προτιμήσεων',
      email_notifications_settings_accept: 'Αποδοχή',
      email_notifications_settings_reject: 'Απόρριψη',
      email_notifications_settings: 'Ρυθμίσεις ειδοποιήσεων μέσω email',
      alternative: 'Εναλλακτικά',
      deposit_to_account_prompt: 'Μπορείτε να καταθέσετε το παραπάνω ποσό στον τραπεζικό μας λογαριασμό με αιτιολογία "Ανανέωση συνδρομής για τον λογαριασμό',
      deposit_to_account_bank: 'Τράπεζα Πειραιώς (IARTS I.K.E.)',
      deposit_to_account_account_num: 'Αρ. Λογ/σμού: 52 2609 0247 758',
      deposit_to_account_iban: 'IBAN: GR1501722260005226090247758',
      update_your_address_for_users: 'Ενημερώστε την διεύθυνση σας ώστε να σας βρίσκουν ευκολότερα τα υπόλοιπα μέλη',
      go_to_settings: 'Μετάβαση στις ρυθμίσεις',
      renew_subscription: 'Ανανέωση συνδρομής',
      smasaa: 'ΣΜΑΣΑΑ',
      your_smasaa_register: 'Το Α.Μ. σας στον Σ.Μ.Α.Σ.Α.Α. (προαιρετικό)',
      new_message_lc: 'νέο μήνυμα',
      new_messages_lc: 'νέα μηνύματα',
      new_collab_notifications: 'Νέες ενημερώσεις στην συνεργασία',
      collab_notifications: 'ενημερώσεις',
      cooperation_accepted_event_message: 'Το αίτημα συνεργασίας έγινε δεκτό',
      friend_accepted_event_message: 'Αποδέχτηκε το αίτημα φιλίας',
      liked_post_event_message: 'Δήλωσε ότι του αρέσει η δημοσίευση',
      commented_post_event_message: 'Σχολίασε την δημοσίευση',
      cooperation_rejected_event_message: 'Το αίτημα συνεργασίας απορρίφθηκε',
      cooperation_completed_event_message: 'Η συνεργασία ολοκληρώθηκε',
      cooperation_message_event_message: 'Νέο μήνυμα συνεργασίας',
      cooperation_history: 'Ιστορικό συνεργασίας',
      the_user: 'Ο χρήστης',
      sended_cooperation_request: 'έστειλε αίτημα συνεργασίας',
      responded_to_cooperation_request: '',
      deleted_milestone: 'αφαίρεσε το milestone',
      changed_milestone_status: 'άλλαξε την κατάσταση του milestone',
      changed_milestone_status_to: 'σε',
      changed_milestone_description_from: 'άλλαξε την περιγραφή του milestone',
      changed_milestone_description_to: 'σε',
      accepted_cooperation: 'αποδέχτηκε το αίτημα συνεργασίας',
      rejected_cooperation: 'απέρριψε το αίτημα συνεργασίας',
      inserted_new_milestone: 'εισήγαγε ένα νέο milestone',
      closed_cooperation: 'άλλαξε την κατάσταση της συνεργασίας ως Ολοκληρωμένη',
      rated_cooperation: 'αξιολόγησε την συνεργασία σας',
      completed: 'Ολοκληρωμένο',
      pending: 'Σε αναμονή',
      with: 'με',
      chat_cooperation_with: 'Συνεργασία με',
      cooperation: 'Συνεργασία',
      cooperation_chat: 'Συνομιλία',
      created_by: 'Δημιουργήθηκε από',
      closed_by: 'Έκλεισε από',
      create_new_milestone: 'Νέο milestone',
      request_locations: 'Περιοχές ζήτησης',
      amount: 'Ποσό',
      choose_plan: 'Επιλογή',
      monthly_plan: 'Μηνιαίο πλάνο',
      monthly_description: 'Πλήρη πρόσβαση στο network.realstatus.net για 1 μήνα',
      year_description: 'Πλήρη πρόσβαση στο network.realstatus.net για 1 έτος με έκπτωση 10%',
      year_plan: 'Ετήσιο πλάνο',
      per_month: 'ανά μήνα',
      per_year: 'ανά έτος',
      renew_subscription: 'Ανανέωση συνδρομής',
      complete_payment: 'Ολοκλήρωση πληρωμής',
      payment_success: 'Η πληρωμή καταγράφηκε!',
      payment_status: 'Κατάσταση πληρωμής',
      access_renewed_until: 'Η πρόσβαση στο network ανανεώθηκε μέχρι',
      download_receipt: 'Κατεβάστε την απόδειξη πληρωμής',
      home: 'Αρχική',
      search_has_been_saved: 'Η αναζήτηση αποθηκεύτηκε',
      saved_searches: 'Αποθηκευμένες αναζητήσεις',
      manage_saved_searches: 'Αναζητήσεις',
      load_saved_search: 'Αναζητήσεις',
      search_title: 'Τίτλος αναζήτησης',
      set_a_search_title: 'Ορίστε έναν τίτλο αναζήτησης',
      save_search: 'Αποθήκευση αναζήτησης',
      social_members_title: 'Βρείτε μέλη',
      report_user: 'Αναφορά λογαριασμού',
      user_sended_you_a_friend_request: 'Ο χρήστης σας έστειλε αίτημα φιλίας',
      admin: 'Διαχειριστής',
      button_remove_privileges: 'Αφαίρεση δικαιωμάτων',
      button_remove_realtor_privileges: 'Αφαίρεση δικαιωμάτων διαχειριστή από τον χρήστη',
      button_add_privileges: 'Προσθήκη δικαιωμάτων',
      button_add_realtor_privileges: 'Προσθήκη δικαιωμάτων διαχειριστή στον χρήστη',
      thank_you_for_the_report: 'Ευχαριστούμε για την αναφορά',
      fill_all_fields: 'Παρακαλώ συμπληρώστε όλα τα πεδία',
      report_a_bug: 'Αναφορά σφάλματος',
      bug_title: 'Σύνοψη σφάλματος',
      bug_description: 'Αναλυτική περιγραφή σφάλματος',
      change_password: 'Ενημέρωση κωδικού πρόσβασης',
      check_code: 'Έλεγχος κωδικού',
      draw_at_least_one_search_location: 'Παρακαλώ σχεδιάστε τουλάχιστον μια περιοχή αναζήτησης στον χάρτη',
      password_field_required: 'Ο κωδικός είναι υποχρεωτικό πεδίο',
      newPassword_field_required: 'Ο νέος κωδικός είναι υποχρεωτικό πεδίο',
      confirmNewPassword_field_required: 'Η επιβεβαίωση κωδικού είναι υποχρεωτικό πεδίο',
      newPassword_confirmNewPassword_missmatch: 'Οι κωδικοί δεν ταιριάζουν',
      expired: 'Ο χρήστης δεν βρέθηκε',
      password_changed: 'Ο κωδικός άλλαξε επιτυχώς',
      wrong_password: 'Λάθος κωδικός πρόσβασης',
      please_choose_transaction: 'Παρακαλώ επιλέξτε σκοπό',
      please_choose_basic_category: 'Παρακαλώ επιλέξτε βασική κατηγορία',
      please_choose_category: 'Παρακαλώ επιλέξτε κατηγορία',
      please_set_description: 'Παρακαλώ εισάγετε περιγραφή',
      please_set_description_en: 'Παρακαλώ εισάγετε περιγραφή στα αγγλικά',
      please_set_price: 'Παρακαλώ ορίστε μια έγκυρη τιμή ακινήτου',
      please_set_sq: 'Παρακαλώ ορίστε τ.μ.',
      please_set_cordes: 'Παρακαλώ ορίστε συντεταγμένες ακινήτου',
      please_set_zip: 'Παρακαλώ ορίστε τ.μ. ακινήτου',
      try_again_single: 'Παρακαλώ προσπαθήστε ξανά',
      you_can_now_change_your_password: 'Μπορείτε να ορίσετε νέο κωδικό πρόσβασης',
      you_will_receive_an_email_with_the_recovery_code: 'Θα σας αποσταλεί ένα email με τον κωδικό ανάκτησης',
      wrong_code: 'Λάθος κωδικός',
      insert_new_password: 'Εισάγετε τον νέο κωδικό πρόσβασης',
      login_again: 'Συνδεθείτε για να συνεχίσετε',
      password_changed: 'Ο κωδικός πρόσβασης ενημερώθηκε!',
      reset_code: 'Κωδικός',
      type_the_code_from_email: 'Εισάγετε τον κωδικό που λάβατε στο email σας',
      account_restore: 'Επανακτήστε πρόσβαση στον λογαριασμό σας',
      send_email: 'Αποστολή email',
      button_delete_property_from_network: 'Αφαίρεση ακινήτου από το δίκτυο',
      button_delete_realtor_from_network: 'Αφαίρεση χρήστη από το δίκτυο',
      button_delete_request_from_network: 'Αφαίρεση ζήτησης από το δίκτυο',
      choose_floors: 'Επιλέξτε όροφο',
      properties_crossings_for_request: 'Ακίνητα από διασταύρωση ζήτησης',
      requests_crossings_for_property: 'Ζητήσεις από διασταύρωση ακινήτου',
      crossings: 'Διασταυρώσεις',
      crossing: 'Διασταύρωση',
      network_updated: 'Οι πληροφορίες του δικτύου αποθηκεύτηκαν',
      set_metro_distance: 'Ορίστε απόσταση από μετρό',
      metro_distance: 'Απόσταση από μετρό',
      update_request: 'Ενημέρωση ζήτησης',
      insert_request: 'Προσθήκη ζήτησης',
      set_min_price: 'Ορίστε ελάχιστη τιμή',
      min_price: 'Ελάχιστη τιμή',
      set_max_price: 'Ορίστε μέγιστη τιμή',
      max_price: 'Μέγιστη τιμή',
      set_min_sq: 'Ορίστε ελάχιστα τ.μ.',
      min_sq: 'Ελάχιστα τ.μ.',
      set_max_sq: 'Ορίστε μέγιστα τ.μ.',
      max_sq: 'Μέγιστα τ.μ.',
      request_basic_information: 'Βασικές πληροφορίες',
      request_location_information: 'Πληροφορίες τοποθεσίας',
      request_additional: 'Επιπλέον πληροφορίες',
      request_location: 'Τοποθεσίες',
      request_distances: 'Αποστάσεις',
      request_optional: 'Προαιρετικές πληροφορίες',
      indifferent: 'Αδιάφορο',
      request_updated: 'Οι αλλαγές αποθηκεύτηκαν',
      request_inserted: 'Η ζήτηση αποθηκεύτηκε',
      property_updated: 'Οι αλλαγές αποθηκεύτηκαν',
      property_inserted: 'Το ακίνητο αποθηκεύτηκε',
      previous_step: 'Προηγούμενο βήμα',
      update_property: 'Επεξεργασία ακινήτου',
      insert_property: 'Εισαγωγή ακινήτου',
      property_location_information: 'Πληροφορίες τοποθεσίας',
      property_basic_information: 'Βασικές πληροφορίες ακινήτου',
      images_uploaded_succesfully: 'Οι φωτογραφίες αποθηκεύτηκαν',
      are_you_sure: 'Είστε βέβαιος;',
      you_are_going_to_delete_this_image: 'Πρόκειται να διαγράψετε την φωτογραφία',
      you_are_going_to_delete_this_network: 'Πρόκειται να διαγράψετε το δίκτυο. Επιθυμείτε να συνεχίσετε;',
      you_are_going_to_delete_this_user_from_your_friend_list: 'Πρόκειται να διαγράψετε τον χρήστη από την λίστα των φίλων σας. Διαγράφοντας τον φίλο δεν θα έχετε πρόσβαση στα δημοσιευμένα ακίνητα και στις ζητήσεις του χρήστη.',
      image_deleted: 'Η φωτογραφία διαγράφτηκε επιτυχώς',
      network_deleted: 'Το δίκτυο διαγράφτηκε επιτυχώς',
      try_again: 'Προσπαθήστε ξανά αργότερα',
      the_property_is_not_deleted: 'Το ακίνητο δεν διαγράφτηκε',
      something_went_wrong: 'Κάτι πήγε στραβά',
      you_deleted_the_request_with_code: 'Διαγράψατε επιτυχώς την ζήτηση με κωδικό',
      you_deleted_the_property_with_code: 'Διαγράψατε επιτυχώς το ακίνητο με κωδικό',
      ok: 'Εντάξει',
      you_are_about_to_delete_the_request_with_code: 'Πρόκειται να διαγράψετε την ζήτηση με κωδικό',
      you_are_about_to_delete_the_property_with_code: 'Πρόκειται να διαγράψετε το ακίνητο με κωδικό',
      continue_question: 'Επιθυμείτε να συνεχίσετε;',
      delete: 'Διαγραφή',
      changes_saved: 'Οι αλλαγές αποθηκεύθηκαν',
      publish_on_profile: 'Δημοσίευση στο προφίλ',
      network_name: 'Ονομασία δικτύου',
      published_in_networks: 'Δημοσίευση στα δίκτυα',
      edit_property_publish: 'Επεξεργασία δημοσιεύσεων ακινήτου',
      publish_to: 'Δημοσίευση σε',
      publish: 'Δημοσίευση',
      typeNet: 'Πληκτρολογήστε το δίκτυο που επιθυμείτε',
      edit: 'Επεξεργασία',
      manage: 'Διαχείριση',
      manage_requests: 'Οι ζητήσεις μου',
      manage_properties: 'Τα ακίνητά μου',
      manage_your_properties: 'Διαχείριση των ακινήτων μου',
      manage_networks: 'Τα δίκτυά μου',
      register_rejected: 'Η αίτηση εγγραφής απορρίφθηκε',
      pending_register_confirmation: 'Εκρεμεί ο έλεγχος της αίτησης εγγραφής σας',
      error_sso: 'Η είσοδος απέτυχε',
      registered_success: 'Εγγραφήκατε επιτυχώς! Θα αποκτήσετε πρόσβαση στον νέο σας λογαριασμό μόλις ολοκληρωθούν οι απαραίτητοι έλεγχοι',
      'land_use': 'Χρήση γης',
      'A/C': 'A/C',
      'airport_distance': 'Απόσταση από αεροδρόμιο',
      'balcony': 'Μπαλκόνι (τ.μ.)',
      'BBQ': 'BBQ',
      'choose': 'Επιλέξτε',
      'choose_conditions': 'Επιλέξτε κατάσταση',
      'choose_heat': 'Επιλέξτε θέρμανση',
      'choose_energy_class': 'Επιλέξτε ενεργειακή κλάση',
      'choose_directions': 'Επιλέξτε προσανατολισμό',
      'choose_land_slope': 'Επιλέξτε κλίση γης',
      'choose_land_use': 'Επιλέξτε χρήση γης',
      'choose_orientation': 'Επιλέξτε Χωροθέτηση',
      'choose_public_service': 'Επιλέξτε ΜΜΜ',
      'choose_retire': 'Ρετιρέ',
      'choose_student': 'Φοιτητικό',
      'city_distance': 'Απόσταση απο πόλη',
      'conditions': 'Κατάσταση',
      'construction_year': 'Έτος κατασκευής',
      'directions': 'Προσανατολισμός',
      'koinoxrista': 'Κοινόχρηστα',
      'land_slope': 'Κλίση',
      'master_rooms': 'Master δωμάτια',
      'orientation': 'Χωροθέτηση',
      'Playroom': 'Playrooms',
      'property_descriptions': 'Περιγραφή ακινήτου',
      'property_distances': 'Αποστάσεις από ακίνητο',
      'public_service': 'Μέσα μεταφοράς',
      'sea_distance': 'Απόσταση απο θάλασσα',
      'set_airport_distance': 'Ορίστε απόσταση από αεροδρόμιο',
      'set_balcony': 'Ορίστε τ.μ. μπαλκονιού',
      'set_city_distance': 'Ορίστε απόσταση από πόλη',
      'set_construction_year': 'Ορίστε έτος κατασκευής',
      'set_description': 'Ορίστε περιγραφή',
      'set_description_en': 'Ορίστε περιγραφή στα αγγλικά',
      'set_master': 'Ορίστε master δωμάτια',
      'set_plot_sq': 'Ορίστε τ.μ. οικοπέδου',
      'set_sea_distance': 'Ορίστε απόσταση από θάλασσα',
      'set_student': 'Φοιτητικό',
      'set_village_distance': 'Ορίστε απόσταση από χωριό',
      'sq_plot': 'τ.μ. οικοπέδου',
      'village_distance': 'Απόσταση από χωριό',
      'Άρτιο': 'Άρτιο',
      'Ανελκυστήρας φορτίων': 'Ανελκυστήρας φορτίων',
      'Ανελκυστήρας': 'Ανελκυστήρας',
      'Ανοιχτωσιά': 'Ανοιχτωσιά',
      'Αντιπαροχή': 'Αντιπαροχή',
      'Αποθήκη': 'Αποθήκη',
      'Βαμμένο': 'Βαμμένο',
      'Γωνιακό': 'Γωνιακό',
      'Διατηρητέο': 'Διατηρητέο',
      'Διπλά τζάμια': 'Διπλά τζάμια',
      'Δομημένη καλωδίωση': 'Δομημένη καλωδίωση',
      'Δορυφορική κεραία': 'Δορυφορική κεραία',
      'Εμβαδόν αποθήκη': 'Εμβαδόν αποθήκη',
      'Εμβαδόν κτίσματος': 'Εμβαδόν κτίσματος',
      'Επαγγελματική Στέγη': 'Επαγγελματική Στέγη',
      'Επενδυτικό': 'Επενδυτικό',
      'Επιπλωμένο': 'Επιπλωμένο',
      'Επιτρέπονται κατοικίδια': 'Επιτρέπονται κατοικίδια',
      'Εσωτερική σκάλα': 'Εσωτερική σκάλα',
      'Ηλεκτρικές συσκευές': 'Ηλεκτρικές συσκευές',
      'Ηλιακός': 'Ηλιακός',
      'Κήπος': 'Κήπος',
      'Κεντρική οδό': 'Κεντρική οδό',
      'Κεντρική οδός': 'Κεντρική οδός',
      'Με άδεια δόμησης': 'Με άδεια δόμησης',
      'Με αέριο': 'Με αέριο',
      'Με γεώτρηση': 'Με γεώτρηση',
      'Με εξοπλισμό': 'Με εξοπλισμό',
      'Με νερό': 'Με νερό',
      'Με οπτικές ίνες': 'Με οπτικές ίνες',
      'Με ρεύμα': 'Με ρεύμα',
      'Με τηλέφωνο': 'Με τηλέφωνο',
      'Νεοκλασικό': 'Νεοκλασικό',
      'Νυχτερινό ρεύμα': 'Νυχτερινό ρεύμα',
      'Οικοδομήσιμο': 'Οικοδομήσιμο',
      'Οροφοδιαμέρισμα': 'Οροφοδιαμέρισμα',
      'ΟΧΙ': 'ΟΧΙ',
      'Πάρκινγκ': 'Πάρκινγκ',
      'Πανοραμικό': 'Πανοραμικό',
      'Παραλιακό': 'Παραλιακό',
      'Περιφραγμένο': 'Περιφραγμένο',
      'Πισίνα': 'Πισίνα',
      'Πολυτελές': 'Πολυτελές',
      'Προαύλιο': 'Προαύλιο',
      'Πρόσβαση ΑΜΕΑ': 'Πρόσβαση ΑΜΕΑ',
      'Ράμπα εκφόρτωσης': 'Ράμπα εκφόρτωσης',
      'Ρετιρέ': 'Ρετιρέ',
      'Ρολά ασφαλείας': 'Ρολά ασφαλείας',
      'Σίτες': 'Σίτες',
      'Σαλοκουζίνα': 'Σαλοκουζίνα',
      'Σιδηροδρομική γραμμή': 'Σιδηροδρομική γραμμή',
      'Σκαλιά εισόδου': 'Σκαλιά εισόδου',
      'Σοφίτα': 'Σοφίτα',
      'Συναγερμός': 'Συναγερμός',
      'Τέντες': 'Τέντες',
      'Τελευταίος όροφος': 'Τελευταίος όροφος',
      'Τζάκι': 'Τζάκι',
      'Τιμή ενοικίου': 'Τιμή ενοικίου',
      'Υπάρχει κτίσμα': 'Υπάρχει κτίσμα',
      'Φωτεινό': 'Φωτεινό',
      'Χρήση Υγειονομικού': 'Χρήση Υγειονομικού',
      'Ψευδοροφή': 'Ψευδοροφή',
      property_optional: 'Προαιρετικές επιλογές',
      view: 'Θέα',
      choose_view: 'Επιλέξτε θέα',
      frame: 'Κουφώματα',
      choose_frame: 'Επιλέξτε κουφώματα',
      floors_materials: 'Πατώματα',
      choose_floors_materials: 'Επιλέξτε πατώματα',
      door: 'Πόρτα',
      choose_door: 'Επιλέξτε',
      closet: 'Ντουλάπα',
      choose_closet: 'Επιλέξτε',
      garden: 'Κήπος',
      choose_garden: 'Επιλέξτε',
      pool: 'Πισίνα',
      choose_pool: 'Επιλέξτε',
      access_type: 'Πρόσβαση από',
      choose_access_type: 'Επιλέξτε',
      parking: 'Πάρκινγκ',
      choose_parking: 'Επιλέξτε πάρκινγκ',
      set_retire: 'Ρετιρέ',
      property_location: 'Περιοχή ακινήτου',
      nomos: 'Νομός',
      choose_nomos: 'Επιλέξτε νομό',
      region: 'Περιοχή',
      choose_region: 'Επιλέξτε περιοχή',
      location: 'Υποπεριοχή',
      choose_location: 'Επιλέξτε υποπεριοχή',
      set_address: 'Διεύθυνση',
      set_zip: 'Τ.Κ.',
      property_additional: 'Επιπρόσθετες πληροφορίες',
      set_rooms: 'Ορίστε αριθμό δωματίων',
      set_spaces: 'Ορίστε αριθμό χώρων',
      rooms: 'Δωμάτια',
      set_livingrooms: 'Ορίστε αριθμό σαλονιών',
      livingrooms: 'Σαλόνια',
      set_bathrooms: 'Ορίστε αριθμό μπάνιων',
      bathrooms: 'Μπάνια',
      set_wc: 'Ορίστε αριθμό wc',
      wc: 'wc',
      set_structure_factor: 'Ορίστε συντελεστή δόμησης',
      structure_factor: 'Συντελεστής δόμησης',
      set_coverage_factor: 'Ορίστε συντελεστή κάλυψης',
      coverage_factor: 'Συντελεστής κάλυψης',
      set_ipsos: 'Ορίστε επιτρεπτό ύψος κτίσης',
      ipsos: 'Ύψος κτίσης',
      set_sxedio: 'Σχέδιο',
      choose_sxedio: 'Επιλέξτε',
      in_plan: 'Εντός σχεδίου',
      not_in_plan: 'Εκτός σχεδίου',
      property_type: 'Τύπος ακινήτου',
      property_values: 'Τιμές ακινήτου',
      purpose: 'Σκοπός',
      choose_purpose: 'Επιλέξτε σκοπό',
      category: 'Κατηγορία',
      subcategory: 'Υποκατηγορία',
      zone: 'Ζώνη',
      choose_zone: 'Επιλέξτε ζώνη',
      set_price: 'Ορίστε τιμή €',
      set_sq: 'Ορίστε τ.μ.',
      property_required_data: 'Υποχρεωτικές πληροφορίες',
      property_additional_data: 'Επιπρόσθετες πληροφορίες',
      property_media: 'Media',
      active_filters: 'ενεργά φίλτρα',
      active_filter: 'ενεργό φίλτρο',
      filters: 'Φίλτρα',
      type_at_least: 'Πληκτρολογήστε',
      or_more_characters: 'ή παραπάνω χαρακτήρες',
      search_purpose: 'Σκοπός',
      search_for_sell: 'Πωλήσεις',
      search_for_rent: 'Ενοικιάσεις',
      search_category: 'Κατηγορία',
      choose_category: 'Επιλέξτε μια κατηγορία',
      search_subcategory: 'Υποκατηγορίες',
      choose_subcategory: 'Επιλέξτε υποκατηγορίες',
      search_locations: 'Περιοχές',
      type_a_location: 'Πληκτρολογήστε μία περιοχή',
      search_price_from: 'Τιμή από',
      search_price_to: 'Τιμή έως',
      search_sq_from: 'τ.μ. από',
      search_sq_to: 'τ.μ. έως',
      results: 'αποτελέσματα',
      no_results: 'Δεν βρέθηκαν αποτελέσματα',
      people: 'Άτομα',
      help_n_support: 'Βοήθεια και Υποστήριξη',
      button_hide: 'Απόκρυψη',
      button_hide_post_info: 'Απόκρυψη από το χρονολόγιο',
      cancel: 'Ακύρωση',
      edit_post: 'Επεξεργασία δημοσίευσης',
      photo: 'Εικόνα',
      i_understand: 'Κατάλαβα',
      fail: 'Αποτυχία',
      title_field_empty: 'Ο τίτλος είναι κενός',
      title_less_than_3_characters: 'Ο τίτλος θα πρέπει να είναι τουλάχιστον 3 χαρακτήρες',
      description_field_empty: 'Η περιγραφή είναι κενή',
      description_less_than_10_characters: 'Η περιγραφή θα πρέπει να είναι τουλάχιστον 10 χαρακτήρες',
      type_field_empty: 'Το πεδίο Τύπος είναι κενό',
      type_not_numeric: 'Το πεδίο Τύπος θα πρέπει να είναι αριθμός',
      alright: 'Εντάξει',
      success: 'Επιτυχία',
      network_created: 'Το δίκτυο δημιουργήθηκε',
      my_properties: 'Τα ακίνητά μου',
      my_requests: 'Οι ζητήσεις μου',
      has_accepted_cooperation_request: 'Αποδέχτηκε το αίτημα συνεργασίας σας',
      first_name_field_empty: 'Το πεδίο Όνομα είναι κενό',
      last_name_field_empty: 'Το πεδίο Επώνυμο είναι κενό',
      gemi_field_empty: 'Το πεδίο Γ.Ε.ΜΗ. είναι κενό',
      email_field_empty: 'Το πεδίο E-mail είναι κενό',
      email_format_invalid: 'Το πεδίο E-mail φαίνεται να είναι λάθος. Βεβαιωθείτε ότι το e-mail είναι της μορφής x@y.com',
      email_already_exists: 'Το E-mail υπάρχει ήδη',
      password_field_empty: 'Το πεδίο Κωδικός πρόσβασης είναι κενό',
      confirm_password_field_empty: 'Το πεδίο Επιβεβαίωση κωδικού πρόσβασης είναι κενό',
      password_must_be_atleast_6_characters_long: 'Ο κωδικός πρόσβασης πρέπει να είναι τουλάχιστον 6 χαρακτήρες',
      confirm_password_missmatch: 'Οι κωδικοί δεν ταιριάζουν',
      you_must_accept_the_terms: 'Για να συνεχίσετε θα πρέπει να αποδεχτείτε τους όρους χρήσης',
      you_registered_successfully: 'Η εγγραφή ολοκληρώθηκε',
      your_last_name: 'Το επώνυμό σας',
      your_gemi: 'Το Γ.Ε.ΜΗ. σας',
      already_have_account: 'Έχετε ήδη λογαριασμό;',
      i_accept_the: 'Αποδέχομαι τους',
      terms_of_use_register: 'όρους χρήσης',
      create_account: 'Δημιουργία λογαριασμού',
      your_name: 'Το όνομά σας',
      your_email: 'Το email σας',
      confirm_password: 'Επιβεβαίωση κωδικού',
      cooperation_request_event_message: 'Έχετε ένα νέο αίτημα συνεργασίας',
      show_cooperation_request: 'Δείτε το αίτημα',
      has_sended_cooperation_request: 'Σας έστειλε αίτημα συνεργασίας',
      cooperation_accepted: 'Αποδεχθήκατε την συνεργασία',
      cooperation_moved_to_active: 'Το αίτημα συνεργασίας μεταφέρθηκε στις ενεργές συνεργασίες',
      cooperation_rejected: 'Η συνεργασία απορρίφθηκε',
      cooperation_moved_to_rejected: 'Το αίτημα συνεργασίας μεταφέρθηκε στις απορριφθήσες συνεργασίες',
      'Απόκρυψη συνεργασίας': 'Απόκρυψη συνεργασίας',
      'Επεξεργασία αξιολόγησης συνεργασίας': 'Επεξεργασία αξιολόγησης συνεργασίας',
      'Κλειστές συνεργασίες': 'Κλειστές συνεργασίες',
      closed_collaborations: 'Έκλεισαν',
      cooperation_rate_sendeed: 'Η αξιολόγηση στάλθηκε επιτυχώς',
      thank_you: 'Ευχαριστούμε',
      rating_message: 'Κείμενο αξιολόγησης',
      cooperation_rating: 'Αξιολόγηση συνεργασίας',
      rate_the_cooperation_with_realtor: 'Αξιολογήστε την συνεργασία σας με τον συνεργάτη',
      cooperation_request_not_sended: 'Η αίτηση ΔΕΝ στάλθηκε',
      request_already_sended: 'Έχετε ήδη στείλει αίτηση συνεργασίας',
      wait_1_day_before_resending_request: 'Έχετε στείλει εντός ημέρας αίτηση συνεργασίας η οποία ακυρώθηκε. Παρακαλώ ξαναδοκιμάστε αύριο',
     cooperation_request_sended: 'Η αίτηση στάλθηκε επιτυχώς',
      you_will_be_informed_if_coop_accepted: 'Θα ενημερωθείτε για την εξέλιξης της αίτησης σας μόλις ο συνεργάτης αποδεχθεί την αίτηση',
      describe_your_reason_of_request_in_a_few_words: 'Περιγράψτε τον λόγο της αίτησης συνεργασίας σε λίγες λέξεις',
      related_property_for_collaboration: 'Προτεινόμενα ακίνητα για συνεργασία',
      related_requests_for_collaboration: 'Προτεινόμενες ζητήσεις για συνεργασία',
      request_content: 'Κείμενο αίτησης',
      search_your_properties: '',
      public_network: 'Δημόσιο',
      private_network: 'Ιδιωτικό',
      network_type: 'Ορατότητα δικτύου',
      media_info: 'Στοιχεία media',
      basic_info: 'Βασικά στοιχεία',
      next_step: 'Επόμενο βημα',
      network_description: 'Περιγραφή δικτύου',
      describe_your_network_in_a_few_words: 'Περιγράψτε το δίκτυο σας σε λίγες λέξεις',
      what_is_your_network_title: 'Ποιός είναι ο τίτλος του δικτύου σας;',
      network_title: 'Τίτλος δικτύου',
      create_new_network: 'Δημιουργία δικτύου',
      connection_down: 'Εκτός σύνδεσης',
      featured: 'Προτεινόμενο',
      msgsend: 'Το μήνυμα απεστάλη',
      realtor_prop: 'Ακίνητα μεσίτη',
      about: 'Σχετικά',
      readall: 'Διαβάστηκαν',
      seeallmsg: 'Δείτε όλα τα μηνύματα',
      'Ξεχάσατε τον κωδικό σας; Πατήστε': 'Ξεχάσατε τον κωδικό σας; Πατήστε',
      'εδώ': 'εδώ',
      'Λάθος e-mail ή κωδικός πρόσβασης': 'Λάθος e-mail ή κωδικός πρόσβασης',
      'περιοχή': 'περιοχή',
      'περιοχές': 'περιοχές',
      'Απορριφθείσες συνεργασίες': 'Απορριφθείσες συνεργασίες',
      'Απορριφθείσες': 'Απορριφθείσες',
      'Διαγραφή αιτήματος': 'Διαγραφή αιτήματος',
      'Ακυρώσατε πρόσφατα αίτημα συνεργασίας': 'Ακυρώσατε πρόσφατα αίτημα συνεργασίας. Προσπαθήστε ξανά αύριο',
      'Προστέθηκε ζήτηση η οποία ταιριάζει με το ακίνητό σας': 'Προστέθηκε ζήτηση η οποία ταιριάζει με το ακίνητό σας',
      'Προστέθηκε ακίνητο το οποίο ταιριάζει με την ζήτησή σας': 'Προστέθηκε ακίνητο το οποίο ταιριάζει με την ζήτησή σας',
      'διασταύρωση': 'διασταύρωση',
      'σύστημα': 'σύστημα',
      'Εξερχόμενα αιτήματα συνεργασίας': 'Εξερχόμενα αιτήματα συνεργασίας',
      'Εισερχόμενα αιτήματα συνεργασίας': 'Εισερχόμενα αιτήματα συνεργασίας',
      'Εισερχόμενα': 'Εισερχόμενα',
      'Εξερχόμενα': 'Εξερχόμενα',
      'Κλείσιμο συνεργασίας': 'Κλείσιμο συνεργασίας',
      'Αξιολόγηση συνεργασίας': 'Αξιολόγηση συνεργασίας',
      'Επαναποστολή ψηφιακής υπογραφής': 'Επαναποστολή ψηφιακής υπογραφής',
      'Ακύρωση αιτήματος': 'Ακύρωση αιτήματος',
      'Απόρριψη': 'Απόρριψη',
      'Αποδοχή': 'Αποδοχή',
      'Συνεργάτης': 'Συνεργάτης',
      'Απορριφθήσα συνεργασία': 'Απορριφθήσα συνεργασία',
      'Ενεργή συνεργασία': 'Ενεργή συνεργασία',
      'Κλειστή συνεργασία': 'Κλειστή συνεργασία',
      'με κωδικό': 'με κωδικό',
      'για την ζήτηση': 'για την ζήτηση',
      'για το ακίνητο': 'για το ακίνητο',
      'Ενεργές συνεργασίες': 'Ενεργές συνεργασίες',
      'Αιτήματα συνεργασίας': 'Αιτήματα συνεργασίας',
      'Βρες συνεργάτες': 'Βρες συνεργάτες',
      'Χάρτης τιμών': 'Χάρτης τιμών',
      'tools': 'Εργαλεία',
      'Γης ανά τμ': 'Γης ανά τ.μ.',
      'Επαγγελματικών ανά τμ': 'Επαγγελματικών ανά τ.μ.',
      'Οικιστικών ανά τμ': 'Οικιστικών ανά τ.μ.',
      'Επιλέξτε μέση τιμή': 'Επιλέξτε μέση τιμή',
      'Δημοσιεύσεις': 'Δημοσιεύσεις',
      'Αποστολή μηνύματος': 'Αποστολή μηνύματος',
      'Αίτηση συνεργασίας': 'Αίτηση συνεργασίας',
      '--1': "Μου αρέσει!",
      'Παρέχεται από τα δίκτυα': 'Παρέχεται από τα δίκτυα',
      'Εγγραφή στο δίκτυο': "Εγγραφή στο δίκτυο",
      'Αποχώρηση από το δίκτυο': 'Αποχώρηση από το δίκτυο',
      'Ακίνητα που ταιριάζουν με την ζήτησή σας': 'Ακίνητα που ταιριάζουν με την ζήτησή σας',
      'Το ακίνητο με κωδικό  ___ ταιρίαζει με την παραπάνω ζήτηση': 'Το ακίνητο με κωδικό  ___ ταιρίαζει με την παραπάνω ζήτηση',
      heart: "Τέλειο!",
      blush: "Νοιάζομαι",
      smile: "Χαχα",
      astonished: "Ουάου!",
      cry: "Λυπάμαι...",
      angry: "Έλεος!",
      show_more: "Δείτε περισσότερα",
      update_profile_image: "Ενημέρωση εικόνας προφίλ",
      update_cover_image: "Ενημέρωση εικόνας εξωφύλλου",
      update_network_image: "Ενημέρωση εικόνας δικτύου",
      date_added: "Ημερομηνία καταχώρησης",
      date_modified: "Ημερομηνία τροποποίησης",
      mapRequest: "Ζήτηση με σχεδίαση χάρτη",
      formRequest: "Περιοχές ζήτησης",
      private: "Ιδιωτικό",
      public: "Δημόσιο",
      published: "Δημοσιευμένη",
      published_net: 'Δημοσιευμένη σε δίκτυο',
      no_published_net: 'Μη δημοσιευμένη σε δίκτυο',
      no: "Όχι",
      yes: "Ναι",
      close: "Κλείσιμο",
      change_image: "Αλλαγή εικόνας",
      upload_image: "Ανέβασμα εικόνας",
      save: "Αποθήκευση",
      menu: "Μενού",
      nothing_new: "Τίποτα νεότερο...",
      my_profile: "Το προφίλ μου",
      available_networks: "Διαθέσιμα δίκτυα",
      colaborations: "Συνεργασίες",
      networks: "Δίκτυα",
      networks_c: "ΔΙΚΤΥΑ",
      network_properties: "Ακίνητα δικτύου",
      network_requests: "Ζητήσεις δικτύου",
      members: "Μέλη δικτύου",
      send_to_network: "Αποστολή στο δίκτυο",
      delete_from_network: "Διαγραφή από το δίκτυο",
      messages: "Μηνύματα",
      message: "Μήνυμα",
      send_message: 'Αποστολή μηνύματος',
      move_to: 'Μετακίνηση σε',
      compose: 'Σύνταξη',
      inbox: 'Εισερχόμενα',
      sent: 'Απεσταλμένα',
      starred: 'Σημαντικά',
      spam: 'Ανεπιθύμητα',
      deleted: 'Διαγραμμένα',
      no_messages_in_the_folder: 'Δεν υπάρχουν μηνύματα στο φάκελο',
      write_your_message: 'Γράψτε το μήνυμά σας...',
      attach_file: 'Επισύναψη αρχείου',
      attach_picture: 'Επισύναψη εικόνας',
      send_lower: 'Αποστολή',
      send: 'ΑΠΟΣΤΟΛΗ',
      new_message: 'Νέο Μήνυμα',
      to: 'Προς',
      subject: 'Θέμα',
      terms_of_use: "Όροι χρήσης",
      homepage: "Αρχική Σελίδα",
      settings: 'Ρυθμίσεις',
      create_post: 'Δημιουργία Δημοσίευσης',
      whats_on_your_mind: "Τι σκέφτεστε;",
      write_something_to: "Γράψτε κάτι στο χρήστη",
      write_something_to_network: "Γράψτε κάτι στο δίκτυο",
      write_a_comment: "Γράψτε ένα σχόλιο...",
      write_a_reply: "Γράψτε μια απάντηση...",
      comment: "Σχόλιο",
      comments: "Σχόλια",
      like: "Μου αρέσει!",
      reply: "Απάντηση",
      replieces: "Απαντήσεις",
      request: 'Ζήτηση',
      photo_video: 'Εικόνα/Video',
      advert: 'Ακίνητο',
      start_typing_to_search: 'Αναζήτηση',
      start_typing_to_search_C: 'ΑΝΑΖΗΤΗΣΗ',
      properties_page_title: 'Ακίνητα',
      page_search_input_placeholder: 'Αναζήτηση...',
      for_sale: 'Πωλείται',
      for_rent: 'Ενοικιάζεται',
      sqm: 'τ.μ.',
      bedrooms: 'Δωμάτια',
      bathrooms: 'Μπάνια',
      spaces: 'Χώροι',
      per_month_shortcut: 'μήνα',
      button_property_interested: 'ΕΝΔΙΑΦΕΡΟΜΑΙ',
      button_add_connection: 'ΠΡΟΣΘΗΚΗ',
      information: 'Πληροφορίες',
      members_page_title: 'Μέλη Δικτύου',
      member_since: 'Έγινε μέλος το',
      properties: 'Ακίνητα',
      requests: 'Ζητήσεις',
      offers: 'Προσφορές',
      clients: 'Πελάτες',
      suggestions: 'Υποδείξεις',
      phone: 'Τηλέφωνο',
      email: 'Email',
      address: 'Διεύθυνση',
      post_code: 'Τ.Κ.',
      city: 'Πόλη',
      code: 'Κωδικός Δικτύου',
      office_code: 'Κωδικός Γραφείου',
      price: 'Τιμή',
      cooperation_request: 'Αίτηση συνεργασίας',
      requests_page_title: 'Ζητήσεις',
      general: 'Γενικά',
      account: 'Λογαριασμός',
      other: 'Άλλο',
      account_information: 'Πληροφορίες',
      first_name: 'Όνομα',
      last_name: 'Επίθετο',
      description: 'Περιγραφή',
      saved_address: 'Διεύθυνση',
      social_account: 'Διευθύνσεις Social Media',
      password: 'Κωδικός Πρόσβασης',
      help: 'Βοήθεια',
      logout: 'Αποσύνδεση',
      want_to_logout: 'Θέλετε να αποσυνδεθείτε;',
      login: 'Σύνδεση',
      register: 'Εγγραφή',
      login_into_your_account: 'Σύνδεση στο λογαριασμό σας',
      your_email_address: 'Διεύθυνση Email',
      remember_me: 'Να με θυμάσαι',
      forgot_your_password: 'Ξεχάσατε τον κωδικό σας;',
      dont_have_account: "Δεν έχετε λογαριασμό;",
      sign_in_alternatives: 'Εναλλακτικοί τρόποι σύνδεσης',
      sign_in_with_google: 'Σύνδεση με Google',
      sign_in_with_facebook: 'Σύνδεση με Facebook',
      sign_in_with_realstatus: "Σύνδεση με RealStatus",
      friends: 'Φίλοι',
      friends_c: "ΦΙΛΟΙ",
      friend_invitation: 'Προσκαλέστε φίλους',
      friend_request: 'Αιτήματα Φιλίας',
      see_all: 'Προβολή όλων',
      no_friend_requests: 'Κανένα νέο αίτημα',
      mutual_friends: 'Κοινοί φίλοι',
      no_mutual_friends: 'Κανένας κοινός φίλος',
      button_add: 'Προσθήκη',
      button_edit: 'Επεξεργασία',
      button_edit_post_info : 'Επεξεργαστείτε την δημοσίευση σας',
      button_delete: 'Αφαίρεση',
      button_delete_post_info : 'Αφαίρεση της δημοσίευσης',
      button_delete_comment_info : 'Αφαίρεση του σχόλιου σας απο την δημοσίευση',
      button_delete_reply_info: 'Αφαίρεση της απάντησης σας απο αυτό το σχόλιο',
      button_pending: 'Το αίτημα εστάλει',
      button_cancel_friend_request: 'Ακύρωση αιτήματος φιλίας',
      button_cancel_request: 'Ακύρωση',
      button_leave_network: 'ΑΠΟΧΩΡΗΣΗ',
      button_join_network: 'ΣΥΜΜΕΤΟΧΗ',
      button_request_join_network: 'ΑΙΤΗΜΑ',
      button_request_join_network_pending: 'ΕΚΚΡΕΜΗ',
      you_are_on_network: 'Είστε μέλος',
      join_network: 'Γίνετε μέλος',
      no_friends_message: 'Δεν έχετε φίλους προς το παρόν',
      no_search_results_found: 'Δεν βρέθηκαν αποτελέσματα',
      notifications: 'Ειδοποιήσεις',
      send_friend_request : 'Αίτημα Φιλίας',
      friend_request_event_message: 'Σας έστειλε ένα αίτημα φιλίας',
      network_request_event_message: 'Σας έστειλε αίτημα συμμετοχής στο δίκτυο',
      network_accepted_event_message: 'αποδέχτηκε το αίτημα συμμετοχής σας στο δίκτυο',
      request_event_message_accepted: 'Το αίτημα έγινε δεκτό',
      request_event_message_rejected: 'Το αίτημα δεν έγινε δεκτό',
      friend_request_message_accepted: 'Αποδέχτηκε το αίτημα φιλίας',
      no_notifications_message: 'Καμία ειδοποίηση προς το παρόν',
      you_are_no_longer_friends: 'Δεν είστε πλέον φίλοι',
      delete_notification: 'Αφαίρεση της ειδοποίησης',
      button_notification_read: 'Το έχω διαβάσει',
      button_notification_unread: 'Δεν το έχω διαβάσει',
      you_are_friends_on_network: 'Είστε φίλοι στο Network',
      characteristics_of_property: 'Χαρακτηριστικά Ακινήτου',
      square_meters_shortcut: 'τ.μ.',
      undefined: 'Απροσδιόριστο',
      error_field: '*Υποχρεωτικό πεδίο',
      first_name_error: '*Μη έγκυρο όνομα',
      last_name_error: '*Μη έγκυρο επίθετο',
      email_error: '*Μη έγκυρο email',
      phone_error: '*Το τηλέφωνο πρέπει να περιέχει 10-14 ψηφία',
      address_error: '*Μη έγκυρη διεύθυνση',
      city_error: '*Μη έγκυρη πόλη',
      post_code_error: '*Μη έγκυρος Τ.Κ.',
      current_password: 'Τρέχων Κωδικός',
      new_password: 'Νέος Κωδικός',
      confirm_new_password: 'Επιβεβαίωση Νέου Κωδικού',
      password_error: '*Ο κωδικός πρέπει να περιέχει...',
      passwords_dont_match: '*Οι κωδικοί δεν ταιριάζουν',
      contacts: 'ΕΠΑΦΕΣ',
      groups: 'ΟΜΑΔΕΣ',
      pages: 'ΣΕΛΙΔΕΣ',
      available: 'Διαθέσιμος',
      unavailable: 'Μη Διαθέσιμος',
      add_story:'Προσθήκη Ιστορίας',
      advert_code: 'Κωδικός Ακινήτου',
      request_code: 'Κωδικός Ζήτησης',
      request_characteristics: {
        for: 'Σκοπός',
        category: 'Κατηγορία',
        subcategory: 'Υποκατηγορίες',
        price_min: 'Ελάχιστη τιμή',
        price_max: 'Μέγιστη τιμή',
        area_min: 'Ελάχιστα τ.μ.',
        area_max: 'Μέγιστα τ.μ.',
        heating_system: 'Θέρμανση',
        energy_class: 'Ενεργειακή κλάση',
        bedrooms_from: 'Ελάχιστα δωμάτια',
        bathrooms_from: 'Ελάχιστα μπάνια',
        wc_from: 'Ελάχιστα wc',
        livingroom_from: 'Ελάχιστα σαλόνια',
        floor: 'Όροφος',
        parking: 'Parking',
        year_of_construction_from: 'Έτος κατασκευής από',
        year_of_construction_to: 'Έτος κατασκευής έως',
        last_update: 'Ανανεώθηκε στις',
        view: 'Θέα',
        access_from: 'Πρόσβαση από',
        orientation: 'Προσανατολισμός',
        land_use: 'Χρήση γης',
        land_slope: 'Κλίση',
      },
      property_characteristics: {
        network_code: 'Κωδικός network',
        office_code: 'Κωδικός γραφείου',
        price: 'Τιμή',
        price_per_square_meter: 'Τιμή ανα τ.μ.',
        area: 'Εμβαδόν',
        type: 'Τύπος',
        heating_system: 'Σύστημα Θέρμανσης',
        energy_class: 'Ενεργειακή κλάση',
        bedrooms: 'Υπνοδωμάτια',
        bathrooms: 'Μπάνια',
        floor: 'Όροφος',
        parking: 'Θέση στάθμευσης',
        year_of_construction: 'Έτος κατασκευής',
        advert_code: 'Κωδικός Ακινήτου',
        available_from: 'Διαθέσιμο από',
        last_update: 'Τελευταία ενημέρωση',
        location: 'Περιοχή',
        structure_factor: 'Συντελεστής δόμησης',
        coverage_factor: 'Συντελεστής κάλυψης',
        within_the_project: 'Εντός σχεδίου',
        zoning: 'Ζώνη',
        height: 'Ύψος',
        depth: 'Βάθος',
        facade: 'Πρόσοψη',
        access_from: 'Πρόσβαση από',
        orientation: 'Προσανατολισμός',
        land_use: 'Χρήση γης',
        land_slope: 'Κλίση',
        view: 'Θέα',
      },
      show_missing_extras: 'Δείξε τι δεν έχει',
      property_extras: {
        artio: 'Άρτιο',
        oikodom: 'Οικοδομήσιμο',
        adeia_oik: 'Με Άδεια δόμησης',
        goniako: 'Γωνιακό',
        train: 'Σιδηροδρομική γραμμή',
        perifragmeno: 'Περιφραγμένο',
        geotrisi: 'Με γεώτρηση',
        nero_reuma: 'Με νερό',
        reuma: 'Με ρεύμα',
        gass: 'Με αέριο',
        tel: 'Με τηλέφωνο',
        optics: 'Με οπτικές ίνες',
        plot_sq: 'Εμβαδόν οικοπέδου',
        orofodiamerisma: 'Οροφοδιαμέρισμα',
        retire_last: 'Τελ. όροφος',
        road: 'Κεντρική οδός',
        elevator: 'Ανελκυστήρας',
        fireplace: 'Τζάκι',
        ac: 'Air Condition',
        alarm: 'Με συναγερμό',
        student: 'Φοιτιτηκό',
        luxury: 'Πολυτελές',
        preserved: 'Διατηρητέο',
        neoclassic: 'Νεοκλασικό',
        amea: 'Πρόσβαση ΑΜΕΑ',
        satellite: 'Δορυφορική κεραία',
        nightpower: 'Νυχτερινό ρεύμα',
        furn: 'Επιπλωμένο',
        devices: 'Ηλ. Συσκευές',
        open: 'Ανοιχτωσιά',
        bright: 'Φωτεινό',
        tents: 'Τέντες',
        doubleglass: 'Διπλά τζάμια',
        pestnet: 'Σίτες',
        internalstairs: 'Εσωτερική σκάλα',
        bbq: 'BBQ',
        attic: 'Σοφίτα',
        playroom: 'Playroom',
        panoramiko: 'Πανοραμικό',
        paraliako: 'Παραλιακό',
        ependitiko: 'Επενδυτικό',
        sun: 'Ηλιακός',
        painted: 'Βαμμένο',
        retire: 'Ρετιρέ',
        stair_steps: 'Σκαλιά εισόδου',
        prof: 'Επαγγ. Στέγη',
        ygion: 'Χρήση Υγειονομικού',
        yard: 'Προαύλιο',
        pets: 'Επιτρέπονται κατοικίδια',
        koin_val: 'Χωρίς κοινόχρηστα',
      },
      profile_image_view: 'Προβολή εικόνας προφίλ',
      profile_image_change: 'Ενημέρωση εικόνας προφίλ',
      welcomemsgshelp: 'Καλώς ήρθατε στο Real Status Network',
      detailshelp: 'Μάθετε πως να χρησιμοποιείτε το Real Status Network ώστε να μπορείτε να πραγματοποιήσετε την εργασία σας χωρίς δυσκολία',
      question1: 'Ποιοί μπορούν να εγγραφούν στο Network; Ελέγχεται η αξιοπιστία των μελών;',
      question2: 'Πως προσκαλώ συνεργάτες στην λίστα των φίλων μου;',
      question3: 'Ποιοί βλέπουν τις δημοσιεύσεις μου;',
      question4: 'Τι είναι τα Δίκτυα;',
      question5: 'Είναι τα δεδομένα μου ασφαλή;',
      question6: 'Πρέπει να αποδέχομαι όλα τα αιτήματα για συνεργασία;',
      question7: 'Πώς γίνεται ο επιμερισμός της μεσιτικής αμοιβής;',
      question8: 'Ποιό είναι το κόστος της συνδρομής;',
      answer1: 'Στο Real Status Network έχουν δικαίωμα εγγραφής ΜΟΝΟ επαγγελματίες μεσίτες που είναι εγγεγραμμένα στο Γενικό Εμπορικό Μητρώο (ΓΕΜΗ). Επιπλέον υπάρχει σύστημα αξιολόγησης (Reputation) το οποίο αυξάνεται ή μειώνεται ανάλογα με την συμπεριφορά και τον επαγγελματισμό του μέλους. Μέλη που θα πέσουν από ένα συγκεκριμένο όριο θα διαγράφονται από το Network.',
      answer2: 'Απλά αναζητήστε το ονομα του από την αναζήτηση που υπάρχει στο πάνω μέρος της σελίδας και επιλέξτε τον για να μεταβείτε στο προφίλ του. Από εκεί μπορείτε να πατήσετε την Προσθήκη. Αν ο ίδιος αποδεχτεί το αίτημα φιλίας τότε από εκείνη τη στιγμή ειστε πλέον φίλοι και συνεργάτες!',
      answer3: 'Όλες σας οι δημοσιεύσεις είτε είναι ακίνητα, είτε ζητήσεις, είτε απλό κείμενο προβάλλονται στους φίλους σας και στα δίκτυα που έχετε εγγραφεί. ',
      answer4:'Έκτος από το προσωπικό σας προφίλ μπορείτε να δημιουργήσετε το δικό σας δίκτυο και να προσκαλέσετε άλλους μεσίτες να εγγραφούν. Παράλληλα μπορείτε εσείς ο ίδιος/α να εγγραφείτε σε ένα άλλο δίκτυο, έτσι ώστε να ενημερώνεστε για νέα ακίνητα και ζητησης, πάντα με σκοπο την συνεργασία. Ένα δίκτυο θα μπορούσε να έχει το όνομα του μεσιτικού σας γραφείου ή του ονομα του franchise που αντιπροσωπεύετε.',
      answer5:'Στο Network δημοσιεύονται μόνο τα απαραίτητα στοιχεία έτσι ώστε να μπορεί να γίνει μια συνεργασία. Στην ουσία υπάρχουν οι πληροφορίες των ακινήτων που έχετε και στην ιστοσελίδα σας και τίποτα παραπάνω. Από την άλλη οι ζητήσεις έχουν μόνο τα απαιτούμενα του πελάτη σας και όχι τα στοιχεία του.',
      answer6:'Μπορείτε να αποφασίσετε εσείς εάν θέλετε να συνεργαστείτε με κάποιο άλλο μεσίτη. Αν δεν ενδιαφέρεστε, μπορείτε να αγνοήσετε ή να απορρίψετε το αίτημα συνεργασίας. ',
      answer7:'To Real Status Network αποτελεί το εργαλείο το οποίο διευκολύνει τις συνεργασίες μεταξύ των μεσιτών. Οι συνεργαζόμενοι επαγγελματίες έχουν την ευχέρεια να συμφωνήσουν και να καθορίσουν από κοινού τον τρόπο με τον οποίο θα αμείβονται. Παρόλα αυτά, προτείνουμε η κάθε πλευρά να λαμβάνει τη δική της μεσιτική αμοιβή αποκλειστικά από τον πελάτη της. ',
      answer8:'Όλοι οι πελάτες του Real Status είναι αυτόματα και μέλη του Real Status Network εντελώς δωρεάν. Για τους συνεργάτες που εργάζονται στα γραφεία τους, εφόσον είναι εγγεγραμμένα στο Γενικό Εμπορικό Μητρώο (ΓΕΜΗ), το κόστος είναι 10 ευρώ + ΦΠΑ / μήνα. Για τους μεσιτες που δεν χρησιμοποιούν το Real Status το κόστος είναι 25 ευρώ + ΦΠΑ / μήνα.',
      metres: 'μέτρα',
      step4: 'Εδώ μπορείτε να δείτε τις συνεργασίες και την κατάσταση τους',
      step3: 'Εδώ μπορείτε να δείτε τα Δίκτυα που ανήκετε καθώς και τους Φίλους σας',
      step5: 'Εδώ μπορείτε να δείτε τα ακίνητα, τις ζητήσεις και τα δίκτυά σας',
      step6: 'Εργαλεία τα οποία θα εμπλουτίζονται συνεχώς',
      step2: 'Εδώ μπορείτε να δείτε τα Ακίνητα και τις Ζητήσεις του δικτύου',
      step1: 'Εδώ μπορείτε να δείτε τις ειδοποιήσεις που έχετε λάβει',
    },
  };
  onChangeLocaleFns= [];

  onChangeLocale(fn) {
    this.onChangeLocaleFns.push(fn);

    return () => {
      this.onChangeLocaleFns.splice(this.onChangeLocaleFns.indexOf(fn), 1);
    };
  }

  clearOnChangeLocale() {
    this.onChangeLocaleFns = []
  }

  getLocale() {
    return this.locale;
  }

  setLocale(locale) {
    this.locale = locale;

    moment.locale(locale);

    this.onChangeLocaleFns.forEach((fn) => {
      fn(locale);
    });
  }

  get_data(key, data) {
    if (this.locale == 'en' && typeof data[`${key}_en`] !== 'undefined') {
      return data[`${key}_en`];
    }

    return data[key];
  }

  t(key, locale = null) {
    // 'foo.bar.baz'
    const keyClues = key.split('.');
    let translation = this.translations[locale || this.locale][keyClues[0]];
   
    for (let i = 1; i < keyClues.length; i++) {
      
      translation = translation[keyClues[i]];
    }

    return translation;
  }
}

const i18n = new I18n();

export default i18n;