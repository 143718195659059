import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece, faTrash, faGlobe, faAngleRight, faPhotoFilm, faEquals } from '@fortawesome/free-solid-svg-icons';
import config from '../config';

class PropertyEditCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    this.localeUnmount = i18n.onChangeLocale(() => {
      this.setState({
        ...this.state,
      });
    });
  }

  componentWillUnmount() {
    this.localeUnmount()
  }

  render() {
    return (
      <div className={`col-lg-12 col-md-12 col-sm-12 mb-3 pe-2 ps-2 flex-lg-row ${this.props.className ? this.props.className : ''}`}>
        <div className={`card property-card w-100 p-0 hover-card border-0 rounded-3 me-1 flex-lg-row`}>
          {/* {this.props.data.featured ? <span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-primary-gradiant d-inline-block text-white position-absolute mt-3 ms-3 z-index-1">Featured</span> : ''} */}
          {
            this.props.match_icon &&
            <FontAwesomeIcon className='match-icon' icon={faPuzzlePiece} />
          }
          <div className="w-100 mb-3 m-3">
            <Link
              to={`/properties/${this.props.data.id}`}
              className="position-relative d-block property-image-link">
              <img src={`${config.property_images_url}${this.props.data.thumbnail}`} alt="" className="w-100" />
            </Link>
          </div>
          <div className="card-body mh-180 d-flex flex-column mw-50 p-relative">
            
            {/* <i className="feather-bookmark font-md text-grey-500 position-absolute right-0 me-3"></i> */}
            <h4 className="fw-700 font-xs mt-0 lh-18 mb-0 property-card-title">
              <Link to={`/properties/${this.props.data.id}`} className="text-dark text-grey-900">
              #{this.props.data.id}{this.props.code !== '' ? (this.props.code) : '' }  {i18n.get_data('title', this.props.data)}
              </Link>
            </h4>
            <h6 className="font-xsss text-grey-600 fw-600 my-2"> {`${this.props.data.address}`}</h6>
            <h5 className="mt-3 d-inline-block font-xsss text-grey-600 me-2">
              <span className="fw-600 me-2">{this.props.data.sq} {i18n.t('sqm')}</span> 
              {this.props.data.rooms > 0 && <span className="fw-600 me-2">{this.props.data.rooms} {i18n.t('bedrooms')}</span> }
              {this.props.data.bathroom > 0 && <span className="fw-600 me-2">{this.props.data.bathroom} {i18n.t('bathrooms')}</span> }
              {this.props.data.wc > 0 && <span className="fw-600 me-2">{this.props.data.wc} wc</span> }
            </h5> 
            
            <div className="clearfix"></div>
            <span className="font-lg fw-700 mt-0 pe-3 ls-2 lh-32 d-inline-block text-black-50 float-left d-block">
              <span className="font-xs">€</span>
              {this.props.data.price}
              {
                this.props.data.action === 'rent' && <span className="font-xsssss text-grey-600">/ {i18n.t('per_month_shortcut')}</span>
              }
            </span>
            <div className='position-absolute bottom-15 mb-2 right-15 btns-action-p'>
              <button onClick={() => this.props.onEditNetwork(this.props.data)} className="rounded-xl border-none p-sm-2 mr-2 bg-primary text-white font-xssss">
                {i18n.t('publish')}
              </button>
              <Link to={`/edit_property/${this.props.data.id}`} className="d-inline-block rounded-xl border-none p-sm-2 mr-2 bg-primary-gradiant text-white font-xssss">
                {i18n.t('edit')} <FontAwesomeIcon icon={faAngleRight} />
              </Link>
              <Link to={`/edit_property_media/${this.props.data.id}`} className="d-inline-block rounded-xl border-none mb-1 p-sm-2 mr-2 bg-primary-gradiant text-white font-xssss">
                Media
              </Link>
              {
                this.props.data.crossing_count > 0 &&
                <Link to={`/requests/cross/${this.props.data.id}`} className="d-inline-block rounded-xl border-none p-sm-2 mr-2 bg-success text-white font-xssss">
                  <span className='cross-counter'>{this.props.data.crossing_count}</span> {this.props.data.crossing_count > 1 ? i18n.t('crossings') : i18n.t('crossing')} 
                </Link>
              }
              
              <button onClick={() => {
                this.props.onDelete(this.props.data)
              }} className=" rounded-xl h-37 border-none p-sm-2 mr-2 bg-danger text-white font-xssss">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
             
            </div>
        </div>
      </div>
    );
  }
}

export default PropertyEditCard;