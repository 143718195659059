
import React, { Fragment } from 'react';
import request from '../../request';
import i18n from '../../i18n';
import moment from 'moment';
import socket from '../../socket';
import FullChat from './FullChat';

class FullChatWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		messages: [],
		last_timestamp: 0
    };
    this.onPrevMessages = this.onPrevMessages.bind(this);
  }

  componentDidMount() {
	this.init()

    this.unmountSocketListener = socket.onChat((data) => {
		console.log(data)
		if (parseInt(data.from) === parseInt(this.props.realtor.id) || parseInt(data.to) === parseInt(this.props.realtor.id)) {
			this.setState({
				messages: this.state.messages.concat([data])
			})
		}
	});
  }

  componentDidUpdate(prevProps, prevState) {
	if (prevProps.realtor.id !== this.props.realtor.id) {
		this.init()
	}
  }

  componentWillUnmount() {
	this.unmountSocketListener();
    i18n.clearOnChangeLocale();
  }

  init() {
	const current = this.props.realtor;
	request.get(`chat/id/${current.id}`).then((response) => {
		const messages = (response.data && response.data.messages) ? response.data.messages.map(r => {
			if (r.receiver == current.id) {
				return {
					message: r.message,
					sender: r.sender,
					to: r.receiver,
					timestamp: r.send_date,
					type: "chat"
				}
			} else {
				return {
					message: r.message,
					sender: r.sender,
					from: r.sender,
					timestamp: r.send_date,
					type: "chat"
				}
			}
			}) : [];
		let last_timestamp = 0
		if (messages[0]) {
			last_timestamp = messages[0].timestamp
		}
		this.setState({
			messages: messages,
			last_timestamp: last_timestamp 
		});
	});
  }

  

  onPrevMessages() {

    request.get(`chat/id/${this.props.realtor.id}/timestamp/${this.state.last_timestamp}`).then((response) => {
	  const current = this.props.realtor;
	  
      const messages = (response.data && response.data.messages) ? response.data.messages.map(r => {
        if (r.receiver == current.id) {
          return {
            message: r.message,
            sender: r.sender,
            to: r.receiver,
            timestamp: r.send_date,
            type: "chat"
          }
        } else {
          return {
            message: r.message,
            sender: r.sender,
            from: r.sender,
            timestamp: r.send_date,
            type: "chat"
          }
        }
      }) : [];

	  let last_timestamp = 0
	  if (messages[0]) {
		last_timestamp = messages[0].timestamp
	  }
	  this.setState({
		messages: messages.concat(this.state.messages),
		last_timestamp: last_timestamp 
	  })
    });
    
  }

  render() {

    
    return (
      <Fragment>
        <FullChat 
			onPrev={() => this.onPrevMessages()}
			realtor={this.props.realtor}
			messages={this.state.messages}
			isFull={this.props.isFull}
		/>
      </Fragment>
    );
  }
}

export default FullChatWrapper;