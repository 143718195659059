import React, { Component , Fragment } from "react";
import { Link } from 'react-router-dom';
import validator from 'validator'
import Header from '../components/Header';
import ImageUploadDialog from "../components/ImageUploadDialog";
import Appfooter from '../components/Appfooter';
import FriendRequestsList from "../components/FriendRequestsList";
import ProfileCard from '../components/ProfileCard';
import Createpost from '../components/Createpost';
import i18n from "../i18n";
import request from "../request";
import storage from '../storage';
import Rightchat from "../components/Rightchat";
import Popupchat from "../components/Popupchat";
import ReputationData from "../components/ReputationData";
import FriendsProfile from "../components/Profile/FriendsProfile";
import NetworksProfile from "../components/Profile/NetworksProfile";
import PostsProfile from "../components/Profile/PostsProfile";
import RequestsProfile from "../components/Profile/RequestsProfile";
import PropertiesProfile from "../components/Profile/PropertiesProfile";
import history from '../helpers/history';
import Utils from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faHand } from "@fortawesome/free-solid-svg-icons";
import ReportUser from "../components/ReportUser";

// import FriendsOnProfile from '../components/FriendsOnProfile';

class Profile extends Component {

  constructor(props) {
    super(props);

    const component = this.props.match.params.component && this.props.match.params.component !== '' ? this.props.match.params.component : 'posts';
    
    this.state = {
      post: '',
      page: 0,
      properties_page: 0,
      end_properties: false,
      loaded: false,
      end: false,
      meta: [],
      imageDialogOpen: false,
      imageType: '',
      properties: [],
      component: component,
      new_post: null,
      show_report_form: false
    };

    this.handlePostChange = this.handlePostChange.bind(this);
    this.addPost = this.addPost.bind(this);
    this.removePreview = this.removePreview.bind(this);
    this.onDocumentScroll = this.onDocumentScroll.bind(this);
  }

  componentDidMount() {
    const profile = this.props.match.params.id ? this.props.match.params.id : localStorage.loginId;

    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    if(profile){
      this.getMemberProfile(profile);
    }

    document.addEventListener('scroll', this.onDocumentScroll);
  }

  onDocumentScroll = (e) => {
    if (!this.state.member) return;
    const profile = this.props.match.params.id ? this.props.match.params.id : localStorage.loginId;
    const component = this.props.match.params.component;

    const totalPageHeight = document.body.scrollHeight - 1; 
    const scrollPoint = window.scrollY + window.innerHeight;

    if(scrollPoint >= totalPageHeight) {
      this.getView(profile, component)
      // this.showMorePosts(profile);
    }
  };

  getView(profile, component) {
    switch(component) {
      case 'properties':
        this.setState({
          component: 'properties'
        })
        break;
      case 'requests':
        this.setState({
          component: 'requests'
        })
        break;
      case 'posts':
        this.setState({
          component: 'posts'
        })
        break;
      case 'friends':
        this.setState({
          component: 'friends'
        })
        break;
      case 'networks':
        this.setState({
          component: 'networks'
        })
        break;
      default: 
        this.setState({
          component: 'posts'
        })
        break;
    }
  }

  handleClickImageDialogOpen = (e) => {
    this.setState({ 
      ...this.state,
      imageDialogOpen: true,
      imageType: e,
    });
  };

  handleClickImageDialogClose = () => {
    this.setState({ 
      ...this.state,
      imageDialogOpen: false 
    });
  };

  changeCoverImage(e) {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        profile_cover: e
      }
    });
  }

  changeProfileImage(e) {
    const member = {
      ...this.state.member,
      profile_image: e,
      reload_image: true
    }
    this.setState({
      ...this.state,
      member: member
    });
  }

  getMemberProfile(profile){
    return request.get(`member/id/${profile}`).then((response) => {
      if (response.data && response.status === 200) {
        this.setState({
          member: response.data, 
          page: 0,
          properties_page: 0,
          requests_page: 0,
          posts: [],
          properties: [],
          requests: [],
          component: 'posts'
        }, () => {this.getView(profile, this.state.component)});
      } 
      else {
        history.push('/');
      }
    });
  } 

  handlePostChange(e){
    var val = e.target.value;
    this.setState({post: val});
    if(val && this.state.meta.length === 0){
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      var url = val.match(urlRegex);
      if(url && validator.isURL(url[0])){
        request.post(`og`,{
          url: url[0],
        }).then((response) => {
          this.setState({
            ...this.state,
            meta: response.data,
          });
        });
      } 
    }
  }

  addPost(){
    if (!Utils.stringEmpty(this.state.post)) {
      request.post(`posts`,{
        post: this.state.post,
        meta: this.state.meta,
        me: localStorage.loginId,
        member: this.state.member.id,
      }).then((response) => {
        if (response.status === 200) {
          this.setState({
            new_post: response.data[0],
            meta: [],
            post: '',
          });
        }
      });
    }
  }

  removePreview(){
    this.setState({
      meta: [],
    });
  }

  onDeleteFriend(data) {
    const member = {
      ...this.state.member,
      connection_id: data.connection_id,
      connection_sender: data.connection_sender,
      connection_receiver: data.connection_receiver,
      connection_status: data.connection_status
    }

    this.setState({
      member: member
    })
  }

  onAddFriend(data) {
    const member = {
      ...this.state.member,
      connection_id: data.connection_id,
      connection_sender: data.connection_sender,
      connection_receiver: data.connection_receiver,
      connection_status: data.connection_status
    }

    this.setState({
      member: member
    })
  }

  onAcceptFriend = () => {
    const member = {
      ...this.state.member,
      connection_status: '1'
    }

    this.setState({
      member: member
    })
  }

  onRejectFriend = () => {
    const member = {
      ...this.state.member,
      connection_status: '2'
    }

    this.setState({
      member: member
    })
  }

  componentWillUnmount() {
    i18n.clearOnChangeLocale();
    document.removeEventListener('scroll', this.onDocumentScroll);
  }

  componentDidUpdate(prevProps, prevState){
    const profile = this.props.match.params.id ? this.props.match.params.id : localStorage.loginId;
    const component = this.props.match.params.component;
    if(this.state.member && this.state.member.id !== profile){
      if(profile){
        this.getMemberProfile(profile);
      }
    } 
    else if (this.state.member && component && component !== prevState.component && component !== '') {
      this.resetState(profile, component)
      
    }
  }
  resetState(profile, component) {
    this.setState({
      posts: [],
      page: 0,
      properties_page: 0,
      end: false,
      end_properties: false,
      end_requests: false,
      loaded: false,
      properties: [],
    }, () => {
      this.getView(profile, component)
    })
  }

  setOpenForCooperation() {
    request.post('set_open_for_cooperation', {
      open: !this.state.member.open_for_cooperation
    }).then(response => {
      if (response.status === 200) {
        this.setState({
          member: {
            ...this.state.member,
            open_for_cooperation: !this.state.member.open_for_cooperation
          }
        })
      }
    })
  }
  render() {
    const member = this.state.member ? this.state.member : null
    const meID = storage.me && storage.me.realtors_id;
    const memberID = member && member.id;
    const properties_count = member ? parseInt(member.properties.rentals) + parseInt(member.properties.sells) : 0;
    const requests_count = member ? parseInt(member.requests.rentals) + parseInt(member.requests.sells) : 0;
    const its_me = meID === memberID;
    return (
      <Fragment> 
        <Header />
        <ImageUploadDialog 
          handleClickImageDialogClose={()=>this.handleClickImageDialogClose()}
          open={this.state.imageDialogOpen} 
          imageType={this.state.imageType}
          changeCoverImage={(e) => this.changeCoverImage(e)}
          changeProfileImage={(e) => this.changeProfileImage(e)}
        />
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12 mb-3">
                  <ProfileCard 
                    handleClickImageDialogOpen={(e)=>this.handleClickImageDialogOpen(e)}
                    member={this.state.member} 
                    onDeleteFriend={(data) => this.onDeleteFriend(data)}
                    onAddFriend={(data) => this.onAddFriend(data)}
                    onAcceptFriend={() => this.onAcceptFriend()}
                    onRejectFriend={() => this.onRejectFriend()}
                    connection={(this.state.member) ? {
                      id: this.state.member.id,
                      connection_id: this.state.member.connection_id,
                      connection_status: this.state.member.connection_status,
                      connection_sender: this.state.member.connection_sender
                    } : {
                      id: null,
                      connection_id: null,
                      connection_status: null,
                      connection_sender: null
                    }
                  }
                  />
                </div>
                <div className="col-xl-4 col-xxl-3 col-lg-4">
                  <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                   <Link to={`/members/${memberID}/posts`}>
                      <div className="card-body d-flex position-relative">
                        <i className="feather-globe text-grey-500 me-3 font-lg"></i>
                        <h4 className="fw-700 text-grey-900 font-xsss mt-1">
                          {i18n.t('Δημοσιεύσεις')}
                        </h4>
                      </div>
                    </Link>
                    <Link to={`/members/${memberID}/properties`}>
                      <div className="card-body d-flex position-relative pt-0">
                        <i className="feather-home text-grey-500 me-3 font-lg"></i>
                        <h4 className="fw-700 text-grey-900 font-xsss mt-1">
                          <span className="badge badge-primary absolute-badge">
                            {this.state.member &&
                            (parseInt(properties_count))}
                          </span>
                          &nbsp;
                          {i18n.t('properties')}
                          {/* <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">
                            {this.state.network && this.state.network.properties.sells} {i18n.t('for_sale')},&nbsp;
                            {this.state.network && this.state.network.properties.rentals} {i18n.t('for_rent')}
                          </span> */}
                        </h4>
                      </div>
                    </Link>
                    <Link to={`/members/${memberID}/requests`}>
                      <div className="card-body d-flex position-relative pt-0">
                        <i className="feather-search text-grey-500 me-3 font-lg"></i>
                        <h4 className="fw-700 text-grey-900 font-xsss mt-1">
                          <span className="badge badge-primary absolute-badge">
                            {this.state.member &&
                            (parseInt(requests_count))}
                          </span>
                          &nbsp;
                          {i18n.t('requests')}
                          {/* <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">
                            {this.state.network && this.state.network.requests.sells} {i18n.t('for_sale')},&nbsp;
                            {this.state.network && this.state.network.requests.rentals} {i18n.t('for_rent')}
                          </span> */}
                        </h4>
                      </div>
                    </Link>
                    <Link to={`/members/${its_me ? meID : memberID}/networks`}>
                    <div className="card-body d-flex position-relative pt-0">
                        <i className="feather-globe text-grey-500 me-3 font-lg"></i>
                        <h4 className="fw-700 text-grey-900 font-xsss mt-1">
                          <span className="badge badge-primary absolute-badge">
                          {this.state.member && this.state.member.networks}
                          </span>
                          &nbsp;
                          {i18n.t('networks')}
                          {/* <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">
                            {this.state.network && this.state.network.requests.sells} {i18n.t('for_sale')},&nbsp;
                            {this.state.network && this.state.network.requests.rentals} {i18n.t('for_rent')}
                          </span> */}
                        </h4>
                      </div>
                    </Link>
                    <Link to={`/members/${memberID}/friends`}>
                      <div className="card-body d-flex position-relative pt-0">
                        <i className="feather-users text-grey-500 me-3 font-lg"></i>
                        <h4 className="fw-700 text-grey-900 font-xsss mt-1">
                          <span className="badge badge-primary absolute-badge">
                          {this.state.member && this.state.member.connections}
                          </span>
                          &nbsp;
                          {i18n.t('friends')}
                          {/* <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">
                            {this.state.network && this.state.network.requests.sells} {i18n.t('for_sale')},&nbsp;
                            {this.state.network && this.state.network.requests.rentals} {i18n.t('for_rent')}
                          </span> */}
                        </h4>
                      </div>
                    </Link>  
                  </div>
                  {/* <Profilephoto /> */}
                  {/* <Events /> */}
                  {
                    this.state.member &&
                    <div>
                      <ReputationData
                        member={this.state.member ? {...this.state.member, reputation: this.state.member.rating} : null}
                      />
                    </div>
                  }
                  
                  
                    {
                      its_me ?
                      <div>
                        <FriendRequestsList />
                      </div> : 
                      <div style={{textAlign: 'center'}}>
                        <button className="btn bg-dark text-white" onClick={() => this.setState({show_report_form: true})}><FontAwesomeIcon icon={faCircleExclamation} /> Report user</button>
                        {
                        this.state.member &&
                        <ReportUser 
                          showForm={this.state.show_report_form}
                          closeForm={() => this.setState({
                            show_report_form: false
                          })}
                          blamed_id={this.state.member.id}
                        />
                      }
                      
                      </div>
                    }
                    {
                      its_me && this.state.member &&
                      <div className={`card w-100 rounded-xxl border-0 mb-3`}>
                        <div className="card-body d-flex direction-column align-items-center p-4">
                          <h4 className="fw-700 mb-0 font-xss text-grey-900">
                            {i18n.t('become_open_for_cooperation_profile_text')}
                          </h4>
                          <div className="d-flex mt-2">
                            <button 
                            className={`btn ${this.state.member.open_for_cooperation ? 'btn-success text-white' : 'text-dark'} rounded-circle  hand-init-btn`}
                            onClick={() => {
                              this.setOpenForCooperation()
                            }}><FontAwesomeIcon icon={faHand} /></button>
                            <span className="show-hand-profile" >{i18n.t('become_open_for_cooperation_profile_explanation')}</span>
                          </div>
                        </div>
                      </div>
                    }
                    
                 
                </div>
                
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                {
                  (this.state.component === '' || this.state.component === 'posts') && member &&
                  <div className="posts">
                    {
                      (this.state.member && (this.state.member.is_me || parseInt(this.state.member.connection_status) === 1)) &&
                      <Createpost 
                        me={storage.me}
                        member={this.state.member}
                        meta={this.state.meta.length>0 ? this.state.meta : null}
                        post={this.state.post}
                        handlePostChange={this.handlePostChange}
                        addPost={this.addPost}
                        removePreview={this.removePreview}
                      />
                    }
                    
                    <PostsProfile member={member} new_post={this.state.new_post}/>
                    </div>
                      
                    }
                    {
                    this.state.component === 'properties' && member &&
                      <div className="container">
                        <div className="row">
                          <PropertiesProfile member={member} />
                        </div>
                      </div>
                    }
                    {
                      this.state.component === 'requests' && member && 
                      
                      <div className="container">
                        <RequestsProfile member={member} />
                      </div>
                    }
                    {
                      (this.state.component === 'friends' && member) &&
                      <div className="container">
                        <FriendsProfile 
                          member={member}
                        />
                      </div>
                    }
                    {
                      (this.state.component === 'networks' && member) &&
                      <div className="container">
                        <NetworksProfile 
                          member={member}
                        />
                      </div>
                    }

                </div>
              </div>
            </div>
          </div>
        </div>
        
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default Profile;