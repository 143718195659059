import React,{Component, Fragment} from 'react';
// import validator from 'validator'
import Preview from './Preview';
import i18n from '../i18n';
// import Utils from "../utils"
// import request from "../request";
// import storage from '../storage';

class Replyview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isReplyMenuOpen: false,
      isReplyActive: false,
      isReplyOpen: false,
    };

  }

  toggleMenuOpen = () => this.setState({ isReplyMenuOpen: !this.state.isReplyMenuOpen });
  toggleActive = () => this.setState({ isReplyActive: !this.state.isReplyActive });
    
  render() {
    
    const {user, time, desc, avater, replyid, meta, removeReply, replyowner, me, setEmotion, replyemotions} = this.props;
    const image = me && me.realtors_site+"/images/properties/agents/"+me.realtors_photo;
    const emotions = (replyemotions && replyemotions.emoji ? replyemotions.emoji.split(',') : null);
    const action = "postView";
    const replyMenuClass = `${this.state.isReplyMenuOpen ? " show" : ""}`;
    const replyEmojiClass = `${this.state.isReplyActive ? " active" : ""}`;
    const replyClass = `${this.state.isReplyOpen ? " show" : " hide"}`;

    return (
      <div className='d-flex'>
        <figure className="avatar me-3"><img src={`${avater}`} alt="avater" className="shadow-sm rounded-circle w25" /></figure>
        <div style={{flex: '2'}} className='d-flex flex-column'>
          <div className="card w-100 shadow-xss rounded-xxl border-0 p-3">
            <div className="card-body p-0 d-flex">
              <h4 className="fw-700 text-grey-900 font-xssss mt-1"> {user}</h4>
              {replyowner ? 
              <div className="ms-auto" style={{position: 'relative'}}>
                <div onClick={this.toggleMenuOpen} className={`ms-auto pointer ${replyMenuClass}`} style={{textAlign: 'right'}} id={`replyMenu${replyid}`} data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="ti-more-alt text-grey-900 font-xsss"></i>
                </div>
                <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${replyMenuClass}`} aria-labelledby={`replyMenu${replyid}`}>
                  <div onClick={() => removeReply(replyid)} className="card-body p-0 d-flex">
                    <i className="feather-trash-2 text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{i18n.t('button_delete')} <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">{i18n.t('button_delete_reply_info')}</span></h4>
                  </div>
                </div>
              </div> 
              : '' }
            </div>
            <div className="card-body p-0">
              <p className="fw-500 text-grey-900 font-xssss">
                {desc}
                {/* <a href="/defaultvideo" className="fw-600 text-primary ms-2">See more</a> */}
              </p>
            </div>
            {/* {meta ? <Preview meta={meta} action={action} /> : null} */}
          </div>
          <div className="card-body d-flex p-0">
          <div onMouseOver={this.toggleActive} onMouseOut={this.toggleActive} style={{position: 'relative', marginTop: '1px'}}>
            <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
              {replyemotions && replyemotions.me ? <span>{i18n.t(`${replyemotions.me}`)} </span> : <span>{i18n.t('like')} </span> }
              &nbsp;&nbsp;&nbsp;&nbsp; 
            </div>
            <div className={`emoji-wrap pointer ${replyEmojiClass}`} style={{ minWidth: '310px', bottom: '30px'}}>
                <ul className="emojis list-inline mb-0">
                    <li onClick={() => setEmotion(replyid, "--1")} className="emoji list-inline-item"><i className="em em---1"></i> </li>
                    <li onClick={() => setEmotion(replyid, "heart")} className="emoji list-inline-item"><i className="em em-heart"></i> </li>
                    <li onClick={() => setEmotion(replyid, "blush")} className="emoji list-inline-item"><i className="em em-blush"></i></li>
                    <li onClick={() => setEmotion(replyid, "smile")} className="emoji list-inline-item"><i className="em em-smile"></i> </li>
                    <li onClick={() => setEmotion(replyid, "astonished")} className="emoji list-inline-item"><i className="em em-astonished"></i> </li>
                    <li onClick={() => setEmotion(replyid, "cry")} className="emoji list-inline-item"><i className="em em-cry"></i></li>
                    <li onClick={() => setEmotion(replyid, "angry")} className="emoji list-inline-item"><i className="em em-angry"></i></li>
                </ul>
            </div>
          </div>
          <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
            {emotions ? 
              <Fragment>
                {
                  emotions && emotions.map( (emotion, i) => {
                    return <i key={i} className={`em em-${emotion} font-xssss`} />
                  })
                }
              </Fragment>
            : null }
            &nbsp;
            {replyemotions && replyemotions.total > 0 ? 
              <Fragment>
                {replyemotions.total}
              </Fragment>
            : null }
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;
            <span className="pointer d-flex align-items-center text-grey-500 lh-26 font-xssss me-2"> 
              {time}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Replyview;