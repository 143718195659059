import React, { Component } from "react";
import i18n from "../i18n";

class ModalGeneric extends Component {
	constructor(props) {
		super(props)

		this.handleClose = this.handleClose.bind(this)
	}

	componentDidMount() {
		window.addEventListener('keyup', (e) => {
			if (e.key == 'Escape') {
				this.handleClose()
			}
		})
	}
	
	handleClose() {
		if (this.props.handleClose) {
			this.props.handleClose()
		}
	}

	render() {
		return (
			<div className={`modal-wrapper ${this.props.show ? 'modal-show' : 'modal-hide'}`}>
				<div className="full-modal modal-dialog modal-lg">
					<div className="modal-content rounded-4">
						<div className="modal-header">
							{
								this.props.header ? this.props.header : ''
							}
							<button
								type="button"
								className="close modal-close"
								data-dismiss="modal"
								aria-label="Close"
								onClick={() => this.handleClose()}
							>
								<span className="feather-x" aria-hidden="true"></span>
							</button>
						</div>
						<div className="modal-body">
							{
								this.props.children ? this.props.children : ''
							}
						</div>
						<div className="modal-footer">
							{
								this.props.footer ? this.props.footer : ''
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalGeneric