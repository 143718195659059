import storage from "./storage";
import config from "./config";

class Socket {
  pendingRequests = [];
  onceConnectFns = [];
  onEventFns = [];
  onChatFns = [];
  onCooperationChatFns = [];
  onFetchOnlineStatusFns = []; //user_status
  onUsersOnlineStatusFns = []; //user_status

  static initialized = false;
  static socket_instance = null;
  retry = 0;
  

  constructor(serverURL) {
    // if (!('io' in window)) {
    //   console.error('Could not connect to websocket server');
    //   return;
    // }
    // this.instance = new WebSocket(serverURL);

    this.client = {
      connected: false,
      token: localStorage.getItem('token') !== null && localStorage.getItem('token') != 'null' ? localStorage.getItem('token') : null
    }

    this.server = serverURL
    
    storage.onChange((key, value) => {
      if (key == 'token' && value !== '' && value !== this.client.token) {
        this.client = {
          ...this.client,
          connected: true,
          token: value
        };

        this.init()
      }
    })
    // storage.onChange((key, value) => {
    //   if (key === 'me' && value && value.realtors_id) {
    //     this.send({
    //       type: 'login',
    //       id: value.realtors_id,
    //       locale: i18n.getLocale(),
    //     });
    //   }
    // });

    this.init()
  }

  init() {
    if (!storage.token) return;
    // if (!this.instance.readyState || this.instance.readyState !== WebSocket.OPEN) {

    if (this.instance && typeof this.instance !== 'undefined' && typeof this.instance.close !== 'undefined') this.instance.close(1000);

    this.instance = new WebSocket(this.server)
    // }
    
    
    this.instance.onopen = (e) => {
      this.client = {
        ...this.client,
        connected: true,
        token: storage.token
      };
      
      if (this.instance && this.instance.readyState && this.instance.readyState === WebSocket.OPEN) {
        // this.instance.send(JSON.stringify(this.client))
        this.send({
          type: 'login',
          id: storage.realtors_id
        });
      } else {
        this.client = {
          ...this.client,
          connected: false,
        };
        // setTimeout(() => {
        //   this.instance.send(JSON.stringify(this.client))
        // }, 500)
      }
      
    };
      // if (!this.initialized) {
      //   this.initialized = true
  
      this.instance.onclose = (event) => {
        
        this.client = {
          ...this.client,
          connected: false,
        };
        //|| event.code === 1006
        if (event.code === 1000 ) return;

        if (this.retry <= 3) {
          setTimeout(() => {
            this.retry++;
            this.init()
          }, 3000)
        } else {
          console.log('Disconnected. Server not responding');
        }
      };
  
      this.instance.onmessage = ( (data) => {
        if (!data.data) {
          return;
        }
        
        data = JSON.parse(data.data)
        
        if ('type' in data && data.type == 'event') {
          this.onEventFns.forEach((fn) => {
            fn(data);
          });
        } 
        else if ('type' in data && data.type == 'chat') {
          this.onChatFns.forEach((fn) => {
            fn(data);
          });
        }
        else if ('type' in data && data.type == 'cooperation_chat') {
          this.onCooperationChatFns.forEach((fn) => {
            fn(data);
          });
        }
        else if ('type' in data && data.type == 'online_friends') {
          this.onFetchOnlineStatusFns.forEach((fn) => {
            fn(data);
          });
        }
        else if ('type' in data && data.type == 'user_status') {
          this.onUsersOnlineStatusFns.forEach((fn) => {
            fn(data);
          });
        }
      });
    // }
    
  }

  propagate_pending() {
    this.pendingRequests.forEach(p => {
      this.send(p)
    })

    this.pendingRequests = []
  }

  status() {
    return this.instance && this.instance.readyState === WebSocket.OPEN;
  }

  disconnect() {
    this.instance.close(1000);
  }

  onConnect(fn) {
    this.onceConnectFns.push(fn);
  }

  onEvent(fn) {
    this.onEventFns.push(fn);

    return () => { // unwatch function
      this.onEventFns.splice(this.onEventFns.indexOf(fn), 1);
    };
  }

  onFetchOnlineStatus(fn) {
    this.onFetchOnlineStatusFns.push(fn);

    return () => { // unwatch function
      this.onFetchOnlineStatusFns.splice(this.onFetchOnlineStatusFns.indexOf(fn), 1);
    };
  }

  onUsersOnlineStatus(fn) {
    this.onUsersOnlineStatusFns.push(fn);

    return () => { // unwatch function
      this.onUsersOnlineStatusFns.splice(this.onUsersOnlineStatusFns.indexOf(fn), 1);
    };
  }

  onChat(fn) {
    this.onChatFns.push(fn);

    return () => { // unwatch function
      this.onChatFns.splice(this.onChatFns.indexOf(fn), 1);
    };
  }

  onCooperationChat(fn) {
    this.onCooperationChatFns.push(fn);

    return () => { // unwatch function
      this.onCooperationChatFns.splice(this.onCooperationChatFns.indexOf(fn), 1);
    };
  }

  send(data) {
    if (typeof this.instance !== 'undefined') {
      if (this.client.connected === false || this.instance.readyState !== WebSocket.OPEN) {
        if (this.instance.close) this.instance.close(1000);
         this.pendingRequests.push(data);
        this.init()
        return;
      }
      
      data.token = storage.token; 
      const messageId = Math.random().toString().slice(-16);
      data.messageId = messageId;
      if (config.enviroment === 'development') {
        data.env = 'development'
      }
      // convert message to binary
      this.instance.send(JSON.stringify(data));

      if (data.type == 'login') {
        setTimeout(() => {
          this.propagate_pending()
        }, 1000)
      }
    }
    else {
      this.pendingRequests.push(data);
      this.init()
    }
  } 
}
// if (!Socket.initialized) {
//   // const socket = new Socket('ws://localhost:8282');
//   const socket = new Socket('wss://network-socket.realstatus.net/websocket');
//   // const socket = new Socket('ws://localhost:8282');
//   window.socket = socket;
  
// }
const socket = new Socket('wss://network-socket.realstatus.net/websocket');
// const socket = new Socket('ws://localhost:8282');
// socket.init()


// const socket = new Socket('ws://localhost:8282');
window.socket = socket;

export default socket;


