import React,{Component, createRef} from 'react';
import { Tooltip } from '@mui/material';
import Button from './Button';
import i18n from '../i18n';
import Utils from "../utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece, faClose, faGlobe, faTrash, faAngleRight, faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

class RequestEditCard extends Component {
	constructor(props) {
		super(props);

		this.searchRef = createRef()
		this.state = {
			selected_properties: [],
			search_results: [],
			request_text: '',
			showModal: false,
			show_suggestions: false
		};
	}

	componentDidMount() {
		i18n.onChangeLocale(() => {
			this.forceUpdate();
		});
		
	}

	render() {
		const {request} = this.props;
		return (
			<div className={`card d-block w-100 border-0 mb-3 bg-white rounded-3 pe-4 pt-4 pb-4 request-card`}  style={{paddingLeft: "120px"}}>
				{
					this.props.match_icon &&
					<FontAwesomeIcon className='match-icon' icon={faPuzzlePiece} />
				}
				<div className='requests-details'>
					<Tooltip title={request.realtor_name} placement="top">
						<img src={`${request.realtor_image}`} alt="job-avater" className="img-avatar-request position-absolute bg-lightblue2 w65 ms-4 left-0 img-circle" />
					</Tooltip>
					<h4 className="font-xss fw-700 text-grey-900 mb-3 pe-4">
						{i18n.get_data('purpose', request)}&nbsp;-&nbsp;{request.categ !== '' ? i18n.get_data('categ', request) : i18n.get_data('categ_basic_name', request)}<br />
					</h4>
				</div>
				
				<Tooltip title={i18n.t('code')} placement="top">
					<h5 className="d-inline-block font-xssss mb-2 text-grey-500 fw-600 mr-5">
						<i className="ti-info-alt"></i> {request.id}
					</h5>
				</Tooltip>|
				<Tooltip title={i18n.t('date_added')} placement="top">
					<h6 className="d-inline-block text-grey-900 font-xssss text-dark mr-5"><i className='ml-5 mr-5 ti-calendar'></i>{request.date}</h6>
				</Tooltip>|
				<Tooltip title={i18n.t('date_modified')} placement="top">
					<h6 className="d-inline-block text-grey-900 font-xssss text-dark"><i className='ml-5 mr-5 ti-pencil'></i>{Utils.getMomentText(request.mod_date)}</h6>
				</Tooltip>
				<h5 className="font-xssss mb-2 text-grey-500 fw-600">
					<span className="text-grey-900 font-xssss text-dark">{i18n.t('price')} : </span> {request.price_min}€&nbsp;-&nbsp;{request.price_max}€
				</h5>
				<h5 className="font-xssss mb-2 text-grey-500 fw-600">
					<span className="text-grey-900 font-xssss text-dark">{i18n.t('sqm')} : </span> {request.sq_min}&nbsp;-&nbsp;{request.sq_max}
				</h5>
				<div className='mb-5'>
						 <h6 className={`d-inline-block p-2 alert-secondary text-secondary fw-600 font-xssss rounded-5 me-2`}>{i18n.get_data('drawn_locations', request)}</h6>
						
					
					
					&nbsp;&nbsp;
					{/* <Tooltip title={i18n.t('office_code')} placement="top">
						<h5 className="d-inline-block font-xssss mb-2 text-grey-500 fw-600">
							<i className="feather-home"></i> {request.code}
						</h5>
					</Tooltip> */}
				</div>
				{
					request.categ_basic === '2'
						? parseInt(request.rooms) > 0 ?
							<h5 className="font-xssss mb-2 text-grey-500 fw-600">
								<span className="text-grey-900 font-xssss text-dark">{i18n.t('spaces')} : </span> {request.rooms}
							</h5> : ''
						: ''
				}
				{
					request.categ_basic === '1'
						? parseInt(request.rooms) > 0 ?
							<h5 className="font-xssss mb-2 text-grey-500 fw-600">
								<span className="text-grey-900 font-xssss text-dark">{i18n.t('bedrooms')} : </span> {request.rooms}
							</h5> : ''
						: ''
				}
				<div className="font-xssss mb-2 text-grey-500 fw-600">
					{
						request.published_on.networks && request.published_on.networks.length > 0 
						? 
							<div>
								<i className="mr-5 font-xsssss btn-round-xs bg-success ti-check text-white"></i>
								<span className="text-grey-900 font-xssss text-dark">{i18n.t('published_net')}: </span>
								{
									request.published_on.networks.map( (network, index) => {
										return <h6 key={index} className={`d-inline-block p-1 alert-primary text-secondary fw-600 font-xssss rounded-5 me-2`}>{network.networks_name}</h6>
									})
								}
							</div>
						: <div>
							<i className="mr-5 font-xsssss btn-round-xss bg-danger text-white"></i>
							<span className="text-grey-900 font-xssss text-dark">{i18n.t('no_published_net')} </span>
						  </div>
					}
					
				</div>
				<div className='position-absolute bottom-15 mb-2 right-15 actions-request'>
					<button onClick={() => this.props.onEditNetwork(request)} className="rounded-xl border-none p-sm-2 mr-2 bg-primary text-white font-xssss">
						{i18n.t('publish')}
					</button>
					<Link to={`/edit_request/${request.id}`} className="d-inline-block rounded-xl border-none p-sm-2 mr-2 bg-primary-gradiant text-white font-xssss">
						{i18n.t('edit')} <FontAwesomeIcon icon={faAngleRight} />
					</Link>
					{
						request.crossing_count > 0 &&
						<Link to={`/listings/cross/${request.id}`} className="d-inline-block rounded-xl border-none p-sm-2 mr-2 bg-success text-white font-xssss">
						<span className='cross-counter'>{request.crossing_count}</span> {request.crossing_count > 1 ? i18n.t('crossings') : i18n.t('crossing')} 
						</Link>
					}
					<button onClick={() => {
						this.props.onDelete(request)
					}} className=" rounded-xl h-37 border-none p-sm-2 mr-2 bg-danger text-white font-xssss">
						<FontAwesomeIcon icon={faTrash} />
					</button>
				</div>
				
				
			</div>
		);
	}
}

export default RequestEditCard;