import React,{Component} from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import request from '../request';
import socket from '../socket';
import storage from '../storage';
import chat from '../chat';
import notificationsHelper from '../notifications-helper';

class FriendRequestsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requests: [],
    };
  }

  componentDidMount() {
    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    this.onSocketEventUnlisten = socket.onEvent((data) => {
      if (data.class === 'friend-request' || data.type == '1') {
        this.getFriendRequests().then(() => {
          this.forceUpdate();
        });
      }
    });

    this.notifOnChange = notificationsHelper.onChange(() => {
      this.getFriendRequests().then(() => {
        this.forceUpdate();
        chat.reinit()
      });
    })

    this.getFriendRequests();
  }

  componentWillUnmount() {
    this.notifOnChange()
    this.onSocketEventUnlisten();
  }

  getFriendRequests() {
    return request.get(`connections/list/requests`).then((response) => {
      if (response.status === 200) {
        this.setState({
          requests: response.data.data,
        });
      }
      
    });
  }

  respondToFriendRequest(friendRequestId, senderId, requestResponse) {
    request.post(`connections/id/${friendRequestId}/response/${requestResponse}`).then((response) => {
      if (response.data.status === '1') {
        const r = this.state.requests.slice(0);
        const friendRequestIndex = r.findIndex((r) => r.id === friendRequestId);
        r.splice(friendRequestIndex, 1);
        this.setState({
          ...this.state,
          requests: r,
        });

        if (requestResponse === 'accept' && storage.me) {
          socket.send({
            type: 'event',
            data: {
              class: 'friend-accepted',
              realtor: senderId,
              me: `${storage.me.realtors_fname} ${storage.me.realtors_lname}`,
            },
          });
          chat.reinit()
        }
      }
    });
  }

  render() {
    return (
      <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
        <div className="card-body d-flex align-items-center p-4">
          <h4 className="fw-700 mb-0 font-xssss text-grey-900">
            {i18n.t('friend_request')}
          </h4>
          <Link to={'/friendrequests'} className="fw-600 ms-auto font-xssss text-primary"><span>{i18n.t('see_all')}</span></Link>
        </div>

        {this.state.requests.map((data , index) => (
        <div className="wrap" key={index}>
          <div className="card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0">
            <figure className="avatar me-3"><img src={`${data.profile_image}`} alt="avater" className="shadow-sm rounded-circle w45" /></figure>
            <h4 className="fw-700 text-grey-900 font-xssss mt-1">
              <Link to={`members/${data.sender_id}`}>{data.fname}&nbsp;{data.lname}</Link>
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                {
                  data.mutual_friends_count === 0
                    ? i18n.t('no_mutual_friends')
                    : `${data.mutual_friends_count} ${i18n.t('mutual_friends')}`
                }
                
              </span>
            </h4>
          </div>
          <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
            <a
              className="p-2 lh-20 w100 bg-success me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl"
              onClick={() => this.respondToFriendRequest(data.id, data.sender_id, 'accept')}
            >
              {i18n.t('Αποδοχή')}
            </a>
            <a
              className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl"
              onClick={() => this.respondToFriendRequest(data.id, data.sender_id, 'reject')}
            >
              {i18n.t('Απόρριψη')}
            </a>
          </div>
        </div>
        ))}

        {
          this.state.requests.length === 0 && (
            <div style={{padding: '24px', textAlign: 'center'}}>
              {i18n.t('no_friend_requests')}
            </div>
          )
        }
      </div>
    );
  }
}

export default FriendRequestsList;