import React, {useState, Component , Fragment } from "react";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import axios from "axios";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import i18n from "../i18n";
import '../css/style.css';

function Socialaccount(){

    const {register, handleSubmit, formState: { errors },} = useForm();
    const onSubmit = (data) => {};

    const [user, setUser] = useState({       
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        skype: '',
        google: '',
    });

    const updateFacebook = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const updateInstagram = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const updateTwitter = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const updateLinkedin = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const updateSkype = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const updateGoogle = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const [error, setError] = useState({message: "", isError: false});

    const submitHandler = async (user, e) => {
        e.preventDefault();
        try {
            const {facebook, instagram, twitter, linkedin, skype, google} = user;
            setError({message: "", isError: false });           
                const resp = await axios({
                method: "POST",
                url: `http://localhost:3000/api/socialmedia`,
                headers: {
                "Content-Type": "application/json",
                },
                data: {facebook, instagram, twitter, linkedin, skype, google,}, });
        } catch (error) {
            setError({message: error.message, isError: true});
        }        
    };

        return (
            <Fragment> 
                <div className="main-wrapper">
                    <Header />
                    <Leftnav />
                    <Rightchat />
                    <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            
                        <div className="middle-sidebar-bottom">
                            <div className="middle-sidebar-left">
                                <div className="middle-wrap">
                                    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                        <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to="/settings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                            <h1 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">{i18n.t('social_account')}</h1>
                                        </div>
                                        <div className="card-body p-lg-5 p-4 w-100 border-0">
                                            <form onSubmit={handleSubmit(submitHandler)}>
                                                <div className="row">
                                                    {/* FACEBOOK */}
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-group">
                                                            <label className="mont-font fw-600 font-xsss">Facebook</label>
                                                            <input type="text" 
                                                            name="facebook"
                                                            id="facebook"
                                                            className="form-control" 
                                                            defaultValue={user.facebook}
                                                            {...register("facebook", {minLength: 2, pattern: /[a-zA-Z0-9\u0370-\u03ff\u1f00-\u1fff_#@/&+-]+$/})}
                                                            onChange={updateFacebook}  />
                                                            {errors.facebook?.type === "pattern" && <p className="error_message">{i18n.t('first_name_error')}</p>}
                                                        </div>        
                                                    </div>

                                                    {/* INSTAGRAM */}
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-group">
                                                            <label className="mont-font fw-600 font-xsss">Instagram</label>
                                                            <input type="text" 
                                                            name="instagram"
                                                            id="instagram"
                                                            className="form-control" 
                                                            defaultValue={user.instagram}
                                                            {...register("instagram", {minLength: 2, pattern: /[a-zA-Z0-9\u0370-\u03ff\u1f00-\u1fff_#@/&+-]+$/})}
                                                            onChange={updateInstagram}  />
                                                            {errors.instagram?.type === "pattern" && <p className="error_message">{i18n.t('first_name_error')}</p>}
                                                        </div>        
                                                    </div>                            
                                                </div>

                                                <div className="row">
                                                    {/* TWITTER */}
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-group">
                                                            <label className="mont-font fw-600 font-xsss">Twitter</label>
                                                            <input type="text" 
                                                            name="twitter"
                                                            id="twitter"
                                                            className="form-control" 
                                                            defaultValue={user.twitter}
                                                            {...register("twitter", {minLength: 2, pattern: /[a-zA-Z0-9\u0370-\u03ff\u1f00-\u1fff_#@/&+-]+$/})}
                                                            onChange={updateTwitter}  />
                                                            {errors.twitter?.type === "pattern" && <p className="error_message">{i18n.t('first_name_error')}</p>}
                                                        </div>        
                                                    </div>

                                                    {/* LINKEDIN */}
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-group">
                                                            <label className="mont-font fw-600 font-xsss">LinkedIn</label>
                                                            <input type="text" 
                                                            name="linkedin"
                                                            id="linkedin"
                                                            className="form-control" 
                                                            defaultValue={user.linkedin}
                                                            {...register("linkedin", {minLength: 2, pattern: /[a-zA-Z0-9\u0370-\u03ff\u1f00-\u1fff_#@/&+-]+$/})}
                                                            onChange={updateLinkedin}  />
                                                            {errors.linkedin?.type === "pattern" && <p className="error_message">{i18n.t('first_name_error')}</p>}
                                                        </div>        
                                                    </div>
                                                </div>
                                                   
                                                {/* <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-group">
                                                            <label className="mont-font fw-600 font-xsss">Flickr</label>
                                                            <input type="text" 
                                                            name="flickr" 
                                                            className="form-control" 
                                                            {...register('flickr')}  />
                                                        </div>        
                                                    </div>

                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-group">
                                                            <label className="mont-font fw-600 font-xsss">Github</label>
                                                            <input type="text" 
                                                            name="github" 
                                                            className="form-control" 
                                                            {...register('github')}  />
                                                        </div>        
                                                    </div>
                                                </div> */}

                                                <div className="row">
                                                    {/* SKYPE */}
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-group">
                                                            <label className="mont-font fw-600 font-xsss">Skype</label>
                                                            <input type="text" 
                                                            name="skype"
                                                            id="skype"
                                                            className="form-control" 
                                                            defaultValue={user.skype}
                                                            {...register("skype", {minLength: 2, pattern: /[a-zA-Z0-9\u0370-\u03ff\u1f00-\u1fff_#@/&+-]+$/})}
                                                            onChange={updateSkype}  />
                                                            {errors.skype?.type === "pattern" && <p className="error_message">{i18n.t('first_name_error')}</p>}
                                                        </div>        
                                                    </div>

                                                    {/* GOOGLE */}
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-group">
                                                            <label className="mont-font fw-600 font-xsss">Google</label>
                                                            <input type="text" 
                                                            name="google"
                                                            id="google"
                                                            className="form-control" 
                                                            defaultValue={user.google}
                                                            {...register("google", {minLength: 2, pattern: /[a-zA-Z0-9\u0370-\u03ff\u1f00-\u1fff_#@/&+-]+$/})}
                                                            onChange={updateGoogle}  />
                                                            {errors.google?.type === "pattern" && <p className="error_message">{i18n.t('first_name_error')}</p>}
                                                        </div>        
                                                    </div>
                                                </div>
                                                
                                                <div className="row">
                                                    {/* SUBMIT BUTTON */}
                                                    <div className="col-lg-12 mb-0 mt-2">
                                                        <button type='submit' className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">{i18n.t('save')}</button>
                                                    </div>
                                                </div>                                          
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>                               
                        </div>            
                    </div>
                </div>

                <Popupchat />
                <Appfooter />            
            </Fragment>
        );    
}

export default Socialaccount;