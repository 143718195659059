import React, { Fragment, useEffect, useState } from "react";
import request from "../request";
import ModalGeneric from "./ModalGeneric";
import i18n from "../i18n";
import Select from 'react-select';
import Swal from "sweetalert2";

const ReportUser = (props) => {
	const [reportTypes, setReportTypes] = useState([])
	const [selectedReportType, setSelectedReportType] = useState(1)
	const [description, setDescription] = useState('')

	useEffect(() => {
		if (props.blamed_id) {
			request.get('report/types').then(response => {
				if (response.status === 200) {
					setReportTypes(response.data)
				}
			})
		} 
	}, [])

	const setReport = () => {
		request.post('report/set', {
			blamed_id: props.blamed_id,
			type: selectedReportType,
			message: description
		}).then(response => {
			if (response.status === 200) {
				Swal.fire({
					title: i18n.t('success'),
					text: i18n.t('we_receiver_your_report_thank_you'),
					icon: 'success'
				}).then(() => {
					props.closeForm()
				})
			}
			else{
				Swal.fire({
					title: i18n.t('warning'),
					text: i18n.t('it_seems_you_already_reported_this_user'),
					icon: 'warning'
				})
			}
		})
	}

	const handleClose = () => {
		props.closeForm()
	}

	return (
		props.blamed_id ? 
		<Fragment>
			<ModalGeneric
				show={props.showForm}
				handleClose={handleClose}
				header={<h4>{i18n.t('report_of_user')} {props.user}</h4>}
				footer={<div className="card-body p-0 d-flex space-between open-house-footer align-center">
								<div className="d-flex">
									<button onClick={handleClose} className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">{i18n.t('cancel')}</button>
									<button onClick={setReport} className="bg-primary text-white btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">{i18n.t('send_lower')}</button>
								</div>
							</div>}
			>
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="form-group">
								<label className="mb-2">{i18n.t('report_reason')}</label>
								<Select 
									className="select-element"
									classNamePrefix="select"
									options={reportTypes.map(type => ({
										value: type.id,
										label: i18n.get_data('type_name', type)
									}))}
									placeholder={i18n.t('select_report_reason')}
									value={reportTypes.filter(r => parseInt(r.id) === parseInt(selectedReportType)).map(r => ({
										value: r.id,
										label: i18n.get_data('type_name', r)
									}))[0]}
									onChange={e => setSelectedReportType(e.value)}
								/>
							
							</div>
						</div>
						<div className="col-md-12 pt-4">
							<div className="form-group">
								<label className="mb-2">{i18n.t('report_description')}</label>
								<textarea rows="6" value={description} onChange={(e) => setDescription(e.target.value)} className="form-control mh-125" placeholder={i18n.t('describe_your_reason_of_report_in_a_few_words')}/>
								<p className="error_message d-none">*{i18n.t('required_field')}</p>
							</div>
						</div>

					</div>
				</div>
				

			</ModalGeneric>
		</Fragment> : ''
	)
}

export default ReportUser;