import React,{Component, createRef} from 'react';
import { Link , NavLink } from 'react-router-dom';

import Darkbutton from '../components/Darkbutton';
import NotificationItem from '../components/NotificationItem';

import i18n from '../i18n';
import '../css/header.css';
import notificationsHelper from '../notifications-helper';
import NotificationFriendRequestItem from './NotificationFriendRequestItem';
import NotificationFriendRequestAcceptedItem from './NotificationFriendRequestAcceptedItem';
import NotificationNetworkRequestItem from './NotificationNetworkRequestItem';
import NotificationCooperationRequestItem from './NotificationCooperationRequestItem';
import NotificationCooperationAcceptedItem from'./NotificationCooperationAcceptedItem';
import NotificationCooperationCompletedItem from './NotificationCooperationCompletedItem';
import request from '../request';
import storage from '../storage';
import settings from '../settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faDoorOpen, faHandshakeSlash, faThumbsUp, faBug, faUserCircle, faHouseSignal, faHeadset, faMagnifyingGlass, faGlobe, faMagnifyingGlassLocation } from '@fortawesome/free-solid-svg-icons';
import { faHandshake as farHandshake } from '@fortawesome/free-regular-svg-icons';
import history from '../helpers/history';
import socket from '../socket';
import Tour from 'reactour';
import NotificationSubmitCordes from './NotificationSubmitCordes';

class Header extends Component {
  constructor(props) {
    super(props);

    let isMobile = window.matchMedia("(max-width: 992px)");
    
    this.profileMenuRef = React.createRef(null)
    this.searchResultsRef = React.createRef(null)
    this.searchResultsRefMobile = React.createRef(null)
    this.leftMenuRef = React.createRef(null)
    this.stepSixth = React.createRef(null)
    this.stepFifth = React.createRef(null)
    this.notificationsRef = React.createRef(null)

    this.state = {
      isOpen: false,
      isActive: false,
      notificationsDropdownOpen: false,
      notifications: [],
      cooperations: {},
      new_message: false,
      menu: false,
      query: '',
      searchResults: {
        realtors: [],
        networks: []
      },
      searchType: 'realtors',
      showSearchResults: false,
      isTourOpen: (localStorage.getItem('saw_tutorial') == 1 || isMobile.matches) ? false : true,
    };
    
    this.handleSearchChange = this.handleSearchChange.bind(this)


    this.steps = [
      {
        selector: '.feather-bell',
        content: i18n.t('step1'),
      },
      {
        selector: '[data-tut="reactour_second_step"]',
        content: i18n.t('step2'),
      },
      {
        selector: '[data-tut="reactour_third_step"]',
        content: i18n.t('step3'),
      },
      {
        selector: '[data-tut="reactour_forth_step"]',
        content: i18n.t('step4'),
      },
      {
        selector: '[data-tut="reactour_fifth_step"]',
        content: i18n.t('step5'),
      },
      {
        selector: '[data-tut="reactour_sixth_step"]',
        content: i18n.t('step6'),
      },
    ]

   this.accentColor = '#5cb7b7';
  }

  componentDidMount() {
    this.unlistenLocaleChange = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    this.windowClick = (e) => {
      if (this.profileMenuRef.current && !this.profileMenuRef.current.contains(e.target) && this.state.menu === true) {
        this.setState({
          menu: false
        })
      }

      if (this.searchResultsRef.current && !this.searchResultsRef.current.contains(e.target) && this.searchResultsRefMobile.current && !this.searchResultsRefMobile.current.contains(e.target) && this.state.showSearchResults === true) {
        this.setState({
          showSearchResults: false
        })
      }

      if (this.notificationsRef.current && !this.notificationsRef.current.contains(e.target) && this.state.notificationsDropdownOpen === true) {
        this.setState({
          notificationsDropdownOpen: false
        })
      }
      
    }

    window.addEventListener('click', this.windowClick)

    storage.onChange((key, value) => {
      if (key === 'me') {
        this.forceUpdate();
      }
    });

    settings.onChange(() => {
      this.setState({
        new_message: settings.messageNotify
      })
    });

    notificationsHelper.onChange(() => {
      this.setState({
        notifications: notificationsHelper.notifications,
      }, () => {
        console.log(this.state.notifications)
      });
    });

    notificationsHelper.onChangeCoop(() => {
      this.setState({
        cooperations: notificationsHelper.cooperation_counter,
      });
    });

    this.setState({
      notifications: notificationsHelper.notifications,
      cooperations: notificationsHelper.cooperation_counter,
    });

    

  }

  handleSearchChange(e) {
    const query = e.target.value;
    this.setState({
      
      query: query
    })
    if (query.length > 2) {
      request.get(`search/${encodeURI(query)}`).then(response => {
        if (response.status === 200) {
          this.setState({
          
            searchResults: response.data
          })
        }
        
      })
    }


  }

  componentWillUnmount() {
    this.unlistenLocaleChange()
    window.removeEventListener('click', this.windowClick)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.notificationsDropdownOpen === true && this.state.notificationsDropdownOpen === false) {
      const unreadNotifications = notificationsHelper.notifications.filter((n) => n.status === '0');
      if (unreadNotifications.length > 0) {
        request.post('notifications', {
          notificationsToRead: unreadNotifications.map((n) => n.id),
        }).then(() => {
          notificationsHelper.refresh();
        });
      }
    }
  }

  onLogout() {
    const theme = localStorage.getItem('theme')
    localStorage.clear()
    storage.set('me', null)
    notificationsHelper.clear()
    socket.disconnect() 

    if (theme) {
      localStorage.setItem('theme', theme)
    }
    history.push('/login')
  }

  removeNotification(notification) {
    const n = this.state.notifications.slice(0);
    const notificationToRemoveIndex = n.findIndex((n) => n.id === notification.id);
    if (notificationToRemoveIndex > -1) {
      n.splice(notificationToRemoveIndex, 1);
      this.setState({
        ...this.state,
        notifications: n,
      });
    }
  }

  updateNotificationStatus(notification, status) {
    const n = this.state.notifications.slice(0);
    const notificationChangedIndex = n.findIndex((n) => n.id === notification.id);
    if (notificationChangedIndex > -1) {
      n[notificationChangedIndex].status = status;
      this.setState({
        ...this.state,
        notifications: n,
      });
    }
  }

  updateNotification = (notification, status, type) => {
    const n = this.state.notifications.slice(0);
    const notificationChangedIndex = n.findIndex((n) => n.id === notification.id);
    if (notificationChangedIndex > -1) {
      n[notificationChangedIndex].status = status;
      this.setState({
        notifications: n,
      });
    }
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  toggleActive = () => this.setState({ isActive: !this.state.isActive, showSearchResults: false });
  toggleisNoti = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    this.setState({ notificationsDropdownOpen: !this.state.notificationsDropdownOpen })
    settings.set('sideContactsListOpen', false);
  };

  toggleChatContactsWindow = (e) => {
    e.stopPropagation()
    e.preventDefault()
    e.nativeEvent.stopImmediatePropagation();
    settings.set('sideContactsListOpen', !settings.sideContactsListOpen);
    settings.set('messageNotify', false);
    this.setState({ notificationsDropdownOpen: false })
  }

   
  closeTour = () => {
    this.leftMenuRef.current.style.overflowY = 'scroll'
    localStorage.setItem('saw_tutorial', 1)
    request.post('user_saw_tour')
    this.setState({ isTourOpen: false });
  };
  
  disableBody = () => {
    this.leftMenuRef.current.style.overflowY = 'hidden'
  }

  scrollLeftMenu = (step) => {
    if(step === 5) {
      this.stepSixth.current.scrollIntoView()
    }
    if(step === 4) {
      this.stepFifth.current.scrollIntoView()
    }
  }
  enableBody = () => {
    this.leftMenuRef.current.style.overflowY = 'scroll'
    localStorage.setItem('saw_tutorial', 1)
  }

  render() {
    const navClass = `${this.state.isOpen ? " nav-active" : ""}`;
    const buttonClass = `${this.state.isOpen ? " active" : ""}`;
    const searchClass = `${this.state.isActive ? " show" : ""}`;
    const notiClass = `${this.state.notificationsDropdownOpen ? " show" : ""}`;
    const searchData = this.state.searchResults[this.state.searchType]
    const showSearch = this.state.showSearchResults && this.state.query.length > 2

    return (
      <div className="nav-header bg-white shadow-xs border-0">
        <Link to="/report_a_bug" className='report-a-bug'><FontAwesomeIcon icon={faBug}/></Link>
               
        {
          this.state.isTourOpen &&
          <Tour
          steps={this.steps}
          isOpen={this.state.isTourOpen}
          onRequestClose={this.closeTour} 
          onAfterOpen={this.disableBody}
          rounded = {5}
          className = 'helperpopup'
          accentColor = {this.accentColor}
          disableDotsNavigation
          inViewThreshold = '20px'
          showNavigation = {false}
          getCurrentStep = {(e) => this.scrollLeftMenu(e)}
          lastStepNextButton = {
            <button className='btn-action btn-primary p-2 rounded-3 font-xsssss text-uppercase fw-700 ls-3' onClick={this.closeTour} >{i18n.t('i_understand')}</button>
          } 
          />
        }
         
        <div className="nav-top">
          <Link to="/home" className="app-logo logo-light">
            <img src="/assets/images/RealStatus_logo.png" alt="Real Status Logo Symbol" height="70" />
          </Link>
          <Link to="/home" className="app-logo logo-dark">
            <img src="/assets/images/RealStatus_logo_white.png" alt="Real Status Logo Symbol" height="70" />
          </Link>
          <div className='nav-left-side'>
            <span onClick={this.toggleActive} className="me-2 menu-search-icon mob-menu"><i className="feather-search text-grey-900 font-sm btn-round-md bg-greylight" style={{cursor: 'pointer'}}></i></span>
            <button onClick={this.toggleOpen} className={`nav-menu me-0 ms-2 ${buttonClass}`}></button>
          </div>
        </div>
          
        <form action="#" className="float-left p-relative header-search ms-3" ref={this.searchResultsRef}>
          <div className="form-group mb-0 icon-input">
            <i className="feather-search font-sm text-grey-400"></i>
            <input 
              type="text" 
              placeholder={i18n.t('page_search_input_placeholder')} 
              onFocus={() => this.setState({showSearchResults: true})} 
              onChange={(e) => this.handleSearchChange(e)} value={this.state.query} 
              className={`${showSearch ? 'active' : ''} placeholder-14 header-search-input bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg`} 
            />
          </div>
          
            <div className={`header-search-results theme-dark-bg scroll-bar ${showSearch ? '' : 'hidden'}`}>
              <div className='tabs theme-dark-bg'>
                <span onClick={() => this.setState({searchType: 'realtors'})} className={`tab ${this.state.searchType === 'realtors' ? 'active' : ''}`}>{i18n.t('people')}</span>
                <span onClick={() => this.setState({searchType: 'networks'})} className={`tab ${this.state.searchType === 'networks' ? 'active' : ''}`}>{i18n.t('networks')}</span>
                
              </div>
              {
                searchData.length > 0 ?
                  searchData.map( (r, index) => {

                    if (this.state.searchType === 'realtors') {
                      return  <Link onClick={() => this.setState({showSearchResults: false})} to={`/members/${r.id}`} key={`result-${index}`}>
                                <img src={r.profile_image}/> 
                                <span>{r.fname} {r.lname}</span>
                              </Link>
                    }
                    else {
                      return  <Link onClick={() => this.setState({showSearchResults: false})} to={`/network/${r.id}`} key={`result-${index}`}>
                                <img src={r.image}/> 
                                <span>{r.name}</span>
                              </Link>
                    }
                    
                  }) :
                  <div className='no-results'>{i18n.t('no_results')}</div>
              }
            </div>
        </form>

        {/* <NavLink activeClassName="active" to="/home" className="p-2 text-center ms-3 menu-icon center-menu-icon"><i className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
         */}
        <span className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`} id="dropdownMenu3" data-bs-toggle="dropdown" aria-expanded="false" onClick={this.toggleisNoti}>
          {
           ( this.state.notifications.some((n) => n.status === '0') || (storage.me && ( storage.me.realtors_lat == '' && storage.me.realtors_long == '')) ) && <span className="dot-count bg-warning"></span>
          }
          <i className="feather-bell font-xl text-current"></i>
        </span>

        <div ref={this.notificationsRef} className={`notifications-dropdown user-select-none dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${notiClass}`} aria-labelledby="dropdownMenu3">
          <h4 className="fw-700 font-xss mb-4">{i18n.t('notifications')}</h4>
          {(this.state.notifications.length === 0 && (!storage.me || ( storage.me.realtors_lat != '' && storage.me.realtors_long != ''))) && 
            <h4 className='font-xsss text-grey-900'>{i18n.t('no_notifications_message')}</h4>
          }
          {
            (storage.me && ( storage.me.realtors_lat == '' && storage.me.realtors_long == '')) &&
            <NotificationSubmitCordes />
          }
          
          {
            this.state.notificationsDropdownOpen &&
            this.state.notifications.map((notification, i) => {
              let notificationComponent;
              if (notification.type === '1') {
                notificationComponent = <NotificationFriendRequestItem
                  key={i}
                  data={notification}
                  onDelete={() => this.removeNotification(notification)}
                  onStatusChange={(status, type) => {
                    this.updateNotification(notification, status, type)
                  }}
                />;
              } else if (notification.type === '2') {
                notificationComponent = <NotificationNetworkRequestItem
                  key={i}
                  data={notification}
                  onDelete={() => this.removeNotification(notification)}
                  onStatusChange={(status) => this.updateNotificationStatus(notification, status)}
                />;
              } else if (notification.type === '3') {
                notificationComponent = <NotificationItem key={i} data={notification} />;
              } else if (notification.type === '4') {
                if (typeof notification.data.cooperation !== 'undefined')
                  notificationComponent = <NotificationCooperationRequestItem
                    key={i}
                    realtor={{
                      avatar: notification.avatar,
                      name: notification.name,
                    }}
                    data={notification}
                    onDelete={() => this.removeNotification(notification)}
                    onStatusChange={(status) => this.updateNotificationStatus(notification, status)}
                  />;
              } else if (notification.type === "5") {
                if (typeof notification.data.cooperation !== 'undefined')
                  notificationComponent = <NotificationCooperationAcceptedItem
                    key={i}
                    realtor={{
                      avatar: notification.avatar,
                      name: notification.name,
                    }}
                    data={notification}
                    onDelete={() => this.removeNotification(notification)}
                    onStatusChange={(status) => this.updateNotificationStatus(notification, status)}
                  />;
              } else if (notification.type === '6') {
                  notificationComponent = <NotificationFriendRequestAcceptedItem
                    key={i}
                    realtor={{
                      avatar: notification.avatar,
                      name: notification.name,
                    }}
                    data={notification}
                    onDelete={() => this.removeNotification(notification)}
                    onStatusChange={(status) => this.updateNotificationStatus(notification, status)}
                  />;
              } else if (notification.type === "9") {
                if (typeof notification.data.cooperation !== 'undefined')
                  notificationComponent = <NotificationCooperationCompletedItem
                    key={i}
                    realtor={{
                      avatar: notification.avatar,
                      name: notification.name,
                    }}
                    data={notification}
                    onDelete={() => this.removeNotification(notification)}
                    onStatusChange={(status) => this.updateNotificationStatus(notification, status)}
                  />;
              }

              return notificationComponent;
            })
          }
        </div>

        <span
          className="pointer p-2 text-center ms-3 menu-icon"
          onClick={this.toggleChatContactsWindow}
        >
          {
            this.state.new_message && <span className="dot-count bg-warning"></span>
          }
          <i className="feather-message-square font-xl text-current"></i>
        </span>

        <span
          className="pointer p-2 text-center ms-3 menu-icon btn-locale"
          onClick={() => {
            if(i18n.getLocale() === 'en')
                i18n.setLocale('el')
            else
                i18n.setLocale('en')
          }}
        >
            <i className="feather-globe"></i>
        </span>
        {storage.me &&
        <div ref={this.profileMenuRef} className='p-relative'>
          <span className="p-0 ms-3 menu-icon" onClick={() => {
            this.setState({
              ...this.state,
              menu: !this.state.menu
            })
          }}>
            <img src={`${storage.me.profile_image}` || "/assets/images/user.png"} alt="user" className="shadow-sm rounded-circle w40 mt--1" />
          </span>
          <div className={`btn-menu`}>
            <div className={`nav-sub-menu nav-sub-menu-left ${this.state.menu ? 'open' : 'd-none'}`}>
              <Link to={`/profile`}
                className="sub-menu-item text-default"
              >
                <FontAwesomeIcon icon={faUserCircle} />  
                <span>{i18n.t('my_profile')}</span>
              </Link>
              <Link to="/settings" 
                className="sub-menu-item text-default"
              >
                <i className="feather-settings"></i>
                <span>{i18n.t('settings')}</span>
              </Link>
              <Link to="/help"
                className="sub-menu-item text-default"
              >
                <FontAwesomeIcon icon={faHeadset} />
                <span>{i18n.t('help_n_support')}</span>
              </Link>
              <Darkbutton className="sub-menu-item text-default" title_light="Dark mode" title_dark="Light mode"/>
              <hr/>
              <div onClick={() => this.onLogout()}
                className="sub-menu-item text-default"
              >
                <FontAwesomeIcon icon={faDoorOpen} />  
                <span>{i18n.t('logout')}</span>
              </div>
              
            </div>
          </div>
        </div>
        }
        <nav className={`navigation scroll-bar ${navClass}`} ref={this.leftMenuRef}>
          <div className="container ps-0 pe-0">
            <div className="nav-content">
              <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
                <h2 className="nav-caption fw-600 font-xssss text-grey-500">{i18n.t('menu')}</h2>
                <ul className="mb-1 top-content">
                    <li className="logo d-none d-xl-block d-lg-block"></li>
                  {storage.me &&
                    <li>
                      <Link to="/profile" className="nav-content-bttn open-font">
                      <img src={`${storage.me.profile_image}` || "/assets/images/user.png"} alt="profile_image" className="shadow-sm rounded-circle w45 me-3" />
                      <span>{storage.me.realtors_lname} {storage.me.realtors_fname}</span>
                      </Link>
                    </li>
                    }
                    {
                    // storage.me &&
                      // <li><Link to={`/emails`} className="nav-content-bttn open-font"><i className="feather-mail btn-round-md bg-primary-gradiant me-3"></i><span>{i18n.t('email')}</span></Link></li>
                    }
                    <div data-tut="reactour_second_step">
                      {storage.me &&
                        <li><Link to={`/listings`} className="nav-content-bttn open-font"><i className="feather-home btn-round-sm bg-mini-gradiant me-3"></i><span>{i18n.t('properties')}</span></Link></li>
                      }
                      {storage.me &&
                        <li><Link to={`/requests`} className="nav-content-bttn open-font"><i className="feather-search btn-round-sm bg-mini-gradiant me-3"></i><span>{i18n.t('requests')}</span></Link></li>  
                      }
                    </div>
                    <div data-tut="reactour_third_step">
                      <li><Link to="/networks" className="nav-content-bttn open-font"><i className="feather-globe btn-round-sm bg-green-gradiant me-3"></i><span>{i18n.t('networks')}</span></Link></li>
                      <li><Link to="/friends" className="nav-content-bttn open-font"><i className="feather-users btn-round-sm bg-primary-gradiant me-3"></i><span>{i18n.t('friends')}</span></Link></li>
                      <li><Link to="/social_members" className="nav-content-bttn open-font"><i className="feather-user-plus btn-round-sm bg-green-gradiant me-3"></i><span>{i18n.t('social_members_title')}</span></Link></li>
                    </div>
                </ul>
              </div>
              <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2" data-tut="reactour_forth_step">
                <h2 className="nav-caption fw-600 font-xssss text-grey-500" >{i18n.t('colaborations')}</h2>
                <ul className="mb-1">
                  <li className="logo d-none d-xl-block d-lg-block"></li>
                  <li className='cooperation-menu'>
                    <Link to="/collabs/active" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-zap me-3 text-grey-500"></i><span>{i18n.t('Ενεργές συνεργασίες')}</span></Link>
                    {this.state.cooperations.active > 0 && 
                      <span className="dot-count bg-success cooperation-header-notify">{this.state.cooperations.active}</span>
                    }
                  </li>
                  <li className='cooperation-menu'>
                    <Link to="/collabs/incoming-requests" className="nav-content-bttn open-font h-auto pt-2 pb-2"><FontAwesomeIcon className="font-sm me-3 text-grey-500" icon={farHandshake} swapOpacity /><span>{i18n.t('Εισερχόμενα')}</span></Link>
                      {this.state.cooperations.incoming > 0 && 
                      <span className="dot-count bg-success cooperation-header-notify">{this.state.cooperations.incoming}</span>
                      }
                  </li>
                  <li><Link to="/collabs/outgoing-requests" className="nav-content-bttn open-font h-auto pt-2 pb-2"><FontAwesomeIcon className="font-sm me-3 text-grey-500" icon={faHandshake}/> <span>{i18n.t('Εξερχόμενα')}</span></Link></li>
                  <li><Link to="/collabs/rejects" className="nav-content-bttn open-font h-auto pt-2 pb-2"><FontAwesomeIcon className="font-sm me-3 text-grey-500" icon={faHandshakeSlash}/> <span>{i18n.t('Απορριφθείσες')}</span></Link></li>
                  <li><Link to="/collabs/closed" className="nav-content-bttn open-font h-auto pt-2 pb-2"><FontAwesomeIcon className="font-sm me-3 text-grey-500" icon={faThumbsUp}/> <span>{i18n.t('closed_collaborations')}</span></Link></li>
                  </ul>
              </div>
              <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2" data-tut="reactour_fifth_step">
                <h2 className="nav-caption fw-600 font-xssss text-grey-500" >{i18n.t('manage')}</h2>
                <ul className="mb-1" ref={this.stepFifth}>
                  <li className="logo d-none d-xl-block d-lg-block"></li>
                  <li><Link to="/manage_properties" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-home me-3 text-grey-500"></i><span>{i18n.t('manage_properties')}</span></Link></li>
                  <li><Link to="/manage_requests" className="nav-content-bttn open-font h-auto pt-2 pb-2"><FontAwesomeIcon className="font-sm me-3 text-grey-500" icon={faMagnifyingGlass} swapOpacity /><span>{i18n.t('manage_requests')}</span></Link></li>
                  <li><Link to="/manage_networks" className="nav-content-bttn open-font h-auto pt-2 pb-2"><FontAwesomeIcon className="font-sm me-3 text-grey-500" icon={faGlobe} swapOpacity /><span>{i18n.t('manage_networks')}</span></Link></li>
                  <li><Link to="/manage_searches" className="nav-content-bttn open-font h-auto pt-2 pb-2"><FontAwesomeIcon className="font-sm me-3 text-grey-500" icon={faMagnifyingGlassLocation} swapOpacity /><span>{i18n.t('manage_saved_searches')}</span></Link></li>
                  
                </ul>
              </div>
              <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2" data-tut="reactour_sixth_step">
                <h2 className="nav-caption fw-600 font-xssss text-grey-500">{i18n.t('tools')}</h2>
                <ul className="mb-1" ref={this.stepSixth}>
                  <li className="logo d-none d-xl-block d-lg-block"></li>
                  <li><Link to="/statistics" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-map me-3 text-grey-500"></i><span className='m-0 p-0'>Insights</span><b className="beta-badge">BETA</b></Link></li>
                  <li><Link to="/potential_partners" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-user-plus me-3 text-grey-500"></i><span>{i18n.t('Βρες συνεργάτες')}</span></Link></li>
                  <li><Link to="/comingsoon" className="nav-content-bttn open-font h-auto pt-2 pb-2"><FontAwesomeIcon className="font-sm me-3 text-grey-500" icon={faHouseSignal}/> <span>Open houses</span></Link></li>
                </ul>
              </div>
              <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
                <h2 className="nav-caption fw-600 font-xssss text-grey-500">{i18n.t('account')}</h2>
                <ul className="mb-1">
                  <li className="logo d-none d-xl-block d-lg-block"></li>
                  <li><Link to="/settings" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-settings me-3 text-grey-500"></i><span>{i18n.t('settings')}</span></Link></li>
                  <li><Link to="/terms" target={'_blank'} className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-shield me-3 text-grey-500"></i><span>{i18n.t('terms_of_use')}</span></Link></li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
          
        <div className={`app-header-search ${searchClass}`}>
          <form className="search-form"  ref={this.searchResultsRefMobile}>
            <div className="form-group searchbox mb-0 border-0 p-1">
              <input 
                type="text" 
                placeholder={i18n.t('page_search_input_placeholder')} 
                onFocus={() => this.setState({showSearchResults: true})} 
                onChange={(e) => this.handleSearchChange(e)} value={this.state.query} 
                className={`${showSearch ? 'active' : ''} form-control border-0`} 
              />
              <i className="input-icon">
                <ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline "></ion-icon>
              </i>
              <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                <i className="ti-close font-xs" onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  this.toggleActive()
                }} style={{cursor: 'pointer'}}></i>
              </span>
            </div>
            
            <div className={`header-search-results theme-dark-bg scroll-bar ${showSearch ? '' : 'hidden'}`}>
              <div className='tabs theme-dark-bg'>
                <span onClick={() => this.setState({searchType: 'realtors'})} className={`tab ${this.state.searchType === 'realtors' ? 'active' : ''}`}>{i18n.t('people')}</span>
                <span onClick={() => this.setState({searchType: 'networks'})} className={`tab ${this.state.searchType === 'networks' ? 'active' : ''}`}>{i18n.t('networks')}</span>
                
              </div>
              {
                searchData.length > 0 ?
                  searchData.map( (r, index) => {

                    if (this.state.searchType === 'realtors') {
                      return  <Link onClick={() => this.setState({showSearchResults: false})} to={`/members/${r.id}`} key={`result-${index}`}>
                                <img src={r.profile_image}/> 
                                <span>{r.fname} {r.lname}</span>
                              </Link>
                    }
                    else {
                      return  <Link onClick={() => this.setState({showSearchResults: false})} to={`/network/${r.id}`} key={`result-${index}`}>
                                <img src={r.image}/> 
                                <span>{r.name}</span>
                              </Link>
                    }
                    
                  }) :
                  <div className='no-results'>{i18n.t('no_results')}</div>
              }
            </div>
          </form>
          {/* <form className="search-form">
            <div className="form-group searchbox mb-0 border-0 p-1">
              <input type="text" className="form-control border-0" placeholder={i18n.t('page_search_input_placeholder')} />
              <i className="input-icon">
                <ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline "></ion-icon>
              </i>
              <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                <i className="ti-close font-xs" onClick={this.toggleActive} style={{cursor: 'pointer'}}></i>
              </span>
            </div>
          </form> */}
        </div>

      </div>
    );
  }
}

export default Header;