import React, {useEffect, useState} from 'react';
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  horizontalListSortingStrategy
} from '@dnd-kit/sortable';

import {SortableItem} from './SortableItem';

function SortableList(props) {
    
  const [items, setItems] = useState([]);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        distance: 10,
      },
    })
  );

  useEffect(() => {
    setItems(props.items)
  }, [])
  useEffect(() => {
    if (props.sortByProps) {
      setItems(props.items)
      return;
    }
    const matched_items = []
    items.forEach(item => {
      const temp = props.items.filter(t => t.id === item.id)
      if (temp.length > 0)
        matched_items.push(temp[0])
    })
    props.items.filter(t => matched_items.filter(t1 => t1.id === t.id).length === 0).forEach(t => {
        matched_items.push(t)
    });
    setItems(matched_items)
  }, [props.items])

  useEffect(() => {
    props.onChange(items.map(item => item.id))
  }, [items])

  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext 
        items={items}
        strategy={props.strategy && props.strategy === 'vertical' ? verticalListSortingStrategy : horizontalListSortingStrategy}
      >
        {items.map((item) => <SortableItem showDragButton={true} key={`sortable-item-${item.id}`} id={item.id} item={item.item}/>)}
      </SortableContext>
    </DndContext>
  );
  
  function handleDragEnd(event) {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      setItems((items) => {
        // var oldIndex = 0;
        // var newIndex = 0;
        // items.map((item, i) => {
        //     if (i + 1 === active.id) oldIndex = i
        //     if (i + 1 === over.id) newIndex = i
        // })
        const oldIndex = items.findIndex(item => active.id === item.id);
        const newIndex = items.findIndex(item => over.id === item.id);
        
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    // const oldIndex = items.indexOf(active.id);
    // const newIndex = items.indexOf(over.id);
    // return arrayMove(items, oldIndex, newIndex);
  }
}

export default SortableList