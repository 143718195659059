import React, { Fragment, createRef } from 'react';
import {
  Link,
} from 'react-router-dom';
import Avatar from '../components/Avatar';
import i18n from "../i18n";
import socket from '../socket';
import request from '../request';
import storage from '../storage';

class RealtorCard extends React.Component {
  constructor(props) {
    super(props);

    this.elementRef = createRef(null)
    this.state = {
      metadata: null,
      isMenuOpen: false,
    };

    this.respondToFriendRequest = this.respondToFriendRequest.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  componentDidMount() {
    storage.onChange((key, value) => {
      if (key === 'me') {
        this.forceUpdate();
      }
    });
    window.addEventListener('click', this.onDocumentClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onDocumentClick)
  }

  onDocumentClick(e) {
    if (this.elementRef.current && !this.elementRef.current.contains(e.target) && this.state.isMenuOpen === true) {
      this.setState({
        isMenuOpen: false
      })
    }
  }

  toggleMenuOpen = () => this.setState({ isMenuOpen: !this.state.isMenuOpen });


  deleteFriend(friendshipId) {
    request.post(`connections/id/${friendshipId}/response/delete`).then((response) => {
      if (response.data.status === '1') {
        this.props.onDeleteFriend(friendshipId, response.data);
      }
    });
  }

  addFriend(friendId) {
    request.put(`connections/to/${friendId}`).then((response) => {
      if (response.data.status === '1') {
        this.props.onAddFriend(response.data);

        request.put(`notifications/to/${this.props.data.id}/type/friend-request/ref/${response.data.connection_id}`).then(() => {
          socket.send({
            type: 'event',
            data: {
              class: 'friend-request',
              realtor: this.props.data.id,
            },
          });
        });
      }
    });
  }

  cancelFriendRequest(friendshipId) {
    request.post(`connections/id/${friendshipId}/response/cancel`).then((response) => {
      if (response.data.status === '1') {
        this.props.onDeleteFriend(friendshipId, response.data);
      }
    });
  }

  respondToFriendRequest(friendRequestId, requestResponse) {
    request.post(`connections/id/${friendRequestId}/response/${requestResponse}`).then((response) => {
      if (response.data.status === '1') {
        this.props.onFriendRequestResponse(response.data);
      }
    });
  }

  render() {
    const menuClass = `${this.state.isMenuOpen ? " show" : ""}`;
    return (
      <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
        {(this.props.network_owner || this.props.network_admin) ? 
          <div className="ms-auto" style={{position: 'relative'}} ref={this.elementRef}>
            <div onClick={this.toggleMenuOpen} className={`ms-auto pointer ${menuClass}`} style={{textAlign: 'right'}} id={`dropdownMenu${this.props.data.id}`} data-bs-toggle="dropdown" aria-expanded="false">
              <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
            </div>
            <div className={`dropdown-menu p-3 right-0 rounded-xxl border-0 shadow-lg ${menuClass}`} aria-labelledby={`dropdownMenu${this.props.data.id}`}>
              
              <div onClick={() => {
                if (this.props.onRemoveFromNetwork) this.props.onRemoveFromNetwork(this.props.data)
              }} className="card-body p-0 d-flex">
                <i className="feather-trash-2 text-grey-500 me-2 font-lg"></i>
                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-3 pointer">{i18n.t('button_delete')} <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500 mw-190">{i18n.t('button_delete_realtor_from_network')}</span></h4>
              </div>
              {
                this.props.network_owner ? 
                <>
                  {
                    this.props.is_realtor_admin ? 
                    <div onClick={() => {
                      if (this.props.onRemovePrivileges) this.props.onRemovePrivileges(this.props.data)
                    }} className="card-body p-0 d-flex">
                      <i className="feather-user-minus text-grey-500 me-2 font-lg"></i>
                      <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-3 pointer">{i18n.t('button_remove_privileges')} <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500 mw-190">{i18n.t('button_remove_realtor_privileges')}</span></h4>
                    </div>
                    :
                    <div onClick={() => {
                      if (this.props.onAddPrivileges) this.props.onAddPrivileges(this.props.data)
                    }} className="card-body p-0 d-flex">
                      <i className="feather-user-plus text-grey-500 me-2 font-lg"></i>
                      <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-3 pointer">{i18n.t('button_add_privileges')} <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500 mw-190">{i18n.t('button_add_realtor_privileges')}</span></h4>
                    </div>
                  }
                </> : ''
              }
            </div>
          </div> 
          : '' }
        <div className="card-body d-block w-100 p-4 text-center">
          <figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1">
            <Link to={`/members/${this.props.data.id}`}>
              <Avatar 
                src={this.props.data.profile_image}
              />
            </Link>
          </figure>

          <div className="clearfix"></div>

          <h4 className="fw-700 font-xsss mt-3 mb-0">
            <Link to={`/members/${this.props.data.id}`}>{this.props.data.fname}&nbsp;{this.props.data.lname}</Link>
          </h4>

          <div className="fw-500 font-xssss text-grey-500 m-0">{this.props.data.email}</div>

          <span className="font-xssss">
            {this.props.is_realtor_admin ? i18n.t('admin') : ''}
          </span>

          <ul className="d-flex align-items-center justify-content-center mt-1">
            <li className="m-2"><h4 className="fw-700 font-sm">{this.props.data.properties || 0} <span className="font-xssss fw-500 mt-1 text-grey-500 d-block">{i18n.t('properties')}</span></h4></li>
            <li className="m-2"><h4 className="fw-700 font-sm">{this.props.data.requests || 0} <span className="font-xssss fw-500 mt-1 text-grey-500 d-block">{i18n.t('requests')}</span></h4></li>
          </ul>

          {
            this.props.data.connection_id === null || this.props.data.connection_status === '2'
              ? <a
                  className={`mt-4 p-0 btn p-2 lh-24 w125 ms-1 ls-3 d-inline-block rounded-xl font-xsssss fw-700 ls-lg bg-primary text-white white-space-no-wrap`}
                  onClick={() => this.addFriend(this.props.data.id)}
                >
                  {i18n.t('button_add')}
                </a>
              : ''
          }
          {
            this.props.data.connection_id !== null && this.props.data.connection_status === '0'
              ? <a
                  className={`mt-4 p-0 btn p-2 lh-24 w125 ms-1 ls-3 d-inline-block rounded-xl font-xsssss fw-700 ls-lg bg-danger text-white white-space-no-wrap`}
                  onClick={() => this.cancelFriendRequest(this.props.data.connection_id)}
                >
                  {i18n.t('button_cancel_request')}
                </a>
              : ''
          }
          {
            this.props.data.connection_id !== null && this.props.data.connection_status === '0' && this.props.data.connection_receiver === storage.me && storage.me.realtors_id
              ? <Fragment>
                  <a
                    className={`mt-4 p-0 btn p-2 lh-24 w125 ms-1 ls-3 d-inline-block rounded-xl font-xsssss fw-700 ls-lg bg-primary text-white white-space-no-wrap`}
                    onClick={() => this.respondToFriendRequest(this.props.data.connection_id, 'accept')}
                  >
                    {i18n.t('button_add')}
                  </a>
                  <a
                    className={`mt-4 p-0 btn p-2 lh-24 w125 ms-1 ls-3 d-inline-block rounded-xl font-xsssss fw-700 ls-lg bg-danger text-white white-space-no-wrap`}
                    onClick={() => this.respondToFriendRequest(this.props.data.connection_id, 'reject')}
                  >
                    {i18n.t('button_delete')}
                  </a>
                </Fragment>
              : ''
          }
          {
            this.props.data.connection_id !== null && this.props.data.connection_status === '1'
              ? <a
                  className="mt-4 p-0 btn p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-danger font-xsssss fw-700 ls-lg text-white"
                  onClick={() => this.deleteFriend(this.props.data.connection_id)}
                >
                  {i18n.t('button_delete')}
                </a>
              : ''
          }
          {
            this.props.data.connection_id !== null && this.props.data.connection_status === '0' && this.props.data.connection_sender === storage.me && storage.me.realtors_id
              ? <a
                className={`mt-4 p-0 btn p-2 lh-24 w125 ms-1 ls-3 d-inline-block rounded-xl font-xsssss fw-700 ls-lg bg-danger text-white white-space-no-wrap`}
                onClick={() => this.cancelFriendRequest(this.props.data.connection_id)}
              >
                {i18n.t('button_cancel_request')}
              </a>
              : ''
          }
        </div>
      </div>
    );
  }
}

export default RealtorCard;