import React, { Component , Fragment } from "react";
import Postview from '../Postview';
import Load from '../Load';
import Utils from "../../utils"
import i18n from "../../i18n";
import request from "../../request";
import storage from '../../storage';

class PostsProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      page: 0,
      loaded: false,
      end: false,
    };

    this.getMemberPosts = this.getMemberPosts.bind(this);
    this.removePost = this.removePost.bind(this);
    this.onDocumentScroll = this.onDocumentScroll.bind(this);
  }

  componentDidMount() {
    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });
	  this.getMemberPosts(1)
    document.addEventListener('scroll', this.onDocumentScroll);
  }

  onDocumentScroll = (e) => {
    if (!this.props.member) return;

    const totalPageHeight = document.body.scrollHeight - 1; 
    const scrollPoint = window.scrollY + window.innerHeight;

    if(scrollPoint >= totalPageHeight) {
      this.showMorePosts();
    }
  };

  getMemberPosts(page){
    return request.get(`posts/id/${this.props.member.id}/?view=profile&page=${page || this.state.page}`).then((response) => {
      if (response.status === 200) {
        this.setState({
          posts: this.state.posts.concat(response.data),
          loaded: true,
          end: response.data.length === 0,
          page: page,
        });
      }
      else {
        this.setState({
          end: true,
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.new_post) {
      if (!prevProps.new_post || prevProps.new_post.id !== this.props.new_post.id) {
        this.setState({
          posts: [this.props.new_post].concat(this.state.posts),
        })
      }
    }
  }

  showMorePosts() {
    if(!this.state.end){
      const newPage = this.state.page + 1;
      this.getMemberPosts(newPage);
      // this.setState({
      //   page: newPage,
      // });
    }
  }
  

  removePost(post){
    request.delete(`posts/id/${post}`).then((response) => {
      if(response.data.status === '1'){
        const posts = this.state.posts.filter(p => p.id !== post);
        this.setState({
          ...this.state,
          posts: posts,
        });
      }
    });
  }

  setEmotion(id, emotion){
    request.put(`posts`,{
      id: id,
      emotion: emotion,
    }).then((response) => {
        const posts = JSON.parse(JSON.stringify(this.state.posts));
        posts.filter(p => p.id === id)[0].emotions = response.data[0].emotions;
        this.setState({
          ...this.state,
          posts: posts,
        });
    });
  }

  updatePost(id, post, meta) {
      
    this.setState({
      ...this.state,
      posts: this.state.posts.map(p => {
        if (p.id === id) {
          return {
            ...p,
            description: post,
            meta: meta
          }
        }

        return p
      })
    })
  }

  componentWillUnmount() {
    i18n.clearOnChangeLocale();
    document.removeEventListener('scroll', this.onDocumentScroll);
  }

  render() {
    const member = this.props.member ? this.props.member : null
    return (
		  <div className="posts">
			
			
			{
			  this.state.posts && this.state.posts.map( (p, i) => {
				return <Postview key={i} 
          raw_data={p}
				  postid={p.id} 
				  postowner={p.owner}
				  postvideo="" 
				  postimage={p.image} 
				  avater={p.user_image} 
				  user_id={p.user_id} 
				  user={p.user_name} 
          rating={p.rating}
          meta={p.meta ? p.meta : null}
				  me={storage.me}
				  member={member}
				  post_to={p.post_to}
				  network_to={p.network_to}
				  time={ Utils.getMomentText(p.timestamp) } 
				  desc={ Utils.getMinifiedDescription(p.description, 200) } 
				  postcomments={p.comments}
				  postemotions={p.emotions}
				  removePost={(e) => this.removePost(e)}
				  setEmotion={(a,b) => this.setEmotion(a,b)}
				  updatePost={(id, post, meta) => this.updatePost(id, post, meta)}
				/>
			  })
			}
			<Load end={this.state.end}/>
		</div>
    );
  }
}

export default PostsProfile;