import React, { Component , Fragment } from "react";
import i18n from "../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChessBishop, faChessKing, faChessPawn, faChessKnight, faChessRook } from '@fortawesome/free-solid-svg-icons'
import { faCalendar, faClock, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ProfilePreview from "./ProfilePreview";

class OpenHousePreview extends Component {
	constructor(props) {
		super(props)

		this.state = {

		}
	}

	

	componentDidMount() {

	}

	render() {
		return (
			<div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
				<div className='open-house-photo'>
					<img className="rounded-4" src={`https://realstatus-demo.gr/images/properties/261-44767.jpg`} />
				</div>
				<div className="card-body p-0 d-flex open-header">
					<div className="d-flex open-date">
						<span className="day-of-date">26</span>
						<span className="month-of-date">ΟΚΤ</span>
					</div>
					<div className='d-flex open-header-data ms-4'>
						<h2>Open house Τετάρτη 26 οκτ.</h2>
						<div className="d-flex">
							<h5 className="fw-600 text-grey-900 font-xssss">Από το χρήστη</h5>
							<h5 className="fw-600 text-grey-900 font-xssss ms-2">Χρήστος Βαρδάκας</h5>
						</div>
					</div>
					
				</div>
				<hr/>
				<div className="card-body mt-2 p-0 d-flex">
					<div className='open-house-info'>
						<h4>
							<span className="title-icon"><FontAwesomeIcon icon={faCalendarAlt} /></span> <span className="title-data font-xsss">15/10/22</span>
						</h4>
						<h4>
							<span className="title-icon"><FontAwesomeIcon icon={faClock} /></span> <span className="title-data font-xsss">09:00 - 18:00</span>
						</h4>
						<p className="fw-500 text-grey-900 font-xssss w-100 mb-2">
							Το Σάββατο 15/10 σας προσκαλούμε να εξερευνήσετε μια κατοικία που μπορεί να γίνει το σπίτι σας
						</p>
					</div>
				</div>
				<div className="card-body mt-2 d-flex open-house-footer">
					<button className="bg-success btn-action btn-accept p-2 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-600">Θα πάω</button>
					<button onClick={() => this.props.showModal()} className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">Περισσότερα</button>
				</div>
			</div>	
		)
	}
}

export default OpenHousePreview