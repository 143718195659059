import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";
import i18n from "../i18n";

const Milestone = (props) => {
	const [checked, setChecked] = useState(false)
	const [desc, setDesc] = useState('')

	const textareaRef = useRef(null)
	const wrapperRef = useRef(null)
	const checkRef = useRef(null)

	useEffect(() => {
		setChecked(props.checked)
	}, [props.checked])

	useEffect(() => {
		setDesc(props.milestone_description ? props.milestone_description : '')
		
	}, [props.milestone_description])

	useEffect(() => {
		autoGrow()
	}, [desc])

	const changeChecked = (checked) => {
		setChecked(checked)
		props.onCheck(checked)
	}

	const setDescription = () => {
		props.onDescriptionChange(desc)
	}

	const onTextChange = (e) => {
		setDesc(e.target.value)
	}

	const autoGrow = () => {
		textareaRef.current.style.height = "5px";
		textareaRef.current.style.height = (textareaRef.current.scrollHeight)+"px";
	}

	const checkSubmit = (e) => {
		if (e.keyCode === 13) {
			textareaRef.current.value = textareaRef.current.value.substring(-1) 
			setDescription(textareaRef.current.value)
		}
	}

	return (
		<div ref={wrapperRef} className={`milestone-element ${props.className ? props.className : ''}`}>
			<div className="milestone-events">
				<label className="label">
					<input className="label__checkbox" ref={checkRef} type='checkbox' onClick={e => changeChecked(e.target.checked)} checked={checked} />
					<span className="label__text">
					<span className="label__check">
						<FontAwesomeIcon icon={faCheck} />
					</span>
					</span>
				</label>
				{/* <input ref={checkRef} type='checkbox' onClick={e => changeChecked(e.target.checked)} checked={checked} /> */}
				<textarea className={checked ? 'line-through' : ''} ref={textareaRef} onKeyUp={e => checkSubmit(e)} onChange={onTextChange} onBlur={e => setDescription()} value={desc}/>
				<span onClick={props.onDelete} className={`cursor-pointer milestone-remove-btn`}><FontAwesomeIcon icon={faTrash} /></span>
			</div>
			<div className="w-100 milestone-footer">
				{i18n.t('created_by')} {props.creator_full_name} 
				{
					props.milestone_status === "1" ? 
					<span> - {i18n.t('closed_by')} {props.closer_full_name}</span> : ''
				}
			</div>
		</div>
	)
}

export default Milestone