import React,{Component, createRef} from 'react';
import i18n from "../i18n";

class Load extends Component {
	constructor(props) {
		super(props)
		this.scrollRef = createRef()
	}

	componentDidMount() {
		if (this.props.onScroll) {
			this.onDocumentScroll = (e) => {
				const totalPageHeight = document.body.scrollHeight - 1; 
				const scrollPoint = window.scrollY + window.innerHeight;
				if(scrollPoint >= totalPageHeight && !this.props.end) {
					this.props.onScroll();
				}
			}

			document.addEventListener('scroll', this.onDocumentScroll);
		}

		
	}

	componentWillUnmount() {
		if (this.onDocumentScroll) 
			document.removeEventListener('scroll', this.onDocumentScroll);
	}

	render() {
		const end = this.props.end;
		return (
			<div ref={this.scrollRef} className="card w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3" style={ end ? {display: 'none'} : {}}>
				<div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
					<div className="stage">
						{ end ?
						<span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{i18n.t('nothing_new')}</span> :
						<div className="dot-typing"></div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default Load;