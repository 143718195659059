import React from 'react';

class Toast extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };

    this.elementRef = React.createRef();

    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.hide();
    }, this.props.hide_ms ? this.props.hide_ms : 10000);
  }

  hide() {
    this.setState({
      show: false,
    });
  }

  render() {
    return (
      <div className={`ttoast animate__animated animate__backInLeft ${this.state.show === false ? 'animate__fadeOutLeft' : ''}`}
        ref={this.elementRef}
      >
        <div className="content">
          {
            this.props.image
              ? <img src={this.props.image} alt="" />
              : ''
          }
          <span>{this.props.message}</span>
        </div>
        
        <button
          className="button-close"
          onClick={this.hide}
        >
          &times;
        </button>
      </div>
    );
  }
}

export default Toast;