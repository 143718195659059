import React, { Component , createRef, Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import i18n from "../i18n";
// import 'leaflet/dist/leaflet.css';
// import 'leaflet.markercluster';
import Load from '../components/Load'
import Rightchat from "../components/Rightchat";
import Popupchat from "../components/Popupchat";
import { Link } from "react-router-dom";
import request from "../request";
import RequestEditCard from "../components/RequestEditCard";
import ModalGeneric from "../components/ModalGeneric";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";


class ManageSearches extends Component {

  constructor(props) {
    super(props)

    this.state = {
      saved_searches: [],
    }
  }

  componentDidMount() {
    this.getSearches()
    
    this.unlistenLocale = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    

    
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.documentClicked)
    this.unlistenLocale()
  }

  getSearches = () => {
    request.get('/saved_searches/properties').then(response => {
		this.setState({
		  saved_searches: response.data
		})
	  })
  }

  deleteSavedSearch = (id) => {
	request.delete(`/saved_searches/properties/${id}`).then(response => {
		this.setState({
		  saved_searches: response.data
		})
	})
  }

  
    
  render() {
    return (
      <Fragment>
        <Header />
        <Leftnav />

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
              <div className="container">
                <Pagetitle 
                  title={i18n.t('saved_searches')}
                >
                </Pagetitle>
                <div className="col-xl-12 chat-left properties-wrapper">
                    <div className="row ps-2 pe-2">
                      {this.state.saved_searches.map((data , index) => (
                        <div className="col-md-6">
							<div className="card search-item shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 d-flex flex-row page-title-header">
								<span className="search-title">
									{`${data.name} (${data.total} ${i18n.t('results')})`}
								</span>
								<button className="btn btn-secondary text-white mr-5 rounded-xl" onClick={() => {
									this.deleteSavedSearch(data.id)
								}}><FontAwesomeIcon icon={faTrash}/></button>
							</div>
						</div>
                        
                      ))}
                    </div>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default ManageSearches;