import React, { Component , Fragment, createRef } from "react";
import { Link } from "react-router-dom";
import i18n from "../i18n";
import request from "../request";
import history from '../helpers/history';


class Register extends Component {
    constructor(props) {
		super(props);
        
        this.passwordRef = createRef(null)
        this.passwordConfirmRef = createRef(null)
		this.state = {
            id: 0,
            rspass: '',
            first_name: '',
            last_name: '',
            email: '',
            gemi: '',
            password: '',
            confirm_password: '',
            accepted_terms: false,
            form_error_messages: []
		};
        const lightTheme = "theme-light"
		const darkTheme = "theme-dark"
        let theme
	
		if (localStorage) {
			theme = localStorage.getItem("theme")
		}
	
		if (theme === lightTheme || theme === darkTheme) {
			document.body.classList.add(theme)
		} else {
			document.body.classList.add(lightTheme)
		}
	  }

	componentDidMount() {
        const user_data = localStorage.getItem('register_data') ? JSON.parse(localStorage.getItem('register_data')) : null;
        if (user_data) {

            this.setState({
                first_name: user_data.fname,
                last_name: user_data.lname,
                email: user_data.mail,
                gemi: user_data.gemi,
                id: user_data.id,
                rspass: user_data.rspass,
            })
        }
		i18n.onChangeLocale(() => {
		  this.forceUpdate();
		});
	}

    changeValue(key, value) {
        this.setState({
            
            [key]: value
        })
    }

	checkKeyPressed(evt) {
		evt = evt || window.event;
    	const charCode = evt.keyCode || evt.which;

		if (charCode === 13) {
			// this.onLogin()
		}
	}

    enumEmptyErrors(key) {
        switch(key) {
            case 'first_name': return 'first_name_field_empty';
            case 'last_name': return 'last_name_field_empty';
            case 'email': return 'email_field_empty';
            case 'gemi': return 'gemi_field_empty';
            case 'password': return 'password_field_empty';
            case 'confirm_password': return 'confirm_password_field_empty';
            case 'accepted_terms': return 'you_must_accept_the_terms';
            default: return 'something_went_wrong';
        }
    }

    printErrors(errors) {
        this.setState({
            
            form_error_messages: errors
        })

        setTimeout(() => {
            this.setState({
                
                form_error_messages: []
            })
        }, 5000)
    }

    submitForm() {
        let passed_test = true
        let error_messages = []
        Object.entries(this.state).forEach( elem => {
            const key = elem[0]
            const value = elem[1]
            if (value === false || value === '' && key !== 'rspass') {
                error_messages.push(this.enumEmptyErrors(key))
                passed_test = false
            }
        })

        if (!passed_test) {
            this.printErrors(error_messages)
            return
        }
        
        if (this.state.password.length < 6) {
            error_messages.push('password_must_be_atleast_6_characters_long')
            this.printErrors(error_messages)
            return
        }

        if (this.state.password !== this.state.confirm_password) {
            error_messages.push('confirm_password_missmatch')
            this.printErrors(error_messages)
            return
        }

        request.post(`/register`, {
            ...this.state
        }).then(response => {
            if (response.status === 200) {
                localStorage.setItem('login_message', JSON.stringify({
                    message: 'registered_success',
                    type: 'success'
                }))
                history.push('login/register_success')
            }
            else if (response.status === 409) {
                const errors = response.data.messages.errors
                this.printErrors(Object.entries(errors).map(error => error[1]))
            }
        })
    }

    render() {
        return (
            <Fragment> 
                <div className="main-wrap">
                    <div className="nav-header bg-transparent shadow-none border-0">
                        <div className="nav-top w-100 navigation-login-menu">
                           
							<Link to="/login" className="header-btn d-none d-lg-block background-light-blue fw-500 text-white font-xsss p-2 ms-auto w100 text-center lh-20 rounded-xl">{i18n.t('login')}</Link>
							<Link to="/register" className="header-btn d-none d-lg-block bg-secondary fw-500 text-white font-xsss p-2 ms-2 w100 text-center lh-20 rounded-xl">{i18n.t('register')}</Link>

							<span className="pointer p-2 text-center ms-3 btn-locale"
								onClick={() => {
									if(i18n.getLocale() === 'en')
										i18n.setLocale('el')
									else
										i18n.setLocale('en')
								}}
								>
								<i className="feather-globe"></i>
							</span>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-5 d-none d-xl-flex p-0 vh-100 bg-primary align-items-center justify-content-center bg-cover">
                            <div className="col-6 text-center">
								<img src="assets/images/real-status-logo-white.png" alt="Real Status Logo Symbol"/>
								{/* <span className="text-white fw-500 font-xsss">A Message for Login Page!</span> */}
							</div>
                        </div>
                        <div className="col-xl-7 register-form vh-100 align-items-center d-flex bg-white rounded-3 bg-cover2">
                            <div className="card shadow-xxs-2 border-0 ms-auto me-auto login-card">
                                <div className="card-body rounded-0 text-left">
                                    <div className="form-group icon-input mb-3">
                                        
                                        {
                                            (this.state.form_error_messages && this.state.form_error_messages.length > 0) && 
                                            <div className={`alert alert-danger`}>
                                                <span>{i18n.t(this.state.form_error_messages[0])} <br/></span>
                                            </div>
                                        }
                                         
                                        
                                    </div>     
                                    <h1 className="fw-700 mb-4">{i18n.t("create_account")}</h1>  
                                    <p>{i18n.t('creating_account_prompt')}</p>
                                    <form className=''>
                                        
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                            <input type="text" 
                                            value={this.state.first_name}
                                            onChange={(e) => this.changeValue('first_name', e.target.value)}
                                            className="style2-input ps-5 form-control login-input text-grey-900 font-xsss fw-600" placeholder={i18n.t("your_name")} />                        
                                        </div>
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                            <input type="text" 
                                            value={this.state.last_name}
                                            onChange={(e) => this.changeValue('last_name', e.target.value)}
                                            className="style2-input ps-5 form-control login-input text-grey-900 font-xsss fw-600" placeholder={i18n.t("your_last_name")} />                        
                                        </div>
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                            <input type="email" 
                                            value={this.state.email}
                                            onChange={(e) => this.changeValue('email', e.target.value)}
                                            className="style2-input ps-5 form-control login-input text-grey-900 font-xsss fw-600" placeholder={i18n.t("your_email")} />                        
                                        </div>
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                            <input type="text" 
                                            value={this.state.gemi}
                                            onChange={(e) => this.changeValue('gemi', e.target.value)}
                                            className="style2-input ps-5 form-control login-input text-grey-900 font-xsss fw-600" placeholder={i18n.t("your_gemi")} />                        
                                        </div>
                                        {
                                        /* <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                            <input type="text" 
                                            value={this.state.smasaa}
                                            onChange={(e) => this.changeValue('smasaa', e.target.value)}
                                            className="style2-input ps-5 form-control login-input text-grey-900 font-xsss fw-600" placeholder={i18n.t("your_smasaa_register")} />                        
                                        </div> */}
                                        <div className="form-group icon-input mb-3">
                                            <input type="Password" 
                                            ref={this.passwordRef}
                                            value={this.state.password}
                                            onChange={(e) => this.changeValue('password', e.target.value)}
                                            className="style2-input px-5 form-control login-input text-grey-900 font-xss ls-3" placeholder={i18n.t("password")} />
                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                            <i 
											onMouseEnter={() => {
												this.passwordRef.current.type = 'text'
											}}
											onMouseLeave={() => {
												this.passwordRef.current.type = 'password'
											}}
											className="font-sm ti-eye pos-right preview-pass text-grey-500 pe-0"></i>
										
                                        </div>
                                        <div className="form-group icon-input mb-1">
                                            <input type="Password" 
                                            ref={this.passwordConfirmRef}
                                            value={this.state.confirm_password}
                                            onChange={(e) => this.changeValue('confirm_password', e.target.value)}
                                            className="style2-input px-5 form-control login-input text-grey-900 font-xss ls-3" placeholder={i18n.t("confirm_password")} />
                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                            <i 
											onMouseEnter={() => {
												this.passwordConfirmRef.current.type = 'text'
											}}
											onMouseLeave={() => {
												this.passwordConfirmRef.current.type = 'password'
											}}
											className="font-sm ti-eye pos-right preview-pass text-grey-500 pe-0"></i>
										
                                        </div>
                                        <div className="form-check text-left mb-3">
                                            <input type="checkbox" 
                                            onChange={(e) => this.changeValue('accepted_terms', e.target.checked)}
                                            className="form-check-input mt-2" id="exampleCheck2" />
                                            <label className="form-check-label font-xsss text-grey-500">{i18n.t("i_accept_the")} <a href="/terms" target="_blank">{i18n.t('terms_of_use_register')}</a></label>
                                            
                                        </div>
                                    </form>
                                    
                                    <div className="col-sm-12 p-0 text-left">
                                        <div className="form-group mb-1"><button 
                                        onClick={() => this.submitForm()}
                                        className="form-control text-center style2-input text-white rounded-xl fw-600 bg-dark border-0 p-0 ">{i18n.t("register")}</button></div>
                                        <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">{i18n.t("already_have_account")} <a href="/login" className="fw-700 ms-1">{i18n.t("login")}</a></h6>
                                    </div>
                                    
                                </div>
                            </div> 
                        </div>
                        
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Register;