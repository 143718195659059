import React, { Component , Fragment } from "react";
import { Link } from 'react-router-dom';
import i18n from "../i18n";
import { Button } from "react-bootstrap";
import logoWhite from '../assets/images/real-status-logo-white.png'
import realstatusLogo from '../assets/images/RealStatus_logo.png'
import smallLogo from '../assets/images/realstatus-logo-symbol.png'
import request from "../request";
import Swal from "sweetalert2";
import history from '../helpers/history';

class ForgotSendEmail extends Component {
	constructor(props) {
		super(props);
	
		this.state = {
		  email: '',
          code: '',
          sended_email: false,
		  message: {},
          code_passed: false,
          new_password: '',
          new_password_confirm: ''
		};
		const lightTheme = "theme-light"
		const darkTheme = "theme-dark"
		let theme
	
		if (localStorage) {
			theme = localStorage.getItem("theme")
		}
	
		if (theme === lightTheme || theme === darkTheme) {
			document.body.classList.add(theme)
		} else {
			document.body.classList.add(lightTheme)
		}
	  }

	componentDidMount() {
		i18n.onChangeLocale(() => {
		  this.forceUpdate();
		});

		const redirectMessage = localStorage.getItem('login_message') ? JSON.parse(localStorage.getItem('login_message')) : null
		
		if (redirectMessage) {
			this.setState({
				message: {
					message: i18n.t(redirectMessage.message),
					type: redirectMessage.type
				}
			})
			localStorage.removeItem('login_message') 
		}
		
		
	}

    sendEmail = () => {
        request.post('forgot_password', {
            email: this.state.email
        }).then(rsp => {
            if (rsp.status === 200) {
                this.setState({
                    sended_email: true
                }, () => {
                    Swal.fire({
                        title: i18n.t('success'),
                        text: i18n.t('you_will_receive_an_email_with_the_recovery_code'),
                        icon: 'success'
                    })
                })
            }
            else {
                Swal.fire({
                    title: i18n.t('something_went_wrong'),
                    text: i18n.t('try_again_single'),
                    icon: 'error'
                })
            }
        })
    }

    checkCode = () => {
        request.post(`forgot_password/${this.state.code}`, {
            email: this.state.email
        }).then(rsp => {
            if (rsp.status === 200) {
                this.setState({
                    code_passed: true
                }, () => {
                    Swal.fire({
                        title: i18n.t('success'),
                        text: i18n.t('you_can_now_change_your_password'),
                        icon: 'success'
                    })
                })
            }
            else {
                Swal.fire({
                    title: i18n.t('wrong_code'),
                    text: i18n.t('try_again_single'),
                    icon: 'error'
                })
            }
        })
    }

    resetPassword = () => {
        request.post(`forgot_password/${this.state.code}`, {
            email: this.state.email,
            new_password: this.state.new_password,
            new_password_confirm: this.state.new_password_confirm
        }).then(rsp => {
            if (rsp.status === 200) {
                Swal.fire({
                    title: i18n.t('password_changed'),
                    text: i18n.t('login_again'),
                    icon: 'success'
                }).then(() => {
                    history.push('/login')
                })
            }
            else {
                Swal.fire({
                    title: i18n.t('passwords_dont_match'),
                    icon: 'error'
                })
            }
        })
    }

	checkKeyPressed(evt) {
		evt = evt || window.event;
    	const charCode = evt.keyCode || evt.which;

		if (charCode === 13) {
            if (!this.state.sended_email) {
                
			    this.sendEmail()
            }
            else if (this.state.sended_email && !this.state.code_passed) {
                this.checkCode()
            }
            else if (this.state.sended_email && this.state.code_passed) {
                this.resetPassword()
            }
		}
	}

	render() {

		return (
			<Fragment> 
				<div className="main-wrap">
					<div className="nav-header bg-transparent shadow-none border-0">
						<div className="nav-top navigation-login-menu w-100 justify-content-xs-end justify-content-sm-end">
							{/* <img src="assets/images/realstatus-logo.png" className="app-logo ms-5" alt="Real Status Logo Symbol" height="70" /> */}
							{/* <button className="nav-menu me-0 ms-auto"></button> */}

							<Link to="/login" className="header-btn d-none d-lg-block background-light-blue fw-500 text-white font-xsss p-2 ms-auto w100 text-center lh-20 rounded-xl">{i18n.t('login')}</Link>
							<Link to="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-2 ms-2 w100 text-center lh-20 rounded-xl">{i18n.t('register')}</Link>

							<span className="pointer p-2 text-center ms-3 btn-locale"
								onClick={() => {
									if(i18n.getLocale() === 'en')
										i18n.setLocale('el')
									else
										i18n.setLocale('en')
								}}
								>
								<i className="feather-globe"></i>
							</span>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-5 d-none d-xl-flex p-0 vh-100 bg-primary align-items-center justify-content-center bg-cover">
							<div className="col-6 text-center">
								<img src={logoWhite} alt="Real Status Logo Symbol"/>
								{/* <span className="text-white fw-500 font-xsss">A Message for Login Page!</span> */}
							</div>
						</div>
                        {
                            !this.state.sended_email &&
                            <div className="col-xl-7 login-wrapper vh-100 align-items-center bg-cover2 d-flex bg-white rounded-3 overflow-hidden">
                                <div className="card shadow-xxs-2 border-0 ms-auto me-auto login-card">
                                    <div className="card-body rounded-0 text-left">
                                        <h1 className="fw-700 mb-3">{i18n.t('account_restore')}</h1>

                                        <div>
                                            <div className="form-group icon-input mb-3">
                                                {
                                                    (this.state.message && this.state.message.message) && 
                                                    <div className={`alert ${this.state.message.type === 'success' ? 'alert-success' : 'alert-warning'}`}>
                                                        {this.state.message.message} &nbsp;
                                                    </div>
                                                }
                                                
                                                
                                            </div>
                                            <div className="form-group icon-input mb-3">
                                                <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                                <input 
                                                    type="text" 
                                                    value={this.state.email} 
                                                    onChange={(e) => this.setState({email: e.target.value})} 
                                                    onKeyUp={(e) => this.checkKeyPressed(e)}
                                                    className="style2-input login-input ps-5 form-control text-grey-900 font-xsss fw-600" 
                                                    placeholder={i18n.t('your_email_address')} 
                                                />                        
                                            </div>
                                            
                                        </div>
                                        
                                        <div className="col-sm-12 p-0 text-left ">
                                            <div className="form-group mb-1 d-flex justify-content-end">
                                                <Button 
                                                    className="text-center rounded-xl btn btn-primary text-white"
                                                    onClick={() => {this.sendEmail()}}
                                                >
                                                    {i18n.t('send_email')}
                                                </Button>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                </div> 
                            </div>
                        }
						{
                            (this.state.sended_email && !this.state.code_passed) &&
                            <div className="col-xl-7 login-wrapper vh-100 align-items-center bg-cover2 d-flex bg-white rounded-3 overflow-hidden">
                                <div className="card shadow-xxs-2 border-0 ms-auto me-auto login-card">
                                    <div className="card-body rounded-0 text-left">
                                        <h1 className="fw-700 mb-3">{i18n.t('type_the_code_from_email')}</h1>

                                        <div>
                                            <div className="form-group icon-input mb-3">
                                                {
                                                    (this.state.message && this.state.message.message) && 
                                                    <div className={`alert ${this.state.message.type === 'success' ? 'alert-success' : 'alert-warning'}`}>
                                                        {this.state.message.message} &nbsp;
                                                    </div>
                                                }
                                                
                                                
                                            </div>
                                            <div className="form-group icon-input mb-3">
                                                <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                                <input 
                                                    type="password" 
                                                    value={this.state.code} 
                                                    onChange={(e) => this.setState({code: e.target.value})} 
                                                    onKeyUp={(e) => this.checkKeyPressed(e)}
                                                    className="style2-input login-input ps-5 form-control text-grey-900 font-xsss fw-600" 
                                                    placeholder={i18n.t('reset_code')} 
                                                />                        
                                            </div>
                                            
                                        </div>
                                        
                                        <div className="col-sm-12 p-0 text-left ">
                                            <div className="form-group mb-1 d-flex justify-content-end">
                                                <Button 
                                                    className="text-center rounded-xl btn btn-primary text-white"
                                                    onClick={() => {this.checkCode()}}
                                                >
                                                    {i18n.t('check_code')}
                                                </Button>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                </div> 
                            </div>
                        }
                        {
                            (this.state.sended_email && this.state.code_passed) &&
                            <div className="col-xl-7 login-wrapper vh-100 align-items-center bg-cover2 d-flex bg-white rounded-3 overflow-hidden">
                                <div className="card shadow-xxs-2 border-0 ms-auto me-auto login-card">
                                    <div className="card-body rounded-0 text-left">
                                        <h1 className="fw-700 mb-3">{i18n.t('insert_new_password')}</h1>

                                        <div>
                                            <div className="form-group icon-input mb-3">
                                                {
                                                    (this.state.message && this.state.message.message) && 
                                                    <div className={`alert ${this.state.message.type === 'success' ? 'alert-success' : 'alert-warning'}`}>
                                                        {this.state.message.message} &nbsp;
                                                    </div>
                                                }
                                                
                                                
                                            </div>
                                            <div className="form-group icon-input mb-3">
                                                <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                                <input 
                                                    type="password" 
                                                    value={this.state.new_password} 
                                                    onChange={(e) => this.setState({new_password: e.target.value})} 
                                                    onKeyUp={(e) => this.checkKeyPressed(e)}
                                                    className="style2-input login-input ps-5 form-control text-grey-900 font-xsss fw-600" 
                                                    placeholder={i18n.t('new_password')} 
                                                />                        
                                            </div>

                                            <div className="form-group icon-input mb-3">
                                                <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                                <input 
                                                    type="password" 
                                                    value={this.state.new_password_confirm} 
                                                    onChange={(e) => this.setState({new_password_confirm: e.target.value})} 
                                                    onKeyUp={(e) => this.checkKeyPressed(e)}
                                                    className="style2-input login-input ps-5 form-control text-grey-900 font-xsss fw-600" 
                                                    placeholder={i18n.t('confirm_new_password')} 
                                                />                        
                                            </div>
                                            
                                        </div>
                                        
                                        <div className="col-sm-12 p-0 text-left ">
                                            <div className="form-group mb-1 d-flex justify-content-end">
                                                <Button 
                                                    className="text-center rounded-xl btn btn-primary text-white"
                                                    onClick={() => {this.resetPassword()}}
                                                >
                                                    {i18n.t('change_password')}
                                                </Button>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                </div> 
                            </div>
                        }
						
					</div>
				</div>
			</Fragment>
		);
	}
}

export default ForgotSendEmail;