import React, { Component , Fragment } from "react";
import { Link } from "react-router-dom";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import i18n from "../i18n";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';


class Helpbox extends Component {
    render() {
        return (
            <Fragment> 
                <Header />
                <Leftnav />
                <Rightchat />
                
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row justify-content-center">
                                <div className="col-xl-10">
                                    <div className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center">
                                        <div className="card-body p-md-5 p-4 text-center helpbox-section">
                                        <Link to="/settings" className="d-inline-block mt-2 border-0 d-flex rounded-3"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                            <h2 className="fw-700 display2-size text-white display2-md-size lh-2">{i18n.t('welcomemsgshelp')}</h2>
                                            <p className="font-xsss ps-lg-5 pe-lg-5 lh-28 text-grey-200">{i18n.t('detailshelp')}</p>
                                        </div>
                                    </div>
                                    <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                                        <Accordion className="accodion-style--1 accordion" preExpanded={'0'}>
                                            <AccordionItem className="card shadow-xss">
                                                <AccordionItemHeading className="card-header">
                                                    <AccordionItemButton>
                                                        <h5 className="fw-600 pt-2 pb-2 mb-0">{i18n.t('question1')}</h5>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel className="card-body">
                                                <p>{i18n.t('answer1')}</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className="card shadow-xss">
                                                <AccordionItemHeading className="card-header">
                                                    <AccordionItemButton>
                                                        <h5 className="fw-600 pt-2 pb-2 mb-0">{i18n.t('question2')}</h5>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel className="card-body">
                                                    <p>{i18n.t('answer2')}</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem className="card shadow-xss">
                                                <AccordionItemHeading className="card-header">
                                                    <AccordionItemButton>
                                                        <h5 className="fw-600 pt-2 pb-2 mb-0">{i18n.t('question3')}</h5>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel className="card-body">
                                                <p>{i18n.t('answer3')}</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className="card shadow-xss">
                                                <AccordionItemHeading className="card-header">
                                                    <AccordionItemButton>
                                                        <h5 className="fw-600 pt-2 pb-2 mb-0">{i18n.t('question4')}</h5>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel className="card-body">
                                                    <p>{i18n.t('answer4')}</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className="card shadow-xss">
                                                <AccordionItemHeading className="card-header">
                                                    <AccordionItemButton>
                                                        <h5 className="fw-600 pt-2 pb-2 mb-0">{i18n.t('question5')}</h5>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel className="card-body">
                                                    <p>{i18n.t('answer5')}</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className="card shadow-xss">
                                                <AccordionItemHeading className="card-header">
                                                    <AccordionItemButton>
                                                        <h5 className="fw-600 pt-2 pb-2 mb-0">{i18n.t('question6')}</h5>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel className="card-body">
                                                    <p>{i18n.t('answer6')}</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className="card shadow-xss">
                                                <AccordionItemHeading className="card-header">
                                                    <AccordionItemButton>
                                                        <h5 className="fw-600 pt-2 pb-2 mb-0">{i18n.t('question7')}</h5>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel className="card-body">
                                                    <p>{i18n.t('answer7')}</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className="card shadow-xss">
                                                <AccordionItemHeading className="card-header">
                                                    <AccordionItemButton>
                                                        <h5 className="fw-600 pt-2 pb-2 mb-0">{i18n.t('question8')}</h5>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel className="card-body">
                                                    <p>{i18n.t('answer8')}</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popupchat />
                <Appfooter /> 

            </Fragment>            
        );
    }
}

export default Helpbox;