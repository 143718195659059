import '../css/ChatList.css';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import chat from '../chat';
import Chat from '../components/Chat/Chat';
import request from '../request';
import settings from '../settings';
import i18n from '../i18n';

class Rightchat extends Component {
    state = {
        isOpen: false
    };

    constructor(props) {
        super(props);
        this.state = {
            width:  800,
            height: 182,

        contacts: chat.contacts,
        window: {
          open: settings.sideContactsListOpen,
          openStateDirty: false,
          openStatePristine: true,
        },
        networks: [],
        }

        this.openChatWindow = this.openChatWindow.bind(this);
        this.onOpenBubble = this.onOpenBubble.bind(this);
        this.getNetworks = this.getNetworks.bind(this);
    }
    
    updateDimensions() {
        if(window.innerWidth < 500) {
          this.setState({ width: 450, height: 102 });
        } else {
          let update_width  = window.innerWidth-100;
          let update_height = Math.round(update_width/4.4);
          this.setState({ width: update_width, height: update_height });
        }
    }
    
    componentDidMount() {

        chat.onChange(() => {
          this.setState({
            contacts: chat.contacts,
          });
        });
    
        chat.onMessage((data) => {
          const c = this.state.contacts.slice(0);
          const contact = c.find((c) => c.id === ('from' in data ? data.from : data.to));
          if (contact) {
            contact.newMessage = true;
          }
        });
    
        settings.onChange(() => {
          this.setState({
            window: {
              ...this.state.window,
              open: settings.sideContactsListOpen,
            },
          });
    
          // this.forceUpdate();
        });

        this.getNetworks();

        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    getNetworks() {
      return request.get(`networks/`).then((response) => {
        this.setState({
          networks: this.state.networks.concat(response.data),
        });
      });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.window.openStatePristine && prevState.window.open !== this.state.window.open) {
          this.setState({
            window: {
              ...this.state.window,
              openStateDirty: true,
              openStatePristine: false,
            },
          });
        }
      }
    
      openChatWindow(contactId) {
        settings.set('openChats', [...settings.openChats, contactId])
        this.onOpenBubble(contactId)
      }
    
      onOpenBubble(contactId) {
        const c = this.state.contacts.slice(0);
        const index = c.findIndex((c) => c.id === contactId);
        c[index].newMessage = false;
        this.setState({
          contacts: c,
        });
      }
    
    componentWillUnmount() {

        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        return (
            <Fragment>
            <div id="main-content-wrap" className={`right-chat nav-wrap mt-2 right-scroll-bar ${this.state.width > 1500 ? "active-sidebar" : " "}`}>
                <div className="middle-sidebar-right-content bg-white shadow-xss rounded-xxl">
                    <div className="section full pe-3 ps-4 pt-4 position-relative feed-body">
                        <h2 className="font-xsssss text-grey-500 fw-700 ls-3">{i18n.t('friends_c')}</h2>
                        <div className="list-group list-group-flush">
                            {
                                this.state.contacts.map((contact, i) =>
                                    <div
                                        key={i}
                                        className={`item ${contact.newMessage ? 'new-message' : ''} bg-transparent list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center`}
                                        onClick={() => this.openChatWindow(contact.id)}
                                        // onClick={() => {
                                        //   settings.set('openChats', [...settings.openChats, contact.id])
                                        // }}
                                    >   
                                        <figure className="avatar float-left mb-0 me-2">
                                            <img src={`${contact.profile_image}`} alt="avater" className="w35 rounded-circle" />
                                        </figure>
                                        <h3 className={`${contact.newMessage && contact.newMessage === true ? 'fw-700' : ''} contact-user-wrapper mb-0 mt-0 me-2`}>
                                            <span className="font-xsss text-grey-600 d-block text-dark model-popup-chat pointer" >{contact.name}</span>
                                            {
                                              (contact.status) ?
                                              <span className="contact-status contact-status-online"></span> : 
                                              <span className="contact-status contact-status-offline"></span>
                                            }
                                        </h3>

                                        <span className={`${contact.status} ms-auto btn-round-xss`}></span>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                            {/* NETEWORKS */}

                    {/* <div className="section full pe-3 ps-4 pt-4 pb-4 position-relative feed-body">
                      <h2 className="font-xsssss text-grey-500 fw-700 ls-3">{i18n.t('networks_c')}</h2>
                        <div className="list-group list-group-flush">
                            {
                              this.state.networks.map((network, index) =>{
                                const amOnNetwork = network.joined;
                                const networkIsPublic = !network.private;
                                const networkImage = network.image ? network.image : ``;
                                return(
                                  <div
                                    key={index}
                                    className={`bg-transparent list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex justify-content-between `} > 
                                    <div className='d-inline-flex justify-content-between'>
                                      <figure className="avatar float-left mb-0 me-2">
                                        {
                                          networkImage !== '' ?
                                          <img src={`${networkImage}`} alt="networks_image" className="w35 rounded-circle" /> :
                                          <i className="feather-users w35 dark-text-white rounded-circle no-img-network-small"></i>
                                        }
                                        
                                      </figure>
                                      <h3 className="mb-0 mt-0 me-2 network-mini-title">
                                          {
                                            (amOnNetwork || networkIsPublic)
                                              ? <Link to={`/network/${network.id}`}><span className="font-xssss text-grey-600 d-block text-dark model-popup-chat pointer">{network.name}</span></Link>
                                              : <Link to={`/network/${network.id}`}><span className="font-xssss text-grey-600 d-block text-dark model-popup-chat pointer">{network.name}</span></Link>
                                          }
                                        </h3>
                                    </div>
                                      
                                      
                                    <span className="me-0 mt-0">
                                      {
                                        (amOnNetwork || networkIsPublic)
                                          ? <span className="hovertext hovertext-left" data-hover={i18n.t('you_are_on_network')}>
                                              <span className="feather-user ms-auto btn-round-xss text-green mt-0"></span>
                                          </span>
                                          : <span className="hovertext hovertext-left" data-hover={i18n.t('join_network')}>
                                              <span className="feather-user-plus ms-auto btn-round-xss"></span>
                                          </span>
                                      }
                                    </span>
                                    
                                </div>
                                );
                              })
                            }
                        </div>
                    </div> */}
                </div>
            </div>
            </Fragment>
        );
    }
}

export default Rightchat;