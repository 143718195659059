import React,{Component, Fragment, createRef} from 'react';
import validator from 'validator'
import Preview from './Preview';
import Replyview from '../components/Replyview';
import i18n from '../i18n';
import Utils from "../utils"
import request from "../request";
import storage from '../storage';

class Commentview extends Component {

  constructor(props) {
    super(props);

    this.menuRef = createRef(null)
    this.replyRef = createRef(null)
    this.state = {
      replieces: [],
      reply: '',
      meta: [],
      isCommentMenuOpen: false,
      isCommentActive: false,
      isReplyOpen: false,
      isRepliecesLoaded: false,
    };

    this.getCommentReplieces = this.getCommentReplieces.bind(this);
    this.handleReplyChange = this.handleReplyChange.bind(this);
    this.addReply = this.addReply.bind(this);
    this.removePreview = this.removePreview.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.onDocumentClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onDocumentClick)
  }

  onDocumentClick(e) {
    if (this.menuRef.current && !this.menuRef.current.contains(e.target) && this.state.isCommentMenuOpen === true) {
      this.setState({
        isCommentMenuOpen: false
      })
    }

    // if (this.replyRef.current && !this.replyRef.current.contains(e.target) && this.state.isReplyOpen === true) {
    //   this.setState({
    //     isReplyOpen: false
    //   })
    // }
  }

  removePreview(){
    this.setState({
      ...this.state,
      meta: [],
    });
  }

  getCommentReplieces(comment){
    this.state.isRepliecesLoaded ?
    this.setState({ isReplyOpen: !this.state.isReplyOpen })
    : 
    request.get(`replieces/id/${comment}`).then((response) => {
      this.setState({
        ...this.state,
        replieces: this.state.replieces.concat(response.data),
        isReplyOpen: !this.state.isReplyOpen,
        isRepliecesLoaded: true,
      });
    })
  }

  handleReplyChange(e){
    var val = e.target.value;
    this.setState({reply: val});
    if(val && this.state.meta.length==0){
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      var url = val.match(urlRegex);
      if(url && validator.isURL(url[0])){
        request.post(`og`,{
          url: url[0],
        }).then((response) => {
          this.setState({
            ...this.state,
            meta: response.data,
          });
        });
      } 
    }
  }

  addReply(e){
    if (this.props && e.keyCode == 13) {
      request.post(`replieces`,{
        reply: this.state.reply,
        meta: this.state.meta,
        post: this.props.postid,
        comment: this.props.commentid,
        me: localStorage.loginId,
        member: this.props.member.id,
      }).then((response) => {
        this.setState({
          ...this.state,
          replieces: response.data.concat(this.state.replieces),
          meta: [],
          reply: '',
        });
  
        // if (requestResponse === 'accept' && storage.me) {
        //   socket.send({
        //     type: 'event',
        //     data: {
        //       class: 'friend-accepted',
        //       realtor: senderId,
        //       me: `${storage.me.realtors_fname} ${storage.me.realtors_lname}`,
        //     },
        //   });
        // }
      });
    }
  }

  removeReply(reply){
    request.delete(`replieces/id/${reply}`).then((response) => {
      if(response.data.status === '1'){
        const replieces = this.state.replieces.filter(c => c.id != reply);
        this.setState({
          ...this.state,
          replieces: replieces,
        });
      }
    });
  }

  setEmotion(id, emotion){
    request.put(`replieces`,{
      id: id,
      emotion: emotion,
    }).then((response) => {
      const replieces = JSON.parse(JSON.stringify(this.state.replieces));
      replieces.filter(p => p.id == id)[0].emotions = response.data[0].emotions;
      this.setState({
        ...this.state,
        replieces: replieces,
      });
    });
  }

  toggleRepliecesOpen = (e) => this.getCommentReplieces(e);
  toggleMenuOpen = () => this.setState({ isCommentMenuOpen: !this.state.isCommentMenuOpen });
  toggleActive = () => this.setState({ isCommentActive: !this.state.isCommentActive });
    
  render() {
    
    const {user, time, desc, avater, commentid, meta, removeComment, commentowner, me, commentreplieces, commentemotions, setEmotion} = this.props;
    const image = me && me.profile_image != '' ? me.profile_image : null
    const emotions = (commentemotions && commentemotions.emoji ? commentemotions.emoji.split(',') : null);
    const action = "postView";
    const commentMenuClass = `${this.state.isCommentMenuOpen ? " show" : ""}`;
    const commentEmojiClass = `${this.state.isCommentActive ? " active" : ""}`;
    const replyClass = `${this.state.isReplyOpen ? " show" : " hide"}`;

    return (
      <div className='d-flex'>
        <figure className="avatar me-3"><img src={`${avater}`} alt="avater" className="shadow-sm rounded-circle w35" /></figure>
        <div style={{flex: '2'}} className='d-flex flex-column'>
          <div className="card w-100 shadow-xss rounded-xxl border-0 p-3">
            <div className="card-body p-0 d-flex">
              
              <h4 className="fw-700 text-grey-900 font-xssss mt-1"> {user}</h4>
              {commentowner ? 
              <div className="ms-auto" style={{position: 'relative'}} ref={this.menuRef}>
                <div onClick={this.toggleMenuOpen} className={`ms-auto pointer ${commentMenuClass}`} style={{textAlign: 'right'}} id={`commentMenu${commentid}`} data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="ti-more-alt text-grey-900 font-xsss"></i>
                </div>
                <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${commentMenuClass}`} aria-labelledby={`commentMenu${commentid}`}>
                  <div onClick={() => removeComment(commentid)} className="card-body p-0 d-flex">
                    <i className="feather-trash-2 text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{i18n.t('button_delete')} <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">{i18n.t('button_delete_comment_info')}</span></h4>
                  </div>
                </div>
              </div> 
              : '' }
            </div>
            <div className="card-body p-0">
              <p className="fw-500 text-grey-900 font-xsss">
                {desc}
                {/* <a href="/defaultvideo" className="fw-600 text-primary ms-2">See more</a> */}
              </p>
            </div>
            {/* {meta ? <Preview meta={meta} action={action} /> : null} */}
          </div>
          <div className="card-body d-flex p-0">
          <div onMouseOver={this.toggleActive} onMouseOut={this.toggleActive} style={{position: 'relative', marginTop: '1px'}}>
            <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
              {commentemotions && commentemotions.me ? <span>{i18n.t(`${commentemotions.me}`)} </span> : <span>{i18n.t('like')} </span> }
              &nbsp;&nbsp;&nbsp;&nbsp; 
            </div>
            <div className={`emoji-wrap pointer ${commentEmojiClass}`} style={{ minWidth: '310px', bottom: '30px'}}>
                <ul className="emojis list-inline mb-0">
                    <li onClick={() => setEmotion(commentid, "--1")} className="emoji list-inline-item"><i className="em em---1"></i> </li>
                    <li onClick={() => setEmotion(commentid, "heart")} className="emoji list-inline-item"><i className="em em-heart"></i> </li>
                    <li onClick={() => setEmotion(commentid, "blush")} className="emoji list-inline-item"><i className="em em-blush"></i></li>
                    <li onClick={() => setEmotion(commentid, "smile")} className="emoji list-inline-item"><i className="em em-smile"></i> </li>
                    <li onClick={() => setEmotion(commentid, "astonished")} className="emoji list-inline-item"><i className="em em-astonished"></i> </li>
                    <li onClick={() => setEmotion(commentid, "cry")} className="emoji list-inline-item"><i className="em em-cry"></i></li>
                    <li onClick={() => setEmotion(commentid, "angry")} className="emoji list-inline-item"><i className="em em-angry"></i></li>
                </ul>
            </div>
          </div>
          <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
            {emotions ? 
              <Fragment>
                {
                  emotions && emotions.map( (emotion, i) => {
                    return <i key={i} className={`em em-${emotion} font-xssss`} />
                  })
                }
              </Fragment>
            : null }
            &nbsp;
            {commentemotions && commentemotions.total > 0 ? 
              <Fragment>
                {commentemotions.total}
              </Fragment>
            : null }
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;
            <span onClick={() => this.toggleRepliecesOpen(commentid)} id={`leaveAReplyOnComment${commentid}`} className="pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"> 
              {commentreplieces == 0 ? i18n.t('reply') : ( commentreplieces == 1 ? `${commentreplieces} ${i18n.t('reply')}` : `${commentreplieces} ${i18n.t('replieces')}`) }
            </span>&nbsp;&nbsp;
            <span className="pointer d-flex align-items-center text-grey-500 lh-26 font-xssss me-2"> 
              {time}
            </span>
          </div>
          <div aria-labelledby={`leaveAReplyOnComment${commentid}`} className={`post-comments ${replyClass}`} ref={this.replyRef}>
            <div className="card-body p-0 mt-3 position-relative">
              <figure className="avatar position-absolute ms-2 mt-1 top-5">
                <img src={image || "assets/images/user.png"} alt="icon" className="shadow-sm rounded-circle w25" />
              </figure>
              <textarea name="reply" onChange={this.handleReplyChange} value={this.state.reply} onKeyDown={this.addReply} className="h100 sm-h45 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" cols="30" rows="10" placeholder={i18n.t('write_a_reply')}></textarea>
            </div>
            {this.state.meta.length > 0 ? <Preview meta={this.state.meta[0]} removePreview={this.removePreview} action="createReply" /> : null}
            {
            this.state.replieces && this.state.replieces.map( (r, i) => {
              return <Replyview key={i} 
                replyid={r.id} 
                replyowner={r.owner}
                avater={r.user_image} 
                user={r.user_name} 
                meta={r.meta}
                me={storage.me}
                time={ Utils.getMomentText(r.timestamp) } 
                desc={ Utils.getMinifiedDescription(r.description, 200) } 
                replyemotions={r.emotions}
                removeReply={(e) => this.removeReply(e)}
                setEmotion={(a,b) => this.setEmotion(a,b)}
              />
            })
          }
          </div>
        </div>
        
      </div>
    );
  }
}

export default Commentview;