import React, {useState, useRef, Component , Fragment } from "react";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import axios from 'axios';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import i18n from "../i18n";
import request from '../request';
import '../css/style.css';
import Swal from "sweetalert2";


function Password (){

    const passwordElemRef = useRef(null)
    const newPasswordElemRef = useRef(null)
    const confirmNewPasswordElemRef = useRef(null)

    const {register, handleSubmit, getValues, watch, formState: { errors }} = useForm({mode: "onBlur"});
    const onSubmit = (data) => {};

    const [user, setUser] = useState({
        password: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    const errorMessages = {
        password_field_required: i18n.t('password_field_required'),
        newPassword_field_required: i18n.t('newPassword_field_required'),
        confirmNewPassword_field_required: i18n.t('confirmNewPassword_field_required'),
        newPassword_confirmNewPassword_missmatch: i18n.t('newPassword_confirmNewPassword_missmatch'),
        expired: i18n.t('expired'),
        password_changed: i18n.t('password_changed'),
        wrong_password: i18n.t('wrong_password'),
    }

    const newPassword = useRef();
    newPassword.current = watch("newPassword", "");
        
    const updatePass = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value});
    };

    const updateNewPass = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value});
    };
        
    const [error, setError] = useState({message: "", isError: false});

    const submitHandler = async (user, e) => {
        e.preventDefault();
        try {
            const {password, newPassword, confirmNewPassword} = user;
            setError({message: "", isError: false }); 
            request.post(`profile/password`, {
                ...user
            }).then(rsp => {
                const passed = rsp.status === 200
                Swal.fire({
                    title: passed ? i18n.t('success') : i18n.t('something_went_wrong'),
                    text: i18n.t(rsp.data.message),
                    icon: passed ? 'success' : 'error'
                })
            })
                
        } catch (error) {
            setError({message: error.message, isError: true});
        }        
    };
    
        return (
            <Fragment> 
                <div className="main-wrapper">
                    <Header />
                    <Leftnav />
                    <Rightchat />
                    <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
                
                        <div className="middle-sidebar-bottom">
                            <div className="middle-sidebar-left">
                                <div className="middle-wrap">
                                    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                        <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                            <Link to="/settings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                            <h1 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">{i18n.t('password')}</h1>
                                        </div>
                                        <div className="card-body p-lg-5 p-4 w-100 border-0">
                                            <form onSubmit={handleSubmit(submitHandler)}>
                                                <div className="row">
                                                    {/* CURRENT PASSWORD */}
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="form-group icon-input with-label mb-1">
                                                            <label className="mont-font fw-600 font-xssss">{i18n.t('current_password')}:</label>
                                                            <input type="password" 
                                                            ref={passwordElemRef}
                                                            name="password"
                                                            id="password"
                                                            className="style2-input login-input px-5 form-control text-grey-900 font-xss ls-3"
                                                            value={user.password}
                                                            {...register("password", {minLength: 6})}
                                                            onChange={updatePass} />
                                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                                            <i 
                                                            onMouseEnter={() => {
                                                                document.getElementById('password').type = 'text'
                                                            }}
                                                            onMouseLeave={() => {
                                                                document.getElementById('password').type = 'password'
                                                            }}
                                                            className="font-sm ti-eye pos-right preview-pass text-grey-500 pe-0"></i>
                                                        </div>        
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    {/* NEW PASSWORD */}
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="form-group icon-input with-label mb-1">
                                                            <label className="mont-font fw-600 font-xssss">{i18n.t('new_password')}:</label>
                                                            <input type="password" 
                                                            ref={newPasswordElemRef}
                                                            name="newPassword"
                                                            id="newPassword"
                                                            className="style2-input login-input px-5 form-control text-grey-900 font-xss ls-3"
                                                            {...register("newPassword", {required: true})} 
                                                            onChange={updateNewPass} />
                                                            {errors.newPassword?.type === "required" && <p className="error_message">{i18n.t('error_field')}</p>}
                                                            {/* {errors.newPassword?.type === "pattern" && <p className="error_message">{i18n.t('password_error')}</p>} */}
                                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                                            <i 
                                                            onMouseEnter={() => {
                                                                document.getElementById('newPassword').type = 'text'
                                                            }}
                                                            onMouseLeave={() => {
                                                                document.getElementById('newPassword').type = 'password'
                                                            }}
                                                            className="font-sm ti-eye pos-right preview-pass text-grey-500 pe-0"></i>
                                                        </div>        
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    {/* CONFIRM NEW PASSWORD */}
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="form-group icon-input with-label mb-1">
                                                            <label className="mont-font fw-600 font-xssss">{i18n.t('confirm_new_password')}:</label>
                                                            <input type="password" 
                                                            ref={confirmNewPasswordElemRef}
                                                            name="confirmNewPassword"
                                                            id="confirmNewPassword"
                                                            className="style2-input login-input px-5 form-control text-grey-900 font-xss ls-3"
                                                            {...register("confirmNewPassword", {required: true,
                                                            validate: {
                                                                matchesPreviousPassword: (value) => {
                                                                  const { newPassword } = getValues();
                                                                  return newPassword === value;
                                                                }
                                                              }})} />
                                                            {errors.confirmNewPassword && <p className="error_message">{i18n.t('passwords_dont_match')}</p>}
                                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                                            <i 
                                                            onMouseEnter={() => {
                                                                document.getElementById('confirmNewPassword').type = 'text'
                                                            }}
                                                            onMouseLeave={() => {
                                                                document.getElementById('confirmNewPassword').type = 'password'
                                                            }}
                                                            className="font-sm ti-eye pos-right preview-pass text-grey-500 pe-0"></i>
                                                        </div>        
                                                    </div>                                     
                                                </div>
                                                <div className="row">
                                                    {/* SUBMIT BUTTON */}
                                                    <div className="col-lg-12 mb-0">
                                                        <button type='submit' className="border-none bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">{i18n.t('save')}</button>
                                                    </div>
                                                </div>                                            
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>            
                    </div>
                </div>
            </Fragment>
        );    
}

export default Password;