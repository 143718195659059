import React from 'react';

class LoadingEvent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
		<div className={`loading-fixed-wrapper ${this.props.show ? '' : 'hidden'}`}>
			<div className={`loadingio-spinner-rolling-wcum84rzqc`}>
				<div className="ldio-hjppnckp3cn">
					<div></div>
				</div>
			</div>
		</div>
      
    );
  }
}

export default LoadingEvent;