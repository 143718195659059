import React, { Component , Fragment } from "react";
import request from "../request";
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import i18n from "../i18n";

class MapWithMarker extends Component {

  static defaultProps = {
    center: {
      lat: 39.6642211,
      lng: 20.85371
    },
    zoom: 15
  };

  constructor(props) {
    super(props);

    this.state = {
      address: '',
      searchQuery: '',
      imagesLoaded: true,
      loaded: false,
      fetchingSearchResults: false,
      page: 1,
      mapDirty: false,
      mapPristine: true,
    };

    this.mapRef = React.createRef();

    this.getAddress = this.getAddress.bind(this);
  }

  componentDidMount() {

    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    this.getAddress().then(() => {
      this.initMap();
    });

  
  }

  getAddress() {
    return request.get(`contactinformation/`).then((response) => {
      this.setState({
        ...this.state,
        address: this.state.address.concat(response.data.data),
        loaded: true,
      });
    });
  }

  initMap() {
    let tileLayer = L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',{
      attribution: 'Real Status Network',
    });

    this.map = L.map(this.mapRef.current, {
      layers: [tileLayer],
      maxZoom: 19,
      minZoom: 5,
      gestureHandling: true
    });
    const map = this.map;

    map.setView([38.227322, 23.211220], 7);
    
    map.on('moveend', () => {
      const bounds = map.getBounds();
      this.searchByMapCenter(bounds);
    });

    this.markers = new L.MarkerClusterGroup({
      disableClusteringAtZoom: 15,
      maxClusterRadius: 120,
      spiderfyDistanceMultiplier: 1,
    });
    const markers = this.markers;

    this.customMarkerIcon = L.icon({
      iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=',
      iconSize: [25, 41],
    });

    this.createMapMarkers();

    map.addLayer(markers);
  }

  searchByMapCenter(bounds) {
    let southlat = bounds.getSouthWest().lat;
    let southlng = bounds.getSouthWest().lng;
    let northlat = bounds.getNorthEast().lat;
    let northlng = bounds.getNorthEast().lng;

    request.get(`contactinformation//map-search/${northlat}x${northlng},${southlat}x${southlng}?page=${this.state.page}`).then((response) => {
      const markers = this.markers;
      // const map = this.map;

      markers.clearLayers();
      
      this.createMapMarkers();

      this.setState({
        ...this.state,
        address: response.data.data
      });
    });
  }

  createMapMarkers() {
    const map = this.map;
    const markers = this.markers;

    this.state.address.forEach((address, i) => {
      markers[i] = L.marker([address.latitude, address.longitude], {icon: this.customMarkerIcon, });
      markers[i].on("click", function(e) {
        L.popup()
        .setLatLng(e.latlng)
        .setContent(`<button>asd</button>`)
        .openOn(map);
      });
      markers.addLayer(markers[i]);
    });
  }

  render() {
    return (
      <Fragment>
        
          <div id="map" ref={this.mapRef}></div>
   
      </Fragment>
    );
  }
}

export default MapWithMarker;