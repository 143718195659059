import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import i18n from "../i18n";
import request from '../request';
import socket from '../socket';
import axios from "axios";
import * as L from "leaflet";
import '../css/leaflet.css'
import '../css/MarkerCluster.Default.css'
import '../css/MarkerCluster.css'
// import { Select } from "@material-ui/core";
import { Select } from "@mui/material";

class Heatmap extends Component {
  constructor(props) {
    super(props);

    this.state = {
		stat_option: ''
    };

    this.mapRef = React.createRef();
	this.polygonRef = React.createRef();
	this.handleStatChange = this.handleStatChange.bind(this)
  }
  

  componentDidMount() {
    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

	this.initMap()
  }

  initMap(index = 'sales_com') {
	var basemap; 
	var counties;
	var polygons;
	var info = L.control({position:'topleft'});

	if (this.polygonmap) {
		this.polygonmap.off();
    	this.polygonmap.remove();
	}
	
    this.polygonmap = L.map(this.polygonRef.current)
			.setView([40.59671410609588, 22.95644725545019], 6);
	const polygonmap = this.polygonmap;

	basemap = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
		attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		maxZoom: 19
	}).addTo(polygonmap);

    function getColor(rentres) {
		return rentres > 3000 ? '#800026' :
				rentres > 2000  ? '#BD0026' :
				rentres > 1000  ? '#E31A1C' :
				rentres > 500  ? '#FC4E2A' :
				rentres > 200   ? '#FD8D3C' :
							'#FFEDA0';
	}

	function highlightFeature(e) {
		var layer = e.target;
	
		layer.setStyle({
			// weight: 2,
			color: '#666',
			dashArray: '',
			fillOpacity: 0.7
		});
	
		if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
			layer.bringToFront();
		}

		info.update(layer.feature.properties);
	}

	function resetHighlight(e) {
		polygons.resetStyle(e.target);
		info.update();
	}

	function style(feature) {
		return {
			fillColor: getColor(feature.properties[index]),
			weight: 2,
			opacity: 1,
			color: 'white',
			dashArray: '3',
			fillOpacity: 0.7
		};
	}
	
	function zoomToFeature(e) {
		polygonmap.fitBounds(e.target.getBounds());
	}

	function onEachFeature(feature, layer) {
		layer.on({
			mouseover: highlightFeature,
			mouseout: resetHighlight,
			click: zoomToFeature
		});
	}

    var legend = L.control({position: 'bottomright'});
	request.get('/sync_heatmap');
	
	fetch('polygons.json')
	.then(r => r.json()).then(data => {
		counties = data
		polygons = L.geoJson(counties, 
		{style: style,
		onEachFeature: onEachFeature
		}).addTo(polygonmap);

		legend.onAdd = function () {

			var div = L.DomUtil.create('div', 'info legend'),
				grades = [0, 200, 500, 1000, 2000, 3000],
				labels = [];
	
			// loop through our density intervals and generate a label with a colored square for each interval
			for (var i = 0; i < grades.length; i++) {
				div.innerHTML +=
					'<div className="inner-flex"><i style="background:' + getColor(grades[i] + 1) + '"></i><span>' + 
					grades[i] + (grades[i + 1] ? '&ndash;' + grades[i + 1] + '€ / m<sup>2</sup></span>' : '+') +"</div>";
			}
	
			return div;
		};
	
		legend.addTo(polygonmap);
		

		info.onAdd = function () {
			this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
			this.update();
			return this._div;
		};

		// method that we will use to update the control based on feature properties passed
		info.update = function (props) {
			this._div.innerHTML = '<h4>Statistics</h4>' +  (props ?
				'<b>' + props.r_name + '</b><br />' + props[index] + '€ / m<sup>2</sup>'
				: '-');
		};

		info.addTo(polygonmap);
		
		
	})
  }

  handleStatChange(e) {
	this.initMap(e.target.value)
	this.setState({
		...this.state,
		stat_option: e.target.value
	})
  }

  componentWillUnmount() {
	
  }

  render() {
	const options=[
		{
			label: i18n.t('Επιλέξτε μέση τιμή'),
			value: ''
		},
		{
			label: i18n.t('Οικιστικών ανά τμ'),
			value: 'sales_res'
		},
		{
			label: i18n.t('Επαγγελματικών ανά τμ'),
			value: 'sales_com'
		},
		{
			label: i18n.t('Γης ανά τμ'),
			value: 'sales_land'
		},
	];
    return (
      <Fragment> 
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content position-relative">
			<select className="polygon-select" value={this.state.stat_option} onChange={this.handleStatChange}>
				{options.map((option) => (
				<option value={option.value}>{option.label}</option>
				))}
			</select>
			<div className='polygon-map h-100' style={{minHeight: '80vh'}} ref={this.polygonRef}></div>
        </div>

        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default Heatmap;