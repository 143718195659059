import React from 'react';

class Avatar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      handled: false,
      showOriginalImage: true,
    };
  }

  componentDidMount() {
    const img = new Image();
    img.onload = () => {
      this.setState({
        ...this.state,
        handled: true,
      });
    };
    img.onerror = () => {
      this.setState({
        ...this.state,
        showOriginalImage: false,
        handled: true,
      });
    };
    img.src = this.props.src;
  }

  render() {
    return (
      <div className={``}>
        {
          this.state.showOriginalImage && this.state.handled
            ? <img src={`${this.props.src}`} alt="avater" className={`float-right p-1 bg-white rounded-circle w-100`} />
            : <img src={`/assets/images/no_img.png`} alt="avater" className={`float-right p-1 bg-white rounded-circle w-100`} />
        }
      </div>
    );
  }
}

export default Avatar;