import React,{Component, Fragment} from 'react';
import i18n from '../i18n';
import socket from '../socket';
import request from '../request';
import storage from '../storage';
import Button from './Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAltSlash, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

class FriendButtons extends Component {
	constructor(props) {
		super(props);

		this.state = {
			metadata: null,
		};

		this.respondToFriendRequest = this.respondToFriendRequest.bind(this);
	}

	componentDidMount() {
		storage.onChange((key, value) => {
			if (key === 'me') {
			  this.forceUpdate();
			}
		  });
	}

	deleteFriend(friendshipId) {
		Swal.fire({
			title: i18n.t('are_you_sure'),
			text: i18n.t('you_are_going_to_delete_this_user_from_your_friend_list'),
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: i18n.t('cancel'),
			confirmButtonText: i18n.t('delete')
		}).then(answer => {
			if (answer.isConfirmed) {
				request.post(`connections/id/${friendshipId}/response/delete`).then((response) => {
					if (response.data.status === '1') {
					  this.props.onDeleteFriend(friendshipId, response.data);
					}
				});
			}
		})
		
	  }
	
	addFriend(friendId) {
		request.put(`connections/to/${friendId}`).then((response) => {
		  if (response.data.status === '1') {
			this.props.onAddFriend(response.data);
	
			request.put(`notifications/to/${this.props.data.id}/type/friend-request/ref/${response.data.connection_id}`).then(() => {
			  socket.send({
				type: 'event',
				data: {
				  class: 'friend-request',
				  realtor: this.props.data.id,
				},
			  });
			});
		  }
		});
	}
	
	cancelFriendRequest(friendshipId) {
		request.post(`connections/id/${friendshipId}/response/cancel`).then((response) => {
		  if (response.data.status === '1') {
			this.props.onDeleteFriend(friendshipId, response.data);
		  }
		});
	}
	
	respondToFriendRequest(friendRequestId, requestResponse) {
		request.post(`connections/id/${friendRequestId}/response/${requestResponse}`).then((response) => {
		  if (response.data.status === '1') {
			if(response.data.connection_status === '1') {
				socket.send({
					type: 'event',
					data: {
					  class: 'friend-accepted',
					  realtor: this.props.data.id,
					},
				});
			}
			this.props.onFriendRequestResponse(requestResponse);
		  }
		});
	}

	render() {
		if (this.props.data === null || this.props.data.id === null) return ''
		return (	
			
			<div className="card-body d-block w-100 p-4 text-center">
				{
					this.props.data.connection_id === null || this.props.data.connection_status === '2'
					? <button
						className={`hollow-btn p-0 btn p-2 lh-24 w150 ms-1 ls-3 rounded-xl bg-success font-xsssss fw-700 ls-lg text-white`}
						onClick={() => this.addFriend(this.props.data.id)}
						>
						<FontAwesomeIcon icon={faUserPlus} /> {i18n.t('send_friend_request')}
						</button>
					: ''
				}
				{
					this.props.data.connection_id !== null && this.props.data.connection_status === '0' && (this.props.me && parseInt(this.props.me) === parseInt(this.props.data.connection_sender))
					? <button
						className={`hollow-btn hollow-btn-danger d-block bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3`}
						onClick={() => this.cancelFriendRequest(this.props.data.connection_id)}
						>
						{i18n.t('button_cancel_friend_request')}
						</button>
					: ''
				}
				{
					this.props.data.connection_id !== null && this.props.data.connection_status === '0' && (this.props.me && parseInt(this.props.me) !== parseInt(this.props.data.connection_sender))
					? <Fragment>
						<h6 className='text-grey-900 font-xsss d-block'>{i18n.t('user_sended_you_a_friend_request')}</h6>
						<button
							className={`hollow-btn p-0 btn p-2 lh-24 w125 ms-1 ls-3 rounded-xl font-xsssss fw-700 ls-lg bg-primary text-white white-space-no-wrap`}
							onClick={() => this.respondToFriendRequest(this.props.data.connection_id, 'accept')}
						>
						<FontAwesomeIcon icon={faUserPlus} /> {i18n.t('Αποδοχή')}
						</button>
						<button
							className={`hollow-btn hollow-btn-danger p-0 btn p-2 lh-24 w125 ms-1 ls-3 rounded-xl font-xsssss fw-700 ls-lg bg-danger text-white white-space-no-wrap`}
							onClick={() => this.respondToFriendRequest(this.props.data.connection_id, 'reject')}
						>
							<FontAwesomeIcon icon={faUserAltSlash} /> {i18n.t('button_reject_friend_request')}
						</button>
						</Fragment>
					: ''
				}
				{
					this.props.data.connection_id !== null && this.props.data.connection_status === '1'
					? <button
						className="hollow-btn hollow-btn-danger p-0 btn p-2 lh-24 w150 ms-1 ls-3 rounded-xl bg-danger font-xsssss fw-700 ls-lg text-white"
						onClick={() => this.deleteFriend(this.props.data.connection_id)}
						>
						<FontAwesomeIcon icon={faUserAltSlash} /> {i18n.t('button_delete_friend')}
					  </button>
					: ''
				}
				
			</div>
						
				
		);
	}
}

export default FriendButtons;