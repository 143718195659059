import React,{Component} from 'react';
import i18n from '../i18n';

class Pagetitle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
    };

    this.onQueryChange = this.onQueryChange.bind(this);
  }

  componentDidMount() {
		this.unlistenLocale = i18n.onChangeLocale(() => {
			this.forceUpdate();
		});
  }

  componentWillUnmount() {
		this.unlistenLocale()
	}
  onQueryChange(e) {
    this.setState({
      query: e.target.value,
    });
    this.props.onSearchQueryChange(e.target.value);
  }

  render() {
    const {title, noSearch} = this.props;
    return (
      <div className='card shadow-xss w-100 d-block border-0 p-4 mb-3  page-title-header'>
      <div className="d-flex flex-row space-between">
        <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">{title}</h2>
        {
          this.props.children &&
          <div className="pt-0 pb-0 ms-auto d-flex page-title-right-section">
            {this.props.children}
          </div>
        }
        {
          this.props.btn &&
          <div className="pt-0 pb-0 ms-auto d-flex page-title-right-section">
            {this.props.btn}
          </div>
        }
      </div>
      {
        this.props.subtitle &&
        <h2 className="fw-300 mb-0 mt-2 font-xs text-grey-900 d-flex align-items-center">
          {this.props.subtitle}
        </h2>
      } 
      </div>           
    );
  }
}

export default Pagetitle;


