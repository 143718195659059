import React, { Fragment } from 'react';
import uriHelper from '../uri_helper';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';

class ActiveFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
		filters: []
    };

	this.ref = React.createRef(null)

  }


  onUnloadFilter(key, value) {
	uriHelper.unset(key, false, value)
  }

  render() {
	const filters = this.props.filters
    return (
      <div className='active-filters'>
		{
			filters.length > 0 &&
			filters.map(f => {
				return 	<div className='active-filter' onClick={() => this.onUnloadFilter(f.key, f.value)}>
							<span>{i18n.get_data('label', f)}</span> 
							<span>{i18n.get_data('value', f)}</span>
							<span>{i18n.get_data('pad_text', f)}</span>
							<FontAwesomeIcon icon={faRemove} />
						</div>
			})
		}
		
	  </div>
    );
  }
}

export default ActiveFilters;