import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import FriendRequestsList from '../components/FriendRequestsList';
import RealtorCard from '../components/RealtorCard';
import i18n from "../i18n";
import request from '../request';
import socket from '../socket';

class Friends extends Component {
  constructor(props) {
    super(props);

    this.state = {
      friends: [],
      searchQuery: '',
      imagesLoaded: true,
      loaded: false,
      fetchingSearchResults: false,
      page: 1,
    };

    this.searchTimeout = null;

    this.search = this.search.bind(this);
    this.onDeleteFriend = this.onDeleteFriend.bind(this);
    this.onAddFriend = this.onAddFriend.bind(this);
    this.getFriends = this.getFriends.bind(this);
  }

  componentDidMount() {
    this.unmountLocale = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    this.getFriends();

    this.onDocumentScroll = (e) => {
      const totalPageHeight = document.body.scrollHeight - 1; 
      const scrollPoint = window.scrollY + window.innerHeight;
      if(scrollPoint >= totalPageHeight) {
        this.showMoreFriends();
      }
    }

    document.addEventListener('scroll', this.onDocumentScroll);

    this.onSocketEventUnlisten = socket.onEvent((data) => {
      if (data.class === 'friend-accepted') {
        this.getFriends();
      }
    });
  }

  componentWillUnmount() {
    this.onSocketEventUnlisten();
    this.unmountLocale()
    document.removeEventListener('scroll', this.onDocumentScroll);
  }

  getFriends(page) {
    request.get(`connections/list/friends?page=${page || this.state.page}`).then((response) => {
      this.setState({
        ...this.state,
        friends: this.state.friends.concat(response.data.data),
        loaded: true,
      });
    });
  }

  onDeleteFriend(friendshipId, data) {
    const f = this.state.friends.slice(0);
    const i = f.findIndex((friend) => parseInt(friend.connection_id) === parseInt(friendshipId));
    f[i].connection_id = data.connection_id;
    f[i].connection_sender = data.connection_sender;
    f[i].connection_receiver = data.connection_receiver;
    f[i].connection_status = data.connection_status;
    this.setState({
      ...this.state,
      friends: f,
    });
  }

  onAddFriend(data) {
    const f = this.state.friends.slice(0);
    const i = f.findIndex((friend) => parseInt(friend.id) === parseInt(data.connection_receiver));
    f[i].connection_id = data.connection_id;
    f[i].connection_sender = data.connection_sender;
    f[i].connection_receiver = data.connection_receiver;
    f[i].connection_status = data.connection_status;
    this.setState({
      ...this.state,
      friends: f,
    });
  }

  search(query) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (query.trim().length > 2) {
        request.get(`connections/list/friends/search/${query.trim()}`).then((response) => {
          this.setState({
            ...this.state,
            friends: response.data.data,
            fetchingSearchResults: false,
          });
        });
      } else {
        request.get(`connections/list/friends`).then((response) => {
          this.setState({
            ...this.state,
            friends: response.data.data,
            fetchingSearchResults: false,
          });
        });
      }
      this.setState({
        ...this.state,
        searchQuery: query,
        fetchingSearchResults: true,
      });
    }, 350);
  }

  showMoreFriends() {
    const newPage = this.state.page + 1;

    this.getFriends(newPage);

    this.setState({
      ...this.state,
      page: newPage,
    });
  }

  render() {
    return (
      <Fragment> 
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title={i18n.t('friends')}
                    onSearchQueryChange={this.search}
                  />
                  <div className="row">
                    <div className="col-12">
                      <FriendRequestsList />
                    </div>
                  </div>
                  <div className="row ps-2 pe-1">
                    <div className="col-12">
                      {
                        this.state.loaded === false
                          ? <div className="row">
                              {Array.from(Array(3)).map((n, i) => (
                                <div key={i} className="col-md-4 col-sm-6 pe-2 ps-2">
                                  <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3 skeleton-shimmer">
                                    <div className="card-body d-block w-100 p-4 text-center">
                                      <figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1">
                                        <div
                                          className="skeleton-shimmer"
                                          style={{
                                            width: '90px',
                                            height: '90px',
                                            backgroundColor: '#EEE',
                                            borderRadius: '50%',
                                          }}
                                        ></div>
                                      </figure>
                                      <div className="clearfix"></div>
                                      <h4 className="fw-700 font-xss mt-3 mb-0">
                                        <div className="d-flex flex-direction-row justify-content-around align-items-start">
                                          <div
                                            style={{
                                              width: '100px',
                                              height: '25px',
                                              backgroundColor: '#EEE',
                                              borderRadius: '27px',
                                            }}
                                          ></div>
                                          <div
                                            style={{
                                              width: '100px',
                                              height: '25px',
                                              backgroundColor: '#EEE',
                                              borderRadius: '27px',
                                            }}
                                          ></div>
                                        </div>
                                      </h4>
                                      <div className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                                        <div className="d-flex flex-direction-row justify-content-around align-items-start">
                                          <div
                                              className="mt-2"
                                              style={{
                                                width: '230px',
                                                height: '25px',
                                                backgroundColor: '#EEE',
                                                borderRadius: '27px',
                                              }}
                                          ></div>
                                        </div>
                                      </div>
                                      <ul className="d-flex align-items-center justify-content-center mt-1">
                                        <li className="m-2">
                                          <h4 className="fw-700 font-sm">
                                            <div
                                              style={{
                                                width: '50px',
                                                height: '25px',
                                                backgroundColor: '#EEE',
                                                borderRadius: '27px',
                                              }}
                                            ></div>
                                          </h4>
                                        </li>
                                        <li className="m-2">
                                          <h4 className="fw-700 font-sm">
                                            <div
                                              style={{
                                                width: '50px',
                                                height: '25px',
                                                backgroundColor: '#EEE',
                                                borderRadius: '27px',
                                              }}
                                            ></div>
                                          </h4>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          : ''
                      }
                      
                      <div className="row">
                      {
                        this.state.loaded && this.state.fetchingSearchResults === false
                          ? this.state.friends.map((data , index) => (
                                <div key={index} className="col-md-3 col-sm-4 pe-2 ps-2">
                                  <RealtorCard
                                    data={data}
                                    onDeleteFriend={this.onDeleteFriend}
                                    onAddFriend={this.onAddFriend}
                                  />
                                </div>
                            ))
                          : ''
                      }
                      </div>
                      {
                        this.state.loaded && this.state.friends.length === 0 && this.state.searchQuery.trim().length === 0 && this.state.fetchingSearchResults === false
                          ? <div className="d-flex flex-column justify-content-center align-items-center my-5">
                              <i className="feather-users text-muted" style={{fontSize: '50px'}}></i>
                              <span className="mt-2">{i18n.t('no_friends_message')}</span>
                            </div>
                          : ''
                      }
                      {
                        this.state.loaded && this.state.friends.length === 0 && this.state.searchQuery.trim().length > 2 && this.state.fetchingSearchResults === false
                          ? <div className="d-flex flex-column justify-content-center align-items-center my-5">
                              <i className="feather-search text-muted" style={{fontSize: '50px'}}></i>
                              <span className="mt-2">{i18n.t('no_search_results_found')}</span>
                            </div>
                          : ''
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default Friends;