import React, { Component } from "react";
import i18n from "../i18n";

class Modal extends Component {
	constructor(props) {
		super(props)

		this.handleClose = this.handleClose.bind(this)
	}
	
	handleClose() {
		if (this.props.handleClose) {
			this.props.handleClose()
		}
	}

	render() {
		return (
			<div className={`modal-wrapper ${this.props.show ? 'modal-show' : 'modal-hide'}`}>
				<div className="full-modal modal-dialog modal-lg">
					<div className="modal-content rounded-4">
						<div className="modal-header">
							<div className="card-body p-0 d-flex open-header">
								<div className="d-flex open-date">
									<span className="day-of-date">26</span>
									<span className="month-of-date">ΟΚΤ</span>
								</div>
								<div className='d-flex open-header-data ms-4'>
									<h2>Open house Τετάρτη 26 οκτ.</h2>
									<div className="d-flex">
										<h5 className="fw-600 text-grey-900 font-xssss">Από το χρήστη</h5>
										<h5 className="fw-600 text-grey-900 font-xssss ms-2">Χρήστος Βαρδάκας</h5>
									</div>
								</div>
								
							</div>
							<button
								type="button"
								className="close modal-close"
								data-dismiss="modal"
								aria-label="Close"
								onClick={() => this.handleClose()}
							>
								<span className="feather-x" aria-hidden="true"></span>
							</button>
						</div>
						<div className="modal-body">
							{
								this.props.children ? this.props.children : ''
							}
						</div>
						<div className="modal-footer">
							<div className="card-body p-0 d-flex space-between open-house-footer align-center">
								<div className="d-flex">
									<div class="d-flex open-date" style={{marginRight: "20px"}}><span class="day-of-date">53</span><span class="month-of-date">θα πάνε</span></div>
									<div class="d-flex open-date"><span class="day-of-date">122</span><span class="month-of-date">ενδιαφέρονται</span></div>
								</div>
								<div className="d-flex">
									<button className="bg-success btn-action btn-accept p-2 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-600">Θα πάω</button>
									<button className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">Ενδιαφέρομαι</button>
									<button className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">Δεν θα πάω</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Modal