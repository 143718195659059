import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Rightchat from "../components/Rightchat";
import Select from 'react-select';
import request from "../request";
import i18n from "../i18n";
import * as L from "leaflet";
import Utils from "../utils";
import Swal from "sweetalert2";
import history from '../helpers/history';
import InteractiveMap from "../components/InteractiveMap";
import storage from "../storage";

class RequestForm extends Component {

  constructor(props) {
    super(props);

    this.required_fields = [
      'int_to',
      'cat_basic_id',
    ]

    this.error_messages = {
      'int_to': i18n.t('please_choose_transaction'),
      'cat_basic_id': i18n.t('please_choose_basic_category'),
    }

    this.categoryRef = React.createRef(null)
    this.mapRef = React.createRef(null)

    this.yes_no_choices = [
      {
        label: i18n.t('indifferent'),
        value: 0
      },
      {
        label: i18n.t('yes'),
        value: 1
      },
      {
        label: i18n.t('no'),
        value: 2
      },
    ]
    /**
     * basic-info-fields
        location-fields
        optional-fields
     */
    this.state = {
      fields_to_show: 'basic-info-fields',
      form_data: {
        cat_basic_id: 1
      },
      publish: {
        profile: 0,
        networks: []
      },
      lookups: {
        transaction_type: [],
        categories_basic: [],
        categories: [],
        nomoi: [],
        regions: [],
        locations: [],
        zoning: [],
        floors: [],
        heat: [],
        views: [],
        frames: [],
        floors_materials: [],
        closets: [],
        doors: [],
        garden: [],
        pool: [],
        access_type: [],
        conditions: [],
        directions: [],
        orientations: [],
        public_services: [],
        current_type: [],
        land_use: [],
      },
      finished_load: false,
      storing: false,
      networks: []
    };

	  this.getCurrentValue = this.getCurrentValue.bind(this) 
	  this.getOptionValue = this.getOptionValue.bind(this) 
  }

  storeRequest() {
    if (!this.state.form_data.geo || this.state.form_data.geo.length === 0) {
      Swal.fire({
        title: i18n.t('something_went_wrong'),
        text: i18n.t('draw_at_least_one_search_location'),
        icon: 'error'
      })
      return;
    }

    if (!this.checkRequirements()) {
      Swal.fire({
        title: i18n.t('something_went_wrong'),
        text: this.error_messages[this.getNextRequired()],
        icon: 'error'
      })
      this.setState({
        storing: false
      })
      return
    }
    if (!this.state.storing) {

      this.setState({
        storing: true
      })
      if (this.state.form_data.id && this.state.form_data.id > 0) {
        request.post(`requests/update`, {
          ...this.state.form_data,
          publish_to: this.state.publish
        }).then(response => {
          if (response.status === 200) {
            Swal.fire({
              title: i18n.t('request_updated'),
              icon: 'success'
            }).then(() => {
              this.setState({
                storing: false
              })
            })
          }
          else {
            this.setState({
              storing: false
            })
          }
        })
      }
      else {
        request.post(`requests/store`, {
          ...this.state.form_data,
          publish_to: this.state.publish
        }).then(response => {
          if (response.status === 200) {
            Swal.fire({
              title: i18n.t('request_inserted'),
              icon: 'success'
            }).then(() => {
              history.push(`/manage_requests`)
            })
          }
          else {
            this.setState({
              storing: false
            })
          }
        })
      }
    }
    
    
  }

  componentDidMount() {
    const id = this.props.match.params.id

    if (id) {
      request.get(`request_for_edit/${id}`, this.state.form_data).then(response => {
        this.setState({
          form_data: {
            ...response.data.request,
            id: id
          },
          finished_load: true
        }, () => {
          this.showProperFieldsForCategory(this.state.form_data.cat_basic_id)
          this.getNetworks()
        })
      })
    }
    else {
      this.getNetworks()
      this.setState({
        finished_load: true
      })
      this.showProperFieldsForCategory(this.state.form_data.cat_basic_id)
    }

    storage.onChange(() => {
      this.getNetworks()
    })
    
    request.get('form_lookups').then(response => {
      
      var lookups = [] 
      Object.keys(response.data).map((index, obj) => {
        lookups[index] = response.data[index].map(lookup => {
          return {
            label: i18n.get_data('name', lookup),
            value: lookup.id,
            parent: lookup.parent ? lookup.parent : null
          }
        })
        
      })
      
      this.setState({
        lookups: lookups
      }, () => {
        this.setState({
          lookups: {
            ...this.state.lookups,
            categories: this.setShowSubcategories(this.state.form_data.cat_basic_id)
          }
        })
      })
    })

    
  }

  getNetworks = () => {
    request.get(`networks/members/${storage.me ? storage.me.realtors_id : ''}`).then(response => {
      if (response.status === 200) {
        
          this.setState({
            networks: response.data.map(a => {
              return {
                name: a.name,
                id: a.id,
                published: false
              }
            }),
            publish: {
              profile: this.state.form_data.published ? parseInt(this.state.form_data.published) : 0,
              networks: this.state.form_data.networks ? this.state.form_data.networks : []
            }
          })
        
        
      }
    });
  }

  setPublishedNetworks = (checked, network_id) => {
    if (!checked) {
      this.setState({
        publish: {
          ...this.state.publish,
          networks: this.state.publish.networks.filter(n => parseInt(n) !== parseInt(network_id))
        }
      })
    }
    else {
      this.setState({
        publish: {
          ...this.state.publish,
          networks: this.state.publish.networks.concat([network_id])
        }
      })
    }
  } 

  checkRequirements() {
    let passed = true

    this.required_fields.forEach(field => {
      if (!this.state.form_data[field] || this.state.form_data[field] == '') {
        passed = false
      }
    })

    return passed
  }
  getNextRequired() {
    let passed = null
    
    this.required_fields.forEach(field => {
      if (!this.state.form_data[field] || this.state.form_data[field] == '') {
        passed = field
        return
      }
    })

    return passed
  }

  showProperFieldsForCategory(categ_id) {
    document.querySelectorAll('.residential, .proffesional, .land, .parking').forEach(function(el) {
      el.style.display = 'none';
    });

    let categ_to_show = 'residential'
    if (categ_id == 1) {
      categ_to_show = 'residential'
    }
    else if (categ_id == 2) {
      categ_to_show = 'proffesional'
    }
    else if (categ_id == 3) {
      categ_to_show = 'land'
    }
    else if (categ_id == 4) {
      categ_to_show = 'parking'
    } 
    document.querySelectorAll(`.${categ_to_show}`).forEach(function(el) {
      if (el.nodeName === 'LABEL') {
        el.style.display = 'inline-block';
      }
      else {
        el.style.display = 'block';
      }
      
    });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.form_data.cat_basic_id !== this.state.form_data.cat_basic_id) {

  //   }
  // }

  setValue(key, value) {
    this.setState({
      form_data: {
        ...this.state.form_data,
        [key]: value
      }
    })
  }

  setValueMulti(key, value) {
    this.setState({
      form_data: {
        ...this.state.form_data,
        [key]: value.map(v => v.value).join(',')
      }
    })
  }

  getCurrentValue(key, is_bool = false) {
    if (is_bool) {
      return this.state.form_data[key] && (this.state.form_data[key] === "1" || this.state.form_data[key] === true)
    }
	  return this.state.form_data[key] ? this.state.form_data[key] : ''
  }

  getOptionValue(key, lookup, is_multi = false) {
    const value = this.getCurrentValue(key)
    if (value !== '' && this.state.lookups[lookup]) {
      if (is_multi) {
        return this.state.lookups[lookup].filter(l => `,${value},`.includes(`,${l.value},`))
      }
      return this.state.lookups[lookup].filter(l => parseInt(l.value) === parseInt(value))[0]
    }

    return ''
  }

  setShowSubcategories(cat_id) {
    return this.state.lookups.categories.map(c => {
      const show =  parseInt(c.parent) === parseInt(cat_id)
      return {
        ...c,
        show: show
      }
    })

  }
  setCategory(val) {
    const categories = this.setShowSubcategories(val)

    this.setState({
      form_data: {
        ...this.state.form_data,
        cat_basic_id: val,
        cat_id: ''
      },
      lookups: {
        ...this.state.lookups,
        categories: categories
      }
    }, () => {
      this.showProperFieldsForCategory(this.state.form_data.cat_basic_id)
    })
  }

  changeNomoi(val) {
    this.setState({
      form_data: {
        ...this.state.form_data,
        nom_id: val
      }
    }, () => {
      request.get(`lookups/regions/${val}`).then(response => {
        this.setState({
          lookups: {
            ...this.state.lookups,
            regions: response.data.map((obj) => {
                return {
                  label: i18n.get_data('name', obj),
                  value: obj.id,
                }
            })
          }
        })
        
      })
    })
  }

  changeRegions(val) {
    this.setState({
      form_data: {
        ...this.state.form_data,
        reg_id: val
      }
    }, () => {
      request.get(`lookups/locations/${val}`).then(response => {
        this.setState({
          lookups: {
            ...this.state.lookups,
            locations: response.data.map((obj) => {
                return {
                  label: i18n.get_data('name', obj),
                  value: obj.id,
                }
            })
          }
        })
      })
    })
  }

  setCheckValue = (key, check) => {
    this.setState({
      form_data: {
        ...this.state.form_data,
        [key]: check.target.checked
      }
    })
  }

  searchAddress(searchText) {
    if (searchText && searchText.length >= 3) {
			if (this.addressTimeout) window.clearTimeout(this.addressTimeout)
			this.addressTimeout = setTimeout(() => {
				Utils.searchAddress(searchText).then(response => {
          if (response.status === 200) {
            
            
            this.setState({
              address_suggestions: response.data.map(place => {
                const address = place.address
                const zip = address.postcode ? address.postcode : ''
                const road = address.road ? address.road : ''
                const house_number = address.house_number ? ' ' + address.house_number : ''
                const city = address.city ? address.city : address.neighbourhood ? address.neighbourhood : address.municipality ? address.municipality : ''
      
                const address_text = road !== '' ? `${road}${house_number}, ${city}` : city

                return {
                  address: address_text,
                  zip: zip,
                  lat: place.lat,
                  lng: place.lon
                }

              })
            })
          }
				})
			}, 1000)
    }
  }

  goTo(fields) {
    this.setState({
      fields_to_show: fields
    }, () => {
      console.log(this.mapRef.current)
      if (this.mapRef.current && this.mapRef.current.invalidateSize)
        this.mapRef.current.invalidateSize()
    })
  }

  getYesNoValue(val) {
    const sel = this.yes_no_choices.filter(c => parseInt(c.value) === parseInt(val))

    if (sel && sel.length > 0) {
      return sel[0]
    }

    return {
      label: i18n.t('indifferent'),
      value: 0
    }
  }

  _onCreateMap(latlng) {

    this.setState({
      form_data: {
        ...this.state.form_data,
        geo: latlng
      }
    }, () => {
      if (this.mapRef && this.mapRef.invalidateSize)
        this.mapRef.invalidateSize()
    })
  }

  render() {
	  const lookups = this.state.lookups
  
    return (
      <Fragment> 
        <Header />
        <Leftnav />
          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom p-0">
              <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
                <div className="container">
                  <div className="row sticky-top form-header">
                    <div className="col-md-12">
                      {
                        this.state.form_data &&
                        <div>
                          {
                            this.state.form_data.id ?
                            <h3>{i18n.t('update_request')} #{this.state.form_data.id}</h3> :
                            <h3>{i18n.t('insert_request')}</h3>
                          }
                        </div>
                      }
                      <div className="property-form-steps">
                        <div onClick={() => this.goTo('basic-info-fields')} className={`property-form-step ${this.state.fields_to_show === 'basic-info-fields' ? 'active' : ''}`}>
                          <span className="step">{i18n.t('request_basic_information')}</span>
                        </div>
                        <div onClick={() => this.goTo('location-fields')} className={`property-form-step ${this.state.fields_to_show === 'location-fields' ? 'active' : ''}`}>
                          <span className="step">{i18n.t('request_location_information')}</span>
                        </div>
                        <div onClick={() => this.goTo('optional-fields')} className={`property-form-step ${this.state.fields_to_show === 'optional-fields' ? 'active' : ''}`}>
                          <span className="step">{i18n.t('request_optional')}</span>
                        </div>
                        <div onClick={() => this.goTo('publish-networks')} className={`property-form-step ${this.state.fields_to_show === 'publish-networks' ? 'active' : ''}`}>
                          <span className="step">{i18n.t('publish')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  { /**property_type */}
                  <div className={`row basic-info-fields ${this.state.fields_to_show === 'basic-info-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('property_type')}</h4>
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('purpose')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_purpose')}
                        value={this.getOptionValue('int_to', 'transaction_type')}
                        isSearchable={false}
                        name="transaction_type"
                        options={lookups.transaction_type}
                        onChange={(e) => this.setValue('int_to', e.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('category')}</label>
                      <Select 
                        ref={this.categoryRef}
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_category')}
                        value={this.getOptionValue('cat_basic_id', 'categories_basic')}
                        isSearchable={false}
                        name="categories_basic"
                        options={lookups.categories_basic}
                        onChange={(e) => this.setCategory(e.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('subcategory')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_subcategory')}
                        value={this.getOptionValue('cat_id', 'categories', true)}
                        isSearchable={false}
                        name="categories"
                        options={lookups.categories.filter(c => c.show)}
                        onChange={(e) => this.setValue('cat_id', e.map(v => v.value).join(','))}
                      />
                    </div>
                  </div>
                  { /**property_values */}
                  <div className={`row basic-info-fields mt-4 ${this.state.fields_to_show === 'basic-info-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('property_values')}</h4>
                    </div>
                    
                    <div className="col-md-3">
                      <label>{i18n.t('set_min_price')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('min_price')} onChange={(e) => this.setValue('min_price', e.target.value)} placeholder={i18n.t('set_min_price')}/>
                    </div>
                    <div className="col-md-3">
                      <label>{i18n.t('set_max_price')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('max_price')} onChange={(e) => this.setValue('max_price', e.target.value)} placeholder={i18n.t('set_max_price')}/>
                    </div>
                    <div className="col-md-3">
                      <label>{i18n.t('set_min_sq')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('min_sq')} onChange={(e) => this.setValue('min_sq', e.target.value)} placeholder={i18n.t('set_min_sq')}/>
                    </div>
                    <div className="col-md-3">
                      <label>{i18n.t('set_max_sq')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('max_sq')} onChange={(e) => this.setValue('max_sq', e.target.value)} placeholder={i18n.t('set_max_sq')}/>
                    </div>
                  </div>
                  { /**property_additional */}
                  <div className={`row basic-info-fields mt-4 ${this.state.fields_to_show === 'basic-info-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('request_additional')}</h4>
                    </div>
                    <div className="col-md-3 residential proffesional">
                      <label className="residential">{i18n.t('set_rooms')}</label>
                      <label className="proffesional">{i18n.t('set_spaces')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('rooms')} onChange={(e) => this.setValue('rooms', e.target.value)} placeholder={i18n.t('rooms')}/>
                    </div>
                    <div className="col-md-3 residential proffesional">
                      <label>{i18n.t('set_livingrooms')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('livingroom')} onChange={(e) => this.setValue('livingroom', e.target.value)} placeholder={i18n.t('livingrooms')}/>
                    </div>
                    <div className="col-md-3 residential proffesional">
                      <label>{i18n.t('set_bathrooms')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('bathroom')} onChange={(e) => this.setValue('bathroom', e.target.value)} placeholder={i18n.t('bathrooms')}/>
                    </div>
                    <div className="col-md-3 residential proffesional">
                      <label>{i18n.t('set_wc')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('wc')} onChange={(e) => this.setValue('wc', e.target.value)} placeholder={i18n.t('wc')}/>
                    </div>
                    
                    <div className="col-md-3 land">
                      <label>{i18n.t('set_structure_factor')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('sdom')} onChange={(e) => this.setValue('sdom', e.target.value)} placeholder={i18n.t('structure_factor')}/>
                    </div>
                    <div className="col-md-3 land">
                      <label>{i18n.t('set_coverage_factor')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('skal')} onChange={(e) => this.setValue('skal', e.target.value)} placeholder={i18n.t('coverage_factor')}/>
                    </div>
                    <div className="col-md-3 land">
                      <label>{i18n.t('set_ipsos')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('ipsos')} onChange={(e) => this.setValue('ipsos', e.target.value)} placeholder={i18n.t('ipsos')}/>
                    </div>
                    <div className="col-md-3 land">
                      <label>{i18n.t('set_sxedio')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('in_plan')}
                        value={this.state.form_data.sxedio === 1 ? {
                          label: i18n.t('in_plan'),
                          value: 1
                        } :
                        {
                          label: i18n.t('not_in_plan'),
                          value: 2
                        }
                        }
                        isSearchable={false}
                        name="sxedio"
                        options={[
                          {
                            label: i18n.t('in_plan'),
                            value: 1
                          },
                          {
                            label: i18n.t('not_in_plan'),
                            value: 2
                          }
                        ]}
                        onChange={(e) => this.setValue('sxedio', e.value)}
                      />
                    </div>
                  </div>
                  { /**request_descriptions */}
                  
                  <div className={`row basic-info-fields mt-4 ${this.state.fields_to_show === 'basic-info-fields' ? 'active' : 'hidden'}`}>
                    
                    <div className="col-md-3 my-4 offset-md-9">
                      <button className="btn btn-primary text-white" onClick={() => this.goTo('location-fields')}>{i18n.t('next_step')}</button>
                    </div>
                  </div>
                  { /**request_location */}
                  <div className={`row location-fields mt-4 ${this.state.fields_to_show === 'location-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('request_location')}</h4>
                    </div>
                    <div className="col-md-12">
                      {
                        this.state.finished_load && 
                        <InteractiveMap 
                          className="request-map-wrapper"
                          initialPolygons={
                            this.state.form_data.id ?
                            this.state.form_data.geo : []
                          }
                          _onCreate={(e) => this._onCreateMap(e)}
                          multiple
                          ref={this.mapRef}
                          fetchRef={(ref) => {
                            this.mapRef = ref
                          }}
                        />
                      }
                      
                    </div>
                    
                  </div>
                  { /**request_distances */}
                  {/* <div className={`row location-fields mt-4 ${this.state.fields_to_show === 'location-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('request_distances')}</h4>
                    </div>
                    <div className="col-md-4 residential proffesional land">
                      <label>{i18n.t('sea_distance')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('d_sea')} onChange={(e) => this.setValue('d_sea', e.target.value)} placeholder={i18n.t('set_sea_distance')}/>
                    </div>
                    <div className="col-md-4 residential proffesional land">
                      <label>{i18n.t('metro_distance')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('d_metro')} onChange={(e) => this.setValue('d_metro', e.target.value)} placeholder={i18n.t('set_metro_distance')}/>
                    </div>
                    <div className="col-md-4 residential proffesional land">
                      <label>{i18n.t('airport_distance')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('d_airport')} onChange={(e) => this.setValue('d_airport', e.target.value)} placeholder={i18n.t('set_airport_distance')}/>
                    </div>
                  </div> */}
                  <div className={`row location-fields mt-4 ${this.state.fields_to_show === 'location-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-3 my-4">
                      <button className="btn btn-primary text-white" onClick={() => this.goTo('basic-info-fields')}>{i18n.t('previous_step')}</button>
                    </div>
                    <div className="col-md-3 my-4 offset-md-6">
                      <button className="btn btn-primary text-white" onClick={() => this.goTo('optional-fields')}>{i18n.t('next_step')}</button>
                    </div>
                  </div>
                  { /**request_optional */}
                  <div className={`row optional-fields mt-4 ${this.state.fields_to_show === 'optional-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('request_optional')}</h4>
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('view')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_view')}
                        value={this.getOptionValue('view', 'views', true)}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.views}
                        onChange={(e) => this.setValueMulti('view', e)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('frame')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_frame')}
                        value={this.getOptionValue('kouf', 'frames', true)}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.frames}
                        onChange={(e) => this.setValueMulti('kouf', e)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('choose_floors')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_floors')}
                        value={this.getOptionValue('floor', 'floors', true)}
                        isSearchable={false}
                        name="floor"
                        options={lookups.floors}
                        onChange={(e) => this.setValueMulti('floor', e)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('floors_materials')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_floors_materials')}
                        value={this.getOptionValue('floors_mat', 'floors_materials', true)}
                        isSearchable={false}
                        name="floors_materials"
                        options={lookups.floors_materials}
                        onChange={(e) => this.setValueMulti('floors_mat', e)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('door')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_door')}
                        value={this.getOptionValue('tdoor', 'doors', true)}
                        isSearchable={false}
                        name="tdoor"
                        options={lookups.doors}
                        onChange={(e) => this.setValueMulti('tdoor', e)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('closet')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_closet')}
                        value={this.getOptionValue('closet', 'closets', true)}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.closets}
                        onChange={(e) => this.setValueMulti('closet', e)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('garden')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_garden')}
                        value={this.getOptionValue('garden', 'garden', true)}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.garden}
                        onChange={(e) => this.setValueMulti('garden', e)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('pool')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_pool')}
                        value={this.getOptionValue('pool', 'pool', true)}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.pool}
                        onChange={(e) => this.setValueMulti('pool', e)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('access_type')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_access_type')}
                        value={this.getOptionValue('access', 'access_type', true)}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.access_type}
                        onChange={(e) => this.setValueMulti('access', e)}
                      />
                    </div>
                    
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('parking')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_parking')}
                        value={this.getOptionValue('parcking', 'parking', true)}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.parking}
                        onChange={(e) => this.setValueMulti('parcking', e)}
                      />
                    </div>
                    
                    
                    <div className="col-md-4 residential proffesional land">
                        <label>{i18n.t('Επενδυτικό')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Επενδυτικό')}
                            value={this.getYesNoValue(this.state.form_data.ependitiko)}
                            isSearchable={false}
                            name="ependitiko"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('ependitiko', e.value)}
                          />
                          
                    </div>
                    <div className="col-md-4 residential proffesional">
                        <label>{i18n.t('Αποθήκη')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Αποθήκη')}
                            value={this.getYesNoValue(this.state.form_data.storage)}
                            
                            isSearchable={false}
                            name="storage"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('storage', e.value)}
                          />
                          
                    </div>
                    {/* <div className="col-md-4 land">
                        <label>{i18n.t('Υπάρχει κτίσμα')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Υπάρχει κτίσμα')}
                            value={this.getYesNoValue(this.state.form_data.ktisma)}
                            
                            isSearchable={false}
                            name="ktisma"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('ktisma', e.value)}
                          />
                    </div> */}
                    
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('conditions')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_conditions')}
                        value={this.getOptionValue('cond_id', 'conditions', true)}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.conditions}
                        onChange={(e) => this.setValueMulti('cond_id', e)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('directions')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_directions')}
                        value={this.getOptionValue('directions', 'directions')}
                        isSearchable={false}
                        name="directions"
                        options={lookups.directions}
                        onChange={(e) => this.setValue('directions', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('orientation')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        isMulti
                        placeholder={i18n.t('choose_orientation')}
                        value={this.getOptionValue('orient_id', 'orientations', true)}
                        isSearchable={false}
                        name="orientation"
                        options={lookups.orientations}
                        onChange={(e) => this.setValueMulti('orient_id', e)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('public_service')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_public_service')}
                        value={this.getOptionValue('public_services', 'public_services')}
                        isSearchable={false}
                        name="public_services"
                        options={lookups.public_services}
                        onChange={(e) => this.setValue('public_services', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('construction_year_from')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('year_from')} onChange={(e) => this.setValue('year_from', e.target.value)} placeholder={i18n.t('set_construction_year_from')}/>
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('construction_year_to')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('year_to')} onChange={(e) => this.setValue('year_to', e.target.value)} placeholder={i18n.t('set_construction_year_to')}/>
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('balcony')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('balk')} onChange={(e) => this.setValue('balk', e.target.value)} placeholder={i18n.t('set_balcony')}/>
                    </div>
                    <div className="col-md-4 residential">
                      <label>{i18n.t('set_student')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_student')}
                        value={this.getYesNoValue(this.state.form_data.student)}
                        isSearchable={false}
                        name="student"
                        options={this.yes_no_choices}
                        onChange={(e) => this.setValue('student', e.value)}
                      />
                    </div>
                  </div>
                  { /**property_extra_values */}
                  <div className={`row optional-fields mt-4 property-form-checks ${this.state.fields_to_show === 'optional-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                    <h4 className="property-form-title">{i18n.t('property_extra_values')}</h4>
                    </div>
                    <div className="col-md-4">
                            <label>{i18n.t('Επιπλωμένο')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Επιπλωμένο')}
                            value={this.getYesNoValue(this.state.form_data.furn)}
                            isSearchable={false}
                            name="furn"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('furn', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Ανοιχτωσιά')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Ανοιχτωσιά')}
                            value={this.getYesNoValue(this.state.form_data.open)}
                            isSearchable={false}
                            name="open"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('open', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Φωτεινό')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Φωτεινό')}
                            value={this.getYesNoValue(this.state.form_data.bright)}
                            isSearchable={false}
                            name="bright"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('bright', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Τέντες')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Τέντες')}
                            value={this.getYesNoValue(this.state.form_data.tents)}
                            isSearchable={false}
                            name="tents"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('tents', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Ηλιακός')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Ηλιακός')}
                            value={this.getYesNoValue(this.state.form_data.sun)}
                            isSearchable={false}
                            name="sun"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('sun', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Βαμμένο')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Βαμμένο')}
                            value={this.getYesNoValue(this.state.form_data.painted)}
                            isSearchable={false}
                            name="painted"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('painted', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Ρετιρέ')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Ρετιρέ')}
                            value={this.getYesNoValue(this.state.form_data.retire)}
                            isSearchable={false}
                            name="retire"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('retire', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('A/C')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('A/C')}
                            value={this.getYesNoValue(this.state.form_data.ac)}
                            isSearchable={false}
                            name="ac"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('ac', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Ανελκυστήρας')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Ανελκυστήρας')}
                            value={this.getYesNoValue(this.state.form_data.elevator)}
                            isSearchable={false}
                            name="elevator"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('elevator', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Τζάκι')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Τζάκι')}
                            value={this.getYesNoValue(this.state.form_data.fireplace)}
                            isSearchable={false}
                            name="fireplace"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('fireplace', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Σαλοκουζίνα')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Σαλοκουζίνα')}
                            value={this.getYesNoValue(this.state.form_data.salok)}
                            isSearchable={false}
                            name="salok"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('salok', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Επιτρέπονται κατοικίδια')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Επιτρέπονται κατοικίδια')}
                            value={this.getYesNoValue(this.state.form_data.pets)}
                            isSearchable={false}
                            name="pets"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('pets', e.value)}
                          />
                        </div>
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Πολυτελές')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Πολυτελές')}
                            value={this.getYesNoValue(this.state.form_data.luxury)}
                            isSearchable={false}
                            name="luxury"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('luxury', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Πρόσβαση ΑΜΕΑ')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Πρόσβαση ΑΜΕΑ')}
                            value={this.getYesNoValue(this.state.form_data.amea)}
                            isSearchable={false}
                            name="amea"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('amea', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Νεοκλασικό')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Νεοκλασικό')}
                            value={this.getYesNoValue(this.state.form_data.neoclassic)}
                            isSearchable={false}
                            name="neoclassic"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('neoclassic', e.value)}
                          />
                        </div> */}
                        <div className="col-md-4">
                            <label>{i18n.t('Συναγερμός')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Συναγερμός')}
                            value={this.getYesNoValue(this.state.form_data.alarm)}
                            isSearchable={false}
                            name="alarm"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('alarm', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Ηλεκτρικές συσκευές')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Ηλεκτρικές συσκευές')}
                            value={this.getYesNoValue(this.state.form_data.devices)}
                            isSearchable={false}
                            name="devices"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('devices', e.value)}
                          />
                        </div>
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Με εξοπλισμό')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Με εξοπλισμό')}
                            value={this.getYesNoValue(this.state.form_data.equipped)}
                            isSearchable={false}
                            name="equipped"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('equipped', e.value)}
                          />
                        </div> */}
                        <div className="col-md-4">
                            <label>{i18n.t('Τελευταίος όροφος')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Τελευταίος όροφος')}
                            value={this.getYesNoValue(this.state.form_data.retire_last)}
                            isSearchable={false}
                            name="retire_last"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('retire_last', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Κεντρική οδός')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Κεντρική οδός')}
                            value={this.getYesNoValue(this.state.form_data.road)}
                            isSearchable={false}
                            name="road"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('road', e.value)}
                          />
                        </div>
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Δορυφορική κεραία')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Δορυφορική κεραία')}
                            value={this.getYesNoValue(this.state.form_data.satellite)}
                            isSearchable={false}
                            name="satellite"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('satellite', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Νυχτερινό ρεύμα')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Νυχτερινό ρεύμα')}
                            value={this.getYesNoValue(this.state.form_data.nightpower)}
                            isSearchable={false}
                            name="nightpower"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('nightpower', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Διατηρητέο')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Διατηρητέο')}
                            value={this.getYesNoValue(this.state.form_data.preserved)}
                            isSearchable={false}
                            name="preserved"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('preserved', e.value)}
                          />
                        </div> */}
                        <div className="col-md-4">
                            <label>{i18n.t('Οροφοδιαμέρισμα')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Οροφοδιαμέρισμα')}
                            value={this.getYesNoValue(this.state.form_data.orofodiamerisma)}
                            isSearchable={false}
                            name="orofodiamerisma"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('orofodiamerisma', e.value)}
                          />
                        </div>
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Διπλά τζάμια')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Διπλά τζάμια')}
                            value={this.getYesNoValue(this.state.form_data.doubleglass)}
                            isSearchable={false}
                            name="doubleglass"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('doubleglass', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Σίτες')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Σίτες')}
                            value={this.getYesNoValue(this.state.form_data.pestnet)}
                            isSearchable={false}
                            name="pestnet"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('pestnet', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Εσωτερική σκάλα')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Εσωτερική σκάλα')}
                            value={this.getYesNoValue(this.state.form_data.internalstairs)}
                            isSearchable={false}
                            name="internalstairs"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('internalstairs', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Σιδηροδρομική γραμμή')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Σιδηροδρομική γραμμή')}
                            value={this.getYesNoValue(this.state.form_data.train)}
                            isSearchable={false}
                            name="train"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('train', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('BBQ')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('BBQ')}
                            value={this.getYesNoValue(this.state.form_data.bbq)}
                            isSearchable={false}
                            name="bbq"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('bbq', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Σοφίτα')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Σοφίτα')}
                            value={this.getYesNoValue(this.state.form_data.attic)}
                            isSearchable={false}
                            name="attic"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('attic', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Playroom')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Playroom')}
                            value={this.getYesNoValue(this.state.form_data.playroom)}
                            isSearchable={false}
                            name="playroom"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('playroom', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Ψευδοροφή')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Ψευδοροφή')}
                            value={this.getYesNoValue(this.state.form_data.furred_ceiling)}
                            isSearchable={false}
                            name="furred_ceiling"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('furred_ceiling', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Δομημένη καλωδίωση')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Δομημένη καλωδίωση')}
                            value={this.getYesNoValue(this.state.form_data.structured_wiring)}
                            isSearchable={false}
                            name="structured_wiring"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('structured_wiring', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Ρολά ασφαλείας')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Ρολά ασφαλείας')}
                            value={this.getYesNoValue(this.state.form_data.security_shutters)}
                            isSearchable={false}
                            name="security_shutters"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('security_shutters', e.value)}
                          />
                        </div> */}
                        <div className="col-md-4">
                            <label>{i18n.t('Σκαλιά εισόδου')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Σκαλιά εισόδου')}
                            value={this.getYesNoValue(this.state.form_data.stair_steps)}
                            isSearchable={false}
                            name="stair_steps"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('stair_steps', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Επαγγελματική Στέγη')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Επαγγελματική Στέγη')}
                            value={this.getYesNoValue(this.state.form_data.prof)}
                            isSearchable={false}
                            name="prof"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('prof', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Χρήση Υγειονομικού')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Χρήση Υγειονομικού')}
                            value={this.getYesNoValue(this.state.form_data.ygion)}
                            isSearchable={false}
                            name="ygion"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('ygion', e.value)}
                          />
                        </div>
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Ανελκυστήρας φορτίων')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Ανελκυστήρας φορτίων')}
                            value={this.getYesNoValue(this.state.form_data.anel_fort)}
                            isSearchable={false}
                            name="anel_fort"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('anel_fort', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Ράμπα εκφόρτωσης')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Ράμπα εκφόρτωσης')}
                            value={this.getYesNoValue(this.state.form_data.rampa)}
                            isSearchable={false}
                            name="rampa"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('rampa', e.value)}
                          />
                        </div> */}
                        <div className="col-md-4">
                            <label>{i18n.t('Προαύλιο')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Προαύλιο')}
                            value={this.getYesNoValue(this.state.form_data.yard)}
                            isSearchable={false}
                            name="yard"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('yard', e.value)}
                          />
                        </div>
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Άρτιο')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Άρτιο')}
                            value={this.getYesNoValue(this.state.form_data.artio)}
                            isSearchable={false}
                            name="artio"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('artio', e.value)}
                          />
                        </div> */}
                        <div className="col-md-4">
                            <label>{i18n.t('Οικοδομήσιμο')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Οικοδομήσιμο')}
                            value={this.getYesNoValue(this.state.form_data.oikodom)}
                            isSearchable={false}
                            name="oikodom"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('oikodom', e.value)}
                          />
                        </div>
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Με άδεια δόμησης')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Με άδεια δόμησης')}
                            value={this.getYesNoValue(this.state.form_data.adeia_oik)}
                            isSearchable={false}
                            name="adeia_oik"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('adeia_oik', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Πανοραμικό')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Πανοραμικό')}
                            value={this.getYesNoValue(this.state.form_data.panoramiko)}
                            isSearchable={false}
                            name="panoramiko"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('panoramiko', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Παραλιακό')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Παραλιακό')}
                            value={this.getYesNoValue(this.state.form_data.paraliako)}
                            isSearchable={false}
                            name="paraliako"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('paraliako', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Περιφραγμένο')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Περιφραγμένο')}
                            value={this.getYesNoValue(this.state.form_data.perifragmeno)}
                            isSearchable={false}
                            name="perifragmeno"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('perifragmeno', e.value)}
                          />
                        </div> */}
                        {/* <div className="col-md-4">
                            <label>{i18n.t('Με γεώτρηση')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Με γεώτρηση')}
                            value={this.getYesNoValue(this.state.form_data.geotrisi)}
                            isSearchable={false}
                            name="geotrisi"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('geotrisi', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Με νερό')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Με νερό')}
                            value={this.getYesNoValue(this.state.form_data.nero_reuma)}
                            isSearchable={false}
                            name="nero_reuma"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('nero_reuma', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Με ρεύμα')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Με ρεύμα')}
                            value={this.getYesNoValue(this.state.form_data.reuma)}
                            isSearchable={false}
                            name="reuma"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('reuma', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Με αέριο')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Με αέριο')}
                            value={this.getYesNoValue(this.state.form_data.gass)}
                            isSearchable={false}
                            name="gass"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('gass', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Με τηλέφωνο')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Με τηλέφωνο')}
                            value={this.getYesNoValue(this.state.form_data.tel)}
                            isSearchable={false}
                            name="tel"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('tel', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Με οπτικές ίνες')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Με οπτικές ίνες')}
                            value={this.getYesNoValue(this.state.form_data.optics)}
                            isSearchable={false}
                            name="optics"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('optics', e.value)}
                          />
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t('Γωνιακό')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Γωνιακό')}
                            value={this.getYesNoValue(this.state.form_data.goniako)}
                            isSearchable={false}
                            name="goniako"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('goniako', e.value)}
                          />
                        </div> */}
                        <div className="col-md-4">
                            <label>{i18n.t('Αντιπαροχή')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Αντιπαροχή')}
                            value={this.getYesNoValue(this.state.form_data.antiparoxi)}
                            isSearchable={false}
                            name="antiparoxi"
                            options={this.yes_no_choices}
                            onChange={(e) => this.setValue('antiparoxi', e.value)}
                          />
                        </div>
                    
                  </div>
                  <div className={`row optional-fields mt-4 ${this.state.fields_to_show === 'optional-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-3 my-4">
                      <button className="btn btn-primary text-white" onClick={() => this.goTo('location-fields')}>{i18n.t('previous_step')}</button>
                    </div>
                    <div className="col-md-3  my-4 offset-md-6">
                      <button className="btn btn-primary text-white" onClick={() => this.goTo('publish-networks')}>{i18n.t('next_step')}</button>
                    </div>
                  </div>
                  <div className={`row publish-networks mt-4 property-form-checks ${this.state.fields_to_show === 'publish-networks' ? 'active' : 'hidden'}`}>
                    <div className="col-lg-3 col-md-4 col-sm-12 mini-margin">
                      <div className="form-check form-check-single form-check-simple">
                          <div className="button-custom">
                              <input 
                                className="checkbox" 
                                type="checkbox" 
                                id="publish-profile" 
                                name="publish-profile" 
                                value="1" 
                                checked={parseInt(this.state.publish.profile) === 1} 
                                onChange={e => this.setState({
                                  publish: {
                                    ...this.state.publish,
                                    profile: e.target.checked ? 1 : 0
                                  }
                                })} 
                              />
                              <div className="details-button">
                                  <span>{i18n.t('ΟΧΙ')}</span>
                              </div>
                              <div className="layer"></div>
                          </div>
                          <label className="form-check-label" htmlFor="publish-profile">{i18n.t("publish_on_profile")}</label>
                      </div>
                    </div>
                    {
                      this.state.networks.map(network => {
                        return (
                          <div className="col-lg-3 col-md-4 col-sm-12 mini-margin">
                            <div className="form-check form-check-single form-check-simple">
                                <div className="button-custom">
                                    <input
                                      className="checkbox" 
                                      type="checkbox" 
                                      id={`publish-${network.id}`} 
                                      name={`publish-${network.id}`} 
                                      value="1" 
                                      checked={this.state.publish.networks.filter(n => parseInt(n) === parseInt(network.id)).length > 0} 
                                      onChange={e => this.setPublishedNetworks(e.target.checked, network.id)} 
                                    />
                                    <div className="details-button">
                                        <span>{i18n.t('ΟΧΙ')}</span>
                                    </div>
                                    <div className="layer"></div>
                                </div>
                                <label className="form-check-label" htmlFor={`publish-${network.id}`}>{network.name}</label>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className={`row publish-networks mt-4 ${this.state.fields_to_show === 'publish-networks' ? 'active' : 'hidden'}`}>
                    <div className="col-md-3 my-4">
                      <button className="btn btn-primary text-white" onClick={() => this.goTo('optional-fields')}>{i18n.t('previous_step')}</button>
                    </div>
                    <div className="col-md-3 my-4 offset-md-6">
                      <button className="btn btn-primary text-white" onClick={() => this.storeRequest()}>{i18n.t('save')}</button>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default RequestForm;