import React from 'react'
import moment from 'moment';
import i18n from "./i18n";
import axios from 'axios';

class Utils{

    static getMomentText(timestamp) {
        return moment.unix(timestamp).fromNow();
    } 

    static getMomentTextFromMySqlDate(timestamp) {
        var date = new Date(timestamp);
        return moment(date).fromNow();
    } 

    static getMinifiedDescription(desc, total) {
        var min = desc.slice(0, total);
        if(desc.length > total){
            return (<span> 
                    <span className="minified-desc" style={{display: 'inline'}}>{min}...</span> 
                    <span className="desc" style={{display: 'none'}}>{desc}</span> 
                    <a onClick={(e) => {
                        let wrapper = e.target.parentElement.parentElement;
                        if(wrapper.querySelector('.minified-desc').style.display == 'none'){
                            wrapper.querySelector('.minified-desc').style.display = 'inline'
                            wrapper.querySelector('.desc').style.display = 'none'
                        }else{
                            wrapper.querySelector('.minified-desc').style.display = 'none'
                            wrapper.querySelector('.desc').style.display = 'inline'
                            wrapper.querySelector('.show-more-btn').style.display = 'none'
                        }
                    }} role="button" className="align-items-center show-more-btn font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4">
                        <span className="d-none-xs">{i18n.t('show_more')}</span>
                    </a>
                </span>);
        }else{
            return <span> <span className="desc">{desc}</span> </span>;
        }
        
    }

    static sortPriceObject(obj) {
        const sortable = Object.keys(obj)
            .sort((a, b) => {
            const a_key = a.substring(a.length-1)
            const b_key = b.substring(b.length-1)
            
            if (a_key === 'm' && b_key === 'k') return 1
            if (a_key === 'k' && b_key === 'm') return -1

            const a_val = a.substring(0, a.length-1)
            const b_val = b.substring(0, b.length-1)

            return parseInt(a_val) - parseInt(b_val)
            })
            ;
        const final_obj = {}
        sortable.forEach(s => {
            final_obj[s] = obj[s]
        })

        return final_obj;
    }

    static image_exists(url) {
        // Define the promise
        const imgPromise = new Promise(function imgPromise(resolve, reject) {

            // Create the image
            const imgElement = new Image();

            // When image is loaded, resolve the promise
            imgElement.addEventListener('load', function imgOnLoad() {
                resolve(true);
            });

            // When there's an error during load, reject the promise
            imgElement.addEventListener('error', function imgOnError() {
                resolve(false);
            })

            // Assign URL
            imgElement.src = url;

        });

        return imgPromise;
    }

    static getFullDescription(desc){
        return desc;
    }

    static async reverseGeocode(lat, lng) {
        // https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?outSR=4326&returnIntersection=false&location=22.956662165604342%2C40.59766166191537&f=json
        // https://geocode.maps.co/reverse
        // return axios.get(`https://geocode.maps.co/reverse?lat=${lat}&lon=${lng}`)
        
        return axios.get(`https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?outSR=4326&returnIntersection=false&location=${lng}%2C${lat}&f=json`)
        
    }

    static async searchAddress(query) {
        // https://nominatim.openstreetmap.org/search?q=%CE%91%CE%B3%CE%AF%CE%B1+%CE%A4%CF%81%CE%B9%CE%AC%CE%B4%CE%B1&format=geocodejson
        return axios.get(`https://nominatim.openstreetmap.org/search?addressdetails=1&q=${encodeURIComponent(query)}&format=json&zoom=18`)
    }

    static stringEmpty(str) {
        return !str.replace(/\s/g, '').length
    }

    static arrayEquals(a, b) {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

}

export default Utils;