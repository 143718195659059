import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 5px 5px 15px 5px;
  border-radius: 15px;
  border: 0.5px solid #9e9e9e57;
`;

const ImageWrapper = styled.div`
  display: inline-block;
  position: relative; 
  width: 100%;
  height: 0;
  padding-top: 51.25%;
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px 15px 0px 0px;
`;

const Info = styled.div`
  padding: 15px;
`;

const SiteName = styled.span`
  display: block;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 12px;
  text-transform: uppercase;
  color: #616770;
  font-family: "Roboto", sans-serif;
`;

const Title = styled.span`
  display: block;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  color: #858585;
  margin-top: 3px;
`;

const Description = styled.span`
  display: block;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 13px;
  color: #616770;
  margin-top: 3px;
`;

const Preview = props => {
  const meta = Array.isArray(props.meta) ? props.meta[0] : props.meta;
  
  const is_edit = props.action === "createPost" || props.action === "createComment" || props.action === "createReply"
  if(meta){
    const info = <Info>
                <SiteName>{meta.url}</SiteName>
                <Title>{meta.title}</Title>
                <Description>{meta.description}</Description>
              </Info>
    return (
      <Container>
        {
        meta.image && (
          is_edit ? 
            <ImageWrapper src={meta.image}>
                <i onClick={props.removePreview}
                  className="font-md feather-x-circle me-3 text-grey-500"
                  style={{position: 'absolute', top: '8px', right: '-5px', cursor: 'pointer'}}></i>
            </ImageWrapper> : 
            <a href={meta.url} target='_blank'>
              <ImageWrapper src={meta.image}>
              </ImageWrapper> 
            </a>
        ) }

        {
          is_edit ? info :
          <a href={meta.url} target='_blank'>
              {info}
          </a>
        }
        
      </Container>  
        
    );
  }else{
    return null;
  }
};

export default Preview;