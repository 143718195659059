import socket from './socket';
import request from './request';

class Messenger {
  contacts = [];
  onMessageFns = [];
  onFetchOnlineStatusFns = [];
  onUsersOnlineStatusFns = [];
  onChangeFns = [];

  constructor() {
    
    this.init() 

    socket.onChat((data) => {
      this.onMessageFns.forEach((fn) => {
        fn(data);
      });
    });

    socket.onEvent((data) => {
      if (data.type == '6' || data.class == 'friend-accepted') {
        this.reinit()
      }
      
    });

    socket.onFetchOnlineStatus((data) => {

      if (data.type === 'online_friends') {
        const ids = data.ids && data.ids.length > 0 ? data.ids : []

        const contacts = this.contacts.map(c => {
          return {
            ...c,
            status: ids.filter(id => parseInt(id) === parseInt(c.id)).length > 0
          }
        })
        
        
        this.set('contacts', contacts)
      }
    })
  
    socket.onUsersOnlineStatus((data) => {
      if (data.type === 'user_status' && typeof data.id !== 'undefined') {

        const contacts = this.contacts.map(c => {
          const prevStatus = c.status ? c.status : false 
          return {
            ...c,
            status: parseInt(c.id) === parseInt(data.id) ? data.status : prevStatus
          }
        })
        
        this.set('contacts', contacts)
      }
    })
  }
  init() {
    request.get(`connections/all`).then((response) => {
      if (response.data && response.data.data) {
        this.set('contacts', response.data.data.map((contact) => {
          return {
            ...contact,
            status: false,
            name: contact.fname + ' ' + contact.lname,
          };
        }));
        this.status_check = setInterval(() => {
          if (socket.status())
            socket.send({
              type: 'online_friends'
            });
          else {
            window.clearInterval(this.status_check)
          }
        }, 30000)

        socket.send({
          type: 'online_friends'
        });
      }
    });
  }

  reinit() {
    window.clearInterval(this.status_check)
    request.get(`connections/all`).then((response) => {
      if (response.data && response.data.data) {
        this.set('contacts', response.data.data.map((contact) => {
          return {
            ...contact,
            status: false,
            name: contact.fname + ' ' + contact.lname,
          };
        }));
        this.status_check = setInterval(() => {
          if (socket.status())
            socket.send({
              type: 'online_friends'
            });
          else {
            window.clearInterval(this.status_check)
          }
        }, 30000)

        socket.send({
          type: 'online_friends'
        });
      }

      // this.onChange()
    });
  }

  setContacts(contacts) {
    this.contacts = contacts;
  }

  onMessage(fn) {
    this.onMessageFns.push(fn);
  }

  onChange(fn) {
    this.onChangeFns.push(fn);
    return () => {
      this.onChangeFns.splice(this.onChangeFns.indexOf(fn), 1);
    }
  }

  onceChange(fn) {
    fn.once = true;
    this.onChangeFns.push(fn);
  }

  set(key, value) {
    this[key] = value;

    this.onChangeFns.forEach((fn) => {
      fn(key, value);

      if (fn.once) {
        this.onChangeFns.splice(this.onChangeFns.indexOf(fn), 1);
      }
    });
  }
}

const chat = new Messenger();
window.chat = chat;

export default chat;