import React,{Component, Fragment} from 'react';
import validator from 'validator'
import Preview from './Preview';
import Commentview from '../components/Commentview';
import i18n from '../i18n';
import Utils from "../utils"
import request from "../request";
import storage from '../storage';
import { Link } from 'react-router-dom';
import ProfilePreview from './ProfilePreview';
import PropertyCard from './PropertyCard';
import RequestCard from './RequestCard';
import { Button } from 'react-bootstrap';


class SpecialPostView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      show_more: false,
      cross_data: props.cross_data
    };
  }

  fetchAllCrossings() {
    request.get(`posts/cross_data/${this.props.post_id}`).then(response => {
      this.setState({
        ...this.state,
        cross_data: response.data,
        show_more: true
      })
    })
  }

  render() {
    
    const {type, related_data, cross_data, my_type, my_id} = this.props;

    let type_text, type_object, cross_object

    if (type == '1') {
      type_text = i18n.t('Προστέθηκε ακίνητο το οποίο ταιριάζει με την ζήτησή σας');
      type_object = <PropertyCard 
        key={`main-special-property`}
        data={related_data}
        horizontal={true}
        system={true}
        match_icon={true}
      />
    } else {
      type_text = i18n.t('Προστέθηκε ζήτηση η οποία ταιριάζει με το ακίνητό σας');
      type_object = <RequestCard
        key={`main-special-request`}
        request={related_data}
        cooperationRequest={(e) => {}}
        system={true}
        match_icon={true}
      />
    }
    
    const image = "https://network.realstatus.net/images/assistant-avatar.png";

    return (
      <div className="card w-100 rounded-xxl light-shadow-xss border-0 p-4 mb-3 system-card">
        <div className="card-body p-0 d-flex">
          <div className='d-flex'>
          <ProfilePreview 
            user={`Network assistant`}
            avatar={image}
            system={true}
            system_type={i18n.t('διασταύρωση')}
          />
          </div>
        </div>
        
        <div className="card-body p-0">
          <p className="fw-500 text-grey-900 font-xssss w-100 mb-2">
            {type_text}
          </p>
        </div>
        
        <div className="card-body p-0">
          {type_object}
        </div>
        <div className="center-title">
          <h3>{type == '1' ? i18n.t('my_requests') : i18n.t('my_properties')}</h3>
        </div>
        <div className={`card-body p-0 cross-data-wrapper ${this.props.total_cross_data > 1 && !this.state.show_more ? 'show-more' : 'show-less'}`}>
          {
            this.state.cross_data.map((c, index) => {
              if (type == '1') {
                return <div key={`special-post-request-${index}`}>
                  <RequestCard
                    
                    request={c}
                    cooperationRequest={(e) => {}}
                    system={true}
                  />
                  <hr/>
                </div>
              } else {
                return <div key={`special-post-property-${index}`}>
                  <PropertyCard 
                    
                    data={c}
                    horizontal={true}
                    system={true}
                  />
                  <hr/>
                </div>
              }
               
            })
          }
          {
            (this.props.total_cross_data > 1 && !this.state.show_more) &&
            <div className="controls">
              <Button onClick={() => {
                this.fetchAllCrossings()
              }} className="position-absolute show-more-btn px-1 py-0 lh-24 ls-3 font-xsssss fw-700 ls-lg bg-primary text-white white-space-no-wrap">{i18n.t('show_more')}...</Button>
            </div>  
          }

        </div>
      </div>
    );
  }
}

export default SpecialPostView;