import React, { Component , Fragment } from "react";
import Load from '../Load';
import i18n from "../../i18n";
import request from "../../request";
import PropertyCard from "../PropertyCard";

// import FriendsOnProfile from '../components/FriendsOnProfile';

class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      properties_page: 0,
      loaded: false,
      end_properties: false,
      end: false,
      properties: []
    };

    this.onDocumentScroll = this.onDocumentScroll.bind(this);
  }

  componentDidMount() {

    i18n.onChangeLocale(() => {
      this.forceUpdate();
	})

	
	this.getProperties(1);

    document.addEventListener('scroll', this.onDocumentScroll);
  }

  onDocumentScroll = (e) => {
	
    const totalPageHeight = document.body.scrollHeight - 1; 
    const scrollPoint = window.scrollY + window.innerHeight;

    if(scrollPoint >= totalPageHeight) {
      this.showMoreProperties()
    }
  };

  fetchProperties(params, force_update = false) {
    
    
    if (!this.state.end_properties || force_update) {
      return request.get(`properties/members/${this.props.member.id}/page/${params.page}`)
    }
  }  

  getProperties(page) {
    if (!this.state.end_properties) {
      return this.fetchProperties({
        page: page || this.state.properties_page,
        search: this.state.searchQuery,
      }).then((response) => {
        this.setState({
          ...this.state,
          properties: this.state.properties.concat(response.data.data),
          end_properties: response.data.data.length < 10
        });
      });
    } else {
      this.setState({
        ...this.state,
        end_properties: true
      });
    }
      
  }

  showMoreProperties() {
    if (!this.state.end_properties) {
      const newPage = this.state.properties_page + 1;
      this.getProperties(newPage);
      this.setState({
        ...this.state,
        properties_page: newPage,
        component: 'properties'
      });
    } else {
      this.setState({
        ...this.state,
        end_properties: true,
        component: 'properties'
      });
    }
  }

  componentWillUnmount() {
    i18n.clearOnChangeLocale();
    document.removeEventListener('scroll', this.onDocumentScroll);
  }
  
  render() {
    const member = this.props.member ? this.props.member : null

    return (
      <Fragment> 
        {
		member &&
			this.state.properties.map((data , index) => (
				<PropertyCard 
				key={index} 
				data={data}
				/>
			))
		}
		
		<Load end_properties={this.state.end_properties}/>
      </Fragment>
    );
  }
}

export default Profile;