import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import i18n from "../i18n";
import RealtorCard from "../components/RealtorCard";
import request from '../request';
import socket from '../socket';

class Members extends Component {
  constructor(props) {
    super(props);

    this.state = {
      members: [],
    };

    this.onDeleteFriend = this.onDeleteFriend.bind(this);
    this.onAddFriend = this.onAddFriend.bind(this);
    this.onFriendRequestResponse = this.onFriendRequestResponse.bind(this);
    this.getMembers = this.getMembers.bind(this);
  }

  componentDidMount() {
    this.unmountLocale = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    this.getMembers();

    this.onSocketEventUnlisten = socket.onEvent((data) => {
      if (data.class === 'friend-accepted') {
        this.getMembers();
      }
    });
  }

  componentWillUnmount() {
    this.onSocketEventUnlisten();
    this.unmountLocale()
  }

  getMembers() {
    request.get(`members/id/${this.props.match.params.id}`).then((response) => {
      this.setState({
        ...this.state,
        members: response.data,
      });
    });
  }

  onDeleteFriend(friendshipId, data) {
    const m = this.state.members.slice(0);
    const i = m.findIndex((member) => parseInt(member.connection_id) === parseInt(friendshipId));
    m[i].connection_id = data.connection_id;
    m[i].connection_sender = data.connection_sender;
    m[i].connection_receiver = data.connection_receiver;
    m[i].connection_status = data.connection_status;
    this.setState({
      ...this.state,
      members: m,
    });
  }

  onAddFriend(data) {
    const m = this.state.members.slice(0);
    const i = m.findIndex((member) => parseInt(member.id) === parseInt(data.connection_receiver));
    m[i].connection_id = data.connection_id;
    m[i].connection_sender = data.connection_sender;
    m[i].connection_receiver = data.connection_receiver;
    m[i].connection_status = data.connection_status;
    this.setState({
      ...this.state,
      members: m,
    });
  }

  onFriendRequestResponse(data) {
    const m = this.state.members.slice(0);
    const i = m.findIndex((member) => parseInt(member.id) === parseInt(data.connection_sender));
    m[i].connection_id = data.connection_id;
    m[i].connection_sender = data.connection_sender;
    m[i].connection_receiver = data.connection_receiver;
    m[i].connection_status = data.connection_status;
    this.setState({
      ...this.state,
      members: m,
    });
  }

  render() {
    return (
      <Fragment> 
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title={i18n.t('members_page_title')}
                  />
                  
                  <div className="row ps-2 pe-1">
                    {this.state.members.map((data , index) => (

                    <div key={index} className="col-md-4 col-sm-6 pe-2 ps-2">
                        <RealtorCard
                          data={data}
                          onDeleteFriend={this.onDeleteFriend}
                          onAddFriend={this.onAddFriend}
                          onFriendRequestResponse={this.onFriendRequestResponse}
                        />
                    </div>

                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default Members;