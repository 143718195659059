import React, { Component , Fragment } from "react";
import { Link } from "react-router-dom";
import Appfooter from "../components/Appfooter";
import Header from '../components/Header';

class Comingsoon extends Component {
    render() {
        return (
            <Fragment> 
                <Header />
                <div className="main-content pt-0 right-chat-active d-flex align-items-center bg-coming">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h2 className="fw-700 text-grey-900 display3-size text-centeer display4-md-size lh-2 pl-025">SOMETHING 
                                    <span className="text-secondary shadow-text"> AWESOME </span> IS COMING</h2>
                                   
                                    <div className="container-social-media container">
                                        <ul className='our-social-list'>
                                            <li><Link to='https://www.facebook.com/realstatus.gr/'><i className="text-white feather-facebook font-xl"></i></Link></li>
                                            <li><Link to='https://twitter.com/iartsgr'><i className="text-white feather-twitter font-xl"></i></Link></li>
                                            <li><Link to='https://www.instagram.com/iarts.gr/'><i className="text-white feather-instagram font-xl"></i></Link></li>
                                            <li><Link to='https://www.linkedin.com/company/iarts?trk=tabs_biz_home'><i className="text-white feather-linkedin font-xl"></i></Link></li>
                                            <li><Link to='https://www.youtube.com/@IartsGr'><i className="text-white feather-youtube font-xl"></i></Link></li>
                                        </ul>
                                    </div>
                                    <div className="text-right py-4 flex-b">
                                        <div className='box-bubble'>
                                        <p>Stay tuned!!! Follow US on Social Media</p>
                                        </div>
                                        <img src="/assets/images/avatar-real.png" style={{height:'200px'}} alt="coming_soon_image"></img>
                                    </div>
                                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Appfooter />
            </Fragment>    
        );
    }
}

export default Comingsoon;