const apiBaseURL = 'https://network.realstatus.net/api/';
// const apiBaseURL = 'https://network-dev.realstatus.net/api/';
// const apiBaseURL = 'http://localhost:8080/api/';
const config = {
  apiBaseURL: apiBaseURL,
  basename: '/',
  imageURL: 'https://network.realstatus.net',
  property_images_url: 'https://network.realstatus.net/images/properties/',
  // enviroment: 'development',
  enviroment: 'production',
  stripe_public_key: 'pk_live_h2avtR7ukCenlOjRibi3bKxI00I9wOZ6Qh',
  // stripe_public_key: 'pk_live_h2avtR7ukCenlOjRibi3bKxI00I9wOZ6Qh',
};
window.config = config;

export default config;