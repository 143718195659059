import React from 'react';

class LoadingIcon extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="loadingio-spinner-rolling-wcum84rzqc">
        <div className="ldio-hjppnckp3cn">
          <div></div>
        </div>
      </div>
    );
  }
}

export default LoadingIcon;