import React, { Component , Fragment } from "react";
import { Link } from 'react-router-dom';
import validator from 'validator'
import Header from '../components/Header';
import ImageUploadDialog from "../components/ImageUploadDialog";
import Appfooter from '../components/Appfooter';
import FriendRequestsList from "../components/FriendRequestsList";
import NetworkCard from '../components/NetworkCard';
import Createpost from '../components/Createpost';
import Postview from '../components/Postview';
import Load from '../components/Load';
import Utils from "../utils"
import i18n from "../i18n";
import request from "../request";
import storage from '../storage';
import Rightchat from "../components/Rightchat";
import Popupchat from "../components/Popupchat";
import PropertyCard from "../components/PropertyCard";
import RequestCard from "../components/RequestCard";
import RealtorCard from "../components/RealtorCard";
// import FriendsOnProfile from '../components/FriendsOnProfile';
/*
'id' => $row['networks_id'],
'members' => $realtors,
'name' => $row['networks_name'],
'image' => $image,
'members_count' => $membersTotal,			
'properties' => $propertiesTotal,
'requests' => $requestsTotal,
'private' => ($row['networks_type_id'] == 1 ? false : true),
'joined' => $joined,
'pending' => $pending,
'owner' => $owner,
'actions' => $actions,
*/
class Network extends Component {

  constructor(props) {
    super(props);
    const component = this.props.match.params.component && this.props.match.params.component != '' ? this.props.match.params.component : 'posts';
    this.state = {
      posts: [],
      post: '',
      page: 0,
      properties_page: 0,
      requests_page: 0,
      friends_page: 0,
      members_page: 1,
      loaded: false,
      end: false,
      end_properties: false,
      end_requests: false,
      end_friends: false,
      end_members: false,
      meta: [],
      members: [],
      imageDialogOpen: false,
      imageType: '',
      properties: [],
      requests: [],
      friends: [],
      component: component
    };

    this.getNetworkPosts = this.getNetworkPosts.bind(this);
    this.handlePostChange = this.handlePostChange.bind(this);
    this.addPost = this.addPost.bind(this);
    this.removePreview = this.removePreview.bind(this);
    this.removePost = this.removePost.bind(this);
    this.getProperties = this.getProperties.bind(this);
    this.getFriends = this.getFriends.bind(this);
    this.onPostScroll = this.onPostScroll.bind(this);
    this.onPropertiesScroll = this.onPropertiesScroll.bind(this);
    this.onMembersScroll = this.onMembersScroll.bind(this);
    this.showRequests = this.showRequests.bind(this);
    this.showProperties = this.showProperties.bind(this);
    this.showFriends = this.showFriends.bind(this);
    this.showPosts = this.showPosts.bind(this);
    this.onDeleteFriend = this.onDeleteFriend.bind(this);
    this.onAddFriend = this.onAddFriend.bind(this);
    this.onFriendRequestResponse = this.onFriendRequestResponse.bind(this);
    this.getMembers = this.getMembers.bind(this);
    this.changeCoverImage = this.changeCoverImage.bind(this);
    this.changeProfileImage = this.changeProfileImage.bind(this);
  }

  onPostScroll = (e) => {
    const network = this.props.match.params.id;
    const totalPageHeight = document.body.scrollHeight - 1; 
    const scrollPoint = window.scrollY + window.innerHeight;
    if(scrollPoint >= totalPageHeight) {
      this.showMorePosts(network);
    }
  };

  onMembersScroll = (e) => {
    if( e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight - 1))
    {
      if (this.memberstimeout) clearTimeout(this.memberstimeout)
        this.getMembers();
    }
  };

  onPropertiesScroll = (e) => {
    if(e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight - 1))
    {
      this.showMoreProperties();
    }
  };

  onRequestsScroll = (e) => {
    if(e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight - 1))
    {
      this.showMoreRequests();
    }
  };

  componentDidMount() {
    const network = this.props.match.params.id;

    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    
    let fn;
    switch(this.state.component) {
      case 'posts': 
        document.addEventListener('scroll', this.onPostScroll);
        fn = this.showPosts;
        break;
      case 'properties':
        fn = this.showProperties;
        break;
      case 'requests':
        fn = this.showRequests;
        break;
      case 'friends':
        fn = this.showFriends;
        break;
      case 'members':
        fn = this.showMembers;
        break;
      default: 
        document.addEventListener('scroll', this.onPostScroll);
        fn = this.showPosts;
        break;
    }
    // if (this.state.component == 'posts' || this.state.component == '') {
    //   document.addEventListener('scroll', this.onPostScroll);
    // }

    if(network){
      this.getNetwork(network, fn);
    }
    
  }

  fetchProperties(params, force_update = false) {
    
    
    if (!this.state.end_properties || force_update) {
      const search = params.search && params.search.trim() != '' ? `/search/${params.search.trim()}` : '';
    
      let map = ''
      if(params.southlat &&
        params.southlng &&
        params.northlat &&
        params.northlng
      ) {
        map = `/map-search/${params.northlat}x${params.northlng},${params.southlat}x${params.southlng}`; 
      }
      
      return request.get(`properties/networks/${this.state.network.id}${map}${search}/?page=${params.page}`)
    }
  }  

  getProperties(page) {
    if (!this.state.end_properties) {
      return this.fetchProperties({
        page: page || this.state.properties_page,
        search: this.state.searchQuery,
      }).then((response) => {
        this.setState({
          ...this.state,
          properties: this.state.properties.concat(response.data.data),
          end_properties: response.data.data.length < 10
        });
      });
    } else {
      this.setState({
        ...this.state,
        end_properties: true
      });
    }
      
  }

  handleClickImageDialogOpen = (e) => {
    this.setState({ 
      ...this.state,
      imageDialogOpen: true,
      imageType: e,
    });
  };

  handleClickImageDialogClose = () => {
    this.setState({ 
      ...this.state,
      imageDialogOpen: false 
    });
  };

  changeCoverImage(e) {
    
    this.setState({
      ...this.state,
      network: {
        ...this.state.network,
        cover: e
      }
    });
  }

  changeProfileImage(e) {
    const network = {
      ...this.state.network,
      image: e
    }
    this.setState({
      ...this.state,
      network: network
    });
  }

  getNetwork(network, fn = () => {}){
    return request.get(`network/id/${network}`).then((response) => {
      this.setState({
        ...this.state,
        network: response.data,
        posts: [],
        properties: [],
        requests: [],
        page: 0,
        properties_page: 0,
        requests_page: 0,
        friends_page: 0,
        end: 0,
        end_properties: false,
        end_requests: false,
        end_friends: false,
      }, () => {fn()});
    });
  } 

  getNetworkPosts(page = 1){
    if (!this.state.network) return;
    request.get(`posts/network/${this.state.network.id}/?view=profile&page=${page || this.state.page}`).then((response) => {
      if (response.data) {
        this.setState({
          ...this.state,
          posts: this.state.posts.concat(response.data),
          end: response.data.length < 10,
        });
      }
    });
  }

  getRequests(page){
    if (!this.state.network) return;
    request.get(`requests/networks/${this.state.network.id}/?page=${page || this.state.requests_page}`).then((response) => {
      if (response.data) {
        this.setState({
          ...this.state,
          requests: this.state.requests.concat(response.data.listings,),
          end_requests: response.data.listings.length < 10
        });
      }
    });
  }

  getMembers() {
    if (!this.state.network || this.state.end_members) return;
    if (this.memberstimeout) clearTimeout(this.memberstimeout)
    this.memberstimeout = setTimeout(() => {
      request.get(`members/id/${this.state.network.id}/page/${this.state.members_page}`).then((response) => {
        if (response.data) {
          const next_page = this.state.members_page + 1;
          this.setState({
            members: this.state.members.concat(response.data),
            members_page: next_page,
            end_members: response.data.length < 10
          });
        }
        else {
          this.setState({
            end_members: true
          })
        }
      });
    }, 1000)
    
  }
  getFriends(page) {
    request.get(`connections/list/friends?page=${page || this.state.page}`).then((response) => {
      if (response.data) {
        this.setState({
          friends: this.state.friends.concat(response.data.data),
          component: 'friends'
        });
      }
    });
  }

  showPosts() {
    this.showMorePosts()
  }

  showProperties() {
    this.showMoreProperties()
  }

  showRequests() {
    this.showMoreRequests()
  }
  showFriends() {
    this.showMoreFriends()
  }

  showMembers = () => {
    this.getMembers()
    this.setState({
      component: 'members'
    });
  }

  onAddToNetwork(friendId, netId) {
    // console.log('to do')
  }

  onDeleteFriend(friendshipId, data) {
    const m = this.state.members.slice(0);
    const i = m.findIndex((member) => parseInt(member.connection_id) === parseInt(friendshipId));
    m[i].connection_id = data.connection_id;
    m[i].connection_sender = data.connection_sender;
    m[i].connection_receiver = data.connection_receiver;
    m[i].connection_status = data.connection_status;
    this.setState({
      ...this.state,
      members: m,
    });
  }

  onAddFriend(data) {
    const m = this.state.members.slice(0);
    const i = m.findIndex((member) => parseInt(member.id) === parseInt(data.connection_receiver));
    m[i].connection_id = data.connection_id;
    m[i].connection_sender = data.connection_sender;
    m[i].connection_receiver = data.connection_receiver;
    m[i].connection_status = data.connection_status;
    this.setState({
      ...this.state,
      members: m,
    });
  }

  onFriendRequestResponse(data) {
    const m = this.state.members.slice(0);
    const i = m.findIndex((member) => parseInt(member.id) === parseInt(data.connection_sender));
    m[i].connection_id = data.connection_id;
    m[i].connection_sender = data.connection_sender;
    m[i].connection_receiver = data.connection_receiver;
    m[i].connection_status = data.connection_status;
    this.setState({
      ...this.state,
      members: m,
    });
  }
  

  showMorePosts(network = null) {
    // this.setState({
    //   ...this.state,
    //   component: 'posts'
    // });
    if(!this.state.end){
      const newPage = this.state.page + 1;
      this.getNetworkPosts(newPage);
      this.setState({
        ...this.state,
        page: newPage,
        component: 'posts'
      });
    } else {
      this.setState({
        ...this.state,
        component: 'posts'
      });
    }
  }

  showMoreProperties() {
    if (!this.state.end_properties) {
      const newPage = this.state.properties_page + 1;
      this.getProperties(newPage);
      this.setState({
        properties_page: newPage,
        component: 'properties'
      });
    } else {
      this.setState({
        ...this.state,
        end_properties: true,
        component: 'properties'
      });
    }
  }

  showMoreFriends() {
    if (!this.state.end_friends) {
      const newPage = this.state.friends_page + 1;
      this.getFriends(newPage);
      this.setState({
        friends_page: newPage,
        component: 'friends'
      });
    } else {
      this.setState({
        component: 'friends'
      });
    }
  }

  showMoreRequests() {
    if(!this.state.end_requests){
      const newPage = this.state.requests_page + 1;
      this.getRequests(newPage);
      this.setState({
        ...this.state,
        requests_page: newPage,
        component: 'requests'
      });
    } else {
      this.setState({
        ...this.state,
        component: 'requests'
      });
    }
  }

  handlePostChange(e){
    var val = e.target.value;
    this.setState({post: val});
    if(val && this.state.meta.length === 0){
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      var url = val.match(urlRegex);
      if(url && validator.isURL(url[0])){
        request.post(`og`,{
          url: url[0],
        }).then((response) => {
          this.setState({
            ...this.state,
            meta: response.data,
          });
        });
      } 
    }
  }

  addPost(){
      if (!Utils.stringEmpty(this.state.post)) {
      request.post(`posts`,{
        post: this.state.post,
        meta: this.state.meta,
        me: localStorage.loginId,
        network: this.state.network.id,
      }).then((response) => {
        if (response.status === 200) {
          this.setState({
            ...this.state,
            posts: response.data.concat(this.state.posts),
            meta: [],
            post: '',
          });
        }
      });
    }
  }

  removePreview(){
    this.setState({
      ...this.state,
      meta: [],
    });
  }

  removePost(post){
    request.delete(`posts/id/${post}`).then((response) => {
      if(response.data.status === '1'){
        const posts = this.state.posts.filter(p => p.id !== post);
        this.setState({
          ...this.state,
          posts: posts,
        });
      }
    });
  }

  setEmotion(id, emotion){
    request.put(`posts`,{
      id: id,
      emotion: emotion,
    }).then((response) => {
        const posts = JSON.parse(JSON.stringify(this.state.posts));
        posts.filter(p => p.id === id)[0].emotions = response.data[0].emotions;
        this.setState({
          ...this.state,
          posts: posts,
        });
    });
  }

  componentWillUnmount() {
    i18n.clearOnChangeLocale();
    document.removeEventListener('scroll', this.onPostScroll);
  }

  cooperationRequest(data){
    this.setState({
      ...this.state,
      requests: this.state.requests.map(r => {
        if (r.id !== data.id) return r

        return {
          ...r,
          collab: data.collab,
          spam_collab: data.spam_collab
        }
      })
    })
      
  }

  cancelCooperationRequest(data){
    this.setState({
      ...this.state,
      requests: this.state.requests.map(r => {
        if (r.id !== data.id) return r

        return {
          ...r,
          collab: data.collab,
          spam_collab: data.spam_collab
        }
      })
    })
  }

  leaveNetwork = () => {
    this.setState({
      in_network: false
    })
  }

  joinNetwork = () => {
    this.setState({
      in_network: true
    })
  }

  componentDidUpdate(prevProps, prevState){
    if (prevState && prevState !== null) {
      const network = this.props.match.params.id;
      const component = this.props.match.params.component;
      
      if(this.state.network && parseInt(this.state.network.id) !== parseInt(network)){
        if(network){
          this.getNetwork(network);
        }
      } else if (this.state.network && component && component != prevState.component && component != '') {
        document.removeEventListener('scroll', this.onPostScroll)
        switch(component) {
          case 'properties':
            this.showMoreProperties()
            break;
          case 'requests':
            this.showMoreRequests()
            break;
          case 'posts':
            this.showMorePosts(network);
            document.addEventListener('scroll', this.onPostScroll)
            break;
          case 'members':
            this.showMembers()
            break;
          case 'friends':
            this.showMoreFriends()
            break;
          default: 
            this.showMorePosts(network)
            document.addEventListener('scroll', this.onPostScroll)
            break;
        }
      }
    }
  }

  onRemoveProperty = (data) => {
    request.delete(`networks/properties/${this.state.network.id}/${data.id}`).then(rsp => {
      this.setState({
        properties: this.state.properties.filter(p => p.id !== data.id)
      })
    })
  }

  onRemoveRequest = (data) => {
    request.delete(`networks/requests/${this.state.network.id}/${data.id}`).then(rsp => {
      this.setState({
        requests: this.state.requests.filter(p => p.id !== data.id)
      })
    })
  }
  
  onRemoveRealtor = (data) => {
    request.delete(`networks/members/${this.state.network.id}/${data.id}`).then(rsp => {
      this.setState({
        network: {
          ...this.state.network,
          admins: this.state.network.admins.push(data.id)
        }
      })
    })
  }

  onRemovePrivileges = (data) => {
    
    request.delete(`networks/privileges/${this.state.network.id}/${data.id}`).then(rsp => {
      this.setState({
        network: {
          ...this.state.network,
          admins: this.state.network.admins.filter( a => parseInt(a) !== parseInt(data.id))
        }
      })
    })
  }

  onAddPrivileges = (data) => {
    request.patch(`networks/privileges/${this.state.network.id}/${data.id}`).then(rsp => {
      this.setState({
        network: {
          ...this.state.network,
          admins: this.state.network.admins.concat([data.id])
        }
      })
    })
  }

  render() {
    const meID = storage.me && storage.me.realtors_id;
    const network = this.state.network && this.state.network.id;
    const in_network = this.state.network && this.state.network.members.filter(m => parseInt(m.id) === parseInt(meID)).length > 0
    const owner = this.state.network && parseInt(this.state.network.owner) === parseInt(meID)
    const admin = this.state.network && this.state.network.admins.indexOf(meID) > -1
    const description = this.state.network ? this.state.network.description : '';
    const private_net = (this.state.network && this.state.network.private)? this.state.network.private : '';
    return (
      <Fragment> 
        <Header />
        <ImageUploadDialog 
          handleClickImageDialogClose={()=>this.handleClickImageDialogClose()}
          open={this.state.imageDialogOpen} 
          imageType={this.state.imageType}
          changeProfileImage={(e) => this.changeProfileImage(e)}
          changeCoverImage={(e) => this.changeCoverImage(e)}
        />
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12 mb-3 mt-3">
                  {
                    this.state.network && 
                    <NetworkCard 
                      handleClickImageDialogOpen={(e)=>this.handleClickImageDialogOpen(e)}
                      network={this.state.network} 
                      in_network={in_network}
                      leaveNetwork={this.leaveNetwork}
                      joinNetwork={this.joinNetwork}
                      owner={owner}
                      admin={admin}
                    />
                  }
                  
                </div>
                <div className="col-xl-4 col-xxl-3 col-lg-4">
                  <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                    <div className="card-body d-block p-4">
                      <h4 className="fw-700 mb-3 font-xsss text-grey-900">{i18n.t('about')}</h4>
                      <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{description}</p>
                     
                    </div>
                      {
                        private_net === true 
                        ?
                        <div className="card-body d-flex pt-0">
                          <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                          <h4 className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{i18n.t('private')}</h4>
                        </div>
                        :
                        <div className="card-body d-flex pt-0">
                          <i className="feather-eye text-grey-500 me-3 font-lg"></i>
                          <h4 className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{i18n.t('public')}</h4>
                        </div>
                      }
                    
                  </div>
                  {/* <Profilephoto /> */}
                  {/* <Events /> */}
                  <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                    <div className="card-body d-flex align-items-center space-between">
                      <h4 className="fw-700 font-xsss text-grey-900">{i18n.t('members')}</h4>
                        <Link to={`/network/${network}/members`}>
                          <h4 className="font-xssss">{i18n.t('show_more')}</h4>
                        </Link>
                    </div>
                    <div className="card-body d-block p-3">
                          {
                            in_network &&
                            this.state.network.members.filter((item, index) => index < 5).map((member, index) => {
                              return (
                                <Link to={`/members/${member.id}`}>
                                  <div className="card-body d-flex align-items-center position-relative p-1" key={index}>
                                    <img className='me-3 w-12 h-12 flex-shrink-0 overflow-hidden relative rounded-circle' src={member.profile_image}></img>
                                    <h4 className="fw-700 text-grey-900 font-xsss mt-1">
                                      {`${member.first_name} ${member.last_name}`}
                                    </h4>
                                  </div>
                                </Link>
                              )
                            })
                          }
                    </div>
                  </div>
                </div>
                
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                    {/* <Createpost 
                      me={storage.me}
                      member={this.state.network}
                      meta={this.state.meta.length>0 ? this.state.meta : null}
                      post={this.state.post}
                      handlePostChange={this.handlePostChange}
                      addPost={this.addPost}
                      removePreview={this.removePreview}
                    /> */}
                    {
                      (this.state.component === '' || this.state.component === 'posts') &&
                      <div className='posts'>
                        {
                          in_network &&
                          <Createpost 
                            me={storage.me}
                            network={this.state.network}
                            meta={this.state.meta && this.state.meta.length > 0 ? this.state.meta : null}
                            post={this.state.post}
                            handlePostChange={this.handlePostChange}
                            addPost={this.addPost}
                            removePreview={this.removePreview}
                          />
                        }
                        {
                          this.state.posts && this.state.posts.map( (p, i) => {
                            return <Postview key={i} 
                              raw_data={p}
                              postid={p.id} 
                              postowner={p.owner}
                              postvideo="" 
                              postimage={p.image} 
                              avater={p.user_image} 
                              user={p.user_name} 
                              user_id={p.user_id} 
                              rating={p.rating}
                              meta={p.meta ? p.meta : null}
                              me={storage.me}
                              member={storage.me}
                              // post_to={p.post_to}
                              // network_to={p.network_to}
                              time={ Utils.getMomentText(p.timestamp) } 
                              desc={ Utils.getMinifiedDescription(p.description, 200) } 
                              postcomments={p.comments}
                              postemotions={p.emotions}
                              network_owner={owner}
                              network_admin={admin}
                              removePost={(e) => this.removePost(e)}
                              setEmotion={(a,b) => this.setEmotion(a,b)}
                            />
                          })
                        }
                        <Load end={this.state.end}/>
                      </div>
                      
                    }
                    {
                    this.state.component === 'properties' &&
                      <div className="container network-properties-wrapper scroll-bar" onScroll={this.onPropertiesScroll}>
                        <div className="row">
                          {this.state.properties &&
                            this.state.properties.map((data , index) => (
                              <PropertyCard 
                                key={index} 
                                data={data}
                                onRemoveFromNetwork={this.onRemoveProperty}
                                network_owner={owner}
                                network_admin={admin}
                              />
                            ))
                          }
                          <Load end_properties={this.state.end_properties}/>
                        </div>
                      </div>
                    }
                    {
                      this.state.component === 'requests' &&
                      <div className="container network-requests-wrapper scroll-bar" onScroll={this.onRequestsScroll}>
                        <div className="row">
                        {
                          this.state.requests && this.state.requests.map((request , index) => {
                            return <RequestCard key={index} 
                              request={request}
                              onRemoveFromNetwork={this.onRemoveRequest}
                              cooperationRequest={(e) => {this.cooperationRequest(e)}}
                              cancelCooperationRequest={(e) => {this.cancelCooperationRequest(e)}}
                              network_owner={owner}
                              network_admin={admin}
                            />
                          })
                        }
                       <Load end={this.state.end_requests}/>
                       </div>
                      </div>
                    }
                    {
                      this.state.component === 'members' &&
                      <div className="container network-members-wrapper scroll-bar" onScroll={this.onMembersScroll}>
                        <div className="row">
                        {
                          this.state.members && this.state.members.map((data , index) => {
                            return <div key={index} className="col-md-6 col-sm-6 pe-2 ps-2">
                              <RealtorCard
                                data={data}
                                onRemoveFromNetwork={this.onRemoveRealtor}
                                onDeleteFriend={this.onDeleteFriend}
                                onAddFriend={this.onAddFriend}
                                onFriendRequestResponse={this.onFriendRequestResponse}
                                network_owner={owner}
                                network_admin={admin}
                                is_realtor_admin={this.state.network.admins.indexOf(data.id) > -1}
                                onRemovePrivileges={this.onRemovePrivileges}
                                onAddPrivileges={this.onAddPrivileges}
                              />
                            </div>
                          })
                        }
                        <Load 
                          end={this.state.end_members}
                        />
                       </div>
                      </div>
                    }
                     {
                      this.state.component === 'friends' &&
                      <div className="container network-friends-wrapper scroll-bar">
                        <div className="row">
                        {
                          this.state.friends && this.state.friends.filter(friend => {
                            return !this.state.network.members.filter(member => member.id === friend.id).length
                          }).map((data , index) => {
                            return <div key={index} className="col-md-6 col-sm-6 pe-2 ps-2">
                              <RealtorCard
                                data={data}
                                onAddToNetwork = {this.onAddToNetwork}
                                onDeleteFriend={this.onDeleteFriend}
                                onAddFriend={this.onAddFriend}
                                onFriendRequestResponse={this.onFriendRequestResponse}
                              />
                            </div>
                          })
                        }
                       </div>
                      </div>
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default Network;