import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import i18n from "../i18n";
import request from '../request';
import socket from '../socket';
import storage from "../storage";
import chat from "../chat";

class FriendRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requests: [],
      searchQuery: '',
      imagesLoaded: true,
      loaded: false,
      fetchingSearchResults: false,
      page: 1,
    };

    this.searchTimeout = null;

    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.unmountLocale = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    this.onSocketEventUnlisten = socket.onEvent((data) => {
      if (data.class === 'friend-request') {
        this.getFriendRequests().then(() => {
          this.forceUpdate();
        });
      }
    });

    this.getFriendRequests();

    document.addEventListener('scroll', (e) => {
      const totalPageHeight = document.body.scrollHeight - 1; 
      const scrollPoint = window.scrollY + window.innerHeight;
      if(scrollPoint >= totalPageHeight) {
        this.showMoreFriendRequests();
      }
    });
  }

    componentWillUnmount() {
      this.onSocketEventUnlisten();
      this.unmountLocale()
    }
  
    getFriendRequests() {
      return request.get(`connections/list/requests`).then((response) => {
        this.setState({
          ...this.state,
          requests: response.data.data,
        });
      });
    }
  
    respondToFriendRequest(friendRequestId, senderId, requestResponse) {
      request.post(`connections/id/${friendRequestId}/response/${requestResponse}`).then((response) => {
        if (response.data.status === '1') {
          const r = this.state.requests.slice(0);
          const friendRequestIndex = r.findIndex((r) => r.id === friendRequestId);
          r.splice(friendRequestIndex, 1);
          this.setState({
            ...this.state,
            requests: r,
          });
  
          if (requestResponse === 'accept' && storage.me) {
            socket.send({
              type: 'event',
              data: {
                class: 'friend-accepted',
                realtor: senderId,
                me: `${storage.me.realtors_fname} ${storage.me.realtors_lname}`,
              },
            });
            chat.reinit()
          }
        }
      });
    }

  search(query) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (query.trim().length > 2) {
        request.get(`connections/list/requests/search/${query.trim()}`).then((response) => {
          this.setState({
            ...this.state,
            friends: response.data.data,
            fetchingSearchResults: false,
          });
        });
      } else {
        request.get(`connections/list/requests`).then((response) => {
          this.setState({
            ...this.state,
            friends: response.data.data,
            fetchingSearchResults: false,
          });
        });
      }
      this.setState({
        ...this.state,
        searchQuery: query,
        fetchingSearchResults: true,
      });
    }, 350);
  }

  showMoreFriendRequests() {
    const newPage = this.state.page + 1;

    this.getFriendRequests(newPage);

    this.setState({
      ...this.state,
      page: newPage,
    });
  }

  render() {
    return (
      <Fragment> 
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title={i18n.t('friend_request')}
                    onSearchQueryChange={this.search}
                  />
                  <div className="row ps-2 pe-1">
                    <div className="col-12">
                      {this.state.requests.map((data , index) => (
                        <div key={index} className="row">
                          <div className="col-md-4 col-6 pe-2 ps-2">
                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                              <div className="card-body d-block w-100 p-4 text-center">
                                <figure className="avatar ms-auto me-auto mb-0 position-relative z-index-1"><img src={`${data.profile_image}`} alt="avater" className="shadow-sm rounded-circle w90" /></figure>
                                <div className="clearfix"></div>
                                <h4 className="fw-700 text-grey-900 font-xss mt-3">
                                  {data.fname}&nbsp;{data.lname}
                                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                                    {
                                      data.mutual_friends_count === 0
                                        ? i18n.t('no_mutual_friends')
                                        : `${data.mutual_friends_count} ${i18n.t('mutual_friends')}`
                                    }  
                                  </span>
                                </h4>
                                <div className="d-flex justify-content-around flex-wrap">
                                  <a
                                    className="mt-3 p-0 btn p-2 lh-24 w125 ls-1 d-inline-block rounded-xl font-xsss fw-700 ls-lg bg-primary-gradiant text-white white-space-no-wrap"
                                    onClick={() => this.respondToFriendRequest(data.id, data.sender_id, 'accept')}
                                  >
                                    {i18n.t('Αποδοχή')}
                                  </a>
                                  <a
                                    className="mt-3 p-0 btn p-2 lh-24 w125 ls-1 d-inline-block rounded-xl font-xsss fw-700 ls-lg bg-grey text-grey-800 white-space-no-wrap"
                                    onClick={() => this.respondToFriendRequest(data.id, data.sender_id, 'reject')}
                                  >
                                    {i18n.t('Απόρριψη')}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        ))}

                        {
                          this.state.requests.length === 0 && (
                            <div style={{padding: '24px', textAlign: 'center'}}>
                              {i18n.t('no_friend_requests')}
                            </div>
                          )
                        }

                        {
                          this.state.loaded && this.state.requests.length === 0 && this.state.searchQuery.trim().length === 0 && this.state.fetchingSearchResults === false
                            ? <div className="d-flex flex-column justify-content-center align-items-center my-5">
                                <i className="feather-users text-muted" style={{fontSize: '50px'}}></i>
                                <span className="mt-2">{i18n.t('no_friends_message')}</span>
                              </div>
                            : ''
                        }
                        {
                          this.state.loaded && this.state.requests.length === 0 && this.state.searchQuery.trim().length > 2 && this.state.fetchingSearchResults === false
                            ? <div className="d-flex flex-column justify-content-center align-items-center my-5">
                                <i className="feather-search text-muted" style={{fontSize: '50px'}}></i>
                                <span className="mt-2">{i18n.t('no_search_results_found')}</span>
                              </div>
                            : ''
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default FriendRequests;