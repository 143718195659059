import React, { Fragment } from 'react';
import LoadingIcon from './LoadingIcon';

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: true,
    };

    this.onClick = this.onClick.bind(this);
    this.setNotActive = this.setNotActive.bind(this);
  }

  onClick() {
    if (this.state.active) {
      if (typeof this.props.onClick === 'function') {
        this.props.onClick();
        this.setNotActive();
        setTimeout(() => {
          this.setActive();
        }, 3000);
      }
    }
  }

  setActive() {
    this.setState({
      ...this.state,
      active: true,
    });
  }

  setNotActive() {
    this.setState({
      ...this.state,
      active: false,
    });
  }

  render() {
    return (
      <a
        className={`button ${this.props.className ? this.props.className : ''} ${this.state.active === false ? 'button-disabled' : ''}`}
        onClick={this.onClick}
      >
        {
          this.state.active === false
          ? <Fragment>
              <LoadingIcon />
              &nbsp;
            </Fragment>
          : ''
        }
        {this.props.children}
      </a>
    );
  }
}

export default Button;