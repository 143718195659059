import React, { Component , createRef, Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import i18n from "../i18n";
// import 'leaflet/dist/leaflet.css';
// import 'leaflet.markercluster';
import Load from '../components/Load'
import Rightchat from "../components/Rightchat";
import Popupchat from "../components/Popupchat";
import { Link } from "react-router-dom";
import request from "../request";
import RequestEditCard from "../components/RequestEditCard";
import ModalGeneric from "../components/ModalGeneric";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";


class ManageRequests extends Component {

  constructor(props) {
    super(props)
    
		this.searchRef = createRef()

    this.state = {
      requests: [],
      networks: [],
      selected_networks: [],
      profile: 0,
      active_property: null,
      edit_property: null,
      page: 1,
      end_of_data: false,
      showPublishModal: false,
      show_suggestions: false
    }
  }

  componentDidMount() {
    this.getRequests()
    
    this.unlistenLocale = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    this.inputClicked = () => {
			this.setState({
				show_suggestions: true
			})
		}

    this.documentClicked = (e) => {
			
			if (this.searchRef.current && this.searchRef.current !== e.target && !this.searchRef.current.contains(e.target) && this.state.show_suggestions === true) {
				this.setState({
					show_suggestions: false
				})
      }
			
		}
    if (this.searchRef.current)
		  this.searchRef.current.addEventListener('click', this.inputClicked)
		document.addEventListener('click', this.documentClicked)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.documentClicked)
    this.unlistenLocale()
  }

  getRequests = (page = 1) => {
    request.get(`my_requests_for_edit/${page}`).then(response => {
      if (response.status === 200) {
        var end_of_data = false;
        if (response.data.length < 10) end_of_data = true;

        this.setState({
          requests: this.state.requests.concat(response.data),
          end_of_data: end_of_data,
          page: page
        })
      }
    })
  }

  searchNetwork = (str) => {
    if (str.length > 3) {
      request.get(`my_networks/${str}`).then(response => {
        if (response.status === 200) {
          this.setState({
            networks: response.data
          })
        }
      })
    }
  }

  editPublish = (request) => {
    this.setState({
      selected_networks: request.published_on.networks,
      profile: parseInt(request.published_on.profile),
      edit_request: request.id,
      showPublishModal: true,
      active_request: request.id
    })
  }

  onDelete = (request_obj) => {
    Swal.fire({
      title: i18n.t('you_are_about_to_delete_the_request_with_code') + ' #' + request_obj.id,
      text: i18n.t('continue_question'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: i18n.t('delete'),
      cancelButtonText: i18n.t('cancel')
    }).then(result => {
      if (result.isConfirmed) {
        request.delete(`requests/${request_obj.id}`).then(response => {
          if (response.status === 200) {
            Swal.fire({
              title: i18n.t('you_deleted_the_request_with_code') + ' #' + request_obj.id,
              icon: 'success',
              confirmButtonText: i18n.t('ok'),
            })
            this.setState({
              requests: this.state.requests.filter(p => p.id !== request_obj.id)
            })
          }
          else {
            Swal.fire({
              title: i18n.t('something_went_wrong'),
              text: i18n.t('the_request_is_not_deleted'),
              icon: 'warning',
              confirmButtonText: i18n.t('ok'),
            })
          }
        })
      }
    })
  }

  savePrefferences = () => {
    request.post(`request_publication/${this.state.edit_request}`, {
      networks: this.state.selected_networks.map(s => s.networks_id),
      profile: this.state.profile
    }).then(response => {
      if (response.status === 200) {
        Swal.fire({
          title: i18n.t('changes_saved'),
          icon: 'success',
          timer: 2000
        })
        this.setState({
          requests: this.state.requests.map(p => {
            if (p.id !== this.state.edit_request) return p;

            return {
              ...p,
              published_on: {
                networks: this.state.selected_networks,
                profile: this.state.profile
              }
            }
          }),
          selected_networks: [],
          profile: 0,
          edit_request: null,
          showPublishModal: false,
          active_request: null
        })
      }
    })
  }
    
  render() {
    return (
      <Fragment>
        <Header />
        <Leftnav />

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
              <div className="container">
                <Pagetitle 
                  title={i18n.t('manage_requests')}
                  btn={
                    <Link className="btn btn-primary text-white" to={`/add_request`}>{i18n.t('insert_request')}</Link>
                  }
                >
                </Pagetitle>
                <div className="col-xl-12 chat-left properties-wrapper">
                    <div className="row ps-2 pe-2">
                      {this.state.requests.map((data , index) => (
                        <RequestEditCard key={index} 
                          request={data}
                          onEditNetwork={(data) => this.editPublish(data)}
                          onDelete={(data) => this.onDelete(data)}
                        />
                        
                      ))}
                    </div>
                    <Load 
                    end={this.state.end_of_data}
                    onScroll={() => this.getRequests(this.state.page + 1)}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalGeneric
          show={this.state.showPublishModal}
					handleClose={() => this.setState({
						showPublishModal: false
					})}
					header={<h4>{i18n.t('edit_request_publish')}</h4>}
					footer={<div className="card-body p-0 d-flex space-between open-house-footer align-center">
                    <div className="d-flex">
                      <button onClick={() => this.setState({
                        showPublishModal: false
                      })} className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase rounded-xl border-none p-sm-2 mr-2 bg-danger text-white font-xssss">{i18n.t('close')}</button>
                      <button onClick={() => this.savePrefferences()} className="btn-action btn-primary p-2 ms-2 z-index-1 rounded-xl border-none p-sm-2 mr-2 bg-primary text-white font-xssss">{i18n.t('save')}</button>
                    </div>
                  </div>
          }
        >
          <div className="card w-100 border-0 p-4 mb-3">
						<div className="card-body mt-2 p-0">
              <div className="row">
                <div className="col-md-12">
                  <h4>{i18n.t('published_in_networks')}</h4>
                  {
                    this.state.selected_networks.map(network => {
                      return (
                          <div className={`p-relative property-suggestion network-suggestion`} onClick={() => this.setState({
                            selected_networks: this.state.selected_networks.filter(sp => sp.networks_id !== network.networks_id)
                          })}>
                            {/* <img src={network.image.network_image} /> */}
                            <div className='property-title'>
                              <b>#{network.networks_name}</b><br/>
                            </div>
                            <FontAwesomeIcon icon={faClose} />
                          </div>
                      )
                    })
                  }
                  
                </div>
              </div>
              

              <div className="row">
                <div className="col-md-12">
                  <input 
                    type="text" 
                    className='form-control' 
                    onChange={(e) => this.searchNetwork(e.target.value)}
                    placeholder={i18n.t('network_name')}
                    ref={this.searchRef}
                  />
                  <div className={`search-results scroll-bar ${this.state.show_suggestions ? '' : 'hidden'}`}>
                    {
                      this.state.networks && 
                      this.state.networks.filter(s => this.state.selected_networks.filter(sp => sp.networks_id === s.networks_id).length === 0).map(s => {
                        return (
                          <div className={`property-suggestion`} onClick={() => this.setState({
                            selected_networks: [...this.state.selected_networks, s]
                          })}>
                            {/* <img src={s.thumbnail} /> */}
                            <div className='property-title'>
                              <b>{s.networks_name}</b><br/>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <p className="font-xssss">*{i18n.t('typeNet')}</p>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="form-check form-check-single">
                      <div className="button-custom">
                          <input className="checkbox" type="checkbox" id="ygion" name="ygion" value="1" checked={this.state.profile === 1} onChange={e => this.setState({profile: e.target.checked ? 1 : 0})} />
                          <div className="details-button">
                              <span>{i18n.t('ΟΧΙ')}</span>
                          </div>
                          <div className="layer"></div>
                      </div>
                      <label className="form-check-label" htmlFor="ygion">{i18n.t("publish_on_profile")}</label>
                  </div>
                </div>
              </div>
						</div>
					</div>
        </ModalGeneric>
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default ManageRequests;