import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import i18n from '../i18n';
import config from '../config';
import request from '../request';
import socket from '../socket';
import Button from '../components/Button';
import storage from '../storage';
// import FriendButtons from './FriendButtons';

class NetworkCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menu: false,
			// friends: [],
		};

		this.elementRef = React.createRef();

		// this.closeBubble = this.closeBubble.bind(this);
	}

	componentDidMount() {
		i18n.onChangeLocale(() => {
			this.forceUpdate();
		});

		this.onWindowClick = (e) => {
			if (!this.elementRef.current.querySelector('.btn-menu').contains(e.target) && this.state.menu === true) {
				this.setState({
					...this.state,
					menu: false,
				});
			}
		};

		window.addEventListener('click', this.onWindowClick);
	}

	sleep(timeout) {
		return new Promise((resolve, reject) => {
		  setTimeout(resolve, timeout);
		});
	  }
	
	async leaveNetwork(networkId) {
		await this.sleep(2000);
		request.delete(`members/id/${networkId}`).then((response) => {
			
			if (this.props.leaveNetwork) this.props.leaveNetwork()
		});
	}
	async joinNetwork(networkId) {
		await this.sleep(2000);
	
		request.put(`members/id/${networkId}`).then((response) => {
		  if (this.props.joinNetwork) this.props.joinNetwork()
	
		  if (response.data.status === '1' && response.data.pending) {
			request.put(`notifications/to/${response.data.owner}/type/network-request/ref/${networkId}`).then(() => {
			  socket.send({
				type: 'event',
				data: {
				  class: 'network-request',
				  networkOwner: response.data.owner,
				},
			  });
			});
		  }
		});
	}
	

	componentWillUnmount() {
		window.removeEventListener('click', this.onWindowClick);
	}

	// openBubble(memberId) {
	// 	this.props.onOpenBubble(member.id)
	// }
	
	//   closeBubble(membertId) {
	// 	this.props.onCloseBubble(member.id)
	// }
	

	// onDeleteFriend(friendshipId, data) {
	// 	const f = this.state.friends.slice(0);
	// 	const i = f.findIndex((friend) => parseInt(friend.connection_id) === parseInt(friendshipId));
	// 	f[i].connection_id = data.connection_id;
	// 	f[i].connection_sender = data.connection_sender;
	// 	f[i].connection_receiver = data.connection_receiver;
	// 	f[i].connection_status = data.connection_status;
	// 	this.setState({
	// 	  ...this.state,
	// 	  friends: f,
	// 	});
	//   }
	
	//   onAddFriend(data) {
	// 	const f = this.state.friends.slice(0);
	// 	const i = f.findIndex((friend) => parseInt(friend.id) === parseInt(data.connection_receiver));
	// 	f[i].connection_id = data.connection_id;
	// 	f[i].connection_sender = data.connection_sender;
	// 	f[i].connection_receiver = data.connection_receiver;
	// 	f[i].connection_status = data.connection_status;
	// 	this.setState({
	// 	  ...this.state,
	// 	  friends: f,
	// 	});
	//   }

	imageInit(e, skeletonClass) {
		e.target.style.display = 'block'
		document.querySelector(`.${skeletonClass}`).style.display = 'none'
	}

	imageError(e, skeletonClass) {
		e.target.style.display = 'none'
		document.querySelector(`.${skeletonClass}`).style.display = 'block'
	}

	render() {
		const id = this.props.network && this.props.network.id ? this.props.network.id : '';
		const name = this.props.network && this.props.network.name ? this.props.network.name : '';
		const profile_image = this.props.network && this.props.network.image ? this.props.network.image : '';
		const network_cover = this.props.network && this.props.network.cover ? this.props.network.cover : '';
		const network_members = this.props.network && this.props.network.members_count ? this.props.network.members_count : '';
		const amOwner = storage.me && (this.props.network.owner === storage.me.realtors_id);
			return (
				<div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
					<div style={{position: 'relative'}}>
						<div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
							{
								(id !== '') &&
								<img src={network_cover} onLoad={(e) => this.imageInit(e, 'cover-skeleton')} onError={(e) => this.imageError(e, 'cover-skeleton')} style={{width: '100%'}} alt="network_cover" />
							}
							<div className="float-right p-1 bg-grey w-100 h-100 cover-skeleton" style={{minHeight: '300px'}}></div>
						</div>
						{
								id === localStorage.loginId &&
							
								<Tooltip title={i18n.t('update_cover_image')} placement="top">
									<i onClick={(e)=>this.props.handleClickImageDialogOpen('network_cover')}
											className="text-grey-900 btn-round-md bg-greylight font-md feather-camera me-3 text-grey-500"
											style={{position: 'absolute', top: '70%', right: '3%', cursor: 'pointer'}}>
									</i>
								</Tooltip>
							}
					</div>
					<div className="card-body p-2 position-relative d-flex card-space-between">
						<div className='d-flex align-center'>
							<figure ref={this.elementRef} className="avatar notification--avatar network-logo-avatar" style={{margin: 0}}>
							{
								(id !== '') &&
								<img src={profile_image}
								onLoad={(e) => this.imageInit(e, 'network-image-skeleton')}
								onError={(e) => this.imageError(e, 'network-image-skeleton')}
								alt="network_image" 
								className="float-right p-1 w-100"
								/>
								
								
							}
							<div className="float-right p-1 w-100 h-100 network-image-skeleton" style={{position: "absolute"}}></div>
							
							<div className={`btn-menu ${this.state.menu ? 'active' : ''}`}>
								<div onClick={() => { this.setState({ ...this.state, menu: !this.state.menu }); }}
										style={{width:'100px'}}>
								</div>
								<div className={`menu menu-right ${this.state.menu && this.props.owner ? 'open' : 'd-none'}`}>
									<span
										className="text-default"
									>
										{i18n.t('profile_image_view')}
									</span>
									<span
										className="text-default"
										onClick={(e)=>this.props.handleClickImageDialogOpen('profile_image')}
									>
										{i18n.t('profile_image_change')}
									</span>
								</div>
							</div>
							</figure>
							<h4 className="fw-700 font-sm main-name-wrapper">
								<span className='main-name'>{name}</span>
								<p className='fw-500 text-grey-500 lh-24 font-xssss mb-0'> {network_members} {i18n.t('members')}</p>
							</h4>
						</div>
						<div className="d-flex align-center">
							{
								this.props.in_network ?
								<Button className="button  bg-danger btn-action btn-leave p-3 z-index-1 rounded-xl text-white font-xsssss text-uppercase fw-700 pd-3-6" 
								onClick={() => this.leaveNetwork(id)}
								>
									{i18n.t('Αποχώρηση από το δίκτυο')}
								</Button>
								:
								<Button className="button bg-success btn-action btn-join p-3 z-index-1 rounded-xl text-white font-xsssss text-uppercase fw-700 pd-3-6" 
									onClick={() => this.joinNetwork(id)}
								>
									{i18n.t('Εγγραφή στο δίκτυο')}
									
								</Button>
							}
							
							{/* <FriendButtons 
							onDeleteFriend={this.onDeleteFriend}
							onAddFriend={this.onAddFriend}
							/> */}
							
						</div>
					</div>
					<div className='card-body pt-3 position-relative d-flex card-space-between border-top-xs  overflow-auto w-100'>
						<div className='d-flex align-center'>
						<Link to={`/network/${id}/posts`}>
							<div className="card-body d-flex position-relative pt-0">
								<i className="feather-globe text-grey-500 me-3 font-lg"></i>
								<h4 className="fw-700 text-grey-900 font-xsss mt-1">
								{i18n.t('Δημοσιεύσεις')}
								</h4>
							</div>
						</Link>
						<Link to={`/network/${id}/properties`}>
							<div className="card-body d-flex position-relative pt-0">
								<i className="feather-home text-grey-500 me-3 font-lg"></i>
								<h4 className="fw-700 text-grey-900 font-xsss mt-1">
								<span className="badge badge-primary absolute-badge">
									{this.props.network &&
									(parseInt(this.props.network.properties))}
								</span>
								&nbsp;
								{i18n.t('properties')}
								</h4>
							</div>
						</Link>
						<Link to={`/network/${id}/requests`}>
							<div className="card-body d-flex position-relative pt-0">
								<i className="feather-search text-grey-500 me-3 font-lg"></i>
								<h4 className="fw-700 text-grey-900 font-xsss mt-1">
								<span className="badge badge-primary absolute-badge">
									{this.props.network &&
									(parseInt(this.props.network.requests))}
								</span>
								&nbsp;
								{i18n.t('requests')}
								</h4>
							</div>
						</Link>
						{amOwner &&
							<Link to={`/network/${id}/friends`}>
								<div className="card-body d-flex position-relative pt-0">
									<h4 className="fw-700 text-grey-900 font-xsss mt-1">
										{i18n.t('friend_invitation')}
									</h4>
								</div>
							</Link>
						}
						</div>
					</div>

						{/* <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
								<ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
										<li className="active list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block active" href="#navtabs1" data-toggle="tab">About</a></li>
										<li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs2" data-toggle="tab">Membership</a></li>
										<li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs3" data-toggle="tab">Discussion</a></li>
										<li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs4" data-toggle="tab">Video</a></li>
										<li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs3" data-toggle="tab">Group</a></li>
										<li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs1" data-toggle="tab">Events</a></li>
										<li className="list-inline-item me-5"><a className="fw-700 me-sm-5 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs7" data-toggle="tab">Media</a></li>
								</ul>
						</div> */}
				</div>
			);
	}
}

export default NetworkCard;