import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import i18n from '../i18n';
import config from '../config';
import FriendButtons from './FriendButtons';
import chat from '../chat';
import settings from '../settings';

class ProfileCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menu: false,
			// friends: [],
		};

		this.elementRef = React.createRef();

		// this.closeBubble = this.closeBubble.bind(this);
		this.onAddFriend = this.onAddFriend.bind(this);
		this.onDeleteFriend = this.onDeleteFriend.bind(this);
	}

	componentDidMount() {
		i18n.onChangeLocale(() => {
			this.forceUpdate();
		});

		this.onWindowClick = (e) => {
			if (!this.elementRef.current.querySelector('.btn-menu').contains(e.target) && this.state.menu) {
				this.setState({
					...this.state,
					menu: false,
				});
			}
		};

		window.addEventListener('click', this.onWindowClick);
	}

	componentWillUnmount() {
			window.removeEventListener('click', this.onWindowClick);
	}

	openBubble(memberId) {
		this.props.onOpenBubble(this.props.member.id)
	}
	
	  closeBubble(membertId) {
		this.props.onCloseBubble(this.props.member.id)
	}
	

	onDeleteFriend(friendshipId, data) {
		// const f = this.state.friends.slice(0);
		// const i = f.findIndex((friend) => parseInt(friend.connection_id) === parseInt(friendshipId));
		// f[i].connection_id = data.connection_id;
		// f[i].connection_sender = data.connection_sender;
		// f[i].connection_receiver = data.connection_receiver;
		// f[i].connection_status = data.connection_status;
		// this.setState({
		// 	...this.state,
		// 	friends: f,
		// });
		chat.reinit()
		this.props.onDeleteFriend(data)

	}

	onAddFriend(data) {
		// const f = this.state.friends.slice(0);
		// const i = f.findIndex((friend) => parseInt(friend.id) === parseInt(data.connection_receiver));
		// f[i].connection_id = data.connection_id;
		// f[i].connection_sender = data.connection_sender;
		// f[i].connection_receiver = data.connection_receiver;
		// f[i].connection_status = data.connection_status;
		// this.setState({
		// 	...this.state,
		// 	friends: f,
		// });
		
		this.props.onAddFriend(data)
	}

	imageInit(e, skeletonClass) {
		e.target.style.display = 'block'
		document.querySelector(`.${skeletonClass}`).style.display = 'none'
	}

	imageError(e, skeletonClass) {
		if (skeletonClass === 'profile-image-skeleton') {
			e.target.src = 'https://network.realstatus.net/images/profile_image/default.jpg';
			return;
		}
		
		e.target.style.display = 'none'
		document.querySelector(`.${skeletonClass}`).style.display = 'block'
	}

	onFriendRequestResponse = (response) => {
		if (response === 'accept') {
			if (this.props.onAcceptFriend) this.props.onAcceptFriend()
			chat.reinit()
		}
		else {
			if (this.props.onRejectFriend) this.props.onRejectFriend()
		}
	}

	render() {
		const id = this.props.member && this.props.member.id ? this.props.member.id : '';
		const lname = this.props.member && this.props.member.lname ? this.props.member.lname : '';
		const fname = this.props.member && this.props.member.fname ? this.props.member.fname : '';
		const email = this.props.member && this.props.member.email ? this.props.member.email : '';
		let profile_image = `${config.imageURL}/images/profile_image/${id}.png`;
		let profile_cover = `${config.imageURL}/images/profile_cover/${id}.png`;

		if (this.props.member) {
			profile_image = this.props.member.profile_image;
			profile_cover = this.props.member.profile_cover;
		}
		else {
			
		}

			return (
				<div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
					<div style={{position: 'relative'}}>
						<div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
							{
								(id !== '') &&
								<img src={profile_cover} onLoad={(e) => this.imageInit(e, 'cover-skeleton')} onError={(e) => this.imageError(e, 'cover-skeleton')} style={{width: '100%'}} alt="profile_cover" />
							}

							<div className="float-right p-1 bg-grey w-100 h-100 cover-skeleton" style={{minHeight: '300px'}}></div>
							
						</div>
							{
								id === localStorage.loginId &&
							
								<Tooltip title={i18n.t('update_cover_image')} placement="top">
									<i onClick={(e)=>this.props.handleClickImageDialogOpen('profile_cover')}
											className="text-grey-900 btn-round-md bg-greylight font-md feather-camera me-3 text-grey-500"
											style={{position: 'absolute', top: '70%', right: '3%', cursor: 'pointer'}}>
									</i>
								</Tooltip>
							}
						</div>
						<div className="card-body p-0 position-relative profile-s">
							<figure ref={this.elementRef} className="avatar notification--avatar position-absolute w100 z-index-1" style={{top:'-40px',left:'30px'}}>
							{
								(id !== '') &&
								<img src={profile_image}
								onLoad={(e) => this.imageInit(e, 'profile-image-skeleton')}
								onError={(e) => this.imageError(e, 'profile-image-skeleton')}
								alt="profile_image" 
								className="float-right p-1 bg-white rounded-circle w-100"
								/>
								
								
							}
							<div className="float-right p-1 bg-grey rounded-circle w-100 h-100 profile-image-skeleton" style={{position: "absolute"}}></div>
							
							<div className={`btn-menu ${this.state.menu ? 'active' : ''}`}>
								<div onClick={() => { this.setState({ ...this.state, menu: !this.state.menu }); }}
										style={{width:'100px',height:'100px'}}>
								</div>
								<div className={`menu menu-left ${this.state.menu && id === localStorage.loginId ? 'open' : 'd-none'}`}>
									<span
										className="text-default"
									>
										{i18n.t('profile_image_view')}
									</span>
									<span
										className="text-default"
										onClick={(e)=>this.props.handleClickImageDialogOpen('profile_image')}
									>
										{i18n.t('profile_image_change')}
									</span>
								</div>
							</div>
							</figure>
							<h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
								<span className='main-name'>{lname + " " + fname}</span>
								<span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">{email}</span>
							</h4>
							{
							id !== localStorage.loginId &&
							<div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2 mb-2">
								<span className="hovertext" data-hover={i18n.t('send_message')}>
									{
										this.props.connection.connection_id !== null && this.props.connection.connection_status === '1' &&
									
										<button 
											onClick={() => {
												settings.set('openChats', [...settings.openChats, id])
											}}
											className="hollow-btn btn p-2 lh-24 ms-1 ls-3 w125 rounded-xl font-xsssss fw-700"
										><i className="feather-message-square font-md"></i> {i18n.t('message')}</button>
									}
									{/* <Bubble
										onOpen={() => this.openBubble(this.props.member.id)}
										onClose={() => this.closeBubble(this.props.member.id)}
									/> */}

								</span>
								{/* <Link to="" className="d-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3" >{i18n.t('button_add_connection')}
								</Link> */}
								{
									(this.props.member && this.props.member.status == '1') &&
									<FriendButtons 
									me={localStorage.loginId}
									data={this.props.connection}
									onDeleteFriend={this.onDeleteFriend}
									onAddFriend={this.onAddFriend}
									onFriendRequestResponse={this.onFriendRequestResponse}
									/>
								}
							</div>
							}
						</div>

						{/* <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
								<ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
										<li className="active list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block active" href="#navtabs1" data-toggle="tab">About</a></li>
										<li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs2" data-toggle="tab">Membership</a></li>
										<li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs3" data-toggle="tab">Discussion</a></li>
										<li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs4" data-toggle="tab">Video</a></li>
										<li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs3" data-toggle="tab">Group</a></li>
										<li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs1" data-toggle="tab">Events</a></li>
										<li className="list-inline-item me-5"><a className="fw-700 me-sm-5 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs7" data-toggle="tab">Media</a></li>
								</ul>
						</div> */}
				</div>
			);
	}
}

export default ProfileCard;