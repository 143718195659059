import '../css/ChatList.css';
import React, { createRef, Fragment } from 'react';
import chat from '../chat';
import Chat from '../components/Chat/Chat';
import settings from '../settings';
import request from '../request';
import notifyWav from '../assets/mp3/message_alt.mp3'
import i18n from '../i18n';
import { Tooltip } from 'react-bootstrap';
import Utils from '../utils';
import moment from 'moment';
import { Link } from 'react-router-dom';

class AppChat extends React.Component {
  constructor(props) {
    super(props);


    this.is_menu_changing = false
    this.leftChatRef = createRef(null)
    this.audio = new Audio(notifyWav)
    this.state = {
      contacts: chat.contacts,
      window: {
        open: settings.sideContactsListOpen,
        openStateDirty: false,
        openStatePristine: true,
      },
      chatWindows: []
    };

    this.openChatWindow = this.openChatWindow.bind(this);
    this.onOpenBubble = this.onOpenBubble.bind(this);
    this.onCloseBubble = this.onCloseBubble.bind(this);
    this.onPrevMessages = this.onPrevMessages.bind(this);
  }

  onDocumentClick = (e) => {
    const footer_elem = document.getElementById('footer-chat-show')
    if (this.leftChatRef.current && !this.leftChatRef.current.contains(e.target) && footer_elem && !footer_elem.contains(e.target) && this.state.window.open === true) {
      settings.set('sideContactsListOpen', false);
    }
  }
  componentDidMount() {
    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    document.addEventListener('click', this.onDocumentClick)

    chat.onChange(() => {
      this.setState({
        ...this.state,
        contacts: chat.contacts,
      });
    });

    chat.onMessage((data) => {
      const c = this.state.contacts.slice(0);
      const contact = c.find((c) => (c.id) == ('from' in data ? data.from : data.to));
      const chatWindowIndex = this.state.chatWindows.findIndex((cw) => cw.id == ('from' in data ? data.from : data.to));
      if (contact) {
        contact.newMessage = true;
        if (!contact.last_message) contact.last_message = {}
        contact.last_message.message = data.message
        contact.last_message.read = 0
        
        let cw = undefined;
        if (chatWindowIndex > -1) {
          cw = this.state.chatWindows.slice(0);
          cw[chatWindowIndex].messages.push(data);
          this.setState({
            ...this.state,
            contacts: c,
            chatWindows: cw,
          });
        } else {
          this.setState({
            ...this.state,
            contacts: c,
          });
          if ('from' in data) {
            this.audio.play()
            settings.set('messageNotify', true)
          }
        }
      }
    });

    settings.onChange(() => {
      this.is_menu_changing = true
      this.setState({
        window: {
          ...this.state.window,
          open: settings.sideContactsListOpen,
        },
      }, () => {
        this.is_menu_changing = false
      });
      settings.openChats.forEach(contact => {
        this.openChatWindow(contact)
      })
      // this.forceUpdate();
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick)
    i18n.clearOnChangeLocale();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.window.openStatePristine && prevState.window.open !== this.state.window.open) {
      this.setState({
        ...this.state,
        window: {
          ...this.state.window,
          openStateDirty: true,
          openStatePristine: false,
        },
      });
    }
  }

  ReadItAll() {
    request.get('chat/read').then((response) => {
      this.setState({
       contacts: this.state.contacts.map(c => {
        return {
          ...c,
          last_message: {
            ...c.last_message,
            read: 1
          }
        }
       })
      })
    })
  }

  openChatWindow(contactId) {
    const cw = this.state.chatWindows.slice(0);
    const index = cw.findIndex((w) => w.id === contactId);
    if (index === -1) {
      const current = this.state.contacts.find((c) => c.id === contactId);
      if (typeof current !== 'undefined') {
        request.get(`chat/id/${current.id}`).then((response) => {

          cw.push({
            ...current,
            messages: (response.data && response.data.messages) ? response.data.messages.map(r => {
              if (r.receiver == current.id) {
                return {
                  message: r.message,
                  sender: r.sender,
                  to: r.receiver,
                  timestamp: r.send_date,
                  type: "chat"
                }
              } else {
                return {
                  message: r.message,
                  sender: r.sender,
                  from: r.sender,
                  timestamp: r.send_date,
                  type: "chat"
                }
              }
            }) : [],
          });
          this.setState({
            ...this.state,
            chatWindows: cw,
          });
        });
      }
    }
  }

  onOpenBubble(contactId) {
    const c = this.state.contacts.slice(0);
    const index = c.findIndex((c) => c.id === contactId);
    c[index].newMessage = false;
    this.setState({
      ...this.state,
      contacts: c,
    });
  }

  onCloseBubble(contactId) {
    const cw = this.state.chatWindows.slice(0);
    const index = cw.findIndex((w) => w.id === contactId);
    cw.splice(index, 1);
    settings.set('openChats', cw.map(c => c.id))
    this.setState({
      ...this.state,
      chatWindows: cw,
    });
  }

  onPrevMessages(contactId) {
    const cw = this.state.chatWindows.slice(0);
    const index = cw.findIndex((w) => w.id === contactId);

    if (cw[index].last_timestamp && cw[index].last_timestamp == cw[index].messages[0].timestamp) return;

    request.get(`chat/id/${cw[index].id}/timestamp/${cw[index].messages[0].timestamp}`).then((response) => {
      cw[index].last_timestamp = cw[index].messages[0].timestamp
      const messages = (response.data && response.data.messages) ? response.data.messages.map(r => {
        if (r.receiver == cw[index].id) {
          return {
            message: r.message,
            sender: r.sender,
            to: r.receiver,
            timestamp: r.send_date,
            type: "chat"
          }
        } else {
          return {
            message: r.message,
            sender: r.sender,
            from: r.sender,
            timestamp: r.send_date,
            type: "chat"
          }
        }
      }) : [];

      cw[index].messages = messages.concat(cw[index].messages);
      this.setState({
        ...this.state,
        chatWindows: cw,
      });
    });
    
  }

  render() {

    
    return (
      <Fragment>
        <div ref={this.leftChatRef} className={`ChatList animate__animated ${this.state.window.open ? 'animate__slideInRight' : 'd-none'}`}>
          <div className={`list scroll-bar`}>
            <div className='d-flex justify-content-between align-items-center w-100'>
              <h4 className="fw-700 text-grey-900 font-xsss mt-1">{i18n.t('messages')}</h4>
              {/* <Tooltip title={i18n.t('readall')} placement='top'> */}
                <i onClick={()=>this.ReadItAll()} className="cursor-pointer ti-check-box font-xss readit"></i>
              {/* </Tooltip> */}
            </div>
            <input type="text" placeholder={i18n.t('page_search_input_placeholder')} 
              className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w-100 theme-dark-bg" value="">
            </input>
            {
              this.state.contacts.map((contact, i) =>
                {
                  const last_msg = contact.last_message
                  return (
                    <div
                      key={i}
                      className={`item`}
                      onClick={() => this.openChatWindow(contact.id)}
                    >
                      <div 
                        className="avatar"
                        style={{backgroundImage: `url('${contact.profile_image}')`}}
                      ></div>
                      
                      <div className={`name ${contact.status ? 'active-member' : ''} ${( last_msg && (parseInt(last_msg.read) == 0 && last_msg.read != null) ) ? 'notread' : ''}`}>
                        <span>{contact.name}</span>
                        <h6 className='text-grey-500 fw-500 font-xssss lh-4 elipsi'>
                          {last_msg ? last_msg.message : ''}
                          <span className='msg-time'>{last_msg && last_msg.send_date ?
                          Utils.getMomentText(moment(last_msg.send_date).format("X")) : ''
                          }</span>
                        </h6>
                      </div>
                    </div>
                  )
                }
              )
            }
          </div>
          <div className='seeall'>
            <Link to='/messages'>{i18n.t('seeallmsg')}</Link>
          </div>
        </div>

        <Chat
          bubbles={this.state.chatWindows}
          onOpenBubble={this.onOpenBubble}
          onCloseBubble={this.onCloseBubble}
          onPrevMessages={this.onPrevMessages}
        />
      </Fragment>
    );
  }
}

export default AppChat;