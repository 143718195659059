import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import i18n from "../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock, faMapLocation } from '@fortawesome/free-solid-svg-icons'
import OpenHousePreview from '../components/OpenHousePreview'
import ModalCompose from "../components/ModalCompose";
import Modal from "../components/Modal";
import * as L from "leaflet";
import '../css/leaflet.css'


class OpenHouses extends Component {
	constructor(props) {
		super(props)

		this.state = {
			showModal: false
		}

		this.mapRef = React.createRef();
	}

	
	initMap() {
		// origin style https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png
		let tileLayer = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png',{
		  attribution: 'Real Status Network',
		});
	
		this.map = L.map(this.mapRef.current, {
		  layers: [tileLayer],
		  maxZoom: 19,
		  minZoom: 5,
		  gestureHandling: true
		});
		const map = this.map;
	
		map.setView([40.59689334945887, 22.956436528835187], 17);
		this.customMarkerIcon = L.icon({
			iconUrl: '/assets/images/open_house.png',
			iconSize: [51, 51],
			iconAnchor: [21, 6]
		});

		this.marker = L.marker([40.59689334945887, 22.956436528835187], {icon: this.customMarkerIcon})
	
		map.addLayer(this.marker);
	  }

	componentDidMount() {
		this.initMap()
	}

	render() {
		return (
			<Fragment> 
				<Header />
				<Leftnav />
				<Rightchat />

				<div className="main-content right-chat-active">
					<div className="middle-sidebar-bottom">
						<div className="middle-sidebar-left pe-0">
							<div className="row">
								<div className="col-xl-8 col-xxl-9 col-lg-8">
									{
										Array.from(Array(10).keys()).map(a => <>
											<OpenHousePreview 
												showModal={() => {
													this.setState({
														...this.state,
														showModal: true
													}, () => {this.map.invalidateSize()})
												}}
											/>
										</>)
									}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					show={this.state.showModal}
					handleClose={() => this.setState({
						...this.state,
						showModal: false
					})}
				>
					<div className="card w-100 border-0 p-4 mb-3">
						<div className='open-house-photo'>
							<img className="rounded-4" src={`https://realstatus.gr/clients/v2/images/properties/108-1203520.jpg`} />
						</div>
						<div className="card-body mt-2 p-0 d-flex">
							<div className='open-house-info'>
								<div className="d-flex space-between">
									<h4>
										<span className="title-icon"><FontAwesomeIcon icon={faCalendarAlt} /></span> <span className="title-data font-xsss">15/10/22</span>
									</h4>
									<h4>
										<span className="title-icon"><FontAwesomeIcon icon={faClock} /></span> <span className="title-data font-xsss">09:00 - 18:00</span>
									</h4>
									<h4>
										<span className="title-icon"><FontAwesomeIcon icon={faMapLocation} /></span> <span className="title-data font-xsss">Σκιάθου 2, Ντεπώ</span>
									</h4>
								</div>
								
								<p className="fw-500 text-grey-900 font-xsss w-100 mb-2">
									Το Σάββατο 15/10 σας προσκαλούμε να εξερευνήσετε μια κατοικία που μπορεί να γίνει το σπίτι σας
								</p>
							</div>
						</div>
						<div className="card-body mt-2 p-0">
							<h4>Χάρτης</h4>
							<div className="open-house-map" ref={this.mapRef}></div>
						</div>
					</div>
				</Modal>
				<Popupchat />
				<Appfooter /> 
			</Fragment>
		)
	}
}

export default OpenHouses